import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import {
  PreorderSummaryParams,
  PreorderSummaryDTO,
  SetPreorderSummaryParams,
  MatchedRequestStatusType,
  SetMatchedRequestStatusAndNote,
  EditPreorderParams,
  VatRate,
} from '@/features/shared/truck-offering/types';
import { STORE_ID } from '@/store';
import CarrierPreorderMapper from '@/features/srm/truck-offering/mappers/CarrierPreorderMapper';
import {
  Nullable,
  Page,
  PageParams,
  TrailerVolumeType,
} from '@/features/shared/types';
import CarrierPreorderSharedModule, { CARRIER_PREORDER_SHARED_MODULE_ID } from '@/features/shared/truck-offering/store';
import CarrierPreorderService, {
  CARRIER_PREORDER_SERVICE_ID,
} from '@/features/srm/truck-offering/services/CarrierPreorderService';
import { TruckOfferingForm } from '@/features/srm/truck-offering/types';

export const CARRIER_PREORDER_MODULE_ID = 'carrier-preorder';

@Module
@Injectable()
export default class CarrierPreorderModule extends VuexModule {
  @LazyInject(CARRIER_PREORDER_SERVICE_ID) carrierPreorderService: CarrierPreorderService;

  @LazyInject(CARRIER_PREORDER_SHARED_MODULE_ID) carrierPreorderSharedModule: CarrierPreorderSharedModule;

  preorderSummary: Nullable<Page<PreorderSummaryDTO>> = null;

  searchQuery: Nullable<string> = null;

  matchedRequestStatusTypes: MatchedRequestStatusType[] = [];

  truckOffering: Nullable<TruckOfferingForm> = null;

  constructor(
    @Inject(STORE_ID) store: Store<unknown>,
  ) {
    super({ name: CARRIER_PREORDER_MODULE_ID, store });
  }

  getTrailerVolumeTypes(): TrailerVolumeType[] {
    return this.carrierPreorderSharedModule.trailerVolumeTypes;
  }

  vatRatesTypes(): VatRate[] {
    return this.carrierPreorderSharedModule.vatRatesTypes;
  }

  @Action
  async savePreorder(params: TruckOfferingForm): Promise<void> {
    const data = CarrierPreorderMapper.toRequest(params);
    return this.carrierPreorderService.createPreorder(data);
  }

  @Action
  async editPreorder({ params, preorderId }: EditPreorderParams): Promise<void> {
    const data = CarrierPreorderMapper.toRequest(params);
    await this.carrierPreorderService.editPreorder(data, preorderId);
  }

  @Action
  async closePreorder(preorderId: number): Promise<void> {
    return this.carrierPreorderService.closePreorder(preorderId);
  }

  @Action
  async deletePreorder(preorderId: number): Promise<void> {
    return this.carrierPreorderService.deletePreorder(preorderId);
  }

  @Action
  async loadTrailerVolumeTypes(): Promise<void> {
    await this.carrierPreorderSharedModule.loadTrailerVolumeTypes();
  }

  @Action
  async getVatRateTypes(): Promise<void> {
    await this.carrierPreorderSharedModule.getVatRateTypes();
  }

  @Action
  async loadPreorderSummary(params: PreorderSummaryParams): Promise<void> {
    const page = await this.carrierPreorderSharedModule.getPreorderSummary(params);
    this.setPreorderSummary({ page, isSavePrevItems: params.isSavePrevItems });
  }

  @Action
  async loadPreorderPage(params: PageParams): Promise<void> {
    const page = await this.carrierPreorderSharedModule.getPreorderPage(params);
    this.setPreorderSummary({ page, isSavePrevItems: false });
  }

  @Action
  async preorderSearchQuery(search: Nullable<string>): Promise<void> {
    this.setPreorderSearchQuery(search);
  }

  @Action
  async getPreorderMatchedRequestStatuses(): Promise<void> {
    if (!this.matchedRequestStatusTypes.length) {
      const types = await this.carrierPreorderService.getStatusTypes();
      this.setMatchedRequestStatusTypes(types);
    }
  }

  @Action
  async setPreorderStatusAndNote(data: SetMatchedRequestStatusAndNote): Promise<void> {
    return this.carrierPreorderService.setPreorderStatusAndNote(data);
  }

  @Action
  async loadPreorderById(preorderId: number): Promise<void> {
    const response = await this.carrierPreorderService.getPreorderById(preorderId);
    this.setTruckOffering(CarrierPreorderMapper.fromResponse(response));
  }

  @Mutation
  setPreorderSummary({ page, isSavePrevItems }: SetPreorderSummaryParams): void {
    const prevItems = this.preorderSummary?.content ?? [];
    this.preorderSummary = page;
    if (isSavePrevItems) {
      this.preorderSummary.content = [...prevItems, ...page.content];
    }
  }

  @Mutation
  setPreorderSearchQuery(search: Nullable<string>): void {
    this.searchQuery = search;
  }

  @Mutation
  setMatchedRequestStatusTypes(statuses: MatchedRequestStatusType[]): void {
    this.matchedRequestStatusTypes = statuses;
  }

  @Mutation
  setTruckOffering(truckOffering: TruckOfferingForm): void {
    this.truckOffering = truckOffering;
  }
}

container
  .bind<CarrierPreorderModule>(CARRIER_PREORDER_MODULE_ID)
  .to(CarrierPreorderModule)
  .inSingletonScope();
