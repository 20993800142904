import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_modal = _resolveComponent("ui-modal")!

  return (_openBlock(), _createBlock(_component_ui_modal, {
    target: $props.target,
    "close-on-click-overlay": $props.closeOnClickOverlay,
    "overlay-color": $props.overlayColor,
    "z-index": $props.zIndex,
    onClose: $options.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({
        [_ctx.$style.main]: true,
        [_ctx.$style.isCentered]: $props.isCentered,
        [_ctx.$style.isFullWidth]: $props.isFullWidth,
      })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass($options.containerClasses),
          style: _normalizeStyle({ width: `${$props.width}px`, ...$options.customStyles }),
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          ($props.showClose)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                "aria-label": "Close",
                class: _normalizeClass(_ctx.$style.closeContainer),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClose && $options.onClose(...args)))
              }, [
                _createVNode(_component_svg_icon, { name: "close_light" })
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.header)
              }, [
                _renderSlot(_ctx.$slots, "header")
              ], 2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.$slots.footer)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.$style.footer)
              }, [
                _renderSlot(_ctx.$slots, "footer")
              ], 2))
            : _createCommentVNode("", true)
        ], 6)
      ], 2)
    ]),
    _: 3
  }, 8, ["target", "close-on-click-overlay", "overlay-color", "z-index", "onClose"]))
}