import {
  FilterValue,
  Region,
} from '@/features/srm/types';

export default class RegionsMapper {
  static fromResponse(regions: Region[]): FilterValue[] {
    return regions.map((region) => ({
      id: region.id,
      filter: region.name,
    }));
  }
}
