export enum LoyaltyProgramTabs {
  Balance = 'balance',
  History = 'history',
}

export enum CashbackOperation {
  Apply = 'APPLY',
  Burn = 'BURN',
  Accrual = 'ACCRUAL',
}

export enum CashbackType {
  Order = 'ORDER',
  Welcome = 'WELCOME',
  Present = 'PRESENT',
}

export enum HoldTypes {
  Nothing = 'NOTHING',
  Welcome = 'WELCOME',
  IncreasePrice = 'INCREASE_PRICE',
}

export enum RejectReason {
  HasHold = 'HAS_HOLD',
  UserNotFound = 'USER_NOT_FOUND',
  UsersMoreThanOne = 'USERS_MORE_THAN_ONE',
  TrucksMoreThan100 = 'TRUCKS_MORE_THAN_100 ',
}

export const getTabName = (tab: LoyaltyProgramTabs | string): string => {
  switch (tab) {
    case LoyaltyProgramTabs.Balance:
      return 'Ваш счет';
    case LoyaltyProgramTabs.History:
      return 'История операций';
    default: return 'Ваш счет';
  }
};
