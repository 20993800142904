import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "width", "height"]
const _hoisted_2 = {
  key: 1,
  style: {"max-width":"100%"}
}
const _hoisted_3 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!$data.clicked)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.imgFrame),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.clicked = true))
        }, [
          _createElementVNode("img", {
            src: $props.imgFrameUrl,
            alt: "video",
            width: $props.width,
            height: $props.height,
            style: {"cursor":"pointer"}
          }, null, 8, _hoisted_1),
          _createVNode(_component_svg_icon, {
            name: "playVideo",
            "view-box": "0 0 24 24"
          })
        ], 2))
      : _createCommentVNode("", true),
    ($data.clicked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("iframe", {
            title: "Video",
            src: $props.videoUrl,
            allowfullscreen: "allowfullscreen",
            width: $props.width,
            height: $props.height,
            frameborder: 0
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
  ]))
}