
import {
  defineComponent,
  onMounted,
  computed,
} from 'vue';
import container from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { useCarrierInfo } from '@/features/redesigned/srm/composable/carrier-info';

export default defineComponent({
  name: 'EmptyForTalkMe',

  setup() {
    const configService = computed<ConfigService>(() => container.get(CONFIG_SERVICE_ID));

    onMounted(() => {
      const id: string = configService.value.talkMeScriptId;
      if (!id) return;

      (function c(d, w, m, i) {
        window.supportAPIMethod = m;
        const s = d.createElement('script');
        s.id = 'supportScript';

        s.src = `${
          !i ? 'https://lcab.talk-me.ru/support/support.js'
            : 'https://static.site-chat.me/support/support.int.js'}?h=${id}`;
        s.onerror = i ? undefined : function () { c(d, w, m, true); };
        w[m] = w[m] || function () { (w[m].q = w[m].q || []).push(arguments); };
        (d.head || d.body).appendChild(s);
      }(document, window, 'TalkMe'));

      const { user } = useCarrierInfo();

      const userName = computed<string>(() => (user.value ? `${user.value.lastName} ${user.value.firstName}` : ''));

      window.TalkMe('setClientInfo', {
        name: userName.value,
        phone: `${user.value?.numericPhoneNumber}`,
        email: user.value?.email,
      });
      window.TalkMe('reload');
    });

    return {};
  },
});
