
import { PropType } from 'vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';

export default {
  name: 'AppSidebarButton',

  components: {
    SvgIcon,
  },

  props: {
    type: {
      type: String as PropType<string>,
      default: 'router-link',
    },

    to: {
      type: Object as PropType<Nullable<Location>>,
      default: null,
    },

    target: {
      type: String as PropType<string>,
      default: '_self',
    },

    icon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    additionalIcon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    caption: {
      type: String as PropType<string>,
      required: true,
    },

    includedRoutes: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },

    isMarked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    isBlink: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    dataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    svgDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    textDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    svgUseDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },
  },

  computed: {
    isActive(): boolean {
      return this.includedRoutes.includes(this.$route?.name as string);
    },

    classes(): Styles {
      const { $style } = this;

      return {
        [$style.menuItem]: true,
        [$style.isActive]: this.isActive,
        [$style.isMarked]: this.isMarked,
        [$style.isBlink]: this.isBlink,
        [$style.isWide]: this.isWide,
      };
    },
  },
};
