/**
 * Форматирование цены
 * 77848.26666 => 77 848,27
 */
export const formatPrice = (
  value: number,
  currency = 'RUB',
  maximumFractionDigits?: number,
): string => {
  if (!Number.isFinite(value)) {
    return '';
  }

  const d = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });

  return d.format(value);
};

export const formatPriceWithoutCurrency = (
  value: number, minimumFractionDigits?: number,
): string => {
  if (!Number.isFinite(value)) {
    return '';
  }

  const d = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: minimumFractionDigits !== undefined ? minimumFractionDigits : 2,
  });

  return d.format(value);
};

export const formatPriceSimple = (
  value: number,
): string => {
  if (!Number.isFinite(value)) {
    return '';
  }

  const d = new Intl.NumberFormat(undefined, {
    style: 'decimal',
  });

  return d.format(value);
};

export default { formatPrice, formatPriceWithoutCurrency, formatPriceSimple };
