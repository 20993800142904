import container, {
  Inject,
  Injectable,
} from '@/di';
import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import {
  CounterpartyModel,
  OrgExistenceDTO,
  OrgModel,
  OrgsDTO,
} from '@/features/srm/orgs/services/OrgService.types';
import {
  OperationResponse,
} from '@/features/srm/types';
import {
  DictionaryModel,
  Page,
  Carrier,
} from '@/features/shared/types';
import {
  API_CARRIER_COUNTERPARTY_NEW,
  API_CHANGE_CARRIER_STATUS,
  API_CONTACT,
  API_COUNTERPARTY_CHECK,
} from '@/features/srm/orgs/services/endpoints';

export const SRM_ORG_SERVICE_ID = Symbol('SRMOrgService');

@Injectable()
export default class SRMOrgService {
  srmService: SRMService;

  constructor(
    @Inject(SRM_SERVICE_ID) srmService: SRMService,
  ) {
    this.srmService = srmService;
  }

  /**
   * Получить списка организаций для определенного типа сущности
   */
  getOrgs(params: OrgsDTO): Promise<Page<OrgModel>> {
    return this.srmService.request({
      method: 'GET',
      url: API_CARRIER_COUNTERPARTY_NEW,
      params,
    });
  }

  /**
   * Удалить контакт организации
   */
  deleteAdditionalContact(contactId: string): Promise<void> {
    return this.srmService.request({
      method: 'DELETE',
      url: API_CONTACT,
      params: {
        contactId,
      },
    });
  }

  /**
   * Обновление статуса перевозчика
   */
  updateStatusOrg(org: { id: string; statusId: number }): Promise<OperationResponse<Carrier>> {
    return this.srmService.request({
      method: 'PUT',
      url: API_CHANGE_CARRIER_STATUS,
      data: org,
    });
  }

  checkOrgExistence(params: OrgExistenceDTO): Promise<CounterpartyModel> {
    return this.srmService.request({
      method: 'POST',
      url: API_COUNTERPARTY_CHECK,
      data: params,
    });
  }

  /**
   * Получение статусов организации
   */
  // eslint-disable-next-line class-methods-use-this
  getOrgStatuses(): Promise<DictionaryModel[]> {
    const STATUSES = [
      {
        id: -1,
        name: 'Не заполнен',
      },
      {
        id: 0,
        name: 'Неизвестно',
      },
      {
        id: 1,
        name: 'Новый',
      },
      {
        id: 2,
        name: 'Согласование',
      },
      {
        id: 3,
        name: 'Согласован',
      },
      {
        id: 4,
        name: 'Отклонен',
      },
      {
        id: 11,
        name: 'Не проверено на портале',
      },
    ];

    return Promise.resolve(STATUSES);
  }
}

container.bind<SRMOrgService>(SRM_ORG_SERVICE_ID).to(SRMOrgService);
