import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.userName)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.name)
        }, [
          _createVNode(_component_svg_icon, {
            name: "person",
            class: _normalizeClass(_ctx.$style.icon)
          }, null, 8, ["class"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.userName), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.company)
    }, _toDisplayString(_ctx.currentUserCompany), 3)
  ], 2))
}