import { GetUrlWithStringIdParameter } from '@/features/security/types';

export const API_REQUESTS = '/requests';
export const API_REQUESTS_COUNTS = '/requests/counts';
export const API_REQUEST_HISTORY = '/request/history';
export const API_REQUEST_CHANGE_STATUS = '/request/change-status';
export const API_REQUEST_SET_ASSIGNEE = '/request/set-assignee';
export const API_REQUEST_ACCEPT = '/request/accept';
export const API_REQUEST_NO_ACCEPT = '/request/not-accept';
export const API_VEHICLE_SEND_APPROVAL_ID: GetUrlWithStringIdParameter = (id) => `/vehicle/sendApproval/${id}`;
export const API_DRIVER_SEND_APPROVAL_ID: GetUrlWithStringIdParameter = (id) => `/driver/sendApproval/${id}`;
export const API_ID_STATUS_HISTORY = (id: string): string => `/${id}/status-history`;
export const API_ORDER_CONTRACT_ID = (id: string): string => `/order-contract/${id}`;
export const API_PHONE_CHECK_HISTORY = (id: string): string => `/express-checker/checkPhone/history/${id}`;
export const API_DRIVER_ID = (id: string): string => `/driver/${id}`;
export const API_VEHICLE_ID = (id: string): string => `/vehicle/${id}`;
export const API_VEHICLE_PATCH = (id: string): string => `/request/${id}/change`;
export const API_CARRIER_ID = (id: string): string => `/carrier/${id}`;
export const API_CHECK_PHONE = (id: string): string => `/express-checker/checkPhone/${id}`;
export const API_ORDER_ID = (id: string): string => `/order/${id}`;
export const API_COUNTERPARTY = '/counterparty';
export const API_COUNTERPARTY_ID_ORDER_HISTORY = (id: string): string => `/counterparty/${id}/order-history`;
export const API_COUNTERPARTY_ID_SC_CHANGE = (id: string): string => `/counterparty/${id}/sc-change`;
export const API_COUNTERPARTY_ID_SC_HISTORY = (id: string): string => `/counterparty/${id}/sc-history`;
export const API_COUNTERPARTY_ID_COUNTS = (id: string): string => `/counterparty/${id}/counts`;
export const API_COUNTERPARTY_ID = (id: string): string => `/counterparty/${id}`;
export const API_COUNTERPARTY_ID_DRIVER = (id: string): string => `/counterparty/${id}/driver`;
export const API_COUNTERPARTY_ID_VEHICLE = (id: string): string => `/counterparty/${id}/vehicle`;
export const API_EXPRESS_CHECKER_CHECK_PASSPORT_SERIAL_NUMBER = (serialNumber: string): string => (
  `/express-checker/checkPassport/${serialNumber}`
);
export const API_EXPRESS_CHECKER_CHECK_PASSPORT_SERIAL_NUMBER_HISTORY = (serialNumber: string): string => (
  `/express-checker/checkPassport/${serialNumber}/history`
);
export const API_EXPRESS_CHECKER_CHECK_OSAGO_HISTORY = (id: string): string => `/express-checker/checkOsago/history/${id}`;
export const API_EXPRESS_CHECKER_CHECK_FSSP = (id: string): string => `/express-checker/checkFssp/${id}`;
export const API_EXPRESS_CHECKER_CHECK_FSSP_HISTORY = (id: string): string => `/express-checker/checkFssp/history/${id}`;
export const API_EXPRESS_CHECKER_CHECK_CARRIER = '/express-checker/checkCarrier';
export const API_EXPRESS_CHECKER_REPORT_COMMENTS = '/express-checker/report/comments';
export const API_EXPRESS_CHECKER_REPORT_HISTORY = '/express-checker/report/history';
export const API_EXPRESS_CHECKER_REPORT = '/express-checker/report';
export const API_EXPRESS_CHECKER_REPORT_LAST = '/express-checker/report/last';
export const API_EXPRESS_CHECKER_CHECK_VEHICLE = '/express-checker/checkVehicle';
export const API_EXPRESS_CHECKER_SETTINGS = '/express-checker/settings';
export const API_EXPRESS_CHECKER_SETTINGS_CARGO = '/express-checker/settings/cargo';
export const API_EXPRESS_CHECKER_CHECK_OSAGO = '/express-checker/checkOsago';
export const API_SPECTRUM_INDIVIDUAL = '/spectrum/individual';
export const API_SPECTRUM_VEHICLE = '/spectrum/vehicle';
export const API_SPECTRUM_STATISTIC = '/spectrum/statistic';
export const API_SPECTRUM_HISTORY = '/spectrum/history';
export const API_KFOCUS_REQUEST_TYPE = '/kfocus/request-type';
export const API_KFOCUS_CHECK = '/kfocus/check';
export const API_STATUS = '/status';
export const API_GET_STATUS_LIST = 'order/statuses';
export const API_DOCUMENT_TYPE = 'document-type';
export const API_FILE_TYPE = 'file-type';
export const API_DOCUMENT_FILE_ID = (id: string): string => `/document/file/${id}`;
export const API_DOCUMENT_ID = (id: string): string => `/document/${id}`;
export const API_GET_DRIVER_DOUBLES = (id: string): string => `/driver/${id}/doubles`;
export const API_GET_COUNTERPARTY_AUTO_CHECK = (id: string): string => `/carrier/${id}/autocheck/result`;
export const API_GET_DRIVER_AUTO_CHECK = (id: string): string => `/driver/${id}/autocheck/result`;
export const API_GET_VEHICLE_DOUBLES = (id: string): string => `/vehicle/${id}/doubles`;
export const API_GET_VEHICLE_AUTO_CHECK = (id: string): string => `/vehicle/${id}/autocheck/result`;
export const API_TRAILER_TYPE = 'trailer-type';
export const API_PHONE_VALIDITY_SOURCE = '/express-checker/checkPhone/sources';
export const API_CONTACT = '/contact';
export const API_COUNTRY = '/country';
export const API_GET_FILTERED_COUNTERPARTY_LIST = '/carrier/filter';
export const API_GET_USER_BY_CARRIER = '/usersByCarrier';
export const API_EXPRESS_CHECKER_CHECK_DRIVER_LICENSE = '/express-checker/checkDriverLicense';
export const API_EXPRESS_CHECKER_CHECK_DRIVER_LICENSE_HISTORY = '/express-checker/checkDriverLicense/history';
