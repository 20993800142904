import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { ref: "trigger" }
const _hoisted_2 = { ref: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "trigger")
    ], 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle($props.customStylesContainer),
        class: _normalizeClass([$options.contentWrapperStyles])
      }, [
        (_ctx.$slots.title)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.title)
            }, [
              _renderSlot(_ctx.$slots, "title")
            ], 2))
          : _createCommentVNode("", true),
        ($props.arrow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass($options.arrowStyles)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.arrowBorder)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.arrowInner)
                }, null, 2)
              ], 2)
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default"),
        ($options.isCloseEnabled)
          ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 2,
              class: _normalizeClass(_ctx.$style.closeIcon),
              name: "close_light",
              onClick: $options.onClickClose
            }, null, 8, ["class", "onClick"]))
          : _createCommentVNode("", true)
      ], 6)
    ], 512)
  ]))
}