export const API_SUBSCRIPTION = 'order-subscription';
export const API_USER = '/user';
export const API_GET_CURRENT_USER = '/user/current';
export const API_GET_VERSION = '/';
export const API_GET_USER_BY_CARRIER = '/usersByCarrier';
export const API_GET_USER_BY_ROLE = '/usersInRole';
export const USER_COUNTERPARTY_REL = '/userCounterpartyRel';

export const API_USER_ACTION_BY_ID = (userId: string): string => `/user/${userId}`;
export const API_USER_NOTIFICATION_FLAG_BY_ID = (userId: string): string => `/user/${userId}/notification-flag`;
export const API_SUBSCRIPTION_ACTION_BY_ID = (id: string): string => `order-subscription/${id}`;
export const SET_USER_ROLE = (userId: string, role: string, value: string | boolean):
  string => `/user/${userId}/roles/${role}/${value}`;
export const API_LINK_USER_TO_COUNTERPARTY = (emailOrPhone: string, counterpartyId: string):
  string => `/user/${emailOrPhone}/linkToCounterparty/${counterpartyId}`;
