import { AxiosRequestConfig } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { required } from '@vuelidate/validators';
import container, {
  Inject,
  Injectable,
} from '@/di';
import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import {
  DocumentsDTO,
  DocumentValidation,
} from '@/features/srm/documents/services/DocumentService.types';
import {
  DictionaryModel,
  DocumentFileModel,
  DocumentModel,
  KeyValue,
} from '@/features/shared/types';
import {
  API_DOCUMENT,
  API_DOCUMENT_FILE,
  API_DOCUMENT_FILE_ID,
  API_DOCUMENT_ID,
} from '@/features/srm/documents/services/endpoints';
import {
  DocumentFile,
  OperationResponse,
} from '@/features/srm/types';
import {
  fieldWithFallback,
  Gender,
} from '@/filters/empty';
import { formatDate } from '@/filters/date';
import { getFileTypeFromFileName } from '@/utils/files';
import { DocumentType } from '@/features/shared/constant';

export const SRM_DOCUMENT_SERVICE_ID = Symbol('SRMDocumentService');

@Injectable()
export default class SRMDocumentService {
  constructor(
    @Inject(SRM_SERVICE_ID) private srmService: SRMService,
    @Inject(CONFIG_SERVICE_ID) private configService: ConfigService,
  ) {}

  getDocuments(params: DocumentsDTO): Promise<DocumentFile[]> {
    return this.srmService.request({
      timeout: this.configService.fileUploadTimeout,
      method: 'GET',
      url: API_DOCUMENT,
      params,
    });
  }

  getDocumentFile(fileId: string): Promise<DocumentFile> {
    return this.srmService.request({
      timeout: this.configService.fileUploadTimeout,
      method: 'GET',
      url: API_DOCUMENT_FILE_ID(fileId),
    });
  }

  addDocument(
    document: DocumentFile,
    options: AxiosRequestConfig = {},
  ): Promise<OperationResponse<DocumentFile>> {
    return this.srmService.request({
      timeout: this.configService.fileUploadTimeout,
      method: 'POST',
      url: API_DOCUMENT,
      data: document,
      ...options,
    });
  }

  editDocument(
    document: DocumentFile,
    options: AxiosRequestConfig = {},
  ): Promise<OperationResponse<DocumentFile>> {
    return this.srmService.request({
      timeout: this.configService.fileUploadTimeout,
      method: 'PATCH',
      url: API_DOCUMENT,
      data: document,
      ...options,
    });
  }

  removeDocumentFile(documentId: string): Promise<OperationResponse<string>> {
    return this.srmService.request({
      timeout: this.configService.fileUploadTimeout,
      method: 'DELETE',
      url: API_DOCUMENT_FILE_ID(documentId),
    });
  }

  removeDocument(documentId: string): Promise<OperationResponse<string>> {
    return this.srmService.request({
      method: 'DELETE',
      url: API_DOCUMENT_ID(documentId),
    });
  }

  addFileToDocument(files: DocumentFile): Promise<DocumentFile> {
    return this.srmService.request({
      method: 'POST',
      url: API_DOCUMENT_FILE,
      data: files,
    });
  }

  getDocument(documentId: string): Promise<DocumentFile> {
    return this.srmService.request({
      method: 'GET',
      url: API_DOCUMENT_ID(documentId),
      params: {
        includeThumbnail: true,
      },
    });
  }

  getDocumentTypes(): Promise<DictionaryModel[]> {
    const DOCUMENT_TYPES = [
      {
        id: 0,
        name: 'ПТС',
      },
      {
        id: 1,
        name: 'СТС',
      },
      {
        id: 2,
        name: 'Договор аренды',
      },
      {
        id: 16,
        name: 'Водительское удостоверение',
      },
      {
        id: 21,
        name: 'Паспорт',
      },
      {
        id: 129,
        name: 'Доверенность',
      },
      {
        id: 130,
        name: 'Экспедиторская расписка',
      },
      {
        id: 131,
        name: 'Заявка с перевозчиком',
      },
      {
        id: 132,
        name: 'Заявка с клиентом',
      },
      {
        id: 1000,
        name: 'Свидетельство о постановке на учет в налоговом органе',
      },
      {
        id: 1001,
        name: 'Свидетельство о государственной регистрации',
      },
      {
        id: 1002,
        name: 'Протокол общего собрания о выборе генерального директора юридического лица',
      },
      {
        id: 1003,
        name: 'Приказ о назначении директора',
      },
      {
        id: 1004,
        name: 'Паспорт генерального директора',
      },
      {
        id: 1005,
        name: 'Учредительный протокол юридического лица',
      },
      {
        id: 1006,
        name: 'Устав юридического лица',
      },
      {
        id: 1007,
        name: 'Бухгалтерский баланс за последний отчетный период',
      },
      {
        id: 1008,
        name: 'Отчет о прибылях и убытках за последний отчетный период',
      },
      {
        id: 1009,
        name: 'Декларация по НДС за последний отчетный период',
      },
      {
        id: 1010,
        name: 'Полис страхования ответственности перевозчика\\экспедитора',
      },
      {
        id: 1011,
        name: 'Другие документы',
      },
      {
        id: 8800,
        name: 'Гарантийное обязательство',
      },
      {
        id: 10001,
        name: 'Договор аренды ТС',
      },
      {
        id: 10002,
        name: 'Акт приема передачи ТС',
      },
      {
        id: 10003,
        name: 'Договорная стоимость арендной платы',
      },
      {
        id: 10004,
        name: 'Прочие документы',
      },
    ];

    return Promise.resolve(DOCUMENT_TYPES);
  }

  getDocumentValidations(document: DocumentFile): DocumentValidation {
    const SERIES_REQUIRED_TYPES = [DocumentType.Passport, DocumentType.DrivingLicense];
    const NUMBER_REQUIRED_TYPES = [DocumentType.Passport, DocumentType.DrivingLicense];
    const ISSUED_DATE_REQUIRED_TYPES = [DocumentType.Passport, DocumentType.DrivingLicense];
    const ISSUED_BY_REQUIRED_TYPES = [DocumentType.Passport, DocumentType.DrivingLicense];
    return {
      series: SERIES_REQUIRED_TYPES.includes(document.typeId) ? { required } : {},
      number: NUMBER_REQUIRED_TYPES.includes(document.typeId) ? { required } : {},
      issuedDate: ISSUED_DATE_REQUIRED_TYPES.includes(document.typeId) ? { required } : {},
      issuedBy: ISSUED_BY_REQUIRED_TYPES.includes(document.typeId) ? { required } : {},
      typeId: {},
      documentFiles: {},
    };
  }

  setDocumentIsOld(files: DocumentModel, isUploaded: boolean): DocumentFileModel[] {
    return files.documentFiles.map((item) => {
      const element = item;
      element.uploaded = isUploaded;
      return element;
    });
  }

  getDocumentPreview(document: DocumentModel): KeyValue[] {
    const isDocumentEmpty = [
      document.number,
      document.series,
      document.issuedDate,
      document.issuedBy,
    ].every((entity) => isEmpty(entity));

    if (isDocumentEmpty) {
      return [];
    }

    return [
      {
        key: 'Серия',
        value: fieldWithFallback(document.series, Gender.FEMALE),
      },
      {
        key: 'Номер',
        value: fieldWithFallback(document.number),
      },
      {
        key: 'Дата выдачи',
        value: fieldWithFallback(formatDate(document.issuedDate as string), Gender.FEMALE),
      },
      {
        key: 'Подразделение, выдавшее документ',
        value: fieldWithFallback(document.issuedBy, Gender.NEUTRAL),
      },
    ];
  }

  mergeDocuments(document: DocumentModel, storedDocuments: DocumentModel[]): DocumentModel {
    const currentDocument = storedDocuments.find(({ typeId }) => typeId === document.typeId);

    if (!currentDocument) {
      return document;
    }

    let documentFiles: DocumentFileModel[] = [];

    if (currentDocument.documentFiles) {
      documentFiles = currentDocument.documentFiles.map(
        ({
          fileKindId, fileName, thumbnail, id,
        }) => ({
          id: fileKindId as number,
          status: 3,
          name: fileName,
          size: null,
          thumbnail,
          fileId: id,
          type: getFileTypeFromFileName(fileName as string) as string,
        }),
      );
    }

    return {
      ...document,
      id: currentDocument.id,
      series: currentDocument.series,
      number: currentDocument.number,
      issuedDate: currentDocument.issuedDate
        ? new Date(currentDocument.issuedDate)
        : undefined,
      issuedBy: currentDocument.issuedBy,
      documentFiles,
    };
  }
}

container.bind<SRMDocumentService>(SRM_DOCUMENT_SERVICE_ID).to(SRMDocumentService);
