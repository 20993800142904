import container, { Injectable } from '@/di';
import { Nullable } from '@/features/shared/types';
import { ScrollBehavior } from '@/features/shared/constant';

export const WINDOW_SERVICE_ID = Symbol('windowService');

@Injectable()
export default class WindowService {
  readonly content: Nullable<HTMLElement>;

  readonly defaultScrollToOptions: ScrollToOptions = {
    behavior: ScrollBehavior.Smooth,
    top: 0,
  };

  constructor() {
    this.content = global.document.querySelector('#content');
  }

  scrollContentTop(options?: ScrollToOptions): void {
    if (this.content) {
      this.scrollTop(this.content, { ...this.defaultScrollToOptions, ...options });
    }
  }

  private scrollTop(element: HTMLElement, options: ScrollToOptions): void {
    element.scrollTo(options);
  }
}

container
  .bind<WindowService>(WINDOW_SERVICE_ID)
  .to(WindowService);
