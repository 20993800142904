import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = ["id", "value", "disabled", "data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    "data-qa": $props.dataQa,
    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.isHavePrependedContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.prepend)
        }, [
          _renderSlot(_ctx.$slots, "prepend", {}, () => [
            _createVNode(_component_svg_icon, {
              class: _normalizeClass(_ctx.$style.leftIcon),
              name: _ctx.leftIcon,
              "svg-use-data-qa": $props.searchSvgUseDataQa
            }, null, 8, ["class", "name", "svg-use-data-qa"])
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.control)
    }, [
      _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
        id: $options.internalId,
        ref: "input",
        "data-testid": "UiInputText",
        class: $options.inputClasses,
        value: $options.inputValue,
        disabled: _ctx.disabled,
        "data-qa": $props.inputDataQa,
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onInput && $options.onInput(...args))),
        onAccept: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onAccept && $options.onAccept(...args))),
        onComplete: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.onComplete && $options.onComplete(...args))),
        onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args)))
      }), null, 16, _hoisted_2), [
        [_directive_mask, _ctx.mask]
      ])
    ], 2),
    (_ctx.isHaveAppendedContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.append)
        }, [
          _renderSlot(_ctx.$slots, "append", {}, () => [
            (_ctx.rightIcon)
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  class: _normalizeClass($options.rightIconClasses),
                  name: _ctx.rightIcon,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('clean')))
                }, null, 8, ["class", "name"]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}