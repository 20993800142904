import generateStoreActions from '@/features/crm/store/generator/actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default function generateStore(method) {
  return {
    namespaced: true,

    state: () => ({
      list: [],
      loading: false,
      page: 1,
      totalPages: 1,
    }),

    actions: generateStoreActions(method),
    mutations,
    getters,
  };
}
