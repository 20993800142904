import dayjs, { Dayjs } from 'dayjs';
import { ImageCapture } from 'image-capture';
import {
  VideoSource,
  AxiosResponseError,
  DisplayMediaStreamConstraints,
  SidebarMenuBlockRow,
} from '@/features/shared/types';

/**
 * Возвращает дату со смещением относительно текущего часового пояса
 * @param date - дата
 */
// eslint-disable-next-line import/prefer-default-export
export function getDateForCurrentTimeZone(date: Date | string): Date {
  return dayjs(date).add(dayjs().utcOffset(), 'minute').toDate();
}

/**
 * Возвращает дату в ISO формате без указания часового пояса
 * @param date - дата
 */
export function getISODateWithoutTimeZone(date: Date | string): string {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * Возвращает строку даты в формате YYYY-MM-DD
 * @param date - дата
 * @param isRuFormat - формат даты в русском языке
 */
export function getDateStringWithoutTime(date: Date | string | Dayjs, isRuFormat = false): string {
  return isRuFormat ? dayjs(date).format('DD.MM.YYYY') : dayjs(date).format('YYYY-MM-DD');
}

/**
 * Возвращает номер последнего дня месяца
 * @param year - номер года
 * @param month - номер месяца
 */
export function getLastDateOfMonth(year: number, month: number): number {
  return new Date(year, month + 1, 0).getDate();
}

/**
* Возвращает строку в формате ISO со смещение часового пояса
* @param date - дата
*/
export function toIsoStringWithTimeZoneOffset(date: Date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (number: number) => {
    const norm = Math.floor(Math.abs(number));
    return (norm < 10 ? '0' : '') + norm;
  };

  return `${date.getFullYear()
  }-${pad(date.getMonth() + 1)
  }-${pad(date.getDate())
  }T${pad(date.getHours())
  }:${pad(date.getMinutes())
  }:${pad(date.getSeconds())
  }${dif}${pad(tzo / 60)
  }:${pad(tzo % 60)}`;
}

/**
 * Возвращает текущую таймзону пользователя в формате UTC
 */
export function getUTCOffset(): string {
  const currentDate = new Date().toTimeString();
  /* eslint-disable-next-line no-useless-escape */
  const regGmtOffset = /GMT(\+|\-)\d{4}/i;
  const gmtTimezone = currentDate.match(regGmtOffset) as RegExpMatchArray;
  const sign = gmtTimezone[0].substring(3, 4);
  const offset = Math.floor(parseInt(gmtTimezone[0].slice(4), 10) / 100);

  return `UTC${sign}${offset}`;
}

/**
 * Сохраняет строку в sessionStorage и удаляет из localStorage
 * @param itemType - название записываемого значения
 * @returns {void}
 */
export function saveItem(itemType: string): void {
  if (!window.sessionStorage.getItem(itemType)) {
    const itemContent = window.localStorage.getItem(itemType) as string;
    window.sessionStorage.setItem(itemType, itemContent);
    window.localStorage.removeItem(itemType);
  }
}

/**
 * Создает скриншот текущего лейаута
 * @param routeName — название роута для редиректа
 * @returns {void}
 */
export async function autoScreenshot(routeName: string): Promise<void> {
  const video = document.querySelector('#screenBlock > video') as HTMLVideoElement;

  const displayMediaOptions = {
    video: {
      cursor: 'never',
      displaySurface: 'browser',
    },
    audio: false,
  } as DisplayMediaStreamConstraints;

  try {
    video.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    setTimeout(async () => {
      const videoSrcObject = video.srcObject as VideoSource;
      const videoTrack = videoSrcObject?.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);
      const frame = await imageCapture.grabFrame();
      saveScreenshot(frame, routeName);
    }, 200);
  } catch (e) {
    if (!(e as AxiosResponseError<unknown>).message.endsWith('Permission denied')) {
      window.localStorage.setItem('skip_screenshot', 'true');
      window.open(routeName, '_blank');
    }
    window.self.postMessage('skip:loading', window.location.origin);
  }
}

/**
 * Сохраняет скриншот текущего лейаута в localStorage
 * @param frame — ImageBitmap для вставки в canvas
 * @param routeName — название роута для редиректа
 * @returns {void}
 */
export function saveScreenshot(frame: CanvasImageSource, routeName: string): void {
  const canvas = document.createElement('canvas');
  const ctxCanvas = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctxCanvas.globalCompositeOperation = 'copy';
  canvas.width = 1920;
  canvas.height = 1080;

  ctxCanvas.drawImage(frame, 0, 0, frame.width as number, frame.height as number);

  const dataUrl = canvas.toDataURL();
  ctxCanvas.clearRect(0, 0, canvas.width, canvas.height);

  window.localStorage.setItem('screenshot', dataUrl);
  window.open(routeName, '_blank');
  window.self.postMessage('skip:loading', window.location.origin);
}

/**
 * Конвертирует переданное значение локатора в camelCase
 * @param locator — значение локатора
 * @returns {string}
 */
export function locatorCreator(locator: string): string {
  const simpleLocator = locator.toLowerCase();
  let simpleLocatorSolid: string[];
  if (simpleLocator.search(/_/) !== -1) {
    simpleLocatorSolid = simpleLocator.split('_');
    const simpleLocatorConverted = simpleLocatorSolid.map((item) => {
      const chars = item.split('');
      chars[0] = chars[0].toUpperCase();
      return chars.join('');
    });
    return simpleLocatorConverted.join('');
  }
  simpleLocatorSolid = simpleLocator.split('');
  simpleLocatorSolid[0] = simpleLocatorSolid[0].toUpperCase();

  return simpleLocatorSolid.join('');
}

export function transformBooleanToYesNo(value: boolean): string {
  return value ? 'Да' : 'Нет';
}

export async function Copying(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    console.error(e);
  }
}

export const infoCreator = (
  key: string,
  value: string | SidebarMenuBlockRow[],
  sidebar?: boolean,
): SidebarMenuBlockRow => {
  const info = {} as SidebarMenuBlockRow;

  if (sidebar) {
    info.sidebar = true;
  }

  info.key = key;
  info.value = value;

  return info;
};

export function snakeToPascalCase(input: string): string {
  const words = input.split('_');
  const pascalWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return pascalWords.join('');
}
