import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import WebsocketService from '@/features/shared/services/WebsocketService';

export const SRM_WEBSOCKET_SERVICE_V2_TOPICS_ID = Symbol('srmWebsocketServiceV2Topics');

@Register(
  SRM_WEBSOCKET_SERVICE_V2_TOPICS_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMWebsocketServiceV2Topics extends WebsocketService {
  constructor(configService: ConfigService) {
    super(configService.SRMWebsocketGatewayServiceHostV2);
  }
}

export default SRMWebsocketServiceV2Topics;
