import { ReplenishmentRequestModel } from '@/features/fuel/shared/types';
import { ReplenishmentModel } from '@/features/fuel/statistics/types';
import { formatUTCDate } from '@/filters/date';

export default class ReplenishmentMapper {
  static toRequest(item: ReplenishmentModel): ReplenishmentRequestModel {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

    return {
      id: item.id ?? null,
      clientId: item.clientId ?? null,
      contract: item.contractNumber ?? null,
      amount: item.amount ?? null,
      date: item.date ? formatUTCDate(item.date, dateFormat) : null,
    };
  }
}
