export enum RelationType {
  Unknown = 0,
  ManagerExpedite = 1,
  ResponsibleManagerExpedite = 2,
  ManagerAttract = 3,
  CarrierEmployee = 4,
  ResponsibleCarrierEmployee = 5,
  CarrierEmployeeRequest = 6,
}

export enum NotificationUpdateStatus {
  Loading = 'loading',
  Ready = 'ready',
}

export enum SubscriptionType {
  Icq = 'icq',
  Telegram = 'telegram',
  Email = 'email',
}

export enum SubscriptionTimeType {
  Always = 'always',
  NextWeek = 'nextWeek',
  NextMonth = 'nextMonth',
  Custom = 'custom',
}
