export function SET_LIST(state, list) {
  state.list = [...list];
}

export function SET_LOADING(state, value) {
  state.loading = value;
}

export function SET_PAGE(state, value) {
  state.page = value;
}

export function SET_TOTAL_PAGES(state, value) {
  state.totalPages = value;
}
