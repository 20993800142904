import {
  Container,
  inject,
  decorate,
  injectable,
} from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import { helpers } from 'inversify-vanillajs-helpers';
import { VuexModule } from 'vuex-class-modules';

const container = new Container();

const { lazyInject } = getDecorators(container);
const register = helpers.register(container as Container | any);
const registerConstantValue = helpers.registerConstantValue(container as Container | any);

// External libraries decoration
decorate(injectable, VuexModule);

// Root level ids
const VUE_INSTANCE = Symbol('VUE_INSTANCE');

export {
  VUE_INSTANCE,

  register,
  register as Register,
  inject,
  inject as Inject,
  injectable,
  injectable as Injectable,
  lazyInject,
  lazyInject as LazyInject,
  registerConstantValue,
  registerConstantValue as RegisterConstantValue,
};

export default container;
