
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default {
  name: 'AppHeaderMenuItem',

  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw>,
      required: true,
    },

    isActive: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
};
