import qs from 'query-string';
import {
  Register,
} from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import RemoteService from '@/features/shared/services/base/remote';
import {
  PREORDERS_PAGE,
  PREORDERS_SUMMARY,
  TRAILER_VOLUME_TYPES,
  VAT_RATE_TYPES,
} from '@/features/shared/truck-offering/services/endpoints';
import {
  PreorderSummaryDTO,
  VatRate,
} from '@/features/shared/truck-offering/types';
import {
  Page,
  PageParams,
  TrailerVolumeType,
} from '@/features/shared/types';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const CARRIER_PREORDER_SHARED_SERVICE_ID = Symbol('carrier-preorder-shared-service');

@Register(CARRIER_PREORDER_SHARED_SERVICE_ID, [CONFIG_SERVICE_ID])
export default class CarrierPreorderSharedService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.CarrierPreorderServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  getPreorderPage(params: PageParams): Promise<Page<PreorderSummaryDTO>> {
    return this.request({
      method: 'GET',
      url: PREORDERS_PAGE,
      params,
      paramsSerializer: {
        serialize: (parameters) => qs.stringify(parameters, { skipNull: true }),
      },
    });
  }

  getTrailerVolumeTypes(): Promise<TrailerVolumeType[]> {
    return this.request({
      method: 'GET',
      url: TRAILER_VOLUME_TYPES,
    });
  }

  getPreorderSummary(params: PageParams): Promise<Page<PreorderSummaryDTO>> {
    return this.request({
      method: 'GET',
      url: PREORDERS_SUMMARY,
      params,
      paramsSerializer: {
        serialize: (parameters) => qs.stringify(parameters, { skipNull: true }),
      },
    });
  }

  getVatRateTypes(): Promise<VatRate[]> {
    return this.request({
      method: 'GET',
      url: VAT_RATE_TYPES,
    });
  }
}
