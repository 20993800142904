import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($options.authService.isSecurityManager())
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 0,
          caption: "Рабочий стол",
          icon: "paper",
          to: { name: 'security-requests' },
          "data-qa": "ButtonSidebarSecurityRequests",
          "svg-data-qa": "ButtonSidebarSecurityRequestsIcon",
          "svg-use-qa": "ButtonSidebarSecurityRequestsIconUse",
          "text-data-qa": "ButtonSidebarSecurityRequestsText",
          "included-routes": [
        'security-requests',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.authService.isSecurityManager())
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 1,
          caption: "Контрагенты",
          icon: "briefcase",
          to: { name: 'security-counterparties' },
          "included-routes": [
        'security-counterparties',
        'security-counterparty',
        'security-counterparty-drivers',
        'security-counterparty-vehicles',
        'security-counterparty-history',
        'security-counterparty-auto-check',
        'security-counterparty-documents',
      ],
          "is-wide": $props.isWide,
          "data-qa": "ButtonSidebarSecurityCounterparties",
          "text-data-qa": "ButtonSidebarSecurityCounterpartiesText",
          "svg-data-qa": "ButtonSidebarSecurityCounterpartiesIcon"
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.authService.isSecurityManager())
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 2,
          caption: "Дашборд",
          "data-qa": "ButtonAppSidebarDashboard",
          "svg-data-qa": "ButtonAppSidebarDashboardIcon",
          type: "a",
          icon: "dashboard",
          "icon-after": "open_in_new",
          href: $options.dashboardLink,
          target: "_blank",
          "is-wide": $props.isWide,
          "text-data-qa": "ButtonSidebarDashboardText"
        }, null, 8, ["href", "is-wide"]))
      : _createCommentVNode("", true),
    ($options.authService.isSecurityManager())
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 3,
          caption: "Настройка авто-определения категории",
          icon: "settings",
          to: { name: 'security-definition-settings' },
          "included-routes": [
        'security-definition-settings',
      ],
          "is-wide": $props.isWide,
          "data-qa": "ButtonAppSidebarDetectSettings",
          "text-data-qa": "ButtonSidebarDetectSettingsText",
          "svg-data-qa": "ButtonAppSidebarDetectSettingsIcon"
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.authService.isSecurityManager())
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 4,
          caption: "Настройки определения класса груза",
          icon: "cargo",
          to: { name: 'security-cargo-settings' },
          "included-routes": [
        'security-cargo-settings',
      ],
          "is-wide": $props.isWide,
          "data-qa": "ButtonAppSidebarCargoSettings",
          "text-data-qa": "ButtonSidebarCargoSettingsText",
          "svg-data-qa": "ButtonAppSidebarCargoSettingsIcon"
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true)
  ]))
}