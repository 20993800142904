
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';

import AppHeaderMenuItem from '@/features/shared/layout/AppHeaderMenuItem.vue';
import AppHeaderFuelUser from '@/features/shared/layout/AppHeaderFuelUser.vue';
import AppHeaderCarrierUser from '@/features/shared/layout/AppHeaderCarrierUser.vue';
import AppHeaderManagerExpedition from '@/features/shared/layout/AppHeaderManagerExpedition.vue';
import AppHeaderTaskManagerUser from '@/features/shared/layout/AppHeaderTaskManagerUser.vue';

import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import UiTooltipWrapper from '@/features/shared/kit/UiTooltipWrapper.vue';
import UiTooltip from '@/features/shared/kit/UiTooltip.vue';
import UiButton from '@/features/shared/kit/UiButton.vue';
import LogoutConfirm from '@/features/shared/layout/LogoutConfirm.vue';

export default {
  name: 'AppHeader',

  components: {
    AppHeaderTaskManagerUser,
    AppHeaderManagerExpedition,
    AppHeaderMenuItem,
    AppHeaderFuelUser,
    AppHeaderCarrierUser,
    SvgIcon,
    UiTooltipWrapper,
    UiTooltip,
    UiButton,
    LogoutConfirm,
  },

  props: {
    isExpeditionActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isOrdersActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isSecurityActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isTasksActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isLogisticsActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isFuelActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isIntegrationMonitor: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isMrzActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      isLogoutOpenModal: false,
    };
  },

  computed: {
    authService(): AuthService {
      return container.get<AuthService>(AUTH_SERVICE_ID);
    },

    showSOP(): boolean {
      return (
        this.$can('forKM', 'sop') || this.$can('forMEP', 'sop') || this.$can('forLogist', 'sop')
      );
    },

    showTM(): boolean {
      return this.$can('show', 'TM');
    },

    isExpeditionTabActive(): boolean {
      return (
        this.authService.isExpeditionManager()
        || this.authService.isAttractionManager()
        || this.authService.isSrmAdminRole()
        || this.$can('enter', 'CarrierRequestsTab')
      );
    },

    expeditionTabLabel(): string {
      if (this.$can('showLogistOrdersPageTitle', 'Orders')) {
        return 'Логистика';
      }

      if (this.$can('enter', 'CarrierRequestsTab')) {
        return 'Заявки';
      }

      return 'Экспедиция';
    },

    fuelRoute(): RouteLocationRaw {
      return this.$can('use', 'Fuel') ? { name: 'fuel' } : { name: 'fuel-greeting' };
    },

    integrationMonitorRoute(): RouteLocationRaw {
      return { name: 'integration-monitor' };
    },

    showFuelUser(): boolean {
      return this.isFuelActive && this.$can('use', 'Fuel');
    },

    showCarrierUser(): boolean {
      return this.isExpeditionActive && this.$can('read', 'CarrierUserDetails');
    },

    showManagerExpeditionUser(): boolean {
      return this.isExpeditionActive && !this.$can('read', 'CarrierUserDetails');
    },
  },

  methods: {
    onLogout() {
      this.authService.logout();
    },
  },
};
