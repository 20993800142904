import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.containerStyles)
  }, [
    (_ctx.type === _ctx.HandlerType.SUCCESS)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          class: _normalizeClass(_ctx.$style.icon),
          name: "success"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.message)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}