
import VOtpInput from 'vue3-otp-input';

import {
  defineComponent,
  PropType,
  useCssModule,
  computed,
} from 'vue';
import { Nullable } from '@/features/shared/types';

import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';

export default defineComponent({
  name: 'UiInputDigitalCode',

  components: {
    VOtpInput,
    DefaultTransition,
  },

  props: {
    id: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    value: {
      type: [Number, String] as PropType<Nullable<string | number>>,
      default: '',
    },

    digitCount: {
      type: Number as PropType<number>,
      default: 4,
    },

    isError: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    errorMessage: {
      type: String as PropType<string>,
      default: 'Неверный код',
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isAutoFocus: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    placeholder: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  emits: ['input', 'complete'],

  setup(props) {
    const style = useCssModule();

    const inputStyles = computed<string[]>(() => [
      style.input,
      props.isError ? style.error : '',
      props.disabled ? style.disabled : '',
    ]);
    return {
      inputStyles,
    };
  },
});
