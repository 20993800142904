import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_tooltip = _resolveComponent("ui-tooltip")!
  const _component_ui_tooltip_wrapper = _resolveComponent("ui-tooltip-wrapper")!

  return (_openBlock(), _createBlock(_component_ui_tooltip_wrapper, {
    key: $options.triggerTooltip.length,
    placement: "right-start",
    "to-selector": "#orders-planning-link",
    trigger: $options.triggerTooltip,
    visible: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ui_tooltip, {
        class: _normalizeClass(_ctx.$style.wrapper)
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.header)
          }, " График загрузок ", 2),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.infoText)
          }, " Здесь мы размещаем наши планы по заявкам на ближайшие недели. ", 2),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.infoText)
          }, " Вы можете выбрать подходящие и запланировать загрузку. ", 2),
          _createVNode(_component_svg_icon, {
            name: "close",
            class: _normalizeClass(_ctx.$style.closeIcon)
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["trigger"]))
}