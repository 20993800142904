import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_app_sidebar_button, {
      "included-routes": ['fuel-transactions'],
      "is-wide": $props.isWide,
      to: { name: 'fuel-transactions' },
      caption: "Транзакции",
      icon: "paper"
    }, null, 8, ["is-wide"]),
    _createVNode(_component_app_sidebar_button, {
      "included-routes": ['fuel-statistics'],
      "is-wide": $props.isWide,
      to: { name: 'fuel-statistics' },
      caption: "Статистика",
      icon: "circle_chart"
    }, null, 8, ["is-wide"]),
    _createVNode(_component_app_sidebar_button, {
      caption: $options.clientRouteCaption,
      "included-routes": [
        'fuel-clients',
        'fuel-client-card',
        'fuel-client-overview',
        'fuel-client-employees',
        'fuel-client-cards',
        'fuel-client-limits',
      ],
      "is-wide": $props.isWide,
      to: $options.clientsRoute,
      icon: "briefcase"
    }, null, 8, ["caption", "is-wide", "to"]),
    (_ctx.$can('read', 'FuelCards'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 0,
          "included-routes": ['fuel-cards'],
          "is-wide": $props.isWide,
          to: { name: 'fuel-cards' },
          caption: "Карты",
          icon: "copy"
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('read', 'FuelTariffPolicy'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 1,
          "included-routes": ['fuel-tariff-policy'],
          "is-wide": $props.isWide,
          to: { name: 'fuel-tariff-policy' },
          caption: "Тарифная политика",
          icon: "file",
          class: _normalizeClass(_ctx.$style.tariffPolicy)
        }, null, 8, ["is-wide", "class"]))
      : _createCommentVNode("", true)
  ]))
}