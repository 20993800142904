
import {
  defineComponent,
  reactive,
  ref,
  PropType,
  toRefs,
  computed,
  onMounted,
} from 'vue';

import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import UiInputDigitalCode from '@/features/redesigned/shared/kit/UiInputDigitalCode.vue';

import { ElementHeight } from '@/features/shared/constant';

import toastRedesigned, { toastContent } from '@/utils/toastRedesigned';
import { getHTTPError } from '@/utils/errors';

import container from '@/di';
import VerificationService, { VERIFICATION_SERVICE_ID } from '@/features/shared/services/verification';

export default defineComponent({
  name: 'OrgPhoneConfirmSecondStep',
  components: {
    UiButton,
    UiInputDigitalCode,
    DefaultTransition,
  },

  props: {
    email: {
      type: String as PropType<string>,
      required: true,
    },
  },

  emits: ['click:prev-step', 'click:next-step'],

  setup(props, { emit }) {
    const state = reactive({
      isLoading: false,
      isSubmitButtonDisabled: true,
      isCanRepeatSend: true,
      isInputDigitalCodeDisabled: false,
      isNotVerifiedCode: false,
      verifyCode: '',
    });

    const verificationService = computed<VerificationService>(() => container.get(VERIFICATION_SERVICE_ID));

    const onClickSendCode = async () => {
      state.isLoading = true;
      state.isInputDigitalCodeDisabled = true;

      try {
        const response = await verificationService.value.verifyEmailCode({
          email: props.email,
          code: state.verifyCode,
        });

        if (response.isVerified) {
          emit('click:next-step');
        } else {
          state.isNotVerifiedCode = true;
        }
      } catch (e) {
        state.isSubmitButtonDisabled = false;

        toastRedesigned.error(toastContent(e?.response?.data?.detail ?? getHTTPError(e)));
      }

      state.isLoading = false;
      state.isInputDigitalCodeDisabled = false;
    };

    const onInputVerifyCode = (code: string) => {
      state.isNotVerifiedCode = false;
      state.verifyCode = code;

      state.isSubmitButtonDisabled = code.length === 4;
    };

    const onCompletedVerifyCode = () => {
      onClickSendCode();
    };

    const onClickRepeatSubmit = async () => {
      state.verifyCode = '';
      state.isNotVerifiedCode = false;

      try {
        const response = await verificationService.value.sendCodeOnEmail(props.email);

        if (response.sent) {
          toastRedesigned.success(toastContent(
            `Код верификации отправлен на электронную почту ${props.email}`,
            'Отправлено письмо',
          ));
        } else {
          toastRedesigned.error(toastContent(response.message));
        }
      } catch (e) {
        toastRedesigned.error(toastContent(getHTTPError(e)));
      }

      startTimer();
    };

    onMounted(() => {
      startTimer();
    });

    const countDownTimeInSeconds = ref<number>(30);

    const startTimer = () => {
      state.isCanRepeatSend = false;

      const intervalId = setInterval(() => {
        countDownTimeInSeconds.value -= 1;

        if (countDownTimeInSeconds.value === 0) {
          clearInterval(intervalId);
          state.isCanRepeatSend = true;

          countDownTimeInSeconds.value = 30;
        }
      }, 1000);
    };

    return {
      ...toRefs(state),
      ElementHeight,
      onClickSendCode,
      onClickRepeatSubmit,
      countDownTimeInSeconds,
      onInputVerifyCode,
      onCompletedVerifyCode,
    };
  },
});
