
import { PropType } from 'vue';
import first from 'lodash/first';
import container from '@/di';
import { getHTTPError } from '@/utils/errors';
import FeedbackFormGroup from '@/features/shared/feedback/FeedbackFormGroup.vue';
import {
  CarriersCommunicationService,
  SRM_CARRIERS_COMMUNICATION_SERVICE_ID,
} from '@/features/srm/carriers-communication/services/CarriersCommunicationService';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import { CounterpartyModel } from '@/features/srm/orgs/services/OrgService.types';
import { fieldWithFallback } from '@/filters/empty';
import { formatPhone } from '@/filters/phone';
import SRMOrgModule, { SRM_ORG_MODULE_ID } from '@/features/srm/orgs/store/OrgModule';
import { Nullable } from '@/features/shared/types';
import { useToast } from 'vue-toastification';

export default {
  name: 'CarrierFeedbackForm',

  components: {
    FeedbackFormGroup,
  },

  props: {
    showCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    orgModule(): SRMOrgModule {
      return container.get(SRM_ORG_MODULE_ID);
    },

    carriersCommunicationService(): CarriersCommunicationService {
      return container.get(SRM_CARRIERS_COMMUNICATION_SERVICE_ID);
    },

    srmUserModule(): SRMUserModule {
      return container.get(SRM_USER_MODULE_ID);
    },

    user(): Nullable<UserModel> {
      return this.srmUserModule?.user ?? null;
    },

    counterparty(): Nullable<CounterpartyModel> {
      const counterparties = this.user?.userCounterparties;
      return first(counterparties)?.counterparty ?? null;
    },

    phone(): string {
      return fieldWithFallback(formatPhone(this.user?.numericPhoneNumber));
    },

    carrierOMAP(): UserModel | undefined {
      return this.orgModule.carrierOMAP;
    },

    orgId(): string {
      return this.counterparty?.id ?? '';
    },
  },

  setup() {
    return { toast: useToast() };
  },

  methods: {
    async sendFeedback(feedback: string) {
      try {
        if (!this.carrierOMAP) {
          await this.loadCarrierMAP();
        }

        const params = {
          text: feedback ?? 'Не указано',
        };
        await this.carriersCommunicationService.sendEmailForFeedback(params);
        this.$emit('success');
      } catch (e) {
        this.toast.error(getHTTPError(e));
      }
    },

    async loadCarrierMAP() {
      try {
        await this.orgModule.loadCarrierMAP(this.orgId);
      } catch (e) {
        this.toast.error(getHTTPError(e));
      }
    },
  },
};
