import generateStore from '@/features/crm/store/generator';
import container from '@/di';
import { CRM_DICTIONARY_SERVICE_ID } from '@/features/crm/services/dictionary';

export const CRM_CONSIGNEE_MODULE_ID = 'crm-consignee';

export function createCRMConsigneeModule() {
  const dictionaryService = container.get(CRM_DICTIONARY_SERVICE_ID);
  return generateStore(
    dictionaryService.getConsigneeList.bind(dictionaryService),
  );
}
