import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import container from '@/di';
import { Logger, AxiosErrorResponce } from '@/features/shared/types';
import { LOGGER_ID } from '@/logger';

function isTimeout(error: AxiosError): boolean {
  return error.code === 'ECONNABORTED';
}

export default function createError(error: AxiosError | unknown): string {
  const logger = container.get<Logger>(LOGGER_ID);
  const axiosError = error as AxiosError & AxiosErrorResponce;
  let message = axiosError.response?.data?.title || axiosError.response?.data?.message || axiosError.message;

  if (!message) {
    message = `Request failed with status code ${axiosError.response?.status}`;
  }

  if (isTimeout(axiosError)) {
    message = 'Превышено время ожидания ответа. Проверьте соединение или обратитесь к администратору';
  } else {
    if (!axiosError.response) return message;

    logger.captureException(new Error(message), {
      extra: {
        response: axiosError.response as AxiosResponse,
      },
    });
  }

  return message;
}
