import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useToast } from 'vue-toastification';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import container from '@/di';
import { Nullable } from '@/features/shared/types';
import {
  UserCounterpartyRelModel,
  UserModel,
} from '@/features/srm/profile/services/UserService.types';
import SRMOrgModule, { SRM_ORG_MODULE_ID } from '@/features/srm/orgs/store/OrgModule';
import { getHTTPError } from '@/utils/errors';
import { OrgsJoiningRequestsModule } from '@/features/srm/orgs/types';

const useOrgsJoiningRequests = (): OrgsJoiningRequestsModule => {
  const toast = useToast();

  const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
  const counterparty = computed<Nullable<UserCounterpartyRelModel>>(() => userModule.value.user?.userCounterparties[0] ?? null);
  const carrierName = computed<Nullable<string>>(() => counterparty.value?.carrier?.name ?? null);

  const orgModule = computed<SRMOrgModule>(() => container.get(SRM_ORG_MODULE_ID));
  const carrierMPP = computed<Nullable<UserModel>>(() => orgModule.value.carrierMPP ?? null);
  const MPPName = computed<string>(() => carrierMPP.value?.firstName ?? 'Анастасия');

  const MPPPhone = computed<number>(() => carrierMPP.value?.numericPhoneNumber ?? 79094554273);
  const MPPPhoneHref = computed<string>(() => `tel:${MPPPhone.value}`);
  const isLoading = ref(false);

  onMounted(() => fetchData());

  const fetchData = async () => {
    isLoading.value = true;

    try {
      const orgId = userModule.value?.user?.userCounterparties[0]?.counterpartyId;
      if (orgId) {
        await orgModule.value.loadCarrierMPP({ orgId, checkCarrierEmployeeRequest: false });
      }
    } catch (e) {
      toast.error(getHTTPError(e));
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    carrierName,
    carrierMPP,
    MPPName,
    MPPPhone,
    MPPPhoneHref,
  };
};

export default useOrgsJoiningRequests;
