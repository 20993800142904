import { EmployeeModel } from '@/features/fuel/clients/types';
import { EmployeeRequestModel } from '@/features/fuel/shared/types';

export default class EmployeeMapper {
  static toRequest(item: EmployeeModel): EmployeeRequestModel {
    return {
      id: item.id,
      clientId: item.clientId,
      lastName: item.lastName,
      firstName: item.firstName,
      patronymic: item.patronymic,
      email: item.email,
      phone: item.phone,
    };
  }
}
