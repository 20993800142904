
import { PropType } from 'vue';
import UiModal from '@/features/redesigned/shared/kit/UiModal.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';

export default {
  name: 'UiModalWithContent',

  components: {
    SvgIcon,
    UiModal,
  },

  props: {
    target: {
      type: String as PropType<string>,
      required: true,
    },

    width: {
      type: Number as PropType<number>,
      required: true,
    },

    isCentered: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    closeOnClickOverlay: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    overlayColor: {
      type: String as PropType<string>,
      default: 'light',
      validator: (value) => ['light', 'dark', 'dark-blue'].includes(value),
    },

    hasCustomContainerStyle: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    customContainerStyle: {
      type: Object as PropType<Styles>,
      default: () => ({}),
    },

    showClose: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    isFullWidth: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    zIndex: {
      type: Number as PropType<Nullable<number>>,
      default: null,
    },
  },

  emits: ['close'],

  computed: {
    containerClasses(): Styles {
      return {
        [this.$style.container]: true,
        [this.$style.customContainerStyle]: this.hasCustomContainerStyle,
      };
    },

    customStyles(): Styles {
      return this.hasCustomContainerStyle ? this.customContainerStyle : {};
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
