<template>
  <div :class="$style.wrapper">
    <div
      v-if="charLengthLimit"
      :class="limitIndicatorClasses"
      :data-qa="charLengthLimitDataQa"
    >
      {{ `${charCounter} из ${charLengthLimit}` }}
    </div>
    <div :class="classes">
      <textarea
        v-bind="$attrs"
        :id="internalId"
        ref="input"
        data-testid="UiTextarea"
        :class="inputClasses"
        :value="modelValue"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      />

      <div
        v-if="isHaveAppendedContent"
        :class="$style.appendedContent"
      >
        <slot name="append">
          <svg-icon
            v-if="rightIcon"
            :class="$style.rightIcon"
            :name="rightIcon"
            @click.stop="$emit('click:right-icon')"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import inputMixin from '@/features/shared/kit/mixins/input';

export default {
  name: 'UiTextarea',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  props: {
    resize: {
      type: Boolean,
      default: true,
    },

    focusOnAppearing: {
      type: Boolean,
      default: false,
    },

    charLengthLimit: {
      type: Number,
      default: null,
    },

    charLengthLimitDataQa: {
      type: String,
      default: null,
    },
  },

  computed: {
    inputClasses() {
      return {
        [this.$style.input]: true,
        [this.$style.resize]: this.resize,
        [this.$style.isDisabled]: this.disabled,
      };
    },

    limitIndicatorClasses() {
      return {
        [this.$style.limitIndicator]: true,
        [this.$style.textRed]: this.isLimitOverflowed,
      };
    },

    internalId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `input-${this._uid}`;
    },

    charCounter() {
      if (!this.modelValue?.length) return 0;

      return this.modelValue.length;
    },

    isLimitOverflowed() {
      if (!this.charLengthLimit) return false;

      return this.charCounter > this.charLengthLimit;
    },
  },

  mounted() {
    this.setFocus();
  },

  methods: {
    setFocus() {
      if (this.focusOnAppearing) {
        this.onFocus();
      }
    },
  },
};
</script>

<style module>
.wrapper {
  position: relative;
}

.container {
  display: flex;
  flex-flow: row;
  cursor: text;
  background-clip: padding-box;
  border-radius: var(--border-radius-base);
  box-shadow: inset 0 0 0 1px var(--color-black-65);
  background-color: var(--color-white-100);
  padding: 2px;
  transition: box-shadow var(--timing-input-animation) ease-in-out;
}

.container .appendedContent {
  margin-right: 4px;
}

.container.isDisabled {
  opacity: 0.33;
}

.container.isDisabled .rightIcon {
  color: var(--color-black-09);
  cursor: default;
  pointer-events: none;
}

.container.isInvalid {
  box-shadow: inset 0 0 0 1px var(--color-red-400);
}

.container:not(.isDisabled):hover {
  box-shadow: inset 0 0 0 1px var(--color-black-80);
}

.container:not(.isDisabled).isFocused {
  box-shadow: inset 0 0 0 2px var(--color-navy-500);
}

.container:not(.isDisabled).isInvalid:hover {
  box-shadow: inset 0 0 0 1px var(--color-red-400);
}

.container:not(.isDisabled).isInvalid .isFocused {
  box-shadow: inset 0 0 0 2px var(--color-red-400);
}

.input {
  font: inherit;
  flex-grow: 1;
  outline: none;
  border: none;
  background: transparent;
  padding: 5px 10px 7px 10px;
  border-radius: var(--border-radius-base);
  min-height: var(--size-form-element-height);
  font-size: var(--font-size-base);
  line-height: var(--line-height-small);
  color: var(--color-black-100);
  resize: none;
}

.input.resize {
  resize: vertical;
}

.input::placeholder {
  color: var(--color-black-65);
}

.appendedContent {
  display: inline-flex;
}

.rightIcon {
  width: 24px;
  height: 24px;
  align-self: flex-start;
  cursor: pointer;
  color: var(--color-black-65);
  transition: color var(--timing-input-animation) ease-in-out;
}

.rightIcon:hover {
  color: var(--color-navy-500);
}

.limitIndicator {
  padding: 4px;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: -22px;
}

.textRed {
  color: var(--color-red-400);
}

</style>
