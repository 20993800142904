import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';

export const SRM_SERVICE_ID = Symbol('srmService');

@Register(
  SRM_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMService extends RemoteService {
  constructor(configService) {
    super(configService.SRMServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMService;
