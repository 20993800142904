import { createStore as _createStore } from 'vuex'

import {
  CRM_CONSIGNEE_MODULE_ID,
  createCRMConsigneeModule,
} from '@/features/crm/store/consignee';

export function createStore() {
  return _createStore({
    modules: {
      [CRM_CONSIGNEE_MODULE_ID]: createCRMConsigneeModule(),
    },
  });
}

export const STORE_ID = Symbol('store');
