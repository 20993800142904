
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import container from '@/di';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import { Nullable } from '@/features/shared/types';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import SOPDictionariesService, { SOP_DICTIONARIES_SERVICE_ID } from '@/features/sop/services/DictionariesService';

export default defineComponent({
  name: 'AppHeaderTaskManagerUser',

  components: {
    SvgIcon,
  },

  setup() {
    const authService = computed<AuthService>(() => container.get(AUTH_SERVICE_ID));
    const dictionariesService = computed<SOPDictionariesService>(() => container.get(SOP_DICTIONARIES_SERVICE_ID));
    const fio = computed<string>(() => authService.value.getUserName() ?? '');
    const companyList = ref(null);

    const companyName = computed<Nullable<string>>(() => {
      if (!companyList.value) return '';

      return companyList.value.find((item) => item.id === authService.value.getSysId1c())?.name;
    });

    onMounted(() => getCompanies());

    const getCompanies = async () => {
      try {
        companyList.value = await dictionariesService.value.getCompanies();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      fio,
      companyName,
    };
  },
});
