import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SRM_NEW_ORDER_SERVICE_V2_ID = Symbol('srmNewOrderServiceV2');

@Register(
  SRM_NEW_ORDER_SERVICE_V2_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMNewOrderServiceV2 extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMOrderHostV2);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMNewOrderServiceV2;
