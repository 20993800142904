import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_app_sidebar_button, {
      caption: "Клиенты",
      icon: "person",
      to: { name: 'mrz-clients' },
      "included-routes": [
        'mrz-clients',
        'mrz-client',
        'mrz-client-info',
        'mrz-client-channels',
      ],
      "is-wide": $props.isWide
    }, null, 8, ["is-wide"])
  ]))
}