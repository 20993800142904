import Imask from 'imask';
import {
  checkMaskForCountry,
  checkMaskForPhone,
} from '@/utils/masks';

/**
 * Форматирование телефонного номера
 * 78005553535 => +7 (800) 555-35-35
 *
 * @return { string }
 * @param { string | number } number
 */
export const formatPhone = (number: number | string): string => {
  if (number) {
    const numberString = String(number)
      .includes('-')
      ? String(number)
        .split('-')
        .join('')
        .split(' ')
        .join('')
        .replace('(', '')
        .replace(')', '') : String(number);
    const numberSeven = numberString[0] === '8' ? numberString.replace('8', '7') : numberString;
    return Imask.createMask(checkMaskForCountry(checkMaskForPhone(Number(numberSeven))))
      .resolve(numberSeven);
  }
  return '';
};

/**
 * Форматирование телефонного номера c сокрытием части номера
 * 78005553535 => +7 (800) ***-**-35
 *
 * @param value {number}
 * @return {string}
 */

export const partialHidingPhone = (value?: number): string => {
  if (!value) {
    return '';
  }

  const cleaned = value.toString()
    .replace(/\D/g, '');

  const match = cleaned.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `+7 (${match[1]}) ***-**-${match[4]}`;
  }
  return `+${value}`;
};

export default {
  formatPhone,
  partialHidingPhone,
};
