
import { useHead } from '@unhead/vue';
import { isIOS } from '@/utils/device';

export default {
  setup() {
    useHead({
      title: 'Главная страница',
      titleTemplate: '%s — Globaltruck',
      meta: [
        {
          name: 'description',
          content: '© Цифровая платформа транспортной логистики (GT2). '
            + 'Правообладатель ООО «Глобалтрак информационные технологии»',
        },
      ],
    });
  },

  mounted() {
    if (isIOS()) {
      this.disableIosTextFieldZoom();
    }
  },

  methods: {
    disableIosTextFieldZoom() {
      const viewport = document.querySelector('meta[name=viewport]');

      if (viewport) {
        let content = viewport.getAttribute('content');
        content = [content, 'maximum-scale=1.0'].join(', ');
        viewport.setAttribute('content', content);
      }
    },
  },
};
