
import { PropType } from 'vue';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import UiModalWithContent from '@/features/shared/kit/UiModalWithContent.vue';
import UiModalConfirm from '@/features/shared/kit/UiModalConfirm.vue';
import UiButton from '@/features/shared/kit/UiButton.vue';
import UiInputText from '@/features/shared/kit/UiInputText.vue';
import UiTextarea from '@/features/shared/kit/UiTextarea.vue';
import UiFormGroup from '@/features/shared/kit/UiFormGroup.vue';
import container from '@/di';
import CommonUserService, { COMMON_USER_SERVICE_ID } from '@/features/shared/services/common-user/services';
import createError from '@/utils/createError';
import { useToast } from 'vue-toastification';

export default {
  name: 'AppSidebarMailing',

  components: {
    AppSidebarButton,
    UiModalWithContent,
    UiModalConfirm,
    UiButton,
    UiInputText,
    UiTextarea,
    UiFormGroup,
  },

  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  data() {
    return {
      isCreatePostModalOpen: false,
      isPreviewModalOpen: false,
      isConfirmExitModalOpen: false,
      maxSubjectLength: 70,
      maxContentLength: 5000,
      subject: '',
      content: '',
    };
  },

  computed: {
    commonUserService(): CommonUserService {
      return container.get(COMMON_USER_SERVICE_ID);
    },

    isDisabledViewButton(): boolean {
      return !this.subject.length || !this.content.length;
    },
  },

  methods: {
    backToEdit() {
      this.isCreatePostModalOpen = true;
      this.isPreviewModalOpen = false;
    },

    closeConfirmExit() {
      this.isConfirmExitModalOpen = false;
      this.clearForm();
    },

    clearForm() {
      this.isPreviewModalOpen = false;
      this.isCreatePostModalOpen = false;

      this.subject = '';
      this.content = '';
    },

    async onClickSendPostToUsers(): Promise<void> {
      try {
        await this.commonUserService.sendUsersMailing({
          subject: this.subject,
          content: this.content,
        });

        this.toast.success('Почтовая рассылка успешно отправлена');
        this.clearForm();
      } catch (e) {
        this.toast.error(createError(e));
      }
    },

    setup() {
      return { toast: useToast() };
    },

    async onClickSendPostToInitiator() {
      try {
        await this.commonUserService.sendMailToInitiator({
          subject: this.subject,
          content: this.content,
        });

        this.toast.success('Письмо успешно отправлено инициатору');

        this.isPreviewModalOpen = false;
        this.isConfirmExitModalOpen = true;
      } catch (e) {
        this.toast.error(createError(e));
      }
    },
  },
};
