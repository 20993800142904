import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SRM_LOYALTY_SERVICE_ID = Symbol('srmLoyaltyServiceV2');

@Register(
  SRM_LOYALTY_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMLoyaltyService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMLoyaltyServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMLoyaltyService;
