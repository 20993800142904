import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import { STORE_ID } from '@/store';
import {
  ApplyCashbackParams,
  CashbackHistoryItem,
  HoldType,
  LoyaltyInfo,
  OrderCashbackResponse,
} from '@/features/srm/loyalty/types';
import {
  Nullable,
  Page,
  PageParams,
} from '@/features/shared/types';
import LoyaltyService, { LOYALTY_SERVICE_ID } from '@/features/srm/loyalty/services/LoyaltyService';
import { HoldTypes } from '@/features/srm/loyalty/constants';

export const LOYALTY_PROGRAM_MODULE_ID = 'loyalty-program';

@Module
@Injectable()
export default class LoyaltyProgramModule extends VuexModule {
  @LazyInject(LOYALTY_SERVICE_ID) loyaltyService: LoyaltyService;

  loyaltyInfo = null as Nullable<LoyaltyInfo>;

  isCarrierHasLoyalty: Nullable<boolean> = null;

  orderCashback: Nullable<OrderCashbackResponse> = null;

  holdTypes: HoldType[] = [];

  welcomeCashback: Nullable<number> = null;

  constructor(
    @Inject(STORE_ID) store: Store<unknown>,
  ) {
    super({ name: LOYALTY_PROGRAM_MODULE_ID, store });
  }

  hasWelcomeCashback(): boolean {
    return this.holdTypes.some((item) => item.holdType === HoldTypes.Welcome) && this.welcomeCashback === 1000;
  }

  isRateIncreased(): boolean {
    return this.holdTypes.some((item) => item.holdType === HoldTypes.IncreasePrice);
  }

  @Action
  async loadLoyaltyInfo(): Promise<LoyaltyInfo> {
    const content = await this.loyaltyService.getLoyaltyInfo();
    this.setLoyaltyInfo(content);

    if (content.cashbackWithoutRestrict) {
      this.setWelcomeCashback(content.cashbackWithoutRestrict);
    }

    return content;
  }

  @Action
  async loadCarrierHasLoyalty(): Promise<void> {
    if (this.isCarrierHasLoyalty === null) {
      const content = await this.loyaltyService.carrierHasLoyalty();
      this.setCarrierLoyaltyAvailable((content.hasLoyalty && content.activeLoyalty) || !!content.cashback);
      this.setHoldTypes(content.holdTypes);

      if (content.cashback) {
        this.setWelcomeCashback(content.cashback);
      }
    }
  }

  @Action
  async getCashbackHistory(params: PageParams): Promise<Page<CashbackHistoryItem>> {
    return this.loyaltyService.getCashbackHistory(params);
  }

  @Action
  async applyCashback(data: ApplyCashbackParams): Promise<string> {
    return this.loyaltyService.applyCashback(data);
  }

  @Action
  async loadOrderCashbackInfo(orderId: string): Promise<void> {
    const response = await this.loyaltyService.getOrderCashbackInfo(orderId);
    this.setOrderCashback(response);
  }

  @Mutation
  setLoyaltyInfo(info: LoyaltyInfo): void {
    this.loyaltyInfo = info;
  }

  @Mutation
  setCarrierLoyaltyAvailable(isCarrierHasLoyalty: boolean): void {
    this.isCarrierHasLoyalty = isCarrierHasLoyalty;
  }

  @Mutation
  setOrderCashback(orderCashback: OrderCashbackResponse): void {
    this.orderCashback = orderCashback;
  }

  @Mutation
  setHoldTypes(holdTypes: HoldType[]): void {
    this.holdTypes = holdTypes;
  }

  @Mutation
  setWelcomeCashback(cashback: Nullable<number>): void {
    this.welcomeCashback = cashback;
  }
}

container
  .bind<LoyaltyProgramModule>(LOYALTY_PROGRAM_MODULE_ID)
  .to(LoyaltyProgramModule)
  .inSingletonScope();
