import { h } from 'vue';
import { createToastInterface } from "vue-toastification";
import { TYPE, POSITION } from 'vue-toastification';
import UiToast from "@/features/redesigned/shared/kit/UiToast.vue";
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

const toastRedesigned = createToastInterface({ 
  position: POSITION.TOP_RIGHT,
  containerClassName: 'Vue-Toastification-redesigned____container',
  toastClassName: 'gt-toast',
  maxToasts: 5,
  hideProgressBar: true,
  timeout: 5000,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: {
        functional: true,
        render() {
          return h(SvgIcon, {
            class: 'Vue-Toastification__icon success',
            name: 'check_mark_circle',
          });
        },
      },
    },
    [TYPE.ERROR]: {
      icon: {
        functional: true,
        render() {
          return h(SvgIcon, {
            class: 'Vue-Toastification__icon error',
            name: 'exclamation_circle',
          });
        },
      },
    },
    [TYPE.INFO]: {
      icon: {
        functional: true,
        render() {
          return h(SvgIcon, {
            class: 'Vue-Toastification__icon info',
            name: 'info_circle',
          });
        },
      },
    },
  },
  closeButton: {
    functional: true,
    render() {
      return h(
        'button',
        { class: 'Vue-Toastification__close-button' },
        [
          h(SvgIcon, { name: 'close_light' }),
        ],
      );
    },
  },
  filterBeforeCreate(toast, toasts) {
    const hasSameToasts = toasts.some((item) => item.content === toast.content);
    if (hasSameToasts) {
      return false;
    }
    
    return toast;
  },
});

export default toastRedesigned;

export const toastContent = (content: string, title = 'Ошибка') => ({
  component: UiToast,
  props: {
    title,
    content,
  },
});