const COMPANY_LIST = '/company';
const MANAGERS_LIST = '/individuals/managers/page';
const SOP_CLIENTS_LIST = '/client/search';

const OWN_TS_LIST = '/ownTs/page';
const TRAILER_TYPES = '/trailerType/page';
const KM_LIST = (companyId: string): string => `/manager/km/${companyId}`;
const LOGIST_LIST = (companyId: string): string => `/manager/logist/${companyId}`;
const MEP_LIST = (companyId: string): string => `/manager/mep/${companyId}`;

const CITY_LIST = '/city/page';
const CARGO_TYPES_LIST = '/cargo-dictionary/';

const ALL_CLIENTS = '/customers/page';

export default {
  ALL_CLIENTS,
  COMPANY_LIST,
  MANAGERS_LIST,
  SOP_CLIENTS_LIST,
  OWN_TS_LIST,
  TRAILER_TYPES,
  KM_LIST,
  LOGIST_LIST,
  MEP_LIST,
  CITY_LIST,
  CARGO_TYPES_LIST,
};
