import omit from 'lodash/omit';
import container, {
  Inject,
  Injectable,
} from '@/di';

import HttpService, { CRM_SERVICE_ID } from '@/features/crm/services/http';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';

import {
  Page,
  RoadMapType,
  SortParams,
} from '@/features/shared/types';

import {
  Company,
  Consignee,
  CounterpartyContact,
  Contract,
  LoadingType,
  TransportationType,
  Cargo,
  City,
  Region,
  Location,
  ExtendedTrailerType,
  RequestCancellationReason,
  ConnectionLocation,
  LocationName,
  Individual,
  LoadSingleEntity,
} from '@/features/crm/types';

import {
  API_GET_COMPANIES,
  API_GET_CONSIGNEE_LIST,
  API_GET_CONSIGNEE_TTN,
  API_ADD_CONSIGNEE,
  API_GET_CLIENT_CONTACT_LIST,
  API_GET_CONTACT_LIST,
  API_ADD_CLIENT_CONTACT,
  API_GET_CONTRACT_LIST,
  API_GET_LOADING_TYPES,
  API_GET_TRANSPORTATION_TYPES,
  API_GET_CARGO_LIST,
  API_GET_CARGO_BY_ID,
  API_ADD_CARGO,
  API_GET_LOCATION_LIST,
  API_GET_LOCATION_NAME_LIST,
  API_ADD_LOCATION_NAME,
  API_GET_ROADMAP_TYPES,
  API_GET_REGION_LIST,
  API_GET_CITY_LIST,
  API_GET_MANAGER_LIST,
  API_GET_MANAGER_BY_ID,
  API_GET_TRAILER_TYPE_LIST,
  API_GET_CANCEL_REASON_LIST,
  API_GET_CONNECTION_LOCATION_AND_CONSIGNEE,
  API_DELETE_CONNECTION_LOCATION_AND_CONSIGNEE,
} from '@/features/crm/services/dictionary/endpoints';
import {
  LocationNameReq,
  ContractListDTO,
  ManagerListDTO,
  ExtendedTrailerTypeDTO,
  ConsigneeListDTO,
  ClientContact,
  TransportationTypeParams,
  LoadingTypeParams,
  CargoParams,
  LocationParams,
  RegionParams,
  CityParams,
  RequestCancellationReasonParams,
  LocationForConsigneeDelete,
  ContactCounterparty,
} from '@/features/crm/services/dictionary/types';

export const CRM_DICTIONARY_SERVICE_ID = Symbol('crmDictionaryService');

/**
 * @see https://dev.api.portal.gt.local/dictionaries/swagger-ui.html
 *
 * @example
 * openapi-typescript http://dev.api.portal.gt.local/dictionaries/v3/api-docs.yaml --output schema.ts
 */

@Injectable()
export class CRMDictionaryService {
  private api: HttpService;

  constructor(
    @Inject(CRM_SERVICE_ID) api: HttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
  ) {
    this.api = api;
    this.api.remoteClient.defaults.baseURL = config.CRMDictionaryServiceHost;
  }

  /**
   * Список компаний
   *
   * @param {object} [params]
   * @returns {Promise<Array>}
   */
  getCompanies(params?: SortParams): Promise<Company[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_COMPANIES,
      params,
    });
  }

  /**
   * Получение списка грузополучателей
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.q]
   * @param {string} [params.companyId] - ID организации
   * @param {string} [params.counterpartyId] - ID клиента
   */
  getConsigneeList(params: ConsigneeListDTO): Promise<Page<Consignee>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CONSIGNEE_LIST,
      params,
    });
  }

  /**
   * Получение грузополучателя/грузоотправителя по ТТН
   * @param {object} params
   * @param {string} [params.companyId] - ID организации
   */
  getConsigneeTtn(params: { companyId?: string }): Promise<Consignee> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CONSIGNEE_TTN,
      params,
    });
  }

  /**
   * Созданиие грузополучателя/грузоотправителя
   *
   * @param {object} data
   * @param {string} data.name - название
   * @param {string} data.sysId1c - ID организации
   * @param {string} data.counterpartyId - ID клиента
   * @param {object} [data.inn]
   * @param {object} [data.kpp]
   * @param {string} [data.phoneNumber]
   */
  addConsignee(data: Consignee): Promise<Consignee> {
    return this.api.request({
      method: 'POST',
      url: API_ADD_CONSIGNEE,
      timeout: 30000,
      data,
    });
  }

  /**
   * Получение списка контактных лиц клиента
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.companyId] - ID организации
   */
  getClientContactList(params: ClientContact): Promise<Page<CounterpartyContact>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CLIENT_CONTACT_LIST,
      params,
    });
  }

  /**
   * Получение списка контактов клиента
   */
  getContactList(data: ContactCounterparty): Promise<{ content: CounterpartyContact[] }> {
    return this.api.request({
      method: 'POST',
      url: API_GET_CONTACT_LIST,
      data,
    });
  }

  /**
   * Создание контактного лица контрагента
   *
   * @param data
   * @returns {Promise<any>}
   */
  addClientContact(data: CounterpartyContact): Promise<CounterpartyContact> {
    return this.api.request({
      method: 'POST',
      url: API_ADD_CLIENT_CONTACT,
      timeout: 30000,
      data,
    });
  }

  /**
   * Получение списка договоров
   *
   * @param {ContractListDTO} params
   * @returns {Promise<Page<Contract>>}
   */
  async getContractList(params: Partial<ContractListDTO>): Promise<Page<Contract>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CONTRACT_LIST,
      params,
    });
  }

  /**
   * Список типов погрузки
   *
   * @param {object} [params]
   * @returns {Promise<Array>}
   */
  getLoadingTypes(params?: LoadingTypeParams): Promise<LoadingType[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_LOADING_TYPES,
      params,
    });
  }

  /**
   * Список типов перевозки
   *
   * @param {object} [params]
   * @returns {Promise<Array>}
   */
  getTransportationTypes(params?: TransportationTypeParams): Promise<TransportationType[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_TRANSPORTATION_TYPES,
      params,
    });
  }

  /**
   * Получение списка грузов
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.companyId] - ID организации
   */
  getCargoList(params: CargoParams): Promise<Page<Cargo>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CARGO_LIST,
      params,
    });
  }

  getCargosByIds(params: LoadSingleEntity): Promise<Cargo[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CARGO_BY_ID(params.id),
      params,
    });
  }

  /**
   * Создание груза
   *
   * @param {object} data
   * @param {string} data.name
   * @param {string} data.sysId1c
   * @param {string} [data.code]
   */
  addCargo(data: Cargo): Promise<Cargo> {
    return this.api.request({
      method: 'POST',
      url: API_ADD_CARGO,
      timeout: 30000,
      data,
    });
  }

  /**
   * Получение списка локации с полнотекстовым поиском
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.q]
   * @param {string} [params.companyId] - ID организации
   */
  getLocationList(params: LocationParams): Promise<Page<Location>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_LOCATION_LIST,
      params,
    });
  }

  getLocationNameList(params: LocationNameReq): Promise<Page<LocationName>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_LOCATION_NAME_LIST,
      params,
    });
  }

  addLocationName(data: LocationName): Promise<LocationName> {
    return this.api.request({
      method: 'POST',
      url: API_ADD_LOCATION_NAME,
      data,
    });
  }

  /**
   * Загрузка типов погрузки
   * @returns {Promise<[{code: string, name: string}]>}
   */
  getRoadMapTypes(): Promise<RoadMapType[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_ROADMAP_TYPES,
    });
  }

  /**
   * Поиск региона
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.q]
   * @param {string} [params.countryId]
   * @param {string} [params.companyId] - ID организации
   */
  getRegionList(params: RegionParams): Promise<Page<Region>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_REGION_LIST,
      params,
    });
  }

  /**
   * Поиск города
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.q]
   * @param {string} [params.regionId]
   * @param {string} [params.companyId] - ID организации
   */
  getCityList(params: CityParams): Promise<Page<City>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CITY_LIST,
      params,
    });
  }

  /**
   * Список ответственных клиентских менеджеров
   */
  getManagerList(params: Partial<ManagerListDTO>): Promise<Page<Individual>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_MANAGER_LIST,
      params,
    });
  }

  getManagersByIds(params: LoadSingleEntity): Promise<Individual[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_MANAGER_BY_ID(params.id),
      params: omit(params, ['id']),
    });
  }

  /**
   * Список "общий тип"
   */
  getTrailerTypeList(params: ExtendedTrailerTypeDTO): Promise<Page<ExtendedTrailerType>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_TRAILER_TYPE_LIST,
      params,
    });
  }

  /**
   * Список причин отмены
   *
   * @param params
   * @returns {Promise<RequestCancellationReason[]>}
   */
  getCancelReasonList(params: RequestCancellationReasonParams): Promise<RequestCancellationReason[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CANCEL_REASON_LIST,
      params,
    });
  }

  /**
   * Добавление связи локации и контрагента
   *
   * @param {object} data
   * @param {number} [data.sysId1c]
   * @param {number} [data.locationId]
   * @param {number} [data.consigneeId]
   */
  addConnectionLocationAndConsignee(data: ConnectionLocation): Promise<ConnectionLocation> {
    return this.api.request({
      method: 'POST',
      url: API_GET_CONNECTION_LOCATION_AND_CONSIGNEE,
      data,
    });
  }

  /**
   * Удаление связи локации и контрагента
   *
   * @param {object} params
   * @param {number} [params.locationId]
   * @param {number} [params.consigneeId]
   */
  deleteConnectionLocationAndConsignee(params: LocationForConsigneeDelete): Promise<void> {
    return this.api.request({
      method: 'DELETE',
      url: API_DELETE_CONNECTION_LOCATION_AND_CONSIGNEE,
      params,
    });
  }
}

container
  .bind<CRMDictionaryService>(CRM_DICTIONARY_SERVICE_ID)
  .to(CRMDictionaryService);
