
import {
  computed,
  defineComponent,
} from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import Cookies from 'js-cookie';
import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import { Nullable } from '@/features/shared/types';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';
import container from '@/di';
import { useCarrierInfo } from '@/features/redesigned/srm/composable/carrier-info';
import { isPhone } from '@/utils/device';

export default defineComponent({
  name: 'IncreasedRateModal',

  components: {
    UiModalWithContent,
    UiButton,
  },

  setup(_, { emit }) {
    const orderModule = computed<SRMOrderModule>(() => container.get(SRM_ORDER_MODULE_ID));
    const ordersCounter = computed<Nullable<number>>(() => orderModule.value.carrierOrderCounter);

    const { counterparty } = useCarrierInfo();
    const getOrgRoute = (): RouteLocationNormalized => (isPhone()
      ? { name: 'org-adding' }
      : {
        name: 'orgs',
        query: {
          action: 'add',
        },
      });

    const onClose = () => {
      Cookies.set('increasedRateModalViewed', '1', { expires: 365 });
      emit('close');
    };

    return {
      ordersCounter,
      counterparty,
      getOrgRoute,
      onClose,
    };
  },
});
