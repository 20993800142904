export const API_CONSIGNEE_PAGE = '/consignee/page';
export const API_CUSTOMERS_GET_BY_COMPANY_ID = '/customers/page';
export const API_CONTRACTS_PAGE = '/contracts/page';
export const API_DELAY_REASON = '/delay-reason';
export const API_LOADING_TYPE = '/loading-type';
export const API_TRANSPORTATION_TYPE = '/transportation-type';
export const API_GET_VEHICLE_LIST = '/vehicles/page';
export const API_GET_VEHICLE_BY_ID = (id: string): string => `/vehicles/${id}`;
export const API_GET_DRIVER_LIST = '/drivers/page';
export const API_GET_DRIVER_BY_ID = (id: string): string => `/drivers/${id}`;
export const API_GET_EMPLOYEE_LIST = '/employee/page';
export const API_GET_CLIENT_LIST = '/counterparty/page';

export const API_TRAILER_TYPE_ID = (id: string): string => `/trailer-type/${id}`;
