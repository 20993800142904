import {
  ClientModel,
  ClientResponseModel,
} from '@/features/fuel/clients/types';
import { ClientRequestModel } from '@/features/fuel/shared/types';
import CardMapper from '@/features/fuel/cards/mappers/CardMapper';
import EmployeeMapper from '@/features/fuel/clients/mappers/EmployeeMapper';

export default class ClientMapper {
  static toRequest(item: ClientModel): ClientRequestModel {
    return {
      id: item.id,
      legalForm: item.legalForm ?? null,
      name: item.name,
      activityGroupCode: item.activityGroup?.code ?? null,
      countryCode: item.country?.code ?? null,
      legalAddress: item.legalAddress ?? null,
      address: item.address ?? null,
      contractNumber: item.contractNumber ?? null,
      limit: item.limit ?? null,
      inn: item.inn ?? null,
      kpp: item.kpp ?? null,
      ogrn: item.ogrn ?? null,
      account: item.account ?? null,
      bik: item.bik ?? null,
      cards: item.cards?.map((card) => CardMapper.toRequest(card)) ?? [],
      employees: item.employees?.map((employee) => EmployeeMapper.toRequest(employee)) ?? [],
    };
  }

  static fromResponse(item: ClientResponseModel): ClientModel {
    return {
      id: item.id ?? null,
      legalForm: item.legalForm,
      name: item.name ?? '',
      activityGroup: item.activityGroup,
      country: item.country,
      legalAddress: item.legalAddress,
      address: item.address,
      contractNumber: item.contractNumber,
      limit: item.limit,
      inn: item.inn,
      kpp: item.kpp,
      ogrn: item.ogrn,
      account: item.account,
      bik: item.bik,
      cards: item.cards?.map((card) => CardMapper.fromResponse(card)) ?? [],
      employees: item.employees?.map((employee) => ({
        ...employee,
        key: Symbol('employeeKey'),
      })) ?? [],
      moneyShortageWarningLimit: item.moneyShortageWarningLimit,
    };
  }
}
