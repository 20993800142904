import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!
  const _component_orders_planning_tooltip = _resolveComponent("orders-planning-tooltip")!
  const _component_truck_offering_tooltip = _resolveComponent("truck-offering-tooltip")!
  const _component_srm_report_fast_pay = _resolveComponent("srm-report-fast-pay")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$can('showButtonOrders', 'AppSidebarExpedition'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 0,
          caption: "Заявки",
          icon: "paper",
          to: { name: 'orders' },
          "included-routes": [
        'orders',
        'order',
        'order-preview',
        'order-share',
        'order-edit',
        'order-actualize',
        'order-accept',
        'order-cancel',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('showButtonOrg', 'AppSidebarExpedition'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 1,
          caption: $options.orgsButtonCaption,
          icon: "briefcase",
          to: { name: 'orgs' },
          "included-routes": [
        'orgs',
        'srm-orgs-joining-requests',
        'org',
        'org-documents',
        'org-contracts',
        'org-warranty',
        'org-drivers',
        'org-vehicles',
        'org-users',
        'org-approving-process',
        'org-history',
        'org-loyalty-program',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["caption", "is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('showButtonReport', 'AppSidebarExpedition'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 2,
          caption: "Отчеты",
          icon: "clock_list",
          to: { name: 'reports' },
          "included-routes": ['reports', 'warranty-report'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.isUsersPage)
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 3,
          caption: "Пользователи",
          icon: "partner",
          to: { name: 'users' },
          "included-routes": ['users'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('use', 'OrdersPlanning'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 4,
          id: "orders-planning-link",
          caption: "График загрузок",
          icon: "calendar",
          to: { name: 'planning' },
          "included-routes": ['planning'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.isShowOrdersPlanningTooltip)
      ? (_openBlock(), _createBlock(_component_orders_planning_tooltip, {
          key: 5,
          "is-show-on-hover": ""
        }))
      : _createCommentVNode("", true),
    (_ctx.$can('use', 'TruckOfferingForCarrier') || _ctx.$can('use', 'TruckOfferingForMEP'))
      ? _withDirectives((_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 6,
          id: "truck-offering-link",
          caption: "Предложить машину",
          icon: "truck_ref",
          "additional-icon": "plus",
          "is-blink": $options.isBlinkTruckOfferingLink,
          to: { name: 'truck-offering' },
          "included-routes": ['truck-offering'],
          "is-wide": $props.isWide
        }, null, 8, ["is-blink", "is-wide"])), [
          [_directive_click_outside, $options.onClickOutside]
        ])
      : _createCommentVNode("", true),
    (_ctx.$can('enter', 'AverageRates'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 7,
          caption: "Средние ставки",
          icon: "look-request",
          to: { name: 'average-rates' },
          "included-routes": ['average-rates'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($options.isShowTruckOfferingTooltip)
      ? (_openBlock(), _createBlock(_component_truck_offering_tooltip, {
          key: 8,
          "is-show-on-hover": $data.isShowTruckOfferingTooltipOnHover
        }, null, 8, ["is-show-on-hover"]))
      : _createCommentVNode("", true),
    (_ctx.$can('read', 'AppSidebarSubscriptions'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 9,
          caption: "Подписки",
          icon: "mail_plus",
          to: { name: 'srm-subscriptions' },
          "included-routes": ['srm-subscriptions'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    ($data.isShowModalReportFastPay)
      ? (_openBlock(), _createBlock(_component_srm_report_fast_pay, {
          key: 10,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.isShowModalReportFastPay = false))
        }))
      : _createCommentVNode("", true)
  ]))
}