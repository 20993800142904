import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';

export const SRM_WARRANTY_REPORTS_REMOTE_SERVICE = Symbol('SRMWarrantyReportsRemoteService');

@Register(
  SRM_WARRANTY_REPORTS_REMOTE_SERVICE,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMWarrantyReportsRemoteService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMWarrantyReportsRemoteService, { timeout: 300000 });
    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMWarrantyReportsRemoteService;
