
import tippy from 'tippy.js';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import defaultProps, { booleanProps } from './UiTooltipWrapper.props';

export default {
  name: 'UiTooltipWrapper',

  props: [
    // eslint-disable-next-line vue/require-prop-types
    'to',
    // eslint-disable-next-line vue/require-prop-types
    'toSelector',
    // eslint-disable-next-line vue/require-prop-types
    'toElement',
    // eslint-disable-next-line vue/require-prop-types
    'content',
    // eslint-disable-next-line vue/require-prop-types
    'enabled',
    // eslint-disable-next-line vue/require-prop-types
    'visible',
    // eslint-disable-next-line vue/require-prop-types
    'triggerTarget',
    // eslint-disable-next-line vue/require-prop-types
    'trigger',
    // eslint-disable-next-line vue/require-prop-types
    'hideOnClick',
    // eslint-disable-next-line vue/require-prop-types
    'interactive',
    // eslint-disable-next-line vue/require-prop-types
    'shownDelay',
    // eslint-disable-next-line vue/require-prop-types
    'hiddenDelay',
  ],

  data() {
    return {
      tip: null,
      options: {},
    };
  },

  computed: {
    isManualTrigger() {
      return this.options.trigger === 'manual';
    },
  },

  watch: {
    content() {
      if (this.tip) {
        this.tip.setProps(this.getOptions());
      }
    },

    enabled(val) {
      if (!this.tip) return;
      if (val) {
        this.tip.enable();
      } else {
        this.tip.hide();
        this.tip.disable();
      }
    },

    visible(val) {
      if (!this.tip) return;
      if (val) {
        this.tip.show();
      } else {
        this.tip.hide();
      }
    },
  },

  mounted() {
    this.init();
  },

  updated() {
    if (this.tip && !this.content) {
      this.tip.setProps(this.getOptions());
    }
  },

  beforeUnmount() {
    if (!this.tip) return;
    this.tip.destroy();
  },

  methods: {
    init() {
      if (this.tip) {
        try {
          this.tip.destroy();
        } catch (error) {
          // Do nothing
        }
        this.tip = null;
      }

      let elm = this.toElement;

      if (elm == null) {
        if (this.to) {
          elm = document.querySelector(`[name='${this.to}']`);
        } else if (this.toSelector) {
          elm = document.querySelector(this.toSelector);
        } else if (
          this.$refs.trigger
          && this.$refs.trigger.childElementCount > 0
        ) {
          elm = this.$refs.trigger;
        } else {
          elm = this.$el.parentElement;
        }
      }

      if (!elm) {
        return;
      }
      const tip = tippy(elm, this.getOptions());

      if (!tip) {
        return;
      }

      if (Array.isArray(tip)) {
        if (tip.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.tip = tip[0];
        } else {
          return;
        }
      }

      this.tip = tip;

      this.$emit('on-create', this.tip);
      this.$emit('init', this.tip);

      if (this.enabled === false) {
        this.tip.disable();
      }

      if (this.isManualTrigger && this.visible === true) {
        this.tip.show();
      }
    },

    tippy() {
      return this.tip;
    },

    filterOptions() {
      const getValue = (key, value) => {
        // eslint-disable-next-line no-prototype-builtins
        if (booleanProps.hasOwnProperty(key)) {
          if (value === '') return true;
          if (value === 'false') return false;
          if (value === 'true') return true;
        }

        return value;
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(this.options)) {
        // eslint-disable-next-line no-prototype-builtins
        if (!defaultProps.hasOwnProperty(key)) {
          // We're replacing this.options anyway, we don't have to worry about modifying the object
          delete this.options[key];
        } else {
          this.options[key] = getValue(key, this.options[key]);
        }
      }

      return this.options;
    },

    getOptions() {
      Object.assign(
        this.options,
        mapKeys(
          this.$attrs,
          (value, key) => camelCase(key),
        ),
      );

      this.filterOptions();

      if (!this.options.onShow && this.$attrs.onShow) {
        this.options.onShow = (...args) => this.$attrs.onShow(...args);
      }

      if (!this.options.onShown) {
        this.options.onShown = (...args) => {
          this.$emit('shown', ...args);
        };
      }

      if (!this.options.onHidden) {
        this.options.onHidden = (...args) => {
          this.$emit('hidden', ...args);
        };
      }

      if (!this.options.onHide && this.$attrs.onHide) {
        this.options.onHide = (...args) => this.$attrs.onHide(...args);
      }

      if (!this.options.onMount) {
        this.options.onMount = (...args) => {
          this.$emit('mount', ...args);
        };
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!this.options.hasOwnProperty('content')) {
        this.options.content = this.content ? this.content : this.$refs.content;
      }

      if (this.trigger) {
        this.options.trigger = this.trigger;
      }

      if (this.interactive) {
        this.options.interactive = this.interactive;
      }

      if (typeof (this.hideOnClick) !== 'undefined') {
        this.options.hideOnClick = this.hideOnClick;
      }

      this.options.triggerTarget = this.triggerTarget;
      this.options.offset = [0, 5];

      this.options.delay = [this.shownDelay || null, this.hiddenDelay || null];
      this.options.zIndex = 27;

      return this.options;
    },
  },
};
