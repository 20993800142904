<template>
  <button
    :class="classes"
    data-testid="UiButton"
  >
    <span
      :class="$style.content"
      :data-qa="contentDataQa"
    >
      <svg-icon
        v-if="prependIcon"
        :class="[$style.prependIcon, iconClassName]"
        :name="prependIcon"
      />
      <span :data-qa="textDataQa">
        <svg-icon
          v-if="icon"
          :class="[
            $style.centralIcon,
            iconClassName,
          ]"
          :name="icon"
          :svg-use-data-qa="svgUseDataQa"
        />
        <slot v-else />
      </span>
      <svg-icon
        v-if="appendIcon"
        :class="[$style.appendIcon, iconClassName]"
        :name="appendIcon"
      />
    </span>
    <span
      v-if="isLoading"
      :class="$style.loader"
    >
      <ui-progress-circular
        size="12"
        width="1.5"
        indeterminate
        button
      />
    </span>
  </button>
</template>

<script>
import UiProgressCircular from '@/features/shared/kit/UiProgressCircular.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

export default {
  name: 'UiButton',

  components: {
    UiProgressCircular,
    SvgIcon,
  },

  props: {
    appearance: {
      type: String,
      default: 'filled',
      validator: (value) => ['outline', 'filled', 'clean', 'icon'].includes(value),
    },

    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'danger'].includes(value),
    },

    icon: {
      type: String,
      default: null,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    prependIcon: {
      type: String,
      default: null,
    },

    iconClassName: {
      type: String,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    contentDataQa: {
      type: String,
      default: null,
    },

    textDataQa: {
      type: String,
      default: null,
    },

    svgUseDataQa: {
      type: String,
      default: null,
    },
  },

  computed: {
    classes() {
      const {
        $style, appearance, color, icon, prependIcon, appendIcon,
      } = this;

      return {
        [$style.base]: true,
        [$style[appearance]]: true,
        [$style[color]]: true,
        [$style.isHaveIcon]: icon,
        [$style.isHavePrependIcon]: prependIcon,
        [$style.isHaveAppendIcon]: appendIcon,
        [$style.isLoading]: this.isLoading,
      };
    },
  },
};
</script>

<style module>
.base {
  position: relative;
  font: inherit;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 1.15;

  border: 1px solid;
  border-radius: var(--border-radius-base);
  outline: none;

  display: flex;
  place-items: center;
  place-content: center;

  padding: 0 20px;
  height: var(--size-form-element-height);

  transition: border-color, background-color var(--timing-blink-animation) ease-in-out;
}

.base:not(:disabled) {
  cursor: pointer;
}

.base.isHavePrependIcon {
  padding-left: 8px;
}

.base.isHaveAppendIcon {
  padding-right: 8px;
}

.base.isHaveIcon {
  padding: 0 4px;
}

.filled {
  border-color: transparent;
  color: var(--color-white-100);
}

.filled:disabled {
  background-color: var(--color-black-15);
  color: var(--color-black-33);
}

.filled:disabled .prependIcon,
.filled:disabled .appendIcon {
  color: var(--color-black-15);
}

.filled.primary:not(:disabled) {
  background-color: var(--color-navy-500);
}

.filled.primary:not(:disabled):hover {
  background-color: var(--color-navy-600);
}

.filled.primary:not(:disabled):active {
  background-color: var(--color-navy-600);
}

.filled.danger:not(:disabled) {
  background: var(--color-red-400);
}

.filled.danger:not(:disabled):hover {
  background-color: var(--color-red-400);
}

.filled.danger:not(:disabled):active {
  background-color: var(--color-red-500);
}

.clean {
  background: transparent;
  border-color: transparent;
}

.clean:disabled {
  color: var(--color-black-33);
}

.clean:disabled .prependIcon,
.clean:disabled .appendIcon {
  color: var(--color-black-15);
}

.clean.primary:not(:disabled) {
  color: var(--color-navy-500);
}

.clean.primary:not(:disabled):hover {
  background: var(--color-navy-07);
}

.clean.primary:not(:disabled):active {
  background: var(--color-navy-14);
}

.clean.primary:not(:disabled) .prependIcon,
.clean.primary:not(:disabled) .appendIcon {
  color: var(--color-black-65);
}

.clean.danger:not(:disabled) {
  color: var(--color-red-400);
}

.clean.danger:not(:disabled):hover {
  background: var(--color-red-50);
}

.clean.danger:not(:disabled):active {
  background: var(--color-red-50);
}

.clean.danger:not(:disabled):active .prependIcon,
.clean.danger:not(:disabled):active .appendIcon {
  color: var(--color-black-65);
}

.icon {
  background: transparent;

  border-radius: 0;
  border: none;
  border-bottom: 2px solid transparent;
}

.icon:disabled {
  color: var(--color-black-15);
}

.icon.primary:not(:disabled) {
  color: var(--color-black-65);
}

.icon.primary:not(:disabled):hover,
.icon.primary:not(:disabled):active {
  border-color: var(--color-navy-500);
}

.icon.primary:not(:disabled):active {
  color: var(--color-navy-500);
}

.outline {
  background-color: transparent;
}

.outline.primary:not(:disabled) {
  border-color: var(--color-navy-500);
  color: var(--color-navy-500);
}

.outline.primary:not(:disabled):hover {
  border-color: var(--color-navy-500);
  color: var(--color-navy-500);
}

.outline.primary:not(:disabled):active {
  border-color: var(--color-navy-600);
  color: var(--color-navy-600);
}

.outline.danger:not(:disabled) {
  border-color: var(--color-red-400);
  color: var(--color-red-400);
}

.outline.danger:not(:disabled):hover {
  border-color: var(--color-red-400);
  color: var(--color-red-400);
}

.outline.danger:not(:disabled):active {
  border-color: var(--color-red-500);
  color: var(--color-red-500);
}

.centralIcon {
  width: 24px;
  height: 24px;
  color: var(--color-black-65);
}

.prependIcon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.appendIcon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.content {
  display: flex;
  align-items: center;
}

.isLoading {
  pointer-events: none;
}

.isLoading .content {
  opacity: 0;
}

.isLoading .loader {
  opacity: 1;
}
</style>
