import {
  StatisticsModel,
  StatisticsResponse,
} from '@/features/fuel/statistics/types';
import { CompanyCounters } from '@/features/fuel/shared/types';
import { CompanyNames } from '@/features/fuel/shared/constants';

export default class StatisticsMapper {
  static fromResponse(item: StatisticsResponse): StatisticsModel {
    return {
      costs: formatCompanyCounters(item.costs),
      refueled: formatCompanyCounters(item.refueled),
      currentBalance: item.currentBalance ?? 0,
      lastReplenishment: item.replenishment ? item.replenishment[0] : null,
    };
  }
}

const formatCompanyCounters = (counters: CompanyCounters): CompanyCounters => {
  const companyNames = Object
    .keys(counters)
    .filter((key) => CompanyNames[key]);

  return Object.fromEntries(companyNames.map((key) => [
    CompanyNames[key],
    counters[key],
  ]));
};
