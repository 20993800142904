import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import { API_USER_ACTION_URL } from '@/features/srm/services/endpoints';

export const SRM_USER_SERVICE_ID = Symbol('srmUserService');

@Register(
  SRM_USER_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMUserService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMUserHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  updateUser(user: UserModel): Promise<void> {
    return this.request({
      method: 'PUT',
      url: API_USER_ACTION_URL,
      data: user,
    });
  }
}

export default SRMUserService;
