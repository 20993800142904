
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';

export default {
  name: 'AppSidebarFuel',

  components: {
    AppSidebarButton,
  },

  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  computed: {
    clientsRoute(): RouteLocationRaw {
      const clientsTableRoute = { name: 'fuel-clients' };
      const currentClientCardRoute = {
        name: 'fuel-client-card',
        params: {
          clientId: 0,
        },
      };

      return this.$can('read', 'FuelClients') ? clientsTableRoute : currentClientCardRoute;
    },

    clientRouteCaption(): RouteLocationRaw {
      return this.$can('read', 'FuelClients') ? 'Клиенты' : 'Мой профиль';
    },
  },
};
