
import { PropType } from 'vue';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';

export default {
  name: 'AppSidebarOrder',

  components: { AppSidebarButton },

  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  computed: {
    configService(): ConfigService {
      return container.get(CONFIG_SERVICE_ID);
    },

    authService(): AuthService {
      return container.get(AUTH_SERVICE_ID);
    },
  },
};
