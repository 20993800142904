
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';
import AppSidebarMenuItem from '@/features/redesigned/shared/layout/AppSidebarMenuItem.vue';
import { autoScreenshot } from '@/utils/handlers';

export default defineComponent({
  name: 'AppSidebarSupport',

  components: {
    AppSidebarMenuItem,
  },

  props: {
    isCollapsed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    const isLoading = ref(false);

    window.onmessage = (message: MessageEvent) => {
      if (message.data === 'skip:loading') {
        isLoading.value = false;
      }
    };

    const setPageUrl = () => {
      window.localStorage.setItem('pageUrl', window.location.href);
    };
    const relocate = async () => {
      isLoading.value = true;
      setPageUrl();
      await autoScreenshot('/technical-support');
    };

    return {
      isLoading,
      relocate,
    };
  },
});
