import {
  Client,
  IMessage,
} from '@stomp/stompjs';
import { debounce } from 'lodash';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { AxiosResponse } from 'axios';
import {
  ValidationRule,
  ValidatorFn,
} from '@vuelidate/core';
import {
  DocumentStatuses,
  DocumentType,
  EntityType,
  FileKind,
  SortDirection,
} from '@/features/shared/constant';

export type Nullable<T> = T | null;
export type Undefinable<T> = T | undefined;

export interface Headers {
  title: string;
  key: string;
  show?: boolean;
  isSingleLine?: boolean;
}

export interface MultiSelectItem {
  id: string | number;
  title?: string;
  name?: string;
  value?: string;
  filter?: string;
  code?: string;
}

export interface TabsHeader<T> {
  title: string;
  name: T;
  count: number;
}

export interface City {
  id: number;
  cityCode: string;
  directionId: number;
  name: string;
  sysId1c?: string;
  predefined?: boolean;
  deleteMark?: boolean;
  administrativeCenter?: boolean;
  code?: string;
  latitude?: string;
  longitude?: string;
  regionId?: string;
  municipalDistrictId?: string;
}

export interface UserModel {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  position?: string;
  email: string;
  password?: string;
  shipAgentId?: string;
  userAdId?: string;
  adDomain?: string;
  adLogin?: string;
  numericPhoneNumber?: number;
  token?: string;
  smsCode?: number;
  carrierStatus: CarrierStatusModel;
  icqUserId: Nullable<string>;
  telegramUserId: Nullable<string>;
}

export interface CarrierStatusModel {
  value: string;
  title: string;
  name?: string;
  reason: Nullable<CarrierStatusReason>;
}

export interface CarrierStatusReason {
  id: Nullable<string>;
  name?: string;
}

export type ToReturn = RouteLocationNormalizedLoaded & {
  query: {
    id: string;
  };
};

export interface Counterparty {
  id: string;
  sysId1c: string;
  inn?: string;
  kpp?: string;
  ogrn?: string;
  name?: string;
  fullName?: string;
  legalEntity?: string;
}

export interface PaginationDTO {
  page?: number;
  size?: number;
}

export interface Sort {
  unsorted?: boolean;
  sorted?: boolean;
  empty?: boolean;
}

export interface Styles {
  [key: string]: boolean;
}

export interface BorderColor {
  borderColor?: string;
  backgroundColor?: string;
}

export interface Pageable {
  sort?: Sort;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export interface Page<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  sort?: Sort;
  size: number;
  number: number;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  pageable?: Pageable;
  empty?: boolean;
}

export interface TypesRoadMapPoint {
  code?: number;
  color?: string;
  consigneeLabel?: string;
  icon?: string;
  locationLabel?: string;
  name?: string;
}

export interface Validation<T> {
  $anyDirty: boolean;
  $anyError: boolean;
  $dirty: boolean;
  $error: boolean;
  $invalid: boolean;
  $model?: T | number | string;
  // Any оставил специально, так как там может быть все что угодно
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $params?: any;
  $pending: boolean;
  $touch(): void;
}

export type ValidationProperties<T> = {
  [Key in keyof T]: Validation<T[Key]>;
};

export interface ValidationSchema {
  [key: string]:
    | ValidationSchema
    | ValidatorFn
    | ValidationRule
    | string[];
}

export interface MenuItem {
  id: number;
  isAvailable: boolean;
  title: string;
  block: string;
  dataQa: string;
}

export interface Style {
  [key: string]: boolean;
}

export interface InlineStyle {
  [key: string]: string;
}

export interface Params {
  [key: string]: string;
}

export interface KeyValue<T = string> {
  key: string;
  value: T;
}

export interface WsSubscribeType {
  client: Client;
  destination: string;
  messageCallback: (msg: IMessage) => void;
  subscriptionId: string;
}

export interface WsSendType {
  // TODO добавить тип
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  client: Client;
  destination: string;
}

export interface Carrier {
  atiNumber: string;
  attractedVehiclesCount: number;
  carrierTypeId: number;
  commercialRatingId: number;
  containerPlatformsCount: number;
  contractTrackNumber: string;
  createdBy: string;
  createdDate: Date;
  developmentStatusId: number;
  domesticInsuranceAmount: number;
  domesticInsuranceEnd: Date;
  domesticInsuranceStart: Date;
  garage: string;
  hasDomesticInsurance: boolean;
  hasInternationalInsurance: boolean;
  hasNavi: boolean;
  id: string;
  internationalInsuranceAmount: number;
  internationalInsuranceEnd: Date;
  internationalInsuranceStart: Date;
  isDeleted: boolean;
  isNeedUpload: boolean;
  logistEmail: string;
  logistFirstName: string;
  logistLastName: string;
  logistMiddleName: string;
  logistPhone: number;
  modifiedBy: string;
  modifiedDate: Date;
  ownRefCount: number;
  ownTentCount: number;
  rate: number;
  securityCategory: string;
  shippingType: number;
  status: number;
  tinyFormatVehiclesCount: number;
  totalVehiclesCount: number;
  version: string;
}

export enum TopLevelStatus {
  NEW = 'NEW',
  IN_WORK = 'IN_WORK',
  CONFIRMATION = 'CONFIRMATION',
  EXECUTION = 'EXECUTION',
  CANCELED = 'CANCELED',
}

export enum LowLevelStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  BOOKED = 'BOOKED',
  CONFIRMATION = 'CONFIRMATION',
  RESOURCES_ASSIGNED = 'RESOURCES_ASSIGNED',
  EM_APPROVED = 'EM_APPROVED',
  ESS_APPROVED = 'ESS_APPROVED',
  CLIENT_APPROVED = 'CLIENT_APPROVED',
  EXECUTION_AWAITING = 'EXECUTION_AWAITING',
  EXECUTION_STARTED = 'EXECUTION_STARTED',
  EXECUTION_ENDED = 'EXECUTION_ENDED',
  DOCUMENTS_CHECKED = 'DOCUMENTS_CHECKED',
  CLIENT_SIGNING = 'CLIENT_SIGNING',
  CANCELED = 'CANCELED',
}

export type UrlQuery = string | (string | null)[];

export interface DocumentFileModel {
  id?: Nullable<string | number>;
  fileKindId?: FileKind;
  fileName?: string;
  file?: string;
  name?: Nullable<string>;
  ref?: Nullable<File>;
  type?: Nullable<string | boolean>;
  thumbnail?: Nullable<string>;
  comment?: string;
  documentId?: string;
  fileId?: Nullable<string | FileKind>;
  status?: number;
  size?: Nullable<string>;
  uploadingProgress?: Nullable<string>;
  isDeleteAllowed?: Nullable<boolean>;
  uploaded?: boolean;
}

export interface DocumentModel {
  id: string;
  ref: Nullable<File>;
  typeId: DocumentType;
  series?: string;
  number?: string;
  issuedBy?: string;
  issuedDate?: string | Date;
  relatedEntityId?: string;
  relatedEntityType?: EntityType;
  inPatchMode?: boolean;
  documentFiles: DocumentFileModel[];
  status: DocumentStatuses;
}

export interface FileEvent {
  lengthComputable: boolean;
  loaded: number;
  total: number;
}

export interface DictionaryModel {
  id?: number | string;
  name?: string;
}

export interface EntityPanelStep<T> {
  children?: EntityPanelStepChildren[] | [];
  createOrder?: number;
  editOrder?: number;
  previewOrder?: number;
  preview?: KeyValue[];
  target?: string;
  title?: string;
  validationGroup?: Validation<T>;
  validationFields?: Validation<unknown>[];
}

export interface EntityPanelStepChildren {
  title: string;
  preview: KeyValue[];
  target: string;
  validationGroup: Validation<unknown>;
}

interface AxiosResponseErrorConfig<T> {
  method: string;
  timeout: number;
  baseURL: string;
  url: string;
  xsrfCookieName: string;
  xsrfHeaderName: string;
  maxBodyLength: number;
  maxContentLength: number;
  data: T;
}

interface AxiosResponseErrorData {
  detail: string;
  instance: string;
  status: number;
  stacktrace: string[];
  title: string;
}

export interface AxiosResponseError<T> {
  response: {
    status: number;
    statusText: string;
    data: AxiosResponseErrorData;
    config: AxiosResponseErrorConfig<T>;
  };
  message: string;
}

export interface Individual {
  id: string;
  sysId1c: string;
  fio: string;
  identityCard?: null | string | number;
}

export interface VideoSource extends MediaSource {
  getVideoTracks: () => MediaStreamTrack[];
}

export interface DisplayMediaStreamConstraints {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
}

export interface Logger {
  captureException: (
    error: Error,
    response: { extra: { response: AxiosResponse } }
  ) => void;
}

export interface FilterParams {
  [key: string]: string | number | boolean | null | undefined;
}

export interface SortParams {
  [key: string]: SortDirection | null | undefined;
}

export interface RouteParams<T> {
  name: string;
  params: {
    [key: string]: T;
  };
}

export interface Region {
  id?: string;
  sysId1c?: string;
  isPredefined?: boolean;
  isDeleteMark?: boolean;
  name?: string;
  code?: string;
  countryId?: string;
  isoCode?: string;
  district?: string;
  timeZone?: string;
  route?: string;
}

export type Coordinates = [number | string, number | string];

export interface Status {
  id: number;
  description: string;
  originalEnumName: string;
}

export interface MultiSelectOption<T> {
  name: string;
  value: T;
}

export interface ReadedDocumentFiles {
  documentId?: string;
  file?: string;
  fileKindId?: FileKind;
  fileName?: Nullable<string>;
}

export interface DocumentFile {
  documentFiles?: ReadedDocumentFiles[];
  relatedEntityId?: string;
  relatedEntityType?: number;
  fileId?: string;
  ref?: Nullable<File>;
  id?: Nullable<string>;
  name?: Nullable<string>;
  uploaded?: boolean;
  size?: Nullable<number>;
  status?: number;
  thumbnail?: Nullable<string | ArrayBuffer>;
  type?: Nullable<string>;
  uploadingProgress?: Nullable<number>;
  typeId?: DocumentType;
  series?: Nullable<string>;
  number?: Nullable<number>;
  issuedBy?: Nullable<string>;
  issuedDate?: Nullable<Date>;
  fileName?: string;
}

export interface SidebarMenuBlockRow {
  sidebar: Undefinable<boolean>;
  key: string;
  value: string | SidebarMenuBlockRow[];
}

export enum RoadMapPointType {
  Loading = 'loading',
  Unloading = 'unloading',
  Transportation = 'transportation',
  EmptyCrossing = 'empty_crossing',
  Parking = 'parking',
  Ready = 'ready',
}

export type Debounce = ReturnType<typeof debounce>;

export interface EnvironmentInfo {
  environment: string;
  version: string;
}

export enum ContactType {
  CarrierEmployee = 'CARRIER_EMPLOYEE',
  Logist = 'LOGIST',
  Chief = 'CHIEF',
}

export interface ContactModel {
  id?: Nullable<string>;
  counterpartyId?: string;
  counterpartyName?: string;
  counterpartyInn?: string;
  counterpartyKpp?: string;
  type?: string;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  middleName?: Nullable<string>;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  fullName?: Nullable<string>;
}

export interface PropsInputDatepicker {
  name: Nullable<string>;
  type: Nullable<string>;
  autocomplete: Nullable<string>;
  value: Nullable<string>;
  placeholder: Nullable<string>;
  readonly: boolean;
  disabled: boolean;
}

export interface Tab {
  title: string;
  name: string;
}

export interface TrailerVolumeType {
  id: number;
  code: string;
  name: string;
}

export interface AxiosErrorResponce {
  response: {
    data: {
      userMessage?: string;
      message?: string;
      title?: string;
      error?: string;
    };
    status: number;
  };
}

export interface TrailerVolumeTypeOption {
  id?: number;
  code: string;
  name: string;
}

export interface PageParams {
  page?: number;
  size?: number;
  filter?: Nullable<string[]>;
  sort?: Nullable<string[]>;
  searchQuery?: Nullable<string>;
}

export interface DateStringRange {
  fromDate: Nullable<string>;
  toDate: Nullable<string>;
}

export interface RoadMapType {
  code: string;
  name: string;
}

export interface GetUserAuthorizationCode {
  adLogin: string;
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export type DateValue = string | Date | number | Array<string | Date | number>;
