import { CardRequestModel } from '@/features/fuel/shared/types';
import {
  CardModel,
} from '@/features/fuel/cards/types';

export default class CardMapper {
  static toRequest(item: CardModel): CardRequestModel {
    return {
      id: item.id ?? null,
      number: item.number ?? null,
      block: item.block ?? null,
      blockClient: item.blockClient ?? null,
      blockAdmin: item.blockAdmin ?? null,
      unblockAbility: item.unblockAbility ?? null,
      vink: item.vink?.id ?? null,
      groupCards: item.groupCards?.id ?? null,
      cardOwner: item.cardOwner ?? null,
    };
  }

  static fromResponse(item: CardModel): CardModel {
    return {
      id: item.id,
      number: item.number,
      block: item.block,
      blockClient: item.blockClient,
      blockAdmin: item.blockAdmin,
      unblockAbility: item.unblockAbility,
      vink: item.vink,
      groupCards: item.groupCards,
      cardOwner: item.cardOwner,
    };
  }
}
