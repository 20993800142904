import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$can('showSidebarButton', 'AppSidebarRequests'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 0,
          caption: "Заявки",
          "data-qa": "ButtonAppSidebarRequests",
          icon: "paper",
          to: { name: 'requests' },
          "included-routes": [
        'requests',
        'requests-edit',
        'requests-create',
        'requests-template',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('showSidebarButton', 'AppSidebarRequests'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 1,
          caption: "Клиенты",
          "data-qa": "ButtonAppSidebarClients",
          icon: "person",
          to: { name: 'requests-client-list' },
          "included-routes": [
        'requests-client-locations',
        'requests-client-list',
        'requests-client-mrz',
        'requests-client',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('showSidebarButton', 'AppSidebarRequests'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 2,
          caption: "Типовые заявки",
          "data-qa": "ButtonAppSidebarRequestsLoader",
          icon: "copy",
          to: { name: 'requests-documents' },
          "included-routes": [
        'requests-documents',
        'requests-documents-edit',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('read', 'TruckOfferingTable'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 3,
          caption: "Свободные машины",
          icon: "truck_ref",
          "additional-icon": "plus",
          to: { name: 'crm-truck-offering' },
          "included-routes": ['crm-truck-offering'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('viewTariffs', 'AppSidebarRequests'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 4,
          caption: "Тарифные сетки",
          "data-qa": "ButtonAppSidebarTariffs",
          icon: "person",
          to: { name: 'requests-tariffs' },
          "included-routes": [
        'requests-tariffs',
      ],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true)
  ]))
}