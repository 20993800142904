
import {
  computed,
  defineComponent,
} from 'vue';
import container from '@/di';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import {
  Nullable,
  Undefinable,
  UserModel,
} from '@/features/shared/types';
import { ShipAgentId } from '@/features/shared/constant';

export default defineComponent({
  name: 'AppHeaderManagerExpedition',

  components: {
    SvgIcon,
  },

  setup() {
    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const user = computed<Undefinable<UserModel>>(() => userModule.value.user);

    const userName = computed<string>(() => (user.value ? `${user.value.lastName} ${user.value.firstName.charAt(0)}.` : ''));

    const currentUserCompany = computed<Nullable<string>>(() => {
      switch (user.value?.shipAgentId) {
        case ShipAgentId.Gtl:
          return 'Глобалтрак';
        case ShipAgentId.Longrun:
          return 'Лонгран';
        case ShipAgentId.Lorry:
          return 'Лорри';
        case ShipAgentId.Magna:
          return 'Магна';
        case ShipAgentId.Gruzoprovod:
          return 'Грузопровод';
        default:
          return null;
      }
    });

    return {
      user,
      userName,
      currentUserCompany,
    };
  },
});
