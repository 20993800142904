import { AxiosRequestConfig } from 'axios';

import container from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';

// eslint-disable-next-line import/prefer-default-export
export function authRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const configService = container.get<ConfigService>(CONFIG_SERVICE_ID);

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Token ${configService.suggestionsServiceKey}`;
  return config;
}
