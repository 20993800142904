import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_input_text = _resolveComponent("ui-input-text")!
  const _component_ui_tooltip_with_content = _resolveComponent("ui-tooltip-with-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_ui_input_text, {
      id: "search-header",
      "model-value": _ctx.searchValue,
      class: _normalizeClass(_ctx.$style.searchInput),
      placeholder: "Найти",
      "left-icon": "search",
      clearable: "",
      "onUpdate:modelValue": _ctx.onSearchInput
    }, {
      append: _withCtx(() => [
        (_ctx.searchValue)
          ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 0,
              name: "close_light",
              class: _normalizeClass(_ctx.$style.clearIcon),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSearchInput(null)))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model-value", "class", "onUpdate:modelValue"]),
    (!_ctx.searchValue)
      ? (_openBlock(), _createBlock(_component_ui_tooltip_with_content, {
          key: 0,
          placement: "bottom-start",
          "shown-delay": 400,
          "hidden-delay": 0,
          distance: 12,
          "to-selector": "#search-header"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Поиск по странице ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}