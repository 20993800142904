import container, {
  Inject,
  Injectable,
} from '@/di';

import SRMUserService, { SRM_USER_SERVICE_ID } from '@/features/srm/services/SRMUserService';

import {
  PhoneCodeRequest,
  EmailCodeRequest,
  VerificationCodeResponse,
  SendCodeResponse,
} from '@/features/shared/services/verification/types';

import {
  SEND_CODE_ON_PHONE,
  SEND_CODE_ON_EMAIL,
  VERIFIY_PHONE_CODE_URL,
  VERIFIY_EMAIL_CODE_URL,
} from '@/features/shared/services/verification/endpoints';

export const VERIFICATION_SERVICE_ID = Symbol('verification-service');

@Injectable()
export default class VerificationService {
  srmUserService;

  constructor(@Inject(SRM_USER_SERVICE_ID) srmUserService: SRMUserService) {
    this.srmUserService = srmUserService;
  }

  sendCodeOnPhone(phone: string): Promise<SendCodeResponse> {
    return this.srmUserService.request({
      method: 'POST',
      url: SEND_CODE_ON_PHONE(phone),
    });
  }

  sendCodeOnEmail(email: string): Promise<SendCodeResponse> {
    return this.srmUserService.request({
      method: 'POST',
      url: SEND_CODE_ON_EMAIL(email),
    });
  }

  verifyPhoneCode({ phone, code }: PhoneCodeRequest): Promise<VerificationCodeResponse> {
    return this.srmUserService.request({
      method: 'GET',
      url: VERIFIY_PHONE_CODE_URL(phone),
      params: { code },
    });
  }

  verifyEmailCode({ email, code }: EmailCodeRequest): Promise<VerificationCodeResponse> {
    return this.srmUserService.request({
      method: 'GET',
      url: VERIFIY_EMAIL_CODE_URL(email),
      params: { code },
    });
  }
}

container
  .bind<VerificationService>(VERIFICATION_SERVICE_ID)
  .to(VerificationService)
  .inSingletonScope();
