import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_with_content = _resolveComponent("ui-modal-with-content")!

  return (_openBlock(), _createBlock(_component_ui_modal_with_content, {
    target: "modal",
    "overlay-color": "dark-blue",
    width: 600,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.$style.title)
      }, " Запрос отправлен ", 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.banner)
      }, [
        _createVNode(_component_svg_icon, {
          name: "check_circle_big",
          class: _normalizeClass(_ctx.$style.bannerIcon)
        }, null, 8, ["class"]),
        _createElementVNode("p", null, "Ваш запрос на присоединение к " + _toDisplayString(_ctx.carrierName) + " успешно отправлен", 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.text)
        }, " Рассмотрение запросов обычно занимает 1 час. Мы уведомим вас о результате рассмотрения на почту. ", 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.text)
        }, " В случае возникновения проблем, обратитесь к менеджеру. ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contact)
        }, [
          _createVNode(_component_svg_icon, {
            name: "person_shoulders",
            class: _normalizeClass(_ctx.$style.contactIcon)
          }, null, 8, ["class"]),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.text)
          }, _toDisplayString(_ctx.MPPName), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.contact)
        }, [
          _createVNode(_component_svg_icon, {
            name: "mobile_ring",
            class: _normalizeClass(_ctx.$style.contactIcon)
          }, null, 8, ["class"]),
          _createElementVNode("a", {
            href: _ctx.MPPPhoneHref,
            class: _normalizeClass([_ctx.$style.text, _ctx.$style.phone])
          }, _toDisplayString(_ctx.formatPhone(_ctx.MPPPhone)), 11, _hoisted_1)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.buttonWrapper)
      }, [
        _createVNode(_component_ui_button, {
          class: _normalizeClass(_ctx.$style.button),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Понятно ")
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ]),
    _: 1
  }))
}