import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input_text = _resolveComponent("ui-input-text")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_date_picker, _mergeProps(_ctx.$attrs, {
      ref: "calendar",
      value: $props.modelValue,
      class: _ctx.$style.datepicker,
      inline: $props.inline,
      "input-class": {},
      "popup-class": _ctx.$style.datepickerPopup,
      "disabled-date": $options.disabledDate,
      "disabled-time": $options.disabledTime,
      format: $props.format,
      formatter: $data.dayjsFormat,
      "value-type": $props.valueType,
      multiple: $props.multiple,
      range: $props.range,
      clearable: $props.clearable,
      type: $props.type,
      disabled: $props.disabled,
      confirm: $props.confirmByButton,
      "confirm-text": $props.confirmButtonText,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', $event)))
    }), {
      input: _withCtx(({ value, props }) => [
        _createVNode(_component_ui_input_text, _mergeProps(props, {
          "model-value": value,
          "is-invalid": $props.isInvalid,
          "input-data-qa": $props.qaKey,
          placeholder: $props.placeholder,
          "onUpdate:modelValue": $options.onChangeInputHandler
        }), null, 16, ["model-value", "is-invalid", "input-data-qa", "placeholder", "onUpdate:modelValue"])
      ]),
      "icon-calendar": _withCtx(() => [
        _createVNode(_component_svg_icon, {
          name: "calendar",
          class: _normalizeClass(_ctx.$style.icon),
          "svg-use-data-qa": $props.calendarSvgUseDataQa
        }, null, 8, ["class", "svg-use-data-qa"])
      ]),
      "icon-clear": _withCtx(() => [
        _createVNode(_component_svg_icon, {
          name: "cancel",
          class: _normalizeClass(_ctx.$style.icon)
        }, null, 8, ["class"])
      ]),
      _: 1
    }, 16, ["value", "class", "inline", "popup-class", "disabled-date", "disabled-time", "format", "formatter", "value-type", "multiple", "range", "clearable", "type", "disabled", "confirm", "confirm-text"])
  ], 2))
}