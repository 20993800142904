import {
  DateStringRange,
  Nullable,
  PageParams,
  Params,
} from '@/features/shared/types';
import {
  PageFilterParams,
  RangedPageFilterParams,
  RangedPageParams,
} from '@/features/fuel/shared/types';
import {
  formatDate,
  formatDateRangeToDateTimeString,
} from '@/filters/date';

export const mapFilterParams = <T = Params>(
  params?: T | null,
): string[] | null => {
  let result: string[] | null = null;
  const hasParams = params
    && Object.values(params).findIndex(
      (item) => item !== null && item !== undefined && item !== '',
    ) !== -1;

  if (hasParams && params) {
    result = Object.entries(params)
      .reduce((acc: [string, unknown][], item: [string, unknown]) => {
        if (Array.isArray(item[1])) {
          const entries: [string, unknown][] = item[1].map((value) => [item[0], value]);
          acc.push(...entries);
        } else {
          acc.push(item);
        }

        return acc;
      }, [])
      .filter((item) => item[1] !== null && item[1] !== undefined)
      .map((item) => `${item[0]}|${item[1]}`);
  }

  return result;
};

export const mapDatePeriod = (period?: Nullable<Date[]>): DateStringRange => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  const { fromDate, toDate } = formatDateRangeToDateTimeString(period ?? null);

  return {
    fromDate: fromDate ? formatDate(fromDate, dateFormat) : null,
    toDate: toDate ? formatDate(toDate, dateFormat) : null,
  };
};

export const mapPageParams = <T extends PageFilterParams>(
  params?: T,
  size = 15,
): PageParams => ({
    page: params?.page ? params.page - 1 : 0,
    size,
    filter: mapFilterParams(params?.filter),
    sort: mapFilterParams(params?.sort),
  });

export const mapRangedPageParams = <T extends RangedPageFilterParams>(
  params?: T,
): RangedPageParams => ({
    ...mapDatePeriod(params?.period),
    ...mapPageParams(params),
  });
