import {
  City,
  CityFilterValue,
} from '@/features/srm/types';

export default class CitiesMapper {
  static fromResponse(cities: City[]): CityFilterValue[] {
    return cities.map((city) => ({
      id: city.id,
      filter: city.name,
      parentId: city.regionId,
      region: city.region,
    }));
  }
}
