import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input_digital_code = _resolveComponent("ui-input-digital-code")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_default_transition = _resolveComponent("default-transition")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.prompt)
    }, " Письмо с кодом подтверждения было отправлено на вашу почту ", 2),
    _createVNode(_component_ui_input_digital_code, {
      value: _ctx.verifyCode,
      class: _normalizeClass(_ctx.$style.input),
      disabled: _ctx.isInputDigitalCodeDisabled,
      "is-error": _ctx.isNotVerifiedCode,
      onInput: _ctx.onInputVerifyCode,
      onComplete: _ctx.onCompletedVerifyCode
    }, null, 8, ["value", "class", "disabled", "is-error", "onInput", "onComplete"]),
    _createElementVNode("div", null, [
      _createVNode(_component_ui_button, {
        class: _normalizeClass(_ctx.$style.submitButton),
        "is-loading": _ctx.isLoading,
        disabled: _ctx.isSubmitButtonDisabled,
        onClick: _ctx.onClickSendCode
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Подтвердить ")
        ]),
        _: 1
      }, 8, ["class", "is-loading", "disabled", "onClick"]),
      _createVNode(_component_default_transition, null, {
        default: _withCtx(() => [
          _createVNode(_component_ui_button, {
            color: "secondary",
            appearance: "text",
            disabled: !_ctx.isCanRepeatSend,
            height: _ctx.ElementHeight.Xs,
            class: _normalizeClass(_ctx.$style.repeatSendButton),
            onClick: _ctx.onClickRepeatSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отправить код повторно "),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.countDownTimeInSeconds) + " сек)", 513), [
                [_vShow, !_ctx.isCanRepeatSend]
              ])
            ]),
            _: 1
          }, 8, ["disabled", "height", "class", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_ui_button, {
        appearance: "text",
        color: "secondary",
        class: _normalizeClass(_ctx.$style.backButton),
        "prepend-icon": "arrow_left_tiny",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:prev-step')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Назад ")
        ]),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}