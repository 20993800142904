
import {
  defineComponent,
} from 'vue';
import useSidebarTimer from '@/features/redesigned/shared/layout/composable/sidebar-timer';

export default defineComponent({
  name: 'AppSidebarTimer',

  setup() {
    const {
      date,
      time,
    } = useSidebarTimer();

    return {
      date,
      time,
    };
  },
});
