
import { PropType } from 'vue';

import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';

export default {
  name: 'AppSidebarClients',
  components: { AppSidebarButton },
  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
};
