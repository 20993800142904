
import { PropType } from 'vue';
import {
  required,
} from '@vuelidate/validators';
import UiFormGroup from '@/features/redesigned/shared/kit/UiFormGroup.vue';
import UiTextarea from '@/features/redesigned/shared/kit/UiTextarea.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import { Nullable } from '@/features/shared/types';
import { useVuelidate } from '@vuelidate/core';
import { maxL } from '@/utils/validators';

export default {
  name: 'FeedbackFormGroup',

  components: {
    UiButton,
    UiTextarea,
    UiFormGroup,
  },

  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    showCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      description: null as Nullable<string>,
    };
  },

  validations: {
    description: {
      required,
      maxL: maxL(300),
    },
  },

  methods: {
    onSubmit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.$emit('submit', this.description);
      this.description = null;
      this.v$.$reset();
    },
  },
};
