
import { PropType } from 'vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import inputMixin from '@/features/shared/kit/mixins/input';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';

export default {
  name: 'UiTextarea',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  props: {
    id: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    resize: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  computed: {
    inputClasses(): Styles {
      return {
        [this.$style.input]: true,
        [this.$style.resize]: this.resize,
        [this.$style.isDisabled]: this.disabled,
      };
    },

    internalId(): string {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `input-${this._.uid}`;
    },
  },
};
