
import {
  defineComponent,
  PropType,
} from 'vue';
import { Nullable } from '@/features/shared/types';

export default defineComponent({
  name: 'UiToast',

  props: {
    title: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    content: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },
  },
});
