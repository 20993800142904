import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '@/abilities.types';

// Клиентский менеджер
export function defineAbilitiesForClientManager(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('showSidebarButton', 'AppSidebarRequests');
  can('useSupport', 'AppLayout');

  can('read', 'TruckOfferingTable');
  can('use', 'TruckOfferingForKM');
}

export function defineAbilitiesForTariffAdministrator(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('viewTariffs', 'AppSidebarRequests');
}

export function defineAbilitiesForSupportBannerAdministrator(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('showBannerAdminPanel', 'AppSidebarRequests');
}

export function defineAbilitiesForDebtLimitAdministrator(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('edit', 'DebtLimit');
}
