import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!
  const _component_ui_input_text = _resolveComponent("ui-input-text")!
  const _component_ui_form_group = _resolveComponent("ui-form-group")!
  const _component_ui_textarea = _resolveComponent("ui-textarea")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_with_content = _resolveComponent("ui-modal-with-content")!
  const _component_ui_modal_confirm = _resolveComponent("ui-modal-confirm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_app_sidebar_button, {
      type: "div",
      caption: "Рассылка",
      icon: "mail_plus",
      "is-wide": $props.isWide,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.isCreatePostModalOpen = true))
    }, null, 8, ["is-wide"]),
    ($data.isCreatePostModalOpen)
      ? (_openBlock(), _createBlock(_component_ui_modal_with_content, {
          key: 0,
          target: "modal",
          width: 547,
          onClose: _cache[4] || (_cache[4] = ($event: any) => ($data.isCreatePostModalOpen = false))
        }, {
          header: _withCtx(() => [
            _createTextVNode(" Пользовательская рассылка ")
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.container)
            }, [
              _createVNode(_component_ui_form_group, { label: "Тема рассылки" }, {
                default: _withCtx(({ id }) => [
                  _createVNode(_component_ui_input_text, {
                    id: id,
                    modelValue: $data.subject,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.subject) = $event)),
                    modelModifiers: { trim: true },
                    maxlength: $data.maxSubjectLength,
                    placeholder: "Тема рассылки"
                  }, null, 8, ["id", "modelValue", "maxlength"])
                ]),
                _: 1
              }),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.length)
              }, _toDisplayString($data.subject.length) + " / " + _toDisplayString($data.maxSubjectLength), 3),
              _createVNode(_component_ui_form_group, { label: "Текст рассылки" }, {
                default: _withCtx(({ id }) => [
                  _createVNode(_component_ui_textarea, {
                    id: id,
                    modelValue: $data.content,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.content) = $event)),
                    class: _normalizeClass(_ctx.$style.textarea),
                    placeholder: "Текст рассылки",
                    maxlength: $data.maxContentLength
                  }, null, 8, ["id", "modelValue", "class", "maxlength"])
                ]),
                _: 1
              }),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.length)
              }, _toDisplayString($data.content.length) + " / " + _toDisplayString($data.maxContentLength), 3)
            ], 2)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.footerContainer)
            }, [
              _createVNode(_component_ui_button, {
                appearance: "outline",
                onClick: $options.clearForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Отмена ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ui_button, {
                disabled: $options.isDisabledViewButton,
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.isPreviewModalOpen = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Предварительный просмотр ")
                ]),
                _: 1
              }, 8, ["disabled"])
            ], 2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($data.isPreviewModalOpen)
      ? (_openBlock(), _createBlock(_component_ui_modal_with_content, {
          key: 1,
          target: "modal",
          width: 800,
          "show-close": false
        }, {
          header: _withCtx(() => [
            _createTextVNode(" Предварительный просмотр ")
          ]),
          default: _withCtx(() => [
            _createElementVNode("h3", {
              class: _normalizeClass(_ctx.$style.title)
            }, " Тема: ", 2),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString($data.subject), 3),
            _createElementVNode("h3", {
              class: _normalizeClass(_ctx.$style.title)
            }, " Содержание: ", 2),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString($data.content), 3)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.footerContainer)
            }, [
              _createVNode(_component_ui_button, {
                appearance: "outline",
                onClick: $options.backToEdit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Вернуться к редактированию ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ui_button, {
                "append-icon": "mail_plus",
                appearance: "outline",
                onClick: $options.onClickSendPostToInitiator
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Отправить инициатору ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ui_button, {
                "append-icon": "mail_plus",
                onClick: $options.onClickSendPostToUsers
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Отправить пользователям ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($data.isConfirmExitModalOpen)
      ? (_openBlock(), _createBlock(_component_ui_modal_confirm, {
          key: 2,
          header: "Закрыть окно редактирования письма?",
          "reject-label": "Вернуться к редактированию",
          "accept-label": "Закрыть",
          onReject: _cache[5] || (_cache[5] = ($event: any) => ($data.isConfirmExitModalOpen = false)),
          onAccept: $options.closeConfirmExit
        }, null, 8, ["onAccept"]))
      : _createCommentVNode("", true)
  ]))
}