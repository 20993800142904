import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '@/abilities.types';

export function defineAbilitiesForFuelAdmin(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  can('use', 'Fuel');

  can('enter', 'FuelTab');

  can('read', 'FuelClients');

  can('read', 'FuelClient');
  can('edit', 'FuelClient');

  can('create', 'FuelCard');
  can('transfer', 'FuelCard');

  can('create', 'FuelReplenishment');

  can('edit', 'FuelReplenishmentLimit');

  can('read', 'FuelClientColumn');
  can('read', 'FuelCardGroup');

  can('select', 'FuelStatisticClient');

  can('edit', 'FuelCardAdminLimit');

  can('edit', 'FuelCardFields');

  can('read', 'FuelCards');

  can('use', 'Feedback');

  can('read', 'FuelTariffPolicy');

  can('read', 'FuelReport');

  can('edit', 'FuelClientMoneyShortageWarning');

  can('read', 'FuelCardHistory');
}

export function defineAbilitiesForFuelClient(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  can('use', 'Fuel');

  can('enter', 'FuelTab');

  can('read', 'FuelInstruction');

  can('read', 'FuelCurrentClient');

  can('read', 'FuelStatisticNotice');

  can('use', 'Feedback');

  can('read', 'FuelAdvanceForm');

  can('load', 'FuelTransactionsClientReport');

  can('enter', 'FuelNotifications');
}
