import container, {
  Injectable,
  Inject,
} from '@/di';
import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import {
  SetUserCounterpartyRelParams,
  UserModel,
  VersionModel,
} from '@/features/srm/profile/services/UserService.types';
import {
  API_GET_CURRENT_USER,
  API_GET_USER_BY_CARRIER,
  API_GET_VERSION,
  API_LINK_USER_TO_COUNTERPARTY,
  API_USER,
  API_USER_ACTION_BY_ID,
  API_USER_NOTIFICATION_FLAG_BY_ID,
  SET_USER_ROLE,
  USER_COUNTERPARTY_REL,
} from '@/features/srm/profile/services/endpoints';
import SRMUsersService, { SRM_USER_SERVICE_ID as SRM_USERS_SERVICE_ID } from '@/features/srm/services/SRMUserService';

export const SRM_USER_SERVICE_ID = Symbol('SRMUserService');

@Injectable()
export default class SRMUserService {
  srmService: SRMService;

  userService: SRMUsersService;

  constructor(
    @Inject(SRM_SERVICE_ID) srmService: SRMService,
    @Inject(SRM_USERS_SERVICE_ID) userService: SRMUsersService,
  ) {
    this.srmService = srmService;
    this.userService = userService;
  }

  getUsers(counterpartyId: string): Promise<unknown> {
    return this.srmService.request({
      method: 'GET',
      url: API_USER,
      params: { counterpartyId },
    });
  }

  getCurrentUser(): Promise<UserModel> {
    return this.userService.request({
      method: 'GET',
      url: API_GET_CURRENT_USER,
    });
  }

  getVersion(): Promise<VersionModel> {
    return this.srmService.request({
      method: 'GET',
      url: API_GET_VERSION,
    });
  }

  setUserRole(
    userId: string,
    value: string | boolean,
    role = 'principalUser',
  ): Promise<void> {
    return this.srmService.request({
      method: 'PUT',
      url: SET_USER_ROLE(userId, role, value),
    });
  }

  linkUserToCounterparty(
    emailOrPhone: string,
    counterpartyId: string,
  ): Promise<void> {
    return this.srmService.request({
      method: 'PUT',
      url: API_LINK_USER_TO_COUNTERPARTY(emailOrPhone, counterpartyId),
    });
  }

  removeUserById(userId: string): Promise<void> {
    return this.srmService.request({
      method: 'DELETE',
      url: API_USER_ACTION_BY_ID(userId),
    });
  }

  updateUser(
    user: UserModel,
  ): Promise<void> {
    return this.srmService.request({
      method: 'PUT',
      url: API_USER_ACTION_BY_ID(user.id),
      data: user,
    });
  }

  updateUserNotificationFlag(
    user: UserModel,
  ): Promise<void> {
    return this.userService.request({
      method: 'POST',
      url: API_USER_NOTIFICATION_FLAG_BY_ID(user.id),
      data: user.emailNotificationSettings,
    });
  }

  getUsersByCarrier(
    carrierId: string,
    relTypeId: string,
    checkCarrierEmployeeRequest?: boolean,
  ): Promise<UserModel[]> {
    return this.srmService.request({
      method: 'GET',
      url: API_GET_USER_BY_CARRIER,
      params: {
        carrierId,
        relTypeId,
        checkCarrierEmployeeRequest,
      },
    });
  }

  getUsersInRole(
    roleId: string,
  ): Promise<UserModel[]> {
    return this.userService.request({
      method: 'GET',
      url: API_USER,
      params: { roleId },
      timeout: 60000,
    });
  }

  setUserCounterpartyRel(data: SetUserCounterpartyRelParams): Promise<void> {
    return this.srmService.request({
      method: 'POST',
      url: USER_COUNTERPARTY_REL,
      data,
    });
  }
}

container.bind<SRMUserService>(SRM_USER_SERVICE_ID).to(SRMUserService);
