import dayjs, {
  ManipulateType,
  OpUnitType,
} from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { getDateStringWithoutTime } from '@/utils/handlers';
import { NumberWeekDTO } from '@/features/sop/types';
import {
  DateStringRange,
  Nullable,
  Undefinable,
} from '@/features/shared/types';

dayjs.extend(isoWeek);

/**
 * Форматированиие даты
 *
 * @param date {string}
 * @param format {string}
 * @return {string}
 */
export const formatDate = (
  date?: Nullable<Date | string>,
  format = 'DD.MM.YYYY',
): string => {
  if (!date) {
    return '';
  }

  return dayjs(date).format(format);
};

/**
 * Форматированиие даты и времени по местному времени
 *
 * @param date {string}
 * @param format {string}
 * @return {string}
 */
export const formatLocalDateTime = (
  date?: Nullable<Date | string>,
  format = 'DD.MM.YYYY, H:mm',
): string => {
  if (!date) {
    return '';
  }
  const offsetZoneHours = (new Date().getTimezoneOffset() * (-1)) / 60;
  return dayjs(date).add(offsetZoneHours, 'hours').format(format);
};

/**
 * Форматированиие даты и времени
 *
 * @param date {string}
 * @param format
 * @return {string}
 */
export const formatDateTime = (
  date?: Date | string,
  format = 'DD.MM.YYYY, H:mm',
): string => formatDate(date, format);

/**
 * Форматированиие заголовка для диапазона дат
 *
 * @param min {string}
 * @param max {string}
 * @return {string}
 */
export const rangeTitle = (min: string | null, max: string | null): string => {
  const titleFrom = min ? `${dayjs(min).format('DD.MM.YY')}` : '';
  const titleTo = max ? `${dayjs(max).format('DD.MM.YY')}` : '';
  return titleFrom || titleTo ? `${titleFrom}~${titleTo}` : '';
};

export const formatDateToWords = (time: Date | string): string => {
  const number = dayjs(time).date();
  const month = dayjs(time).month();
  const day = dayjs(time).day();
  return `${number} ${months[month].name}, ${days[day].shortName}`;
};

export const getUTCDate = (date: string | Date): Date => {
  const utcDate = dayjs.utc(date) as unknown;
  const utcDateAsDate = utcDate as string;
  return new Date(utcDateAsDate);
};

export const getStartEndWeek = (
  value: undefined | ValueWeek,
): NumberWeekDTO => {
  const number = value?.number ?? null;
  const differentWeeks = value?.differentWeeks ?? null;

  let numberWeek = number || dayjs().isoWeek();

  if (differentWeeks) numberWeek += differentWeeks;

  const startWeek = dayjs().isoWeek(numberWeek).day(1);
  const endWeek = dayjs().isoWeek(numberWeek).day(7);

  return {
    numberWeek,
    start: getDateStringWithoutTime(startWeek),
    end: getDateStringWithoutTime(endWeek),
  };
};

export const parseDate = (date: string | null): Date | null => {
  if (!date) return null;

  return dayjs(date).toDate();
};

interface ValueWeek {
  number?: number;
  differentWeeks?: number;
}

export const isSameOrBefore = (
  start: Undefinable<Nullable<string | Date>>,
  end: Undefinable<Nullable<string | Date>>,
  unit: OpUnitType = 'day',
): boolean => {
  if (!start || !end) {
    return false;
  }

  return dayjs(start).isSameOrBefore(end, unit);
};

export const isSameDate = (
  start: Nullable<string | Date>,
  end: Nullable<string | Date>,
  unit: OpUnitType = 'day',
): boolean => {
  if (!start || !end) {
    return false;
  }

  return dayjs(start).isSame(end, unit);
};

export const isSameDateUTC = (
  start: Nullable<string | Date>,
  end: Nullable<string | Date>,
  unit: OpUnitType = 'day',
): boolean => {
  if (!start || !end) {
    return false;
  }

  return dayjs(dayjs(start).utc()).isSame(dayjs(end).utc(), unit);
};

export const fromNow = (date: string | Date): string => dayjs(date).fromNow();

export const formatRelativeDate = (
  date?: Nullable<Date | string>,
  format = 'DD.MM.YYYY',
): string => {
  if (!date) {
    return '';
  }

  const today = new Date();
  const yesterday = new Date(new Date().setDate(today.getDate() - 1));

  if (isSameDate(date, today.toString())) {
    return 'Сегодня';
  }

  if (isSameDate(date, yesterday.toString())) {
    return 'Вчера';
  }

  return formatDate(date, format);
};

export const addDate = (date: string | Date, value: number, unit: ManipulateType = 'day'): Nullable<Date> => {
  if (!date) {
    return null;
  }

  return dayjs(date).add(value, unit).toDate();
};

export default {
  formatDate,
  formatDateTime,
  rangeTitle,
  formatDateToWords,
  getUTCDate,
  getStartEndWeek,
  isSameOrBefore,
  isSameDate,
  fromNow,
  formatRelativeDate,
  addDate,
};

export const days = [
  {
    id: 0,
    name: 'воскресенье',
    shortName: 'вс',
  },
  {
    id: 1,
    name: 'понедельник',
    shortName: 'пн',
  },
  {
    id: 2,
    name: 'вторник',
    shortName: 'вт',
  },
  {
    id: 3,
    name: 'среда',
    shortName: 'ср',
  },
  {
    id: 4,
    name: 'четверг',
    shortName: 'чт',
  },
  {
    id: 5,
    name: 'пятница',
    shortName: 'пт',
  },
  {
    id: 6,
    name: 'суббота',
    shortName: 'сб',
  },
];

export const months = [
  {
    id: 0,
    name: 'января',
  },
  {
    id: 1,
    name: 'февраля',
  },
  {
    id: 2,
    name: 'марта',
  },
  {
    id: 3,
    name: 'апреля',
  },
  {
    id: 4,
    name: 'мая',
  },
  {
    id: 5,
    name: 'июня',
  },
  {
    id: 6,
    name: 'июля',
  },
  {
    id: 7,
    name: 'августа',
  },
  {
    id: 8,
    name: 'сентября',
  },
  {
    id: 9,
    name: 'октября',
  },
  {
    id: 10,
    name: 'ноября',
  },
  {
    id: 11,
    name: 'декабря',
  },
];

export const formatUTCDate = (date: Date | string | null, format = 'DD.MM.YYYY'): string => {
  if (!date) return '';

  return dayjs(date).utc(false).format(format);
};

export const formatDateRangeToDateTimeString = (
  period: Nullable<Date[]>,
  format = 'YYYY-MM-DDTHH:mm:ss[Z]',
  utc = true,
): DateStringRange => {
  let fromDate;
  if (period && period[0]) {
    fromDate = new Date(period[0]).setHours(0, 0, 0);
  }

  let toDate;
  if (period && period[1]) {
    toDate = new Date(period[1]).setHours(23, 59, 59);
  }

  const formatFunc = utc ? formatUTCDate : formatDate;

  return {
    fromDate: formatFunc(fromDate, format),
    toDate: formatFunc(toDate, format),
  };
};


