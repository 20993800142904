
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { subject } from '@casl/ability';
import Cookies from 'js-cookie';
import { useElementSize } from '@vueuse/core';
import {
  RouteLocationRaw,
} from 'vue-router';
import {
  LoyaltyCategory,
  LoyaltyInfo,
} from '@/features/srm/loyalty/types';
import container from '@/di';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import {
  Nullable,
  Undefinable,
} from '@/features/shared/types';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import SRMOrgModule, { SRM_ORG_MODULE_ID } from '@/features/srm/orgs/store/OrgModule';
import { CounterpartyModel } from '@/features/srm/orgs/services/OrgService.types';
import { RelationType } from '@/features/srm/profile/constants';
import { formatPriceWithoutCurrency } from '@/filters/price';
import UiTooltipWithContent from '@/features/redesigned/shared/kit/UiTooltipWithContent.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import createError from '@/utils/createError';
import {
  useAbility,
} from '@/utils/vue3';
import LoyaltyProgramModule, { LOYALTY_PROGRAM_MODULE_ID } from '@/features/srm/loyalty/store';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';
import declinationByNumber from '@/filters/declinationByNumber';
import IncreasedRateModal from '@/features/srm/loyalty/components/IncreasedRateModal.vue';
import { useCarrierInfo } from '@/features/redesigned/srm/composable/carrier-info';
import WelcomeCashbackModal from '@/features/srm/loyalty/components/WelcomeCashbackModal.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'AppHeaderCarrierUser',

  components: {
    UiTooltipWithContent,
    SvgIcon,
    DefaultTransition,
    IncreasedRateModal,
    WelcomeCashbackModal,
  },

  setup() {
    const toast = useToast();
    const ability = useAbility();

    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const user = computed<UserModel | undefined>(() => userModule.value.user);

    const org = computed<Nullable<CounterpartyModel>>(() => user.value?.userCounterparties[0]?.counterparty ?? null);

    const userFirstLetterName = computed<string>(() => (user.value ? `${user.value.lastName.charAt(0)}` : ''));
    const userName = computed<string>(() => (user.value ? `${user.value.lastName} ${user.value.firstName.charAt(0)}.` : ''));
    const companyName = computed<Nullable<string>>(() => org.value?.name ?? null);
    const showCompanyName = computed<boolean>(() => (
      !!companyName.value && user.value?.userCounterparties[0]?.relationTypeId !== RelationType.CarrierEmployeeRequest
    ));

    const orgModule = computed<SRMOrgModule>(() => container.get(SRM_ORG_MODULE_ID));
    const legalFormName = computed<string>(() => {
      const legalFormId = org.value?.legalFormId as number;
      return orgModule.value.legalFormById(legalFormId)?.name ?? '';
    });

    const loyaltyModule = computed<LoyaltyProgramModule>(() => container.get(LOYALTY_PROGRAM_MODULE_ID));
    const loyaltyInfo = computed<Nullable<LoyaltyInfo>>(() => loyaltyModule.value.loyaltyInfo);
    const isCarrierHasLoyalty = computed<boolean>(() => !!loyaltyModule.value.isCarrierHasLoyalty);
    const isShowLoyaltyCoinsCount = computed<boolean>(() => (
      ability.can('viewLoyalty', subject('AppHeaderCarrierUser', { relationTypeId: userModule.value.getRelationTypeId() }))
      && isCarrierHasLoyalty.value
      && (!!loyaltyInfo.value || loyaltyModule.value.welcomeCashback))
    );
    const cashbackAvailable = computed<number>(
      () => (loyaltyInfo.value?.cashbackAvailable || loyaltyModule.value.welcomeCashback) ?? 0,
    );
    const currentLoyaltyStatus = computed<Undefinable<LoyaltyCategory>>(
      () => loyaltyInfo.value?.categories.find((category) => category.current),
    );
    const currentLoyaltyStatusDesc = computed<Undefinable<string>>(() => (
      isShowLoyaltyCoinsCount.value
        ? currentLoyaltyStatus.value?.description
        : undefined
    ));

    const fetchData = async () => {
      try {
        if (user.value?.userCounterparties.length && user.value?.userCounterparties[0].carrier) {
          await Promise.all([
            orgModule.value.loadLegalForms(),
            loyaltyModule.value.loadCarrierHasLoyalty(),
          ]);

          if (isCarrierHasLoyalty.value) {
            await loyaltyModule.value.loadLoyaltyInfo();
          }
        }
      } catch (e) {
        toast.error(createError(e));
      }
    };

    const getOrgSectionRoute = (name: string): RouteLocationRaw => {
      let result = {
        name: 'orgs',
      };

      if (carrierId.value) {
        result = {
          name,
          params: {
            orgId: carrierId.value,
          },
        };
      }

      return result;
    };

    const orderModule = computed<SRMOrderModule>(() => container.get(SRM_ORDER_MODULE_ID));
    const ordersCounter = computed<Nullable<number>>(() => orderModule.value.carrierOrderCounter);
    const isRateIncreased = computed<boolean>(() => orderModule.value.isRateIncreased());
    const isIncreasedRateTooltipVisible = computed(() => Cookies.get('increasedRateTooltipViewed') !== '1');
    const onCloseRateTooltip = () => Cookies.set('increasedRateTooltipViewed', '1', { expires: 365 });
    const isIncreasedRateModalOpen = ref(false);

    const containerRef = ref<Nullable<HTMLElement>>(null);
    const { width: containerWidth } = useElementSize(containerRef);

    const isCashbackTooltipVisible = computed(() => Cookies.get('cashbackTooltipViewed') !== '1');
    const onCloseCashbackTooltip = () => Cookies.set('cashbackTooltipViewed', '1', { expires: 365 });

    const { counterparty, carrierId } = useCarrierInfo();
    const onClickLoyaltyLink = () => {
      if (!counterparty.value && loyaltyModule.value.hasWelcomeCashback()) {
        isWelcomeCashbackModalOpen.value = true;
      }
    };
    const cashbackContainerComponent = computed(() => counterparty.value ? 'router-link' : 'div');
    const isWelcomeCashbackModalOpen = ref(false);
    const onCloseWelcomeCashbackModal = () => {
      isWelcomeCashbackModalOpen.value = false;
    };
    const isWelcomeCashbackModalAvailable = computed<boolean>(() => {
      return (!counterparty.value && Cookies.get('welcomeCashbackModalViewed') !== '1')
      || (!!counterparty.value && Cookies.get('welcomeCashbackModalForCarrierWithOrgViewed') !== '1');
    });
    const isIncreasedRateModalAvailable = computed<boolean>(() => {
      return isRateIncreased.value && Cookies.get('increasedRateModalViewed') !== '1';
    });

    onMounted(async () => {
      await fetchData();

      setTimeout(
        () => {
          if (loyaltyModule.value.hasWelcomeCashback() && isWelcomeCashbackModalAvailable.value) {
            isWelcomeCashbackModalOpen.value = true;
          } else if (isIncreasedRateModalAvailable.value) {
            isIncreasedRateModalOpen.value = true;
          }
        },
        3000,
      );
    });

    return {
      declinationByNumber,
      user,
      userName,
      userFirstLetterName,
      showCompanyName,
      companyName,
      legalFormName,
      org,
      formatPriceWithoutCurrency,
      isCarrierHasLoyalty,
      loyaltyInfo,
      isShowLoyaltyCoinsCount,
      cashbackAvailable,
      currentLoyaltyStatusDesc,
      getOrgSectionRoute,
      isRateIncreased,
      ordersCounter,
      isIncreasedRateTooltipVisible,
      onCloseRateTooltip,
      containerRef,
      containerWidth,
      isIncreasedRateModalOpen,
      isCashbackTooltipVisible,
      onCloseCashbackTooltip,
      onClickLoyaltyLink,
      cashbackContainerComponent,
      isWelcomeCashbackModalOpen,
      onCloseWelcomeCashbackModal,
    };
  },
};
