
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import { useAbility } from '@/utils/vue3';
import { useToast } from 'vue-toastification';
import container from '@/di';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import { getHTTPError } from '@/utils/errors';
import FuelClientModule, { FUEL_CLIENT_MODULE_ID } from '@/features/fuel/clients/store';
import { FuelClientDTO } from '@/features/fuel/clients/types';
import { Nullable } from '@/features/shared/types';

export default defineComponent({
  name: 'AppHeaderFuelUser',

  components: {
    SvgIcon,
  },

  setup() {
    const ability = useAbility();
    const toast = useToast();

    const authService = computed<AuthService>(() => container.get(AUTH_SERVICE_ID));
    const fio = computed<string>(() => authService.value.getUserName() ?? '');

    const clientModule = computed<FuelClientModule>(() => container.get(FUEL_CLIENT_MODULE_ID));
    const showClientName = computed<boolean>(() => ability.can('read', 'FuelCurrentClient'));
    const currentUser = ref<Nullable<FuelClientDTO>>(null);
    const legalForm = computed<string>(() => currentUser.value?.companyLegalForm ?? '');
    const companyName = computed<string>(() => currentUser.value?.companyName ?? '');

    const fetchData = async (): Promise<void> => {
      if (showClientName.value) {
        try {
          currentUser.value = await clientModule.value.getCurrentClient();
        } catch (e) {
          toast.error(getHTTPError(e));
        }
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      fio,
      showClientName,
      legalForm,
      companyName,
      currentUser,
    };
  },
});
