import { upperCase } from 'lodash';
import { Nullable } from '@/features/shared/types';
import { ShipAgentId } from '@/features/shared/constant';

export enum ApprovingPhase {
  'Unknown',
  'Security',
  'Jurists',
  'Initiator',
  'Leader',
}

export enum ApprovingStatusName {
  'NotVerified' = 'Не проверен',
  'NotFilled' = 'Не заполнен',
  'UnknownStatus' = 'Неизвестно',
  'New' = 'Новый',
  'OnReview' = 'На согласовании',
  'Approved' = 'Согласован',
  'Rejected' = 'Отклонен',
  'Correcting' = 'Корректировка',
  'NeedAction' = 'Требуется действие',
  'NotCheckedPortal' = 'Не проверено на портале',
  'Suspended' = 'Приостановлен',
}

export enum ApprovingStatusText {
  NotVerified = 'NOT_VERIFIED',
  NotFiled = 'NOT_FILED',
  Unknown = 'UNKNOWN',
  New = 'NEW',
  OnReview = 'ON_REVIEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Correcting = 'CORRECTING',
  NeedAction = 'NEED_ACTION',
  NotCheckedPortalSuspended = 'NOT_CHECKED_PORTALSUSPENDED',
}

export enum ImageFormat {
  'Unknown',
  'Png',
  'Jpeg',
  'Gif',
  'Bmp',
}

export enum ListFilterScenario {
  'Unknown' = 'Unknown',
  'All' = 'All',
  'NotCheckedByManagerAttract' = 'NotCheckedByManagerAttract',
  'NotApproved' = 'NotApproved',
  'WithoutActivity' = 'WithoutActivity',
  'OrdersAll' = 'OrdersAll',
  'OrdersNew' = 'OrdersNew',
  'OrdersInWork' = 'OrdersInWork',
  'OrdersDenied' = 'OrdersDenied',
  'OrdersCompleted' = 'OrdersCompleted',
  'WaitingForApprove' = 'WaitingForApprove',
  'ReservedToManager' = 'ReservedToManager',
  'AuctionOrders' = 'AuctionOrders',
  'OrdersNewForLogist' = 'OrdersNewForLogist',
  'OrdersNewForCarrier' = 'OrdersNewForCarrier',
  'OrdersInWorkForCarrier' = 'OrdersInWorkForCarrier',
  'OrdersRecommendForCarrier' = 'OrdersRecommendForCarrier',
}

export function getListFilterScenarioName(scenario: string): string {
  switch (scenario) {
    case ListFilterScenario.OrdersAll:
      return 'Все';
    case ListFilterScenario.OrdersNew:
    case ListFilterScenario.OrdersNewForCarrier:
      return 'Новые';
    case ListFilterScenario.AuctionOrders:
      return 'Аукционные';
    case ListFilterScenario.ReservedToManager:
      return 'Забронированные';
    case ListFilterScenario.WaitingForApprove:
      return 'Ожидающие ответа';
    case ListFilterScenario.OrdersInWork:
    case ListFilterScenario.OrdersInWorkForCarrier:
      return 'В работе';
    case ListFilterScenario.OrdersDenied:
      return 'Отмененные';
    case ListFilterScenario.OrdersCompleted:
      return 'Завершенные';
    case ListFilterScenario.OrdersNewForLogist:
      return 'Мои Новые';
    case ListFilterScenario.OrdersRecommendForCarrier:
      return 'Рекомендуем';
    default:
      return '';
  }
}

export function getNameShipAgentAttracs(id?: string): string {
  switch (id) {
    case ShipAgentId.Gtl:
      return 'G';
    case ShipAgentId.Longrun:
      return 'LN';
    case ShipAgentId.Lorry:
      return 'L';
    case ShipAgentId.Magna:
      return 'M';
    case ShipAgentId.Gruzoprovod:
      return 'GP';
    default:
      return '';
  }
}

export enum OperationLevel {
  Unknown,
  Success,
  Info,
  Warning,
  Error,
}

export enum ScreenState {
  Loading,
  Ready,
  Error,
}

export enum EventType {
  Loading = 1,
  Unloading = 2,
}

export enum QueryType {
  all = 'ALL',
}

export enum IsReadyState {
  IS_ADDED_TRUCK = 'is-added-truck',
  IS_ADDED_TRAILER = 'is-added-trailer',
  IS_ADDED_LOWTONNAGE = 'is-added-low-tonnage',
  IS_ADDED_DRIVER = 'is-added-driver',
}

export enum ContactType {
  CarrierEmployee = 'CARRIER_EMPLOYEE',
  Logist = 'LOGIST',
}

export enum OrderType {
  Contract = 'CONTRACT',
  Spot = 'SPOT',
  ContractConceptual = 'CONTRACT_CONCEPTUAL',
  Buyout = 'BUYOUT',
  Parsing = 'PARSING',
  External = 'EXTERNAL',
  Client = 'CLIENT',
}

export enum OrderCarrierType {
  Auction = 'AUCTION',
  OfferCollection = 'OFFER_COLLECTION',
}

export function getTrailerType(request: string): string {
  return { TENT: 'icon-truck', REFRIGERATOR: 'icon-ref' }[request] || 'default';
}

export enum LoadingState {
  LOADING = 'LOADING',
  READY = 'READY',
}

export enum HindCarriageType {
  REFRIGERATOR = 'REFRIGERATOR',
  TENT = 'TENT',
}

export enum UnitTypes {
  Vehicle,
  Driver,
}

export enum TrailerType {
  Ref = 'ref',
  Close = 'close',
  Open = 'open',
  Tent = 'tent',
}

export enum ContractFilterType {
  All = 'all',
  IsBuyout = 'isBuyout',
  IsParsing = 'isParsing',
}

export enum LoadingCargoType {
  TOP = 'TOP',
  TAIL_LIFT = 'TAIL_LIFT',
  SIDE_ON_BOTH_SIDES = 'SIDE_ON_BOTH_SIDES',
  WITH_FULL_COVER = 'WITH_FULL_COVER',
  WITH_THE_REMOVAL_OF_THE_CROSSBARS = 'WITH_THE_REMOVAL_OF_THE_CROSSBARS',
  NO_GATE = 'NO_GATE',
  WITH_RACKS_REMOVED = 'WITH_RACKS_REMOVED',
  WITH_CRATE = 'WITH_CRATE',
  WITH_SIDES = 'WITH_SIDES',
  SIDE = 'SIDE',
  BACK = 'BACK',
}

export enum LoadingCargoTypeName {
  TOP = 'Верхняя',
  TAIL_LIFT = 'Гидроборт',
  SIDE_ON_BOTH_SIDES = 'Боковая с двух сторон',
  WITH_FULL_COVER = 'С полной растентовкой',
  WITH_THE_REMOVAL_OF_THE_CROSSBARS = 'Со снятием поперечных перекладин',
  NO_GATE = 'Без ворот',
  WITH_RACKS_REMOVED = 'Со снятием стоек',
  WITH_CRATE = 'С обрешеткой',
  WITH_SIDES = 'С бортами',
  SIDE = 'Боковая',
  BACK = 'Задняя',
}

export function getLoadingCargoTypeIcon(loadingType: LoadingCargoType): Nullable<string> {
  switch (loadingType) {
    case LoadingCargoType.TOP:
    case LoadingCargoType.WITH_THE_REMOVAL_OF_THE_CROSSBARS:
    case LoadingCargoType.WITH_FULL_COVER:
      return 'loading/top';

    case LoadingCargoType.BACK:
    case LoadingCargoType.TAIL_LIFT:
      return 'loading/back';

    case LoadingCargoType.SIDE:
    case LoadingCargoType.SIDE_ON_BOTH_SIDES:
      return 'loading/side';

    default:
      return null;
  }
}

export function stringToConstantCase(string: string): string {
  return upperCase(string).replace(/ /g, '_');
}

export enum OrdersPage {
  Orders,
  TruckOffering,
}

export enum AvailableEditUnitsForCustomer {
  Sibur = '34c03e49-11c3-11ea-82af-0050560301ee',
  TimeTrade = '1b485f11-d354-11eb-82f7-00505601143f'
}
