import { AxiosRequestConfig } from 'axios';

import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';

export default function authRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const authInstance = container.get<AuthService>(AUTH_SERVICE_ID);

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${authInstance.getToken()}`;
  return config;
}
