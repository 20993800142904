import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input_text = _resolveComponent("ui-input-text")!
  const _component_ui_form_group = _resolveComponent("ui-form-group")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.prompt)
    }, " Адрес Вашей электронной почты был изменен. Требуется подтверждение. ", 2),
    _createVNode(_component_ui_form_group, {
      label: "E-mail",
      class: _normalizeClass(_ctx.$style.label)
    }, {
      default: _withCtx(({ id }) => [
        _createVNode(_component_ui_input_text, {
          id: id,
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          disabled: ""
        }, null, 8, ["id", "modelValue"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_ui_button, {
      "is-loading": _ctx.isLoading,
      class: _normalizeClass(_ctx.$style.button),
      onClick: _ctx.onClickSendEmail
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Получить код подтверждения ")
      ]),
      _: 1
    }, 8, ["is-loading", "class", "onClick"])
  ]))
}