import { Register } from '@/di';

import {
  API_CARRIER_ORDER_LIKE,
  API_CARRIER_ORDER_RECOMMEND,
  API_ORDER_MATCH_SEARCH_CARD,
  API_RECOMMENDS_ORDER_COUNT,
  API_RECOMMENDS_ORDER_PAGE,
} from '@/features/redesigned/srm/carriers-order/services/endpoints';

import {
  OrderLikeInfo,
  RecommendOrders,
  RecommendOrderRequest,
  SendOrderLike,
  MatchedOrdersParams,
} from '@/features/redesigned/srm/carriers-order/types';
import { PaginationDTO } from '@/features/shared/types';
import { OrderResponse } from '@/features/srm/orders/services/OrderService.types';
import { OrdersFilterDTO } from '@/features/srm/types';

import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';
import RemoteService from '@/features/shared/services/base/remote';

export const CARRIERS_ORDER_SERVICE_ID = Symbol('carriersOrder');

@Register(CARRIERS_ORDER_SERVICE_ID, [CONFIG_SERVICE_ID])
export default class CarriersOrderService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.carriersOrderHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  getOrderLikeInfo(params: OrderLikeInfo): Promise<boolean> {
    return this.request({
      method: 'GET',
      params,
      url: API_CARRIER_ORDER_LIKE,
    });
  }

  sendOrderLike(data: SendOrderLike): Promise<void> {
    return this.request({
      method: 'POST',
      data,
      url: API_CARRIER_ORDER_LIKE,
    });
  }

  getRecommendOrders(data: RecommendOrderRequest[]): Promise<RecommendOrders> {
    return this.request({
      method: 'POST',
      data,
      url: API_CARRIER_ORDER_RECOMMEND,
    });
  }

  getAllRecommendOrdersPage(data: OrdersFilterDTO, params?: Partial<PaginationDTO>): Promise<OrderResponse> {
    return this.request({
      method: 'POST',
      url: API_RECOMMENDS_ORDER_PAGE,
      params,
      data: { ...data.filters },
    });
  }

  getRecommendOrdersCount(data: OrdersFilterDTO): Promise<number> {
    return this.request({
      method: 'POST',
      url: API_RECOMMENDS_ORDER_COUNT,
      data: { ...data.filters },
    });
  }

  getMatchedOrders(data: string[]): Promise<MatchedOrdersParams[]> {
    return this.request({
      method: 'POST',
      data,
      url: API_ORDER_MATCH_SEARCH_CARD,
    });
  }
}
