import {
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { formatDateTime } from '@/filters/date';
import { SidebarTimerModule } from '@/features/redesigned/shared/layout/composable/types';

const useSidebarTimer = (): SidebarTimerModule => {
  const date = ref<string>('');
  const time = ref<string>('');

  let timeId;
  onMounted(() => {
    calculateTime();
    const seconds = 60 - new Date().getSeconds();
    setTimeout(() => {
      calculateTime();
      timeId = setInterval(calculateTime, 60000);
    }, seconds * 1000);
  });

  const calculateTime = () => {
    const dateTime = formatDateTime(new Date(), 'DD.MM.YY|HH:mm');
    [date.value, time.value] = dateTime.split('|');
  };

  onUnmounted(() => {
    if (timeId) {
      clearInterval(timeId);
    }
  });

  return {
    date,
    time,
  };
};

export default useSidebarTimer;
