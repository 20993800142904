
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useCssModule,
} from 'vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import AppSidebarMenuItem from '@/features/redesigned/shared/layout/AppSidebarMenuItem.vue';
import AppSidebarTimer from '@/features/redesigned/shared/layout/AppSidebarTimer.vue';
import { Styles } from '@/features/shared/types';
import AppSidebarExpedition from '@/features/redesigned/shared/layout/AppSidebarExpedition.vue';
import AppSidebarSupport from '@/features/redesigned/shared/layout/AppSidebarSupport.vue';
import { MaxDisplayWidth } from '@/features/shared/constant';

export default defineComponent({
  name: 'AppSidebar',

  components: {
    SvgIcon,
    AppSidebarMenuItem,
    AppSidebarTimer,
    AppSidebarExpedition,
    AppSidebarSupport,
  },

  setup() {
    const isShowSubmenu = ref(false);

    const isCollapsed = ref(false);
    const $style = useCssModule();
    const containerStyles = computed<Styles>(() => ({
      [$style.container]: true,
      [$style.collapsed]: isCollapsed.value,
    }));

    onMounted(() => {
      isCollapsed.value = window.innerWidth <= MaxDisplayWidth.SmallDesktop;
    });

    return {
      isShowSubmenu,
      isCollapsed,
      containerStyles,
    };
  },
});
