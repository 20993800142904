import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import {
  Client,
  StompSubscription,
} from '@stomp/stompjs';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import { STORE_ID } from '@/store';
import SRMWebsocketService, { SRM_WEBSOCKET_SERVICE_ID } from '@/features/srm/services/SRMWebsocketService';
import {
  Nullable,
  WsSendType,
  WsSubscribeType,
} from '@/features/shared/types';
import {
  SRMWsSendType,
  SRMWsSubscribeType,
} from '@/features/srm/types';
import SRMWebsocketServiceV2Topics, {
  SRM_WEBSOCKET_SERVICE_V2_TOPICS_ID,
} from '@/features/srm/services/SRMWebsocketServiceV2Topics';

export const SRM_WEBSOCKET_MODULE_ID = 'srm-websocket';

@Module
@Injectable()
export default class SRMWebsocketModule extends VuexModule {
  @LazyInject(SRM_WEBSOCKET_SERVICE_ID) websocketService: SRMWebsocketService;

  @LazyInject(SRM_WEBSOCKET_SERVICE_V2_TOPICS_ID) websocketServiceV2: SRMWebsocketServiceV2Topics;

  client = new Client();

  clientV2Topics = new Client();

  isClientConnected = false;

  isClientConnectedV2Topics = false;

  subscriptions: StompSubscription[] = [];

  subscriptionsTopicsV2: StompSubscription[] = [];

  orderId: Nullable<string> = null;

  constructor(
    @Inject(STORE_ID) store: Store<unknown>,
  ) {
    super({ name: SRM_WEBSOCKET_MODULE_ID, store });
  }

  @Action
  async connect(): Promise<void> {
    const client = await this.websocketService.connect();
    client.onConnect = () => this.updateConnectionState(true);
    client.onDisconnect = () => this.updateConnectionState(false);
    this.setClient(client);
  }

  @Action
  async connectV2Topics(): Promise<void> {
    const client = await this.websocketServiceV2.connect();
    client.onConnect = () => this.updateConnectionStateV2Topics(true);
    client.onDisconnect = () => this.updateConnectionStateV2Topics(false);
    this.setClientV2Topics(client);
  }

  @Action
  async subscribe({
    destination,
    messageCallback,
    subscriptionId,
  }: SRMWsSubscribeType): Promise<void> {
    if (this.client.connected) {
      const subscribeData: WsSubscribeType = {
        client: this.client,
        destination,
        messageCallback,
        subscriptionId,
      };
      const data = await this.websocketServiceV2.subscribe(subscribeData);
      this.addSubscription(data);
    }
  }

  @Action
  async subscribeTopicV2({
    destination,
    messageCallback,
    subscriptionId,
  }: SRMWsSubscribeType): Promise<void> {
    if (this.clientV2Topics.connected) {
      const subscribeData: WsSubscribeType = {
        client: this.clientV2Topics,
        destination,
        messageCallback,
        subscriptionId,
      };
      const data = await this.websocketServiceV2.subscribe(subscribeData);
      this.addSubscriptionTopicsV2(data);
    }
  }

  @Action
  async unsubscribe(subscriptionId: string): Promise<void> {
    this.removeSubscriptions(subscriptionId);
  }

  @Action
  async unsubscribeTopicsV2(subscriptionId: string): Promise<void> {
    this.removeSubscriptionsTopicsV2(subscriptionId);
  }

  @Action
  async send({ destination, body }: SRMWsSendType<{orders: string[]}>): Promise<void> {
    if (this.client.connected) {
      const sendData: WsSendType = {
        body,
        client: this.client,
        destination,
      };
      await this.websocketService.send(sendData);
    }
  }

  @Action
  async sendV2Topics({ destination, body }: SRMWsSendType<{orders: string[]}>): Promise<void> {
    if (this.clientV2Topics.connected) {
      const sendData: WsSendType = {
        body,
        client: this.clientV2Topics,
        destination,
      };
      await this.websocketServiceV2.send(sendData);
    }
  }

  @Action
  async disconnect(): Promise<void> {
    this.removeSubscriptions();
    await this.websocketService.disconnect(this.client);
  }

  @Mutation
  setOrderId(orderId: string): void {
    this.orderId = orderId;
  }

  @Mutation
  setClient(client: Client): void {
    this.client = client;
  }

  @Mutation
  setClientV2Topics(client: Client): void {
    this.clientV2Topics = client;
  }

  @Mutation
  addSubscription(subscription: StompSubscription): void {
    this.subscriptions.push(subscription);
  }

  @Mutation
  addSubscriptionTopicsV2(subscription: StompSubscription): void {
    this.subscriptionsTopicsV2.push(subscription);
  }

  @Mutation
  removeSubscriptions(id?: string): void {
    if (id) {
      const subIndex = this.subscriptions.findIndex(
        (subscription: StompSubscription) => subscription.id === id,
      );
      if (subIndex >= 0) {
        this.subscriptions[subIndex].unsubscribe();
        this.subscriptions.splice(subIndex, 1);
      }
    } else {
      this.subscriptions.forEach((subscription: StompSubscription) => subscription.unsubscribe());
    }
  }

  @Mutation
  removeSubscriptionsTopicsV2(id?: string): void {
    if (id) {
      const subIndex = this.subscriptionsTopicsV2.findIndex(
        (subscription: StompSubscription) => subscription.id === id,
      );
      if (subIndex >= 0) {
        this.subscriptionsTopicsV2[subIndex].unsubscribe();
        this.subscriptionsTopicsV2.splice(subIndex, 1);
      }
    } else {
      this.subscriptionsTopicsV2
        .forEach((subscription: StompSubscription) => subscription.unsubscribe());
      this.subscriptionsTopicsV2 = [];
    }
  }

  @Mutation
  updateConnectionState(isConnected: boolean): void {
    this.isClientConnected = isConnected;
  }

  @Mutation
  updateConnectionStateV2Topics(isConnected: boolean): void {
    this.isClientConnectedV2Topics = isConnected;
  }
}

container
  .bind<SRMWebsocketModule>(SRM_WEBSOCKET_MODULE_ID)
  .to(SRMWebsocketModule)
  .inSingletonScope();
