import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_email_confirm_first_step = _resolveComponent("email-confirm-first-step")!
  const _component_email_confirm_second_step = _resolveComponent("email-confirm-second-step")!
  const _component_email_confirm_third_step = _resolveComponent("email-confirm-third-step")!
  const _component_default_transition = _resolveComponent("default-transition")!
  const _component_ui_modal_with_content = _resolveComponent("ui-modal-with-content")!

  return (_openBlock(), _createBlock(_component_ui_modal_with_content, {
    target: "modal",
    width: 600,
    "overlay-color": "dark",
    "close-on-click-overlay": false,
    onClose: _ctx.onClosePhoneConfirmModal
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.title, _ctx.$style.content])
      }, " Подтверждение почты ", 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createVNode(_component_default_transition, null, {
          default: _withCtx(() => [
            (_ctx.currentStage === 1)
              ? (_openBlock(), _createBlock(_component_email_confirm_first_step, {
                  key: 0,
                  "onClick:nextStep": _ctx.onFinishedFirstStep
                }, null, 8, ["onClick:nextStep"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage === 2)
              ? (_openBlock(), _createBlock(_component_email_confirm_second_step, {
                  key: 1,
                  email: _ctx.email,
                  "onClick:prevStep": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentStage = 1)),
                  "onClick:nextStep": _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentStage = 3))
                }, null, 8, ["email"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage === 3)
              ? (_openBlock(), _createBlock(_component_email_confirm_third_step, {
                  key: 2,
                  "onClick:finish": _ctx.onFinishedConfirm
                }, null, 8, ["onClick:finish"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["onClose"]))
}