import container from '@/di';
import { AUTH_SERVICE_ID } from '@/features/auth/services/auth';

// eslint-disable-next-line import/prefer-default-export
export function authRequestInterceptor(config) {
  const authInstance = container.get(AUTH_SERVICE_ID);

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${authInstance.getToken()}`;
  return config;
}
