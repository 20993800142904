
import UiModalWithContent from '@/features/shared/kit/UiModalWithContent.vue';
import gt2 from '@/assets/img/gt2.png';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import container from '@/di';
import { EnvironmentInfo } from '@/features/shared/types';

export default {
  name: 'AboutProgram',
  components: { UiModalWithContent },
  computed: {
    configService(): ConfigService {
      return container.get(CONFIG_SERVICE_ID);
    },

    img(): string {
      return gt2;
    },

    version(): EnvironmentInfo {
      return {
        environment: this.configService.environment,
        version: `v${this.configService.version}`,
      };
    },
  },
};
