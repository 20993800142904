import container, {
  Inject,
  Injectable,
} from '@/di';

import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import RemoteService from '@/features/shared/services/base/remote';
import authInterceptor from '@/features/auth/services/auth.interceptor';
import API from '@/features/sop/services/endpoints';

import {
  Page,
} from '@/features/shared/types';

import {
  ClientsDirectory,
  CommitmentClientsDTO,
} from '@/features/sop/contracts/models';

export const SVC_MAPPER_SERVICE_ID_V2 = Symbol('svcMapperServiceV2');

@Injectable()
export default class SVCMapperServiceV2 extends RemoteService {
  constructor(@Inject(CONFIG_SERVICE_ID) private config: ConfigService) {
    super(config.MrzSvcMapperServiceHostV2);

    this.remoteClient.interceptors.request.use(authInterceptor);
  }

  getAllClientList(params: CommitmentClientsDTO): Promise<Page<ClientsDirectory>> {
    return this.request({
      method: 'GET',
      url: API.ALL_CLIENTS,
      params,
    });
  }
}

container
  .bind<SVCMapperServiceV2>(SVC_MAPPER_SERVICE_ID_V2)
  .to(SVCMapperServiceV2);
