import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_with_content = _resolveComponent("ui-modal-with-content")!

  return (_openBlock(), _createBlock(_component_ui_modal_with_content, {
    target: "modal",
    width: $props.width,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.header), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        ($props.isShowReject)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.reject),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onReject && $options.onReject(...args)))
            }, [
              _renderSlot(_ctx.$slots, "reject", {}, () => [
                _createVNode(_component_ui_button, { appearance: "clean" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.rejectLabel), 1)
                  ]),
                  _: 1
                })
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('accept')))
        }, [
          _renderSlot(_ctx.$slots, "accept", {}, () => [
            _createVNode(_component_ui_button, {
              color: $props.acceptColor,
              "is-loading": $props.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.acceptLabel), 1)
              ]),
              _: 1
            }, 8, ["color", "is-loading"])
          ])
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["width"]))
}