import RemoteService from '@/features/shared/services/base/remote';
import {
  API_SEND_TO_INITIATOR,
  API_SEND_TO_USERS,
} from '@/features/shared/services/common-user/services/endpoints';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';
import container, {
  Inject,
  Injectable,
} from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { MalinigPost } from '@/features/shared/services/common-user/services/types';

export const COMMON_USER_SERVICE_ID = Symbol('commonUserService');

@Injectable()
export default class CommonUserService extends RemoteService {
  constructor(@Inject(CONFIG_SERVICE_ID) configService: ConfigService) {
    super(configService.CommonUserHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  sendUsersMailing(data: MalinigPost): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_SEND_TO_USERS,
      data,
    });
  }

  sendMailToInitiator(data: MalinigPost): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_SEND_TO_INITIATOR,
      data,
    });
  }
}

container
  .bind<CommonUserService>(COMMON_USER_SERVICE_ID)
  .to(CommonUserService)
  .inSingletonScope();
