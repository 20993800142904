import qs from 'query-string';
import
container,
{
  Inject,
  Injectable,
} from '@/di';
import HttpService, {
  FUEL_SERVICE_ID,
} from '@/features/fuel/shared/services/http';
import {
  DictionaryModel,
  Page,
  PageParams,
} from '@/features/shared/types';
import {
  ClientDirectory,
  ClientDTO,
  ClientModel,
  CreateNotificationRecipientParams,
  EmployeeModel,
  FuelClientDTO,
  NotificationRecipientDTO,
  SetClientMinBalanceParams,
  SetClientMinBalanceResponse,
  SetMoneyShortageWarningLimitParams,
  UpdateNotificationRecipientParams,
  UpdateNotificationTypeParams,
} from '@/features/fuel/clients/types';
import {
  ClientRequestModel,
  ReplenishmentRequestModel,
} from '@/features/fuel/shared/types';
import { StatisticsResponse } from '@/features/fuel/statistics/types';
import {
  API_CLIENT_EMPLOYEE,
  API_CLIENT_EMPLOYEE_BY_ID,
  API_CLIENT_EMPLOYEES_BY_CLIENT_ID,
  API_CLIENT_LIST,
  API_CLIENT_MIN_BALANCE,
  API_CLIENT_MONEY_SHORTAGE_WARNING_LIMIT,
  API_CLIENT_NAMES,
  API_CLIENTS_BALANCE_REPL,
  API_CLIENTS_BY_ID,
  API_CLIENTS_EDIT,
  API_CURRENT_CLIENT,
  API_DIRECTORY_COUNTRY,
  API_IS_CLIENT_WITH_INN_EXISTS,
  API_NOTIFICATION_RECIPIENT,
  API_NOTIFICATION_RECIPIENT_ADD_NOTIFICATION_TYPE,
  API_NOTIFICATION_RECIPIENT_CREATE,
  API_NOTIFICATION_RECIPIENT_DELETE_NOTIFICATION_TYPE,
  API_NOTIFICATION_RECIPIENT_EDIT,
  API_NOTIFICATION_RECIPIENTS,
  API_STATISTIC_BRIEFLY,
} from '@/features/fuel/clients/services/endpoints';

export const FUEL_CLIENT_SERVICE_ID = Symbol('fuel-client-service');

@Injectable()
export default class FuelClientService {
  private api: HttpService;

  constructor(@Inject(FUEL_SERVICE_ID) api: HttpService) {
    this.api = api;
  }

  getClients(params: PageParams): Promise<Page<ClientDTO>> {
    return this.api.request({
      method: 'GET',
      url: API_CLIENT_LIST,
      params,
      paramsSerializer: {
        serialize: (parameters) => qs.stringify(parameters, { skipNull: true }),
      },
    });
  }

  getClient(clientId: string): Promise<ClientModel> {
    return this.api.request({
      method: 'GET',
      url: API_CLIENTS_BY_ID(clientId),
    });
  }

  getCurrentClient(): Promise<FuelClientDTO> {
    return this.api.request({
      method: 'GET',
      url: API_CURRENT_CLIENT,
    });
  }

  getBrieflyStatistics(clientId: number): Promise<StatisticsResponse> {
    return this.api.request({
      method: 'GET',
      url: API_STATISTIC_BRIEFLY(clientId),
    });
  }

  saveClient(client: ClientRequestModel): Promise<ClientModel> {
    return this.api.request({
      method: 'POST',
      url: API_CLIENTS_EDIT,
      data: client,
    });
  }

  getEmployees(clientId: number): Promise<EmployeeModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_CLIENT_EMPLOYEES_BY_CLIENT_ID(clientId),
    });
  }

  saveEmployee(data: EmployeeModel): Promise<EmployeeModel> {
    return this.api.request({
      method: 'POST',
      url: API_CLIENT_EMPLOYEE,
      data,
    });
  }

  removeEmployee(employeeId: number): Promise<void> {
    return this.api.request({
      method: 'DELETE',
      url: API_CLIENT_EMPLOYEE_BY_ID(employeeId),
    });
  }

  getCountries(): Promise<DictionaryModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_DIRECTORY_COUNTRY,
    });
  }

  saveReplenishment(replenishment: ReplenishmentRequestModel): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_CLIENTS_BALANCE_REPL,
      data: replenishment,
    });
  }

  isClientWithInnExists(inn: string): Promise<boolean> {
    return this.api.request({
      method: 'GET',
      url: API_IS_CLIENT_WITH_INN_EXISTS(inn),
      params: {
        inn,
      },
    });
  }

  getClientNames(query: string): Promise<ClientDirectory[]> {
    return this.api.request({
      method: 'GET',
      url: API_CLIENT_NAMES,
      params: {
        'sub-string': query,
      },
    });
  }

  setMinBalance({
    clientId,
    value,
  }: SetClientMinBalanceParams): Promise<SetClientMinBalanceResponse> {
    return this.api.request({
      method: 'PATCH',
      url: API_CLIENT_MIN_BALANCE(clientId),
      data: {
        value,
      },
    });
  }

  getNotificationRecipients(): Promise<NotificationRecipientDTO[]> {
    return this.api.request({
      method: 'GET',
      url: API_NOTIFICATION_RECIPIENTS,
    });
  }

  createNotificationRecipient(
    params: CreateNotificationRecipientParams,
  ): Promise<NotificationRecipientDTO> {
    return this.api.request({
      method: 'POST',
      url: API_NOTIFICATION_RECIPIENT_CREATE,
      data: {
        name: params.emailAddress,
        emailAddress: params.emailAddress,
      },
    });
  }

  updateNotificationRecipient(
    params: UpdateNotificationRecipientParams,
  ): Promise<NotificationRecipientDTO> {
    return this.api.request({
      method: 'PATCH',
      url: API_NOTIFICATION_RECIPIENT_EDIT(params.id),
      data: {
        name: params.emailAddress,
        emailAddress: params.emailAddress,
      },
    });
  }

  addNotificationType(
    params: UpdateNotificationTypeParams,
  ): Promise<NotificationRecipientDTO> {
    return this.api.request({
      method: 'POST',
      url: API_NOTIFICATION_RECIPIENT_ADD_NOTIFICATION_TYPE(params.recipientId),
      data: {
        id: params.id,
      },
    });
  }

  deleteNotificationType(
    params: UpdateNotificationTypeParams,
  ): Promise<NotificationRecipientDTO> {
    return this.api.request({
      method: 'DELETE',
      url: API_NOTIFICATION_RECIPIENT_DELETE_NOTIFICATION_TYPE(
        params.recipientId,
      ),
      data: {
        id: params.id,
      },
    });
  }

  deleteNotificationRecipient(recipientId: number): Promise<void> {
    return this.api.request({
      method: 'DELETE',
      url: API_NOTIFICATION_RECIPIENT(recipientId),
    });
  }

  setMoneyShortageWarningLimit(
    params: SetMoneyShortageWarningLimitParams,
  ): Promise<ClientDTO> {
    return this.api.request({
      method: 'PATCH',
      url: API_CLIENT_MONEY_SHORTAGE_WARNING_LIMIT(params.clientId),
      data: {
        value: params.value,
      },
    });
  }
}

container
  .bind<FuelClientService>(FUEL_CLIENT_SERVICE_ID)
  .to(FuelClientService)
  .inSingletonScope();
