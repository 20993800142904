import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import {
  PreorderSummaryDTO,
  PreorderSummaryParams,
  VatRate,
} from '@/features/shared/truck-offering/types';
import { STORE_ID } from '@/store';
import {
  Page,
  PageParams,
  TrailerVolumeType,
} from '@/features/shared/types';
import CarrierPreorderSharedService, {
  CARRIER_PREORDER_SHARED_SERVICE_ID,
} from '@/features/shared/truck-offering/services/CarrierPreorderSharedService';
import PreordersFilterMapper from '@/features/shared/truck-offering/mappers/PreordersFilterMapper';

export const CARRIER_PREORDER_SHARED_MODULE_ID = 'carrier-preorder-shared';

@Module
@Injectable()
export default class CarrierPreorderSharedModule extends VuexModule {
  @LazyInject(CARRIER_PREORDER_SHARED_SERVICE_ID) carrierPreorderSharedService: CarrierPreorderSharedService;

  isLoadingTrailerVolumeTypes = false;

  trailerVolumeTypes: TrailerVolumeType[] = [];

  vatRatesTypes: VatRate[] = [];

  constructor(
    @Inject(STORE_ID) store: Store<unknown>,
  ) {
    super({ name: CARRIER_PREORDER_SHARED_MODULE_ID, store });
  }

  @Action
  async getPreorderPage(params: PageParams): Promise<Page<PreorderSummaryDTO>> {
    return this.carrierPreorderSharedService.getPreorderPage(params);
  }

  @Action
  async loadTrailerVolumeTypes(): Promise<void> {
    if (this.trailerVolumeTypes.length || this.isLoadingTrailerVolumeTypes) return;
    this.setIsLoadingTrailerVolumeTypes(true);
    const response = await this.carrierPreorderSharedService.getTrailerVolumeTypes();
    this.setIsLoadingTrailerVolumeTypes(false);
    this.setTrailerVolumeTypes(response);
  }

  @Action
  async getVatRateTypes(): Promise<void> {
    if (this.vatRatesTypes.length) return;

    const response = await this.carrierPreorderSharedService.getVatRateTypes();

    this.setVatRatesTypes(response);
  }

  @Action
  async getPreorderSummary(params: PreorderSummaryParams): Promise<Page<PreorderSummaryDTO>> {
    const data = PreordersFilterMapper.toRequest(params);
    return this.carrierPreorderSharedService.getPreorderSummary(data);
  }

  @Mutation
  setTrailerVolumeTypes(trailerVolumeTypes: TrailerVolumeType[]): void {
    this.trailerVolumeTypes = trailerVolumeTypes;
  }

  @Mutation
  setVatRatesTypes(vatRatesTypes: VatRate[]): void {
    this.vatRatesTypes = vatRatesTypes;
  }

  @Mutation
  setIsLoadingTrailerVolumeTypes(isLoadingTrailerVolumeTypes: boolean): void {
    this.isLoadingTrailerVolumeTypes = isLoadingTrailerVolumeTypes;
  }
}

container
  .bind<CarrierPreorderSharedModule>(CARRIER_PREORDER_SHARED_MODULE_ID)
  .to(CarrierPreorderSharedModule)
  .inSingletonScope();
