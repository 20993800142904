export const API_SUGGEST_PARTY = '/suggest/party';
export const API_FINDBYID_PARTY = '/findById/party';

export const API_ATISU_GETRATINGS = 'getratings';

export const API_RECOGNITION_PARSE_DRIVER_LICENSE = '/parse-driver-license';
export const API_RECOGNITION_PARSE_STS = '/parse-sts';
export const API_RECOGNITION_PARSE_PASSPORT = '/parse-passport';

export const API_RECOGNITION_ANALYSIS_STS = '/sts';
export const API_RECOGNITION_ANALYSIS_PASSPORT = '/passport';
export const API_RECOGNITION_ANALYSIS_DRIVER_LICENSE = '/driver-license';
