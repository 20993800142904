
import { PropType } from 'vue';
import Cookies from 'js-cookie';
import TruckOfferingTooltip from '@/features/shared/components/TruckOfferingTooltip.vue';
import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import SrmReportFastPay from '@/features/srm/reports/components/SrmReportFastPay.vue';

import OrdersPlanningTooltip from '@/features/shared/components/OrdersPlanningTooltip.vue';

export default {
  name: 'AppSidebarExpedition',

  components: {
    AppSidebarButton,
    SrmReportFastPay,
    TruckOfferingTooltip,
    OrdersPlanningTooltip,
  },

  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    blinkElements: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },

  data() {
    return {
      isShowModalReportFastPay: false,
      isShowTruckOfferingTooltipOnHover: false,
    };
  },

  computed: {
    authService(): AuthService {
      return container.get(AUTH_SERVICE_ID);
    },

    configService(): ConfigService {
      return container.get(CONFIG_SERVICE_ID);
    },

    isUsersPage(): boolean {
      return this.$can('showIconUsers', 'AppSidebarExpedition');
    },

    orgsButtonCaption(): string {
      return this.$can('read', 'Orgs') ? 'Перевозчики' : 'Мой профиль';
    },

    isBlinkTruckOfferingLink(): boolean {
      return this.blinkElements.includes('truck-offering');
    },

    isShowTruckOfferingTooltip(): boolean {
      return this.$can('read', 'TruckOfferingTooltip') || this.isBlinkTruckOfferingLink;
    },

    isBlinkOrdersPlanningLink(): boolean {
      return this.blinkElements.includes('orders-planning');
    },

    isShowOrdersPlanningTooltip(): boolean {
      return this.$can('read', 'OrdersPlanningTooltip') || this.isBlinkOrdersPlanningLink;
    },
  },

  created() {
    this.isShowTruckOfferingTooltipOnHover = !!Cookies.get('truckOfferingTooltipViewed');
  },

  methods: {
    onClickOutside(): void {
      this.$emit('hide:blink', 'truck-offering');
      this.isShowTruckOfferingTooltipOnHover = true;
    },
  },
};
