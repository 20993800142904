import { CompanyCounters } from '@/features/fuel/shared/types';

export enum VerticallyIntegratedOilCompany {
  ALL = 'ALL',
  GAZPROMNEFT = 'GAZPROMNEFT',
  ROSNEFT = 'ROSNEFT',
  TATNEFT = 'TATNEFT',
}

export enum CompanyNames {
  ALL = 'Все',
  GAZPROMNEFT = 'Газпромнефть',
  ROSNEFT = 'Роснефть',
  TATNEFT = 'Татнефть',
}

export enum CompanyCodes {
  GAZPROMNEFT = 1,
  ROSNEFT = 2,
  TATNEFT = 3,
}

export const getCompanyCountersInitialState = (): CompanyCounters => ({
  GAZPROMNEFT: 0,
  ROSNEFT: 0,
  TATNEFT: 0,
});
