
import AppLayout from '@/features/shared/layout/AppLayout.vue';
import RedesignedAppLayout from '@/features/redesigned/shared/layout/AppLayout.vue';
import FeedbackButton from '@/features/shared/feedback/FeedbackButton.vue';
import ScrollTopButton from '@/features/shared/components/ScrollTopButton.vue';
import { MaxDisplayWidth } from '@/features/shared/constant';
import SRMWebsocketModule, { SRM_WEBSOCKET_MODULE_ID } from '@/features/srm/store/SRMWebsocketStore';
import container from '@/di';
import { isPhone } from '@/utils/device';
import OrgJoiningRequestModal from '@/features/redesigned/srm/orgs/pages/OrgJoiningRequestModal.vue';
import { getHTTPError } from '@/utils/errors';
import SRMOrgModule, { SRM_ORG_MODULE_ID } from '@/features/srm/orgs/store/OrgModule';
import { CounterpartyType } from '@/features/srm/orgs/constants';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import LoyaltyProgramModule, { LOYALTY_PROGRAM_MODULE_ID } from '@/features/srm/loyalty/store';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';

export default {
  name: 'SrmLayout',

  components: {
    AppLayout,
    RedesignedAppLayout,
    FeedbackButton,
    ScrollTopButton,
    OrgJoiningRequestModal,
  },

  data() {
    return {
      MaxDisplayWidth,
      width: window.innerWidth,
      isItPhone: false,
      isShowJoiningRequestModal: false,
    };
  },

  computed: {
    websocketModule(): SRMWebsocketModule {
      return container.get(SRM_WEBSOCKET_MODULE_ID);
    },

    orgModule(): SRMOrgModule {
      return container.get(SRM_ORG_MODULE_ID);
    },

    userModule(): SRMUserModule {
      return container.get(SRM_USER_MODULE_ID);
    },

    loyaltyModule(): LoyaltyProgramModule {
      return container.get(LOYALTY_PROGRAM_MODULE_ID);
    },

    orderModule(): SRMOrderModule {
      return container.get(SRM_ORDER_MODULE_ID);
    },

    isShowFeedback() {
      return !this.$can('use', 'TalkMe') && this.$can('use', 'Feedback');
    },
  },

  watch: {
    width() {
      this.isItPhone = isPhone();
    },
  },

  created() {
    window.addEventListener('resize', this.onResize);
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },

  mounted() {
    this.websocketSync();
    this.isItPhone = isPhone();

    this.fetchLoyaltyInfo();

    if (!!sessionStorage.getItem('sendJoiningRequest') && this.$can('send', 'JoiningRequestToCarrier')) {
      this.sendOrgJoiningRequest();
    }
  },

  methods: {
    async fetchLoyaltyInfo() {
      try {
        if (this.$can('use', 'RedesignedSrm')) {
          await this.loyaltyModule.loadCarrierHasLoyalty();
          if (this.userModule.user?.userCounterparties[0]?.carrier && !this.loyaltyModule.isCarrierHasLoyalty) {
            await this.orderModule.getCarrierOrderCounter();
          }
        }
      } catch (e) {
        getHTTPError(e);
      }
    },

    isRedesignActive(): boolean {
      if (!this.$route.name) {
        return false;
      }

      const isOrdersRouteActive = this.$route.matched.some((route) => route.name === 'orders' || route.name === 'planning');
      const isTruckOfferingRouteActive = this.$route.matched.some((route) => route.name === 'truck-offering');
      const isSubscriptionsRouteActive = this.$route.matched.some((route) => route.name === 'srm-subscriptions');

      return ((this.$can('use', 'RedesignedSrm')) || isPhone())
        && (isOrdersRouteActive || isTruckOfferingRouteActive || isSubscriptionsRouteActive);
    },

    onResize() {
      this.width = window.innerWidth;
    },

    async websocketSync(): Promise<void> {
      await this.websocketModule.connectV2Topics();
    },

    async sendOrgJoiningRequest(): Promise<void> {
      if (
        !sessionStorage.getItem('registrationOrgLegalFormId')
        || !sessionStorage.getItem('registrationOrgInn')
      ) return;

      try {
        const data = {
          legalFormId: sessionStorage.getItem('registrationOrgLegalFormId') ?? 0,
          inn: sessionStorage.getItem('registrationOrgInn') ?? null,
          kpp: sessionStorage.getItem('registrationOrgKpp') ?? null,
          typeId: CounterpartyType.Carrier,
        };
        await this.orgModule.sendUserNotice(data);

        sessionStorage.removeItem('registrationOrgLegalFormId');
        sessionStorage.removeItem('registrationOrgInn');
        sessionStorage.removeItem('registrationOrgKpp');
        sessionStorage.removeItem('sendJoiningRequest');

        await this.userModule.loadCurrentUser();

        this.isShowJoiningRequestModal = true;
      } catch (e) {
        getHTTPError(e);
      }
    },
  },
};
