import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SRM_DICTIONARY_SERVICE_V2_ID = Symbol('srmDictionaryServiceV2');

@Register(
  SRM_DICTIONARY_SERVICE_V2_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMDictionaryServiceV2 extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMDictionaryServiceHostV2);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMDictionaryServiceV2;
