
import { PropType } from 'vue';

import VueDraggableResizable from 'vue-draggable-resizable/src/components/vue-draggable-resizable.vue';

export default {
  name: 'UiDraggableWrapper',

  components: {
    VueDraggableResizable,
  },

  props: {
    width: {
      type: Number as PropType<number>,
      required: true,
    },

    height: {
      type: Number as PropType<number>,
      required: true,
    },

    right: {
      type: Number as PropType<number>,
      required: true,
    },

    bottom: {
      type: Number as PropType<number>,
      required: true,
    },

    dragElementId: {
      type: String as PropType<string>,
      required: true,
    },

    zIndex: {
      type: Number as PropType<number>,
      default: 20,
    },
  },

  computed: {
    styles(): Record<string, string> {
      return {
        right: `${this.right}px`,
        bottom: `${this.bottom}px`,
      };
    },

    dragHandle(): string {
      return `#${this.dragElementId}`;
    },
  },

  methods: {
    onDragCallback(x: number, y: number) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (x <= (this.right + this.width) - windowWidth) {
        return false;
      }
      if (x >= this.right) {
        return false;
      }
      if (y <= (this.bottom + this.height) - windowHeight) {
        return false;
      }
      return y < this.bottom;
    },

    dragstop() {
      this.$emit('drag:stop');
    },
  },
};
