import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '@/abilities.types';

function basicRules(builder: AbilityBuilder<AppAbility>) {
  const { can } = builder;

  can('show', 'TM');
}

export function definedAbilitiesTaskManagerCS(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  basicRules(builder);

  can('CallSpecialist', 'TM');
}

export function definedAbilitiesTaskManager(
  builder: AbilityBuilder<AppAbility>,
): void {
  basicRules(builder);
}
