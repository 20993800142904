import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';
import { FuelFeedback } from '@/features/fuel/shared/types';
import { API_USER_FEEDBACK } from '@/features/fuel/shared/services/endpoints';

export const FUEL_SERVICE_ID = Symbol('fuelRemoteService');

@Register(
  FUEL_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
export default class FuelHttpService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.FuelServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  sendUserFeedback(feedback: FuelFeedback): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_USER_FEEDBACK,
      data: feedback,
    });
  }
}
