
import { PropType } from 'vue';
import { useToast } from 'vue-toastification';
import { FuelFeedback } from '@/features/fuel/shared/types';
import FuelClientModule, { FUEL_CLIENT_MODULE_ID } from '@/features/fuel/clients/store';
import container from '@/di';
import FuelHttpService, { FUEL_SERVICE_ID } from '@/features/fuel/shared/services/http';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import FeedbackFormGroup from '@/features/shared/feedback/FeedbackFormGroup.vue';
import { getHTTPError } from '@/utils/errors';

export default {
  name: 'FuelFeedbackForm',

  components: {
    FeedbackFormGroup,
  },

  props: {
    showCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    return { toast: useToast() };
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    clientModule(): FuelClientModule {
      return container.get(FUEL_CLIENT_MODULE_ID);
    },

    fuelService(): FuelHttpService {
      return container.get(FUEL_SERVICE_ID);
    },

    authService(): AuthService {
      return container.get(AUTH_SERVICE_ID);
    },

    isFuelClient(): boolean {
      return this.$can('read', 'FuelCurrentClient');
    },

    userFio(): string {
      return this.authService.getUserName() ?? '';
    },
  },

  methods: {
    async sendFeedback(feedback: string) {
      const params: FuelFeedback = {
        text: feedback,
        userName: this.userFio,
      };

      try {
        if (this.isFuelClient) {
          const client = await this.clientModule.getCurrentClient();
          params.userPhoneNumber = this.authService.getUserPhoneNumber() ?? undefined;
          params.userEmailAddress = client.email;
          params.userCompanyName = client.companyName;
        }

        await this.fuelService.sendUserFeedback(params);
        this.$emit('success');
      } catch (e) {
        this.toast.error(getHTTPError(e));
      }
    },
  },
};
