import container, {
  Inject,
  Injectable,
} from '@/di';
import HttpService, { SRM_SERVICE_ID } from '@/features/srm/services/http';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { Page } from '@/features/shared/types';
import {
  Cargo,
  DelayReason,
  LoadingType,
  LoadingTypeParams,
} from '@/features/srm/types';
import {
  ConsigneeModel,
  ContractListDTO,
  Contract,
  CompanyParams,
  ConsigneeParams,
  Company,
  TrailerTypeByIdParams,
  TrailerTypeDTO,
  TransportationType,
  CargoListDTO,
  ParamsDTO,
  Vehicle,
  VehicleListDTO,
  DriverListDTO,
  VehicleByIdParams,
} from '@/features/srm/dictionary/services/DictionaryService.types';
import {
  API_CONSIGNEE_PAGE,
  API_DELAY_REASON,
  API_GET_DRIVER_LIST,
  API_CUSTOMERS_GET_BY_COMPANY_ID,
  API_GET_EMPLOYEE_LIST,
  API_GET_VEHICLE_BY_ID,
  API_GET_VEHICLE_LIST,
  API_CONTRACTS_PAGE,
  API_LOADING_TYPE,
  API_TRAILER_TYPE_ID,
  API_TRANSPORTATION_TYPE,
} from '@/features/srm/dictionary/services/endpoint';
import { API_GET_CARGO_LIST } from '@/features/srm/orders/services/endpoints';
import { Employee } from '@/features/srm/orders/services/OrderService.types';
import SRMDictionaryServiceV2, { SRM_DICTIONARY_SERVICE_V2_ID } from '@/features/srm/services/SRMDictionaryServiceV2';
import SVCMapperServiceV2, { SVC_MAPPER_SERVICE_ID_V2 } from '@/features/sop/services/SVCMapperServiceV2';

export const SRM_DICTIONARY_SERVICE_ID = Symbol('SRMDictionaryService');

/**
 * https://dev.api.portal.gt.local/api/v1/swagger-ui/index.html?configUrl=/api/v1/v3/api-docs/swagger-config#/consignee-controller
 */

@Injectable()
export default class SRMDictionaryService {
  private api: HttpService;

  srmDictionaryServiceV2: SRMDictionaryServiceV2;

  svcMapperServiceV2: SVCMapperServiceV2;

  constructor(
    @Inject(SRM_SERVICE_ID) api: HttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
    @Inject(SRM_DICTIONARY_SERVICE_V2_ID) srmDictionaryServiceV2: SRMDictionaryServiceV2,
    @Inject(SVC_MAPPER_SERVICE_ID_V2) svcMapperServiceV2: SVCMapperServiceV2,
  ) {
    this.api = api;
    this.api.remoteClient.defaults.baseURL = config.CRMDictionaryServiceHost;
    this.srmDictionaryServiceV2 = srmDictionaryServiceV2;
    this.svcMapperServiceV2 = svcMapperServiceV2;
  }

  /**
   * Получение списка грузополучателей
   *
   * @param {object} params
   * @param {number} [params.size]
   * @param {number} [params.page]
   * @param {string} [params.q]
   * @param {string} [params.companyId] - ID организации
   * @param {string} [params.counterpartyId] - ID клиента
   */
  getConsigneeList(params: ConsigneeParams): Promise<Page<ConsigneeModel>> {
    return this.api.request({
      method: 'GET',
      url: API_CONSIGNEE_PAGE,
      params,
    });
  }

  /**
   * Получение Информации о компкании
   *
   * @param {object} params
   * @param {string} [params.companyId] - ID организации
   * @param {string} [params.counterpartyId] - ID клиента
   */
  getCustomerByCompanyId(params: CompanyParams): Promise<Company> {
    return this.svcMapperServiceV2.request({
      method: 'GET',
      url: API_CUSTOMERS_GET_BY_COMPANY_ID,
      params,
    });
  }

  /**
   * Получение списка договоров
   *
   * @param {ContractListDTO} params
   * @return {Promise<Page<Contract>>}
   */

  getContractList(params: Partial<ContractListDTO>): Promise<Page<Contract[]>> {
    return this.svcMapperServiceV2.request({
      method: 'GET',
      url: API_CONTRACTS_PAGE,
      params,
    });
  }

  getCargoList(params: Partial<CargoListDTO>): Promise<Page<Cargo>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CARGO_LIST,
      params,
    });
  }

  getDelayReasons(companyId: string): Promise<DelayReason[]> {
    return this.api.request({
      method: 'GET',
      url: API_DELAY_REASON,
      params: {
        companyId,
      },
    });
  }

  /**
   * Получение списка типов погрузки
   *
   * @param {LoadingTypeParams} [params]
   * @return {Promise<Array<LoadingType>>}
   */
  getLoadingTypes(params?: LoadingTypeParams): Promise<LoadingType[]> {
    return this.api.request({
      method: 'GET',
      url: API_LOADING_TYPE,
      params,
    });
  }

  /**
   * Получение общего типа
   *
   * @param {TrailerTypeByIdParams} [params]
   * @param {string} [id]
   * @return {Promise<TrailerTypeDTO>}
   */
  getTrailerTypeById(id: string, params: TrailerTypeByIdParams): Promise<TrailerTypeDTO> {
    return this.api.request({
      method: 'GET',
      url: API_TRAILER_TYPE_ID(id),
      params,
    });
  }

  /**
   * Получение списка типов перевозки (междугородняя и т.д.)
   *
   * @return {Promise<TransportationType[]>}
   */
  getTransportationTypes(): Promise<TransportationType[]> {
    return this.api.request({
      method: 'GET',
      url: API_TRANSPORTATION_TYPE,
    });
  }

  getEmployeeList(params: ParamsDTO): Promise<Page<Employee>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_EMPLOYEE_LIST,
      params,
    });
  }

  getDriverList(params: Partial<DriverListDTO>): Promise<Page<Employee>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_DRIVER_LIST,
      params,
    });
  }

  getVehicleList(params: Partial<VehicleListDTO>): Promise<Page<Vehicle>> {
    return this.srmDictionaryServiceV2.request({
      method: 'GET',
      url: API_GET_VEHICLE_LIST,
      params,
    });
  }

  getVehicleById(id: string, params: VehicleByIdParams): Promise<Vehicle> {
    return this.api.request({
      method: 'GET',
      url: API_GET_VEHICLE_BY_ID(id),
      params,
    });
  }
}

container.bind<SRMDictionaryService>(SRM_DICTIONARY_SERVICE_ID).to(SRMDictionaryService);
