import type { AnyMaskedOptions } from 'imask';
import IMask, { InputMask } from 'imask';
import type { ObjectDirective } from 'vue';
import { Undefinable } from '@/features/shared/types';

type MaskedHTMLElement = HTMLElement & {
  maskRef?: IMask.InputMask<AnyMaskedOptions>;
};

function fireEvent(
  el: MaskedHTMLElement,
  eventName: string,
  data: Undefinable<InputMask<AnyMaskedOptions>>,
) {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    detail: data,
  });
  el.dispatchEvent(event);
}

function initMask(el: MaskedHTMLElement, opts: AnyMaskedOptions) {
  // eslint-disable-next-line no-param-reassign
  el.maskRef = IMask(el, opts)
    .on('accept', () => fireEvent(el, 'accept', el.maskRef))
    .on('complete', () => fireEvent(el, 'complete', el.maskRef));
}

function destroyMask(el: MaskedHTMLElement) {
  if (el.maskRef) {
    el.maskRef.destroy();
    // eslint-disable-next-line no-param-reassign
    delete el.maskRef;
  }
}

const Mask: ObjectDirective = {
  beforeMount(el: HTMLElement, directive) {
    const element = el as MaskedHTMLElement;
    const { value: options } = directive;

    if (!options) return;

    initMask(element, options);
  },

  updated(el: HTMLElement, directive) {
    const element = el as MaskedHTMLElement;
    const { value: options } = directive;

    if (!options) {
      destroyMask(el);
      return;
    }

    if (!element.maskRef) {
      initMask(el, options);
      return;
    }

    element.maskRef.updateOptions(options);
    const elementValue = element as MaskedHTMLElement & { value: string };
    const maskRef = element.maskRef as InputMask<AnyMaskedOptions> & {
      _onChange: () => void;
    };

    if (elementValue.value !== element.maskRef.value) {
      // eslint-disable-next-line no-underscore-dangle
      maskRef._onChange();
    }
  },

  unmounted(el: HTMLElement) {
    destroyMask(el);
  },
};

export default Mask;
