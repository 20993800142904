/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import {
  isNull,
  sortBy,
} from 'lodash';
import dayjs from 'dayjs';
import { subject } from '@casl/ability';
import { IMessage } from '@stomp/stompjs';
import isArray from 'lodash/isArray';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import SRMOrderService, { SRM_ORDER_SERVICE_ID } from '@/features/srm/orders/services/OrderService';
import {
  CitiesParams,
  LoadingEvent,
  OrderAuction,
  OrderAuctionBid,
  OrderAuctionListItem,
  OrderAuctionPlaceBet,
  OrderAuctionShort,
  OrderCancelReason,
  OrderCard,
  OrderCardCounterparty,
  OrderChangeStatus,
  OrderHistoryModel,
  OrderListItem,
  OrderModel,
  OrderRequest,
  OrderRequestBid,
  OrderRequestListItem,
  OrderRequestPlaceBet,
  OrderRequestShort,
  OrderSendEmailDTO,
  RegionsParams,
  SendCargoInsuranceEmailDTO,
  Status,
  StatusModel,
} from '@/features/srm/orders/services/OrderService.types';
import { STORE_ID } from '@/store';
import {
  CityFilterValue,
  DataForFilters,
  FilterValue,
  OrderCounters,
  OrderLocalFilters,
  SendEmailDTO,
  TypeValuesFilters,
} from '@/features/srm/types';
import SRMOrgService, { SRM_ORG_SERVICE_ID } from '@/features/srm/orgs/services/OrgService';
import SRMWebsocketModule, { SRM_WEBSOCKET_MODULE_ID } from '@/features/srm/store/SRMWebsocketStore';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { toIsoStringWithTimeZoneOffset } from '@/utils/handlers';
import {
  DictionaryModel,
  DocumentModel,
  Nullable,
  Undefinable,
} from '@/features/shared/types';
import SRMDictionaryService, { SRM_DICTIONARY_SERVICE_ID } from '@/features/srm/dictionary/services/DictionaryService';
import {
  TrailerTypeDTO,
  TransportationType,
} from '@/features/srm/dictionary/services/DictionaryService.types';
import { WS_TOPICS_ADDRESS_ORDER_ID } from '@/features/srm/orders/services/endpoints';
import {
  CarriersCommunicationService,
  SRM_CARRIERS_COMMUNICATION_SERVICE_ID,
} from '@/features/srm/carriers-communication/services/CarriersCommunicationService';
import SRMDocumentService, { SRM_DOCUMENT_SERVICE_ID } from '@/features/srm/documents/services/DocumentService';
import RegionsMapper from '@/features/srm/orders/mappers/RegionsMapper';
import CitiesMapper from '@/features/srm/orders/mappers/CitiesMapper';
import {
  LoadOrderParams,
  ResponsibleLogist,
  UpdateOrdersFilterValueByKey,
} from '@/features/srm/orders/types';
import SRMVehicleService, { SRM_VEHICLE_SERVICE_ID } from '@/features/srm/vehicles/services/VehicleService';
import { CounterpartyModel } from '@/features/srm/orgs/services/OrgService.types';
import { VehicleModel } from '@/features/srm/vehicles/services/VehicleService.types';
import { Driver } from '@/features/srm/drivers/services/DriverService.types';
import { getOrderStatusName } from '@/features/srm/orders/constants';
import {
  getNameShipAgentAttracs,
  LoadingCargoTypeName,
  OrderCarrierType,
  OrdersPage,
} from '@/features/srm/constants';
import {
  OrderStatus,
  TrailerType,
} from '@/features/shared/constant';
import {
  SRM_API_COMPOSER_SERVICE_ID,
  SRMApiComposerService,
} from '@/features/srm/services/SRMApiComposerService';
import CarriersOrderService, {
  CARRIERS_ORDER_SERVICE_ID,
} from '@/features/redesigned/srm/carriers-order/services/CarriersOrder';
import {
  MatchedOrdersParams,
  RecommendOrderRequest,
  RecommendOrders,
} from '@/features/redesigned/srm/carriers-order/types';
import SrmCounterpartyService, { SRM_COUNTERPARTY_SERVICE_ID } from '@/features/srm/counterparty/services/CounterpartyService';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import LoyaltyProgramModule, { LOYALTY_PROGRAM_MODULE_ID } from '@/features/srm/loyalty/store';

export const SRM_ORDER_MODULE_ID = 'srm-order';

@Module
@Injectable()
export default class SRMOrderModule extends VuexModule {
  @LazyInject(SRM_ORDER_SERVICE_ID) orderService: SRMOrderService;

  @LazyInject(SRM_ORG_SERVICE_ID) orgService: SRMOrgService;

  @LazyInject(SRM_VEHICLE_SERVICE_ID) vehicleService: SRMVehicleService;

  @LazyInject(SRM_COUNTERPARTY_SERVICE_ID) counterpartyService: SrmCounterpartyService;

  @LazyInject(SRM_WEBSOCKET_MODULE_ID) websocketModule: SRMWebsocketModule;

  @LazyInject(CONFIG_SERVICE_ID) configService: ConfigService;

  @LazyInject(SRM_DICTIONARY_SERVICE_ID) dictionaryService: SRMDictionaryService;

  @LazyInject(SRM_CARRIERS_COMMUNICATION_SERVICE_ID) carrierCommunicationService: CarriersCommunicationService;

  @LazyInject(SRM_DOCUMENT_SERVICE_ID) documentService: SRMDocumentService;

  @LazyInject(SRM_API_COMPOSER_SERVICE_ID) apiComposerService: SRMApiComposerService;

  @LazyInject(CARRIERS_ORDER_SERVICE_ID) carriersOrderService: CarriersOrderService;

  @LazyInject(SRM_USER_MODULE_ID) srmUserModule: SRMUserModule;

  @LazyInject(LOYALTY_PROGRAM_MODULE_ID) loyaltyModule: LoyaltyProgramModule;

  cancelReasons: OrderCancelReason[] = [];

  order: Nullable<OrderModel> = null;

  truckOfferingOrderCards: OrderCard[] = [];

  orderListOrderCards: OrderCard[] = [];

  loadingOrderCardIds: string[] = [];

  currentOrdersPage: OrdersPage = OrdersPage.Orders;

  shipAgent: Nullable<CounterpartyModel> = null;

  customer: Nullable<CounterpartyModel> = null;

  truck: Nullable<VehicleModel> = null;

  trailer: Nullable<VehicleModel> = null;

  driver: Nullable<Driver> = null;

  counterparty: Nullable<OrderCardCounterparty> = null;

  orders: OrderListItem[] = [];

  regions: FilterValue[] = [];

  cities: CityFilterValue[] = [];

  responsibleLogistList: ResponsibleLogist[] = [];

  history: OrderHistoryModel[] = [];

  counters: OrderCounters = {};

  cargoInsurance: Blob | null = null;

  pages = 0;

  totalElements = null as Nullable<number>;

  statuses: StatusModel[] = [];

  cargoInsuranceEmails: string[] = [];

  orgEmails: string[] = [];

  shipAgents = [];

  statusAndPriceMessage = '';

  recommendOrders: RecommendOrders = {};

  recommendOrdersCount = 0;

  filters: TypeValuesFilters = {
    carriers: null,
    customers: null,
    endLocations: null,
    startLocations: null,
    shipAgentResponsible: null,
    responsibleLogist: null,
    firstCity: null,
    lastCity: null,
    firstRegions: null,
    lastRegions: null,
  };

  auctionList: OrderAuctionListItem[] = [];

  currentOrderAuction: OrderAuction | null = null;

  requestList: OrderRequestListItem[] = [];

  currentOrderRequest: OrderRequest | null = null;

  loadingTypesDictionary: DictionaryModel[] | null = [];

  trailerType: TrailerTypeDTO | null = null;

  transportationTypes: TransportationType[] = [];

  idWs = '';

  searchQuery: Nullable<string> = null;

  statusList: Status[] = [];

  channelsList: Status[] = [];

  sourcesList = [];

  driverDocuments: DocumentModel[] = [];

  documents: DocumentModel[] = [];

  matchedOrdersParams: MatchedOrdersParams[] = [];

  matchedOrdersParamsParentOrderNumber: Nullable<string> = null;

  onboardingOrderMatchId: Nullable<string> = null;

  isOrdersUpdating = false;

  isOrderCardLoading = false;

  isResetFilters = false;

  isOrderCardSuccessfullyLoaded = false;

  ordersFilters: OrderLocalFilters = {};

  carrierOrderCounter: Nullable<number> = null;

  constructor(@Inject(STORE_ID) store: Store<unknown>) {
    super({ name: SRM_ORDER_MODULE_ID, store });
  }

  getLoadingEvents(): LoadingEvent[] {
    if (!this.order) {
      return [];
    }

    return sortBy(this.order.loadingEvents, (loadingEvent) => dayjs(loadingEvent.startDateTime).unix());
  }

  getLoadingTypeById(): Undefinable<string> {
    const name = this.loadingTypesDictionary?.find(
      (el) => el.name === LoadingCargoTypeName[this.order?.loadingTypeId],
    )?.name;
    const nameSpaced = name
      ?.replace(/([А-Я])/g, ' $1')
      .toLowerCase()
      .trim();
    return nameSpaced ? nameSpaced.charAt(0).toUpperCase() + nameSpaced.slice(1) : undefined;
  }

  // общий тип
  getLoadingTrailerType() {
    return this.trailerType;
  }

  getLoadingTransportationType() {
    if (isNull(this.order?.transportationTypeCode)) return null;
    return this.transportationTypes.find((el) => el.code === this.order?.transportationTypeCode)
      ?.name;
  }

  getOrderCards() {
    return this.currentOrdersPage === OrdersPage.Orders ? this.orderListOrderCards : this.truckOfferingOrderCards;
  }

  get statusById() {
    return (id: number) => this.statusList.find((el) => el.id === id);
  }

  newOrderCounter() {
    return this.counters.ORDERS_NEW;
  }

  isRateIncreased(): boolean {
    return !this.loyaltyModule.isCarrierHasLoyalty && (
      this.loyaltyModule.isRateIncreased()
      && (
        !this.srmUserModule.user?.userCounterparties[0]?.counterparty
        || (this.carrierOrderCounter !== null && this.carrierOrderCounter < 3)
      )
    );
  }

  get loadingOperationCaptionByType() {
    return (operationType) => {
      switch (operationType) {
        case 1:
          return 'Погрузка';
        case 2:
          return 'Разгрузка';
        case 3:
          return 'Промежуточная погрузка';
        case 4:
          return 'Промежуточная разгрузка';
        default:
          return 'Неизвестный статус';
      }
    };
  }

  get shipAgentById() {
    return (id) => this.shipAgents.find((shipAgent) => shipAgent.id === id);
  }

  @Action
  async loadResponsibleLogistList(id: string) {
    const responsibleList = await this.orderService.loadResponsibleLogistList(id);

    this.setResponsibleLogistList(responsibleList);
  }

  @Mutation
  setResponsibleLogistList(responsibleList: ResponsibleLogist[]) {
    this.responsibleLogistList = responsibleList;
  }

  @Action
  async loadDriverDocuments(driverId?: string) {
    const documents = await this.documentService.getDocuments({
      entityId: driverId || '',
      skipFileContent: true,
    });

    this.setDriverDocuments(documents);
  }

  @Mutation
  setDriverDocuments(driverDocuments: DocumentModel[]) {
    this.driverDocuments = driverDocuments;
  }

  @Action
  async loadRegions(params: RegionsParams) {
    let regions;

    if (params && params.id) {
      const region = await this.orderService.getRegionById(params.id);
      regions = [region];
    } else {
      regions = await this.orderService.getRegions(params);
    }

    const mappedRegionsForFilter = RegionsMapper.fromResponse(regions);
    this.setRegions(mappedRegionsForFilter);
  }

  @Action
  async loadCities(params: CitiesParams) {
    const { content } = await this.orderService.getCities(params);
    const mappedCitiesForFilter = CitiesMapper.fromResponse(content);

    this.setCities(mappedCitiesForFilter);
  }

  @Action
  async loadLoadingTypeDictionary() {
    const loadingTypesDictionaryResponse = await this.dictionaryService.getLoadingTypes();
    this.setLoadingTypesDictionary(loadingTypesDictionaryResponse);
  }

  @Action
  async loadTrailerTypeById(shipAgentDictionaryCounterpartyId: string) {
    if (
      !this.order?.trailerTypeId
      || !this.order?.shipAgentId
    ) return;
    const id = this.order?.trailerTypeId;
    const params = {
      companyId: shipAgentDictionaryCounterpartyId,
      single: true,
    };
    const trailerType = await this.dictionaryService.getTrailerTypeById(id, params);
    if (!trailerType.id) return;
    this.setTrailerType(trailerType);
  }

  @Action
  async loadTransportationTypesDictionary() {
    const transportationTypes = await this.dictionaryService.getTransportationTypes();
    this.setTransportationTypes(transportationTypes);
  }

  @Action
  async loadOrders({ ordersFilterDTO, params, isSavePrevPageItems }: DataForFilters) {
    const ordersResponse = await this.orderService.getOrders(ordersFilterDTO, params);
    if (!ordersResponse) return;
    this.setOrders({ ordersResponse, isSavePrevPageItems });
    this.setTotalPages(ordersResponse);
    this.setTotalElements(ordersResponse);
  }

  @Action
  async loadShipAgents() {
    const shipAgents = await this.counterpartyService.getShipAgents();
    this.setShipAgents(shipAgents);
  }

  @Action
  loadOrderStatuses() {
    const statuses = Object.values(OrderStatus).map((id) => ({
      id,
      name: getOrderStatusName(id),
    }));
    this.setOrderStatuses(statuses);
  }

  @Action
  async getFiltersValues({ ordersFilterDTO, params }) {
    const filtersValues = await this.orderService.getFiltersValues(ordersFilterDTO, params);
    this.setValuesForFilters(filtersValues);
  }

  @Action
  async completeOrder({ orderId, params }) {
    await this.orderService.completeOrder(orderId, params);
  }

  @Action
  async editResourcesOrder({ orderId, data }) {
    await this.orderService.editResourcesOrder(orderId, data);
  }

  @Action
  async loadOrderCounters({ ordersFilterDTO, params }: DataForFilters) {
    const countsResponse = await this.orderService.getCountersOrders(ordersFilterDTO, params);
    return this.setCounters(countsResponse);
  }

  @Action
  async loadOrder({ orderId, orderVersionId }: LoadOrderParams) {
    if (orderVersionId) {
      const order = await this.orderService.getOrderHistoricalVersion(orderVersionId);
      this.setOrder(order);
    } else {
      const order = await this.orderService.getOrder(orderId);
      this.setOrder(order);
    }
  }

  @Action
  async loadOrderCard(orderId: string) {
    this.setIsOrderCardSuccessfullyLoaded(false);

    const orderCardIndex = this.getOrderCards().findIndex((item) => item.order.id === orderId);
    if (orderCardIndex !== -1) {
      this.setOrderCard(this.getOrderCards()[orderCardIndex]);
    }

    this.loadingOrderCardIds.push(orderId);

    let response;
    let error;
    try {
      response = await this.apiComposerService.getOrderCard(orderId);
      this.setOrderCard(response);
      this.setIsOrderCardSuccessfullyLoaded(true);
    } catch (e) {
      error = e;
    }

    this.loadingOrderCardIds.splice(this.loadingOrderCardIds.findIndex((id) => id === orderId), 1);

    if (error) {
      throw error;
    }

    if (orderCardIndex !== -1) {
      this.getOrderCards().splice(orderCardIndex, 1, response);
    } else {
      this.addToOrderCards(response);
    }

    if (this.getOrderCards().length >= 6) {
      this.getOrderCards().splice(0, 1);
    }
  }

  @Mutation
  addToOrderCards(orderCard: OrderCard) {
    this.getOrderCards().push(orderCard);
  }

  @Mutation
  setIsOrderCardSuccessfullyLoaded(isOrderCardSuccessfullyLoaded: boolean) {
    this.isOrderCardSuccessfullyLoaded = isOrderCardSuccessfullyLoaded;
  }

  @Action
  async loadOrderForWebsocket(orderId: string) {
    const order = await this.orderService.getOrder(orderId);
    this.setOrder(order);

    const orderCardId = this.getOrderCards().findIndex((item) => item.order.id === orderId);
    if (orderCardId !== -1) {
      this.getOrderCards()[orderCardId].order = subject('Order', order);
    }
  }

  @Action
  async loadOrderHistory({ orderVersionId, orderId }) {
    const history = await this.orderService.getOrderHistory(orderId, orderVersionId);

    this.setHistory(history);
  }

  @Action
  async sendEmailWithOrder(sendEmailDTO: OrderSendEmailDTO) {
    await this.orderService.sendEmailWithOrder(sendEmailDTO);
  }

  @Action
  async sendEmailWithDocuments(sendEmailDTO: SendEmailDTO) {
    await this.orderService.sendEmailWithDocuments(sendEmailDTO);
  }

  @Action
  async loadCargoInsurance(orderId: string) {
    const cargoInsurance = await this.orderService.getCargoInsurance(orderId);
    this.setCargoInsurance(cargoInsurance);
  }

  @Action
  async sendEmailWithInsurance(sendCargoInsuranceEmailDTO: SendCargoInsuranceEmailDTO) {
    await this.orderService.sendEmailWithInsurance(sendCargoInsuranceEmailDTO);
  }

  @Action
  async loadDefaultCargoInsuranceEmails(shipAgentId) {
    const emails = await this.orderService.getDefaultCargoInsuranceEmails(shipAgentId);
    this.setCargoInsuranceEmails(emails);
  }

  @Action
  async loadDefaultEmail(orgId: string) {
    const defaultEmail = await this.counterpartyService.getOrgEmail(orgId);
    this.setOrgEmails([defaultEmail]);
  }

  @Action
  async setOrderStatus(params) {
    await this.orderService.setOrderStatus(params);
  }

  @Action
  async setOrderParams(params) {
    await this.orderService.updateOrder(params);
  }

  @Action
  async loadCancelReasons() {
    const reasons = await this.orderService.getCancelReasons();
    this.setCancelReasons(reasons);
  }

  @Action
  async setInstructions(data) {
    this.syncLocalLoadingInstructions(data);
    await this.orderService.setInstructions(data);
  }

  @Action
  async auctionOrdersSubscribe(subscriptionId: string) {
    await this.websocketModule.subscribe({
      destination: this.configService.SRMWebsocketOrderAuctionSubscribeDestination,
      messageCallback: this.updateAuctionOrder,
      subscriptionId,
    });
  }

  @Action
  async orderTopicSubscribe(subscriptionId: string) {
    await this.websocketModule.subscribeTopicV2({
      destination: WS_TOPICS_ADDRESS_ORDER_ID(subscriptionId),
      messageCallback: this.updateOrderWebsocketRequest,
      subscriptionId,
    });
  }

  @Action
  async websocketUnsubscribe(subscriptionId: string) {
    await this.websocketModule.unsubscribe(subscriptionId);
  }

  @Action
  async sendDataForAuctionSubscription(orders?: string[]) {
    let auctionOrders = orders;
    if (!auctionOrders) {
      auctionOrders = this.orders
        .filter((order: OrderListItem) => order.carrierType === OrderCarrierType.Auction)
        .map((order: OrderListItem) => order.orderId);
    }
    await this.websocketModule.send({
      destination: this.configService.SRMWebsocketOrderAuctionSendDestination,
      body: { orders: auctionOrders },
    });
  }

  @Action
  async sendDataForRequestSubscription(orders?: string[]) {
    let requestOrders = orders;
    if (!requestOrders) {
      requestOrders = this.orders
        .filter((order: OrderListItem) => order.carrierType === OrderCarrierType.OfferCollection)
        .map((order: OrderListItem) => order.orderId);
    }
    await this.websocketModule.send({
      destination: this.configService.SRMWebsocketOrderRequestSendDestination,
      body: { orders: requestOrders },
    });
  }

  @Action
  async loadAuctionOrder(orderId: string) {
    const order = await this.orderService.getAuctionOrder(orderId);
    this.setAuctionOrder(order);
  }

  @Action
  async placeAuctionBet(data: OrderAuctionPlaceBet) {
    await this.orderService.placeAuctionBet(data);
  }

  @Action
  async placeRequestBet(data: OrderRequestPlaceBet) {
    await this.orderService.placeRequestBet(data);
  }

  @Action
  async loadAuctionsByOrderIds(orders: string[]) {
    const { auctions } = await this.orderService.getAuctionsByOrderIds(orders);
    this.setAuctionList(auctions);
  }

  @Action
  async loadRequestsByOrderIds(orders: string[]) {
    const { offers } = await this.orderService.getRequestsByOrderIds(orders);
    this.setRequestList(offers);
  }

  @Action
  async requestOrdersSubscribe(subscriptionId: string) {
    await this.websocketModule.subscribe({
      destination: this.configService.SRMWebsocketOrderRequestSubscribeDestination,
      messageCallback: this.updateRequestOrder,
      subscriptionId,
    });
  }

  @Action
  async loadRequestOrder(orderId: string) {
    const request = await this.orderService.getRequestOrder(orderId);
    this.setRequestOrder(request);
  }

  @Action
  async refuseAuctionOrder(orderId: string) {
    await this.orderService.refuseAuctionOrder(orderId);
  }

  @Action
  downloadAuctionHistory(orderId: string): Promise<Blob> {
    const date = toIsoStringWithTimeZoneOffset(new Date());
    return this.orderService.downloadAuctionHistory(orderId, date);
  }

  @Action
  downloadRequestHistory(orderId: string): Promise<Blob> {
    const date = toIsoStringWithTimeZoneOffset(new Date());
    return this.orderService.downloadRequestHistory(orderId, date);
  }

  @Action
  async loadStatusList() {
    if (!this.statusList.length) {
      const statusList = await this.orderService.getStatusList();
      this.setStatusList(statusList);
    }
  }

  @Action
  async loadOrderChannels() {
    const channels = await this.orderService.loadOrderChannels();
    this.setOrderChannels(channels);
  }

  @Action
  async loadOrderSources() {
    const orderSources = await this.orderService.loadOrderSources();
    this.setOrderSources(orderSources);
  }

  @Action
  async loadShipAgent(counterpartyId: string) {
    const shipAgent = await this.counterpartyService.getCounterparty({ counterpartyId });
    this.setShipAgent(shipAgent);
  }

  @Action
  async loadCustomer(counterpartyId: string) {
    const customer = await this.counterpartyService.getCounterparty({ counterpartyId });
    this.setCustomer(customer);
  }

  @Action
  async loadTruck(truckId: string) {
    const truck = await this.counterpartyService.loadVehicle(truckId);
    this.setTruck(truck);
  }

  @Action
  async loadTrailer(trailerId: string) {
    const trailer = await this.counterpartyService.loadVehicle(trailerId);
    this.setTrailer(trailer);
  }

  @Action
  async loadDriver(driverId: string) {
    const driver = await this.counterpartyService.getDriver(driverId);
    this.setDriver(driver);
  }

  @Mutation
  updateOrder(body: OrderAuctionShort) {
    const {
      message,
      status,
    } = body;
    if (status) {
      this.order = {
        ...this.order,
        statusId: status,
      };

      const orderCardId = this.getOrderCards().findIndex((item) => item.order.id === this.order?.id);
      if (orderCardId !== -1 && this.order) {
        this.getOrderCards()[orderCardId].order = this.order;
      }
    }

    this.statusAndPriceMessage = message;
  }

  @Action
  async deleteOrderCard(orderId?: string) {
    const orderCardId = this.getOrderCards().findIndex((item) => item.order.id === orderId);
    if (orderCardId !== -1) {
      this.getOrderCards().splice(orderCardId, 1);
    }
  }

  @Action
  async searchOrderQuery(searchQuery: Nullable<string>) {
    this.setSearchQuery(searchQuery);
  }

  @Action
  setDocuments(documents: DocumentModel[]) {
    this.setOrderCardDocuments(documents);

    const orderCardIndex = this.getOrderCards().findIndex((item) => item.order.id === this.order?.id);
    if (orderCardIndex !== -1) {
      this.getOrderCards()[orderCardIndex].documents = documents;
    }
  }

  @Action
  async loadRecommendOrders() {
    const ordersParamsForRecommend = this.orders.map<RecommendOrderRequest>((order: OrderListItem) => ({
      orderId: order.orderId,
      firstEventCityName: order.firstEventCityName,
      firstEventCityId: order.firstCityId,
      firstEventRegionName: order.firstRegionName,
      firstEventRegionId: order.firstRegionId,
      lastEventCityName: order.lastEventCityName,
      lastEventCityId: order.lastCityId,
      lastEventRegionName: order.lastRegionName,
      lastEventRegionId: order.lastRegionId,
      trailer: order.tent ? TrailerType.Tent : TrailerType.Ref,
    }));

    const recommendOrders = await this.carriersOrderService.getRecommendOrders(ordersParamsForRecommend);
    this.setRecommendOrders(recommendOrders);
  }

  @Action
  async loadRecommendOrdersPage({ ordersFilterDTO, params, isSavePrevPageItems }: DataForFilters) {
    const ordersResponse = await this.carriersOrderService.getAllRecommendOrdersPage(ordersFilterDTO, params);
    this.setOrders({ ordersResponse, isSavePrevPageItems });
    this.setTotalPages(ordersResponse);
    this.setTotalElements(ordersResponse);
  }

  @Action
  async loadRecommendOrdersCount({ ordersFilterDTO }: DataForFilters) {
    const count = await this.carriersOrderService.getRecommendOrdersCount(ordersFilterDTO);

    this.setRecommendOrdersCount(count);
  }

  @Action
  async loadMatchedOrdersParams(orderIds: string[]) {
    const response = await this.carriersOrderService.getMatchedOrders(orderIds);
    this.setMatchedOrdersParams(response);
  }

  @Action
  async getCarrierOrderCounter() {
    if (!this.loyaltyModule.isRateIncreased()) return;

    const response = await this.orderService.getCarrierOrderCounter();
    this.setCarrierOrderCounter(response);
  }

  @Mutation
  setCarrierOrderCounter(value: number) {
    this.carrierOrderCounter = value;
  }

  @Mutation
  setRecommendOrders(recommendOrders: RecommendOrders) {
    this.recommendOrders = recommendOrders;
  }

  @Mutation
  setShipAgent(shipAgent: Nullable<CounterpartyModel>) {
    this.shipAgent = shipAgent;
  }

  @Mutation
  setCustomer(customer: Nullable<CounterpartyModel>) {
    this.customer = customer;
  }

  @Mutation
  setTruck(truck: Nullable<VehicleModel>) {
    this.truck = truck?.id ? truck : null;
  }

  @Mutation
  setTrailer(trailer: Nullable<VehicleModel>) {
    this.trailer = trailer?.id ? trailer : null;
  }

  @Mutation
  setDriver(driver: Nullable<Driver>) {
    this.driver = driver?.id ? driver : null;
  }

  @Mutation
  setRegions(regions: FilterValue[]) {
    this.regions = regions;
  }

  @Mutation
  setCities(cities: CityFilterValue[]) {
    this.cities = cities;
  }

  @Mutation
  setLoadingTypesDictionary(dictionary: DictionaryModel[]) {
    this.loadingTypesDictionary = [...dictionary];
  }

  @Mutation
  setTrailerType(trailerType: TrailerTypeDTO) {
    this.trailerType = { ...trailerType };
  }

  @Mutation
  setTransportationTypes(transportationTypes: TransportationType[]) {
    this.transportationTypes = [...transportationTypes];
  }

  @Mutation
  setAuctionList(auctions: OrderAuctionListItem[]) {
    this.auctionList = [...auctions];
  }

  @Mutation
  setRequestList(offers: OrderRequestListItem[]) {
    this.requestList = [...offers];
  }

  updateAuctionOrder({ body }: IMessage) {
    const auction = JSON.parse(body) as OrderAuctionShort;
    if (auction.isFinalBid && auction.orderId === this.order?.id) {
      this.loadAuctionOrder(auction.orderId);
      this.updateOrderStatus(auction.orderId);
    }
    this.updateOrderAuctionList(auction);
    this.updateCurrentOrderAuction(auction);
  }

  updateRequestOrder({ body }: IMessage) {
    const request = JSON.parse(body) as OrderRequestShort;
    this.updateOrderRequestList(request);
    this.updateCurrentOrderRequest(request);
  }

  updateOrderWebsocketRequest({ body }: IMessage) {
    const order = JSON.parse(body) as OrderAuctionShort;
    this.updateOrder(order);
  }

  @Mutation
  updateOrderStatus(orderId: string) {
    const updatedOrderIndex = this.orders.findIndex(
      (order: OrderListItem) => order.orderId === orderId,
    );
    if (updatedOrderIndex >= 0) {
      this.orders.splice(updatedOrderIndex, 1, {
        ...this.orders[updatedOrderIndex],
        statusId: OrderStatus.BiddingCompleting,
      });
    }
  }

  @Mutation
  updateCurrentOrderAuction({
    bid,
    carrierId,
    carrierName,
    date,
    isFinalBid,
    isMyBid,
    orderId,
    step,
  }: OrderAuctionShort) {
    if (this.currentOrderAuction && this.currentOrderAuction.orderId === orderId) {
      this.currentOrderAuction = {
        ...this.currentOrderAuction,
        winnerCarrierId: isFinalBid ? carrierId : this.currentOrderAuction.winnerCarrierId,
        winnerCarrierName: isFinalBid ? carrierName : this.currentOrderAuction.winnerCarrierName,
        bestBid: bid,
        isFinished: isFinalBid,
        isMyBid,
        orderId,
        bids: [...this.currentOrderAuction.bids],
      };

      const isBidAlreadyAdded = this.currentOrderAuction.bids.find(
        (orderAuctionBid: OrderAuctionBid) => orderAuctionBid.bid === bid,
      );
      if (!isBidAlreadyAdded) {
        this.currentOrderAuction.bids = [
          {
            bid,
            carrierId,
            carrierName,
            date,
            isMyBid,
            step,
          },
          ...this.currentOrderAuction.bids,
        ];
      }
    }
  }

  @Mutation
  updateOrderAuctionList({
    bid, bidsCount, isMyBid, orderId,
  }: OrderAuctionShort) {
    const auctionIndex = this.auctionList.findIndex(
      (orderAuction: OrderAuctionListItem) => orderAuction.orderId === orderId,
    );
    if (auctionIndex >= 0) {
      this.auctionList.splice(auctionIndex, 1, {
        ...this.auctionList[auctionIndex],
        bestBid: bid,
        bidsCount,
        isMyBid,
        orderId,
      });
    }
  }

  @Mutation
  updateCurrentOrderRequest({
    orderId,
    bid,
    date,
    carrierName,
    carrierId,
    comment,
  }: OrderRequestShort) {
    if (this.currentOrderRequest && this.currentOrderRequest.orderId === orderId) {
      this.currentOrderRequest = {
        ...this.currentOrderRequest,
        relevantBid: bid,
        relevantBidComment: comment,
        orderId,
        bids: [...this.currentOrderRequest.bids],
      };

      const isBidAlreadyAdded = this.currentOrderRequest.bids.find(
        (orderRequestBid: OrderRequestBid) => Date.parse(orderRequestBid.bidDate) === Date.parse(date),
      );
      if (!isBidAlreadyAdded) {
        this.currentOrderRequest.bids = [
          {
            bidDate: date,
            bidId: '',
            carrierId: carrierId || '',
            carrierName: carrierName || '',
            comment,
            offersCollectionId: '',
            price: bid || 0,
          },
          ...this.currentOrderRequest.bids,
        ];
      }
    }
  }

  @Mutation
  updateOrderRequestList({ orderId, bid, participantCount }: OrderRequestShort) {
    const requestIndex = this.requestList.findIndex(
      (orderRequest: OrderRequestListItem) => orderRequest.orderId === orderId,
    );
    if (requestIndex >= 0) {
      this.requestList.splice(requestIndex, 1, {
        ...this.requestList[requestIndex],
        relevantBid: bid || this.requestList[requestIndex].relevantBid,
        participantCount,
        orderId,
      });
    }
  }

  @Mutation
  clearCurrentAuction() {
    this.currentOrderAuction = null;
  }

  @Mutation
  clearCurrentRequest() {
    this.currentOrderRequest = null;
  }

  @Mutation
  setAuctionOrder(auction: OrderAuction) {
    this.currentOrderAuction = {
      ...auction,
      winnerCarrierId: auction.winnerCarrierId || this.currentOrderAuction?.winnerCarrierId || null,
      winnerCarrierName:
        auction.winnerCarrierName || this.currentOrderAuction?.winnerCarrierName || null,
      bids: [...auction.bids],
    };
  }

  @Mutation
  setRequestOrder(request: OrderRequest) {
    this.currentOrderRequest = {
      ...request,
      bids: [...request.bids],
    };
  }

  @Mutation
  setCargoInsurance(cargoInsurance) {
    this.cargoInsurance = cargoInsurance;
  }

  @Mutation
  setCargoInsuranceEmails(emails) {
    this.cargoInsuranceEmails = [emails?.defaultTo, emails?.defaultCc].filter(
      (email) => email !== null,
    );
  }

  @Mutation
  setOrderStatusComplete() {
    this.order = { ...this.order, statusId: OrderStatus.Execution };
  }

  @Mutation
  setShipAgents(shipAgents) {
    this.shipAgents = shipAgents;
  }

  @Mutation
  setOrgEmails(emails) {
    this.orgEmails = emails;
  }

  @Mutation
  setOrderStatuses(statuses: StatusModel[]) {
    this.statuses = statuses;
  }

  @Mutation
  setCounters(response: OrderCounters) {
    this.counters = response;
  }

  @Mutation
  setRecommendOrdersCount(count: number) {
    this.recommendOrdersCount = count;
  }

  @Mutation
  setOrders({
    ordersResponse,
    isSavePrevPageItems,
  }) {
    const newOrders = ordersResponse.content?.map((order) => subject('Order', order));
    const orderShipAgentName = newOrders.map((order: OrderModel) => ({
      ...order,
      nameShipAgent: getNameShipAgentAttracs(order.shipAgentId),
    }));
    this.orders = isSavePrevPageItems ? [...this.orders, ...orderShipAgentName] : orderShipAgentName;
  }

  @Mutation
  setOrdersStatusLocal({
    orderId,
    status,
  }: OrderChangeStatus) {
    this.orders = this.orders.map((item) => {
      if (item.orderId === orderId) {
        return {
          ...item,
          statusId: status,
        };
      }
      return item;
    });
  }

  @Mutation
  setTotalPages(response) {
    this.pages = response.totalPages;
  }

  @Mutation
  setTotalElements(response) {
    this.totalElements = response.totalElements;
  }

  @Mutation
  setValuesForFilters(response) {
    this.filters = response;
  }

  @Mutation
  setOrder(order: OrderModel) {
    this.order = subject('Order', order);
  }

  @Mutation
  deleteOrder() {
    this.order = null;
  }

  @Mutation
  setHistory(history: OrderHistoryModel[]) {
    this.history = history;
  }

  @Mutation
  setCancelReasons(cancelReasons: OrderCancelReason[]) {
    this.cancelReasons = cancelReasons;
  }

  @Mutation
  setOptimisticOrderStatus(orderStatus: OrderStatus) {
    if (!this.order) {
      return;
    }

    this.order.statusId = orderStatus;
  }

  @Mutation
  syncLocalLoadingInstructions(instruction) {
    if (!this.order) {
      return;
    }

    this.order.loadingEvents = this.order.loadingEvents.map((event) => {
      if (instruction.id === event.id) {
        return {
          ...event,
          loadingInstructions: instruction.loadingInstructions,
        };
      }

      return event;
    });
  }

  @Mutation
  setStatusList(statusList: Status[]) {
    this.statusList = statusList;
  }

  @Mutation
  setOrderChannels(channelsList: Status[]) {
    this.channelsList = channelsList;
  }

  @Mutation
  setOrderSources(sourcesList) {
    this.sourcesList = sourcesList;
  }

  @Mutation
  setSearchQuery(searchQuery: Nullable<string>) {
    this.searchQuery = searchQuery;
  }

  @Mutation
  setOrderCard(orderCard: OrderCard) {
    this.order = subject('Order', orderCard.order);
    this.truck = orderCard.truck;
    this.trailer = orderCard.trailer;
    this.driver = orderCard.driver;
    this.counterparty = orderCard.counterparty;
    this.documents = orderCard.documents ?? [];
  }

  @Mutation
  setCurrentOrdersPage(ordersPage: OrdersPage) {
    this.currentOrdersPage = ordersPage;
  }

  @Mutation
  setOrderCardDocuments(documents: DocumentModel[]) {
    this.documents = isArray(documents) ? documents : [];
  }

  @Mutation
  setMatchedOrdersParams(ordersParams: MatchedOrdersParams[]) {
    this.matchedOrdersParams = ordersParams;
  }

  @Mutation
  setMatchedOrdersParamsParentOrderNumber(orderNumber: Nullable<string>) {
    this.matchedOrdersParamsParentOrderNumber = orderNumber;
  }

  @Mutation
  setOnboardingOrderMatch(onboardingOrderMatchId: Nullable<string>) {
    this.onboardingOrderMatchId = onboardingOrderMatchId;
  }

  @Mutation
  setIsOrdersUpdating(isUpdating: boolean) {
    this.isOrdersUpdating = isUpdating;
  }

  @Mutation
  setIsResetFilters(isResetFilters: boolean) {
    this.isResetFilters = isResetFilters;
  }

  @Mutation
  setOrdersFilters(ordersFilters: OrderLocalFilters) {
    this.ordersFilters = ordersFilters;
  }

  @Mutation
  updateOrdersFilterValueByKey({ filterKey, value }: UpdateOrdersFilterValueByKey) {
    this.ordersFilters[filterKey] = value;
  }
}

container.bind<SRMOrderModule>(SRM_ORDER_MODULE_ID).to(SRMOrderModule).inSingletonScope();
