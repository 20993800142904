import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_redesigned_app_layout = _resolveComponent("redesigned-app-layout")!
  const _component_app_layout = _resolveComponent("app-layout")!
  const _component_scroll_top_button = _resolveComponent("scroll-top-button")!
  const _component_feedback_button = _resolveComponent("feedback-button")!
  const _component_org_joining_request_modal = _resolveComponent("org-joining-request-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    ($options.isRedesignActive())
      ? (_openBlock(), _createBlock(_component_redesigned_app_layout, { key: 0 }))
      : (_openBlock(), _createBlock(_component_app_layout, { key: 1 })),
    (_ctx.$can('use', 'RedesignedSrm'))
      ? (_openBlock(), _createBlock(_component_scroll_top_button, { key: 2 }))
      : _createCommentVNode("", true),
    ($options.isShowFeedback)
      ? (_openBlock(), _createBlock(_component_feedback_button, {
          key: 3,
          "is-redesigned": $options.isRedesignActive()
        }, null, 8, ["is-redesigned"]))
      : _createCommentVNode("", true),
    ($data.isShowJoiningRequestModal)
      ? (_openBlock(), _createBlock(_component_org_joining_request_modal, {
          key: 4,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.isShowJoiningRequestModal = false))
        }))
      : _createCommentVNode("", true)
  ], 2))
}