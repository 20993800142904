import dayjs from 'dayjs';
import IMask, { AnyMaskedOptions } from 'imask';
import { inputPatterns } from '@/utils/validators';
import {
  Nullable,
  Undefinable,
} from '@/features/shared/types';
import { CountriesNames } from '@/features/shared/constant';

export const armeniaPhoneMask = {
  mask: '+{374} (00) 00-00-00',
};

export const georgiaPhoneMask = {
  mask: '+{995} ({5}00) 00-00-00',
};

export const belorussianPhoneMask = {
  mask: '+{375} (00) 000-00-00',
};

export const russianPhoneMask = {
  mask: '+{7} (000) 000-00-00',
};

export const latvianPhoneMask = {
  mask: '+{371} (00) 000-000',
};

export const phoneMask: IMask.AnyMaskedOptions = {
  mask: '+{7} (000) 000-00-00',
  padFractionalZeros: false,
  prepare: (appended, masked): string => {
    if (appended === '8' && masked.value === '') {
      return '';
    }

    return appended;
  },
};

export const priceMask: IMask.MaskedNumberOptions = {
  mask: Number,
  min: 0,
  max: 99999999999,
  thousandsSeparator: ' ',
  padFractionalZeros: true,
};

export const ownCount: IMask.MaskedNumberOptions = {
  mask: Number,
  min: 0,
  max: 10000,
  scale: 0,
};

export const maxCargoVolume: IMask.MaskedNumberOptions = {
  mask: Number,
  min: 1,
  max: 999,
};

export const integerMask: IMask.MaskedNumberOptions = {
  mask: Number,
  scale: 0,
  prepare: (appended: string, masked) => {
    if (masked.value === '0') {
      // eslint-disable-next-line no-param-reassign
      masked.value = '';
    }

    return appended;
  },
};

export const INNMask: IMask.AnyMaskedOptions = {
  mask: '000 000 000 000',
  normalizeZeros: true,
};

export const dateTimeMask: IMask.MaskedDateOptions = {
  mask: Date,
  pattern: 'DD{.}MM{.}YYYY,{ }HH{.}mm',
  autofix: true,
  overwrite: true,
  format(date: Date): string {
    return dayjs(date).format('DD.MM.YYYY, HH.mm');
  },
  parse(str: string): Date {
    return dayjs(str, 'DD.MM.YYYY, HH.mm').toDate();
  },
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2100,
    } as IMask.AnyMaskedOptions,
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
    } as IMask.AnyMaskedOptions,
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
    } as IMask.AnyMaskedOptions,
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
    } as IMask.AnyMaskedOptions,
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
    } as IMask.AnyMaskedOptions,
  },
};

export const bankAccountMask: IMask.AnyMaskedOptions = {
  mask: '0'.repeat(20),
};

export const digitsMask: IMask.AnyMaskedOptions = {
  mask: inputPatterns.digits,
};

export const limitMask: IMask.AnyMaskedOptions = {
  mask: Number,
  min: 1,
  max: 99999999999,
  scale: 2,
};

export const searchInputMask: IMask.AnyMaskedOptions = {
  mask: /^.{0,36}$/,
};

export const fioMask: IMask.AnyMaskedOptions = {
  mask: inputPatterns.fio,
};

// truck masks

export const truckLicenseMask: IMask.AnyMaskedOptions = {
  mask: 'a 000 aa 00[0]',
};

export const truckNumberKZMask: IMask.AnyMaskedOptions = {
  mask: '000 aa[a] 00',
};

export const truckAndTrailerNumberUAMask: IMask.AnyMaskedOptions = {
  mask: 'aa 0000 aa',
};

export const truckNumberBYMask: IMask.AnyMaskedOptions = {
  mask: '[a][a]0000[a][a]0',
};

// trailers masks

export const trailerLicenseMask: IMask.AnyMaskedOptions = {
  mask: 'aa 0000 00[0]',
};

export const trailerNumberBYMask: IMask.AnyMaskedOptions = {
  mask: 'a0000a{-}0',
};

export const seriesMask: IMask.AnyMaskedOptions = {
  mask: '00 00',
};

export const seriesMaskNumbersWithLetters: IMask.AnyMaskedOptions = {
  mask: 'vv vv',
  definitions: {
    v: /([^\W|_]|[\u0400-\u04FF])/,
  },
};

export const numberMask: IMask.AnyMaskedOptions = {
  mask: '000000',
};

export const emptyMask: IMask.AnyMaskedOptions = {
  mask: /.*/,
};

export const costMask: IMask.AnyMaskedOptions = {
  mask: Number,
  thousandsSeparator: ' ',
};

export const checkMaskForPhone = (phone: Undefinable<number>): Nullable<CountriesNames> => {
  const regRu = /^[7-8][8-9][0-9]{9}$/;
  const regGe = /^9955\d{8}$/;
  const regKz = /^7[6-7][0-9]{9}$/;
  const regAm = /^374[0-9]{8}$/;
  const regBy = /^375[0-9]{9}$/;
  const regLv = /^371[0-9]{8}$/;

  switch (true) {
    case regRu.test(String(phone)):
      return CountriesNames.Russia;
    case regGe.test(String(phone)):
      return CountriesNames.Georgia;
    case regAm.test(String(phone)):
      return CountriesNames.Armenia;
    case regBy.test(String(phone)):
      return CountriesNames.Belarus;
    case regKz.test(String(phone)):
      return CountriesNames.Kazakhstan;
    case regLv.test(String(phone)):
      return CountriesNames.Latvia;
    default:
      return CountriesNames.Russia;
  }
};

export const checkMaskForCountry = (code: Nullable<CountriesNames>): AnyMaskedOptions => {
  switch (code) {
    case CountriesNames.Russia:
      return { ...IMask.createMask(russianPhoneMask) };
    case CountriesNames.Georgia:
      return { ...IMask.createMask(georgiaPhoneMask) };
    case CountriesNames.Armenia:
      return { ...IMask.createMask(armeniaPhoneMask) };
    case CountriesNames.Belarus:
      return { ...IMask.createMask(belorussianPhoneMask) };
    case CountriesNames.Kazakhstan:
      return { ...IMask.createMask(russianPhoneMask) };
    case CountriesNames.Latvia:
      return { ...IMask.createMask(latvianPhoneMask) };
    default:
      return { ...IMask.createMask(russianPhoneMask) };
  }
};

export const bicMask: IMask.AnyMaskedOptions = {
  mask: '000000000',
};
