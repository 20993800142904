
import {
  defineComponent,
  computed,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';
import UiTooltipWithContent from '@/features/redesigned/shared/kit/UiTooltipWithContent.vue';
import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';

import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import AppHeaderCarrierUser from '@/features/redesigned/shared/layout/AppHeaderCarrierUser.vue';
import AppHeaderSearch from '@/features/redesigned/shared/layout/AppHeaderSearch.vue';
import LogoutConfirm from '@/features/shared/layout/LogoutConfirm.vue';

export default defineComponent({
  name: 'AppHeader',

  components: {
    SvgIcon,
    UiTooltipWithContent,
    UiButton,
    DefaultTransition,
    AppHeaderCarrierUser,
    AppHeaderSearch,
    LogoutConfirm,
  },

  setup() {
    const authService = computed<AuthService>(() => container.get<AuthService>(AUTH_SERVICE_ID));
    const isLogoutOpenModal = ref(false);

    const onLogout = () => authService.value.logout();

    const route = useRoute();

    const isShowSearch = computed<boolean>(() => route.name !== 'planning');

    return {
      authService,
      onLogout,
      isLogoutOpenModal,
      isShowSearch,
    };
  },
});
