export enum InformationParams {
  NOT_RELEVANT = 'Заявка оказалась неактуальной',
  NOT_ENOUGH_INFORMATION = 'Недостаточно информации',
  IMPOSSIBLE_REQUIREMENTS = 'Невыполнимые требования',
  LOW_RATE = 'Низкая ставка',
}

export enum FileName {
  FuncCharacteristics = 'Функц. характеристики.docx',
  PrivacyPolicy = 'Политика конфиденциальности.pdf',
  TechSupport = 'Тех. поддержка.docx',
}
