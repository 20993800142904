import { Client, StompConfig, StompSubscription } from '@stomp/stompjs';
import { LazyInject, Register } from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import { WsSendType, WsSubscribeType } from '@/features/shared/types';

export const WEBSOCKET_SERVICE_ID = Symbol('websocketService');

@Register(
  WEBSOCKET_SERVICE_ID,
)
class WebsocketService {
  @LazyInject(AUTH_SERVICE_ID) authService: AuthService;

  config: StompConfig;

  constructor(brokerURL = '') {
    this.config = {
      brokerURL: this.prepareBrokerURL(brokerURL),
      connectHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}` || '',
      },
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 0,
    };
  }

  prepareBrokerURL(path: string): string {
    const url = new URL(path, window.location.href);
    url.protocol = url.protocol.replace('http', 'ws');
    return url.href;
  }

  connect(): Client {
    const client = new Client(this.config);
    if (this.config.brokerURL) {
      client.activate();
    }
    return client;
  }

  send({ client, destination, body }: WsSendType): void {
    client.publish({
      destination,
      body: JSON.stringify(body),
    });
  }

  subscribe({
    client,
    destination,
    messageCallback,
    subscriptionId,
  }: WsSubscribeType): StompSubscription {
    const headers = {
      id: subscriptionId,
      Authorization: `Bearer ${this.authService.getToken()}` || '',
    };
    return client.subscribe(destination, messageCallback, headers);
  }

  disconnect(client: Client): void {
    client.deactivate();
  }
}

export default WebsocketService;
