import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_handler_status = _resolveComponent("ui-handler-status")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ui_handler_status, {
      class: _normalizeClass(_ctx.$style.success)
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Ваша почта успешно подтверждена ")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_ui_button, {
      class: _normalizeClass(_ctx.$style.button),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:finish')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Продолжить ")
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}