import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = ["data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_progress_circular = _resolveComponent("ui-progress-circular")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass($options.classes),
    style: _normalizeStyle($options.styles),
    "data-testid": "UiButton"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.$style.content, $props.contentClassName]),
      "data-qa": $props.contentDataQa
    }, [
      ($props.prependIcon)
        ? (_openBlock(), _createBlock(_component_svg_icon, {
            key: 0,
            class: _normalizeClass([_ctx.$style.prependIcon, $props.iconClassName]),
            name: $props.prependIcon
          }, null, 8, ["class", "name"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", { "data-qa": $props.textDataQa }, [
        ($props.icon)
          ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 0,
              class: _normalizeClass([
            _ctx.$style.centralIcon,
            $props.iconClassName,
          ]),
              name: $props.icon,
              "svg-use-data-qa": $props.svgUseDataQa
            }, null, 8, ["class", "name", "svg-use-data-qa"]))
          : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ], 8, _hoisted_2),
      ($props.appendIcon)
        ? (_openBlock(), _createBlock(_component_svg_icon, {
            key: 1,
            class: _normalizeClass([_ctx.$style.appendIcon, $props.iconClassName]),
            name: $props.appendIcon
          }, null, 8, ["class", "name"]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style.loader)
        }, [
          _createVNode(_component_ui_progress_circular, {
            size: "12",
            width: "1.5",
            indeterminate: "",
            button: ""
          })
        ], 2))
      : _createCommentVNode("", true)
  ], 6))
}