import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_otp_input = _resolveComponent("v-otp-input")!
  const _component_default_transition = _resolveComponent("default-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_v_otp_input, {
      id: _ctx.id,
      value: _ctx.value,
      "input-classes": _ctx.inputStyles,
      separator: "",
      "num-inputs": _ctx.digitCount,
      "should-auto-focus": _ctx.isAutoFocus,
      "is-disabled": _ctx.disabled,
      "conditional-class": [_ctx.$style.first, _ctx.$style.second, _ctx.$style.third, _ctx.$style.fourth],
      "input-type": "number",
      placeholder: _ctx.placeholder,
      onOnChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event))),
      onOnComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('complete', $event)))
    }, null, 8, ["id", "value", "input-classes", "num-inputs", "should-auto-focus", "is-disabled", "conditional-class", "placeholder"]),
    _createVNode(_component_default_transition, null, {
      default: _withCtx(() => [
        (_ctx.isError && _ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.errorMessage)
            }, _toDisplayString(_ctx.errorMessage), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}