
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import container from '@/di';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import { getHTTPError } from '@/utils/errors';

export default {
  name: 'AppSidebarLogistics',

  components: {
    AppSidebarButton,
  },

  props: {
    isWide: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    authService(): AuthService {
      return container.get<AuthService>(AUTH_SERVICE_ID);
    },

    feedBack(): string {
      return 'https://docs.google.com/forms/d/e/1FAIpQLSdFPM3KGA4WrLhd5Ddpr001SxPe4V8OlyL4mYpAMcUaw48UoA/viewform?usp=sf_link';
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        await this.authService.fetchProfile();
      } catch (e) {
        console.error(getHTTPError(e));
      }
    },
  },
};
