import {
  LazyInject,
  Register,
} from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import RemoteService from '@/features/shared/services/base/remote';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';
import CarrierPreorderSharedService, {
  CARRIER_PREORDER_SHARED_SERVICE_ID,
} from '@/features/shared/truck-offering/services/CarrierPreorderSharedService';
import {
  CARRIER_PREORDERS,
  PREORDER_BY_ID,
  PREORDER_BY_ID_DELETE,
  PREORDER_BY_ID_STATUS_CLOSED,
  PREORDER_BY_ID_UPDATE,
  PREORDER_MATCHED_REQUEST_STATUSES,
  PREORDER_MATCHED_STATUS_AND_NOTE,
} from '@/features/srm/truck-offering/services/endpoints';
import { CreatePreorderParams } from '@/features/srm/truck-offering/types';
import {
  MatchedRequestStatusType,
  PreorderSummaryDTO,
  SetMatchedRequestStatusAndNote,
} from '@/features/shared/truck-offering/types';

export const CARRIER_PREORDER_SERVICE_ID = Symbol('carrier-preorder-service');

@Register(CARRIER_PREORDER_SERVICE_ID, [CONFIG_SERVICE_ID])
export default class CarrierPreorderService extends RemoteService {
  @LazyInject(CARRIER_PREORDER_SHARED_SERVICE_ID) carrierPreorderSharedService: CarrierPreorderSharedService;

  constructor(configService: ConfigService) {
    super(configService.CarrierPreorderServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  createPreorder(data: CreatePreorderParams): Promise<void> {
    return this.request({
      method: 'POST',
      url: CARRIER_PREORDERS,
      data,
    });
  }

  editPreorder(data: CreatePreorderParams, preorderId: string): Promise<PreorderSummaryDTO> {
    return this.request({
      method: 'PUT',
      url: PREORDER_BY_ID_UPDATE(preorderId),
      data,
      params: {
        id: preorderId,
      },
    });
  }

  closePreorder(preorderId: number): Promise<void> {
    return this.request({
      method: 'PUT',
      url: PREORDER_BY_ID_STATUS_CLOSED(preorderId),
    });
  }

  deletePreorder(preorderId: number): Promise<void> {
    return this.request({
      method: 'DELETE',
      url: PREORDER_BY_ID_DELETE(preorderId),
    });
  }

  getStatusTypes(): Promise<MatchedRequestStatusType[]> {
    return this.request({
      method: 'GET',
      url: PREORDER_MATCHED_REQUEST_STATUSES,
    });
  }

  setPreorderStatusAndNote(data: SetMatchedRequestStatusAndNote): Promise<void> {
    return this.request({
      method: 'PUT',
      url: PREORDER_MATCHED_STATUS_AND_NOTE,
      data,
    });
  }

  getPreorderById(preorderId: number): Promise<PreorderSummaryDTO> {
    return this.request({
      method: 'GET',
      url: PREORDER_BY_ID(preorderId),
    });
  }
}
