
import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import UiVideo from '@/features/shared/kit/UiVideo.vue';
import videoFrame from '@/assets/img/videoFrame.jpg';

export default {
  name: 'VideoInstuctionsModal',
  components: {
    UiModalWithContent,
    UiVideo,
  },

  computed: {
    imgFrame(): string {
      return videoFrame;
    },
  },
};
