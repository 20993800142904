import axios from 'axios';
import container, { Register } from '@/di';
import { CONFIG_SERVICE_ID } from '@/features/shared/services/config';

export const REMOTE_SERVICE_ID = Symbol('remoteService');

@Register(
  REMOTE_SERVICE_ID,
)
class RemoteService {
  remoteClient;

  constructor(baseURL, options = {}) {
    const configService = container.get(CONFIG_SERVICE_ID);

    this.remoteClient = axios.create({
      baseURL,
      timeout: configService.requestTimeout,
      ...options,
    });
  }

  async request(options = {}) {
    try {
      const response = await this.remoteClient(options);
      if (options.partialContentMessage && response.status === 206) {
        return response.data.message;
      }
      return response.data;
    } catch (e) {
      if (e.code !== 'ERR_CANCELED') throw e;
      return undefined;
    }
  }
}

export default RemoteService;
