import { DictionaryModel, ContactType } from '@/features/shared/types';

export enum CarrierType {
  'Unknown',
  'Owner',
  'Forwarder',
  'Both',
}

export enum ShippingType {
  'Unknown',
  'InCity',
  'BetweenCities',
  'International',
}

export enum DevelopmentStatus {
  'Unknown',
  'New',
  'Active',
  'InProgress',
  'Blocked',
}

export enum CounterpartyType {
  'Unknown',
  'Carrier',
  'Holding',
  'Customer',
}

export enum SecurityCategoryTypes {
  P0 = 'П-0',
  T1 = 'Т-1',
  T2 = 'Т-2',
  T3 = 'Т-3',
  T4 = 'Т-4',
}

export enum OrgStatuses {
  NotFilled = -1,
  Unknown = 0,
  New = 1,
  OnApprove = 2,
  Approved = 3,
  Rejected = 4,
  NotChecked = 11,
}

export enum LoyaltyStatuses {
  Silver = 'SILVER',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
}

export enum CarrierStatus {
  Recall = 'RECALL',
  RecallByPlan = 'RECALL_BY_PLAN',
  Suspended = 'SUSPENDED',
  NoDocuments = 'NO_DOCUMENTS',
  NoLk = 'NO_LK',
  NoActivityLk = 'NO_ACTIVITY_LK',
  NoActivity = 'NO_ACTIVITY',
  OnControl = 'ON_CONTROL',
  Active = 'ACTIVE',
  New = 'NEW',
  NotCarrier = 'NOT_CARRIER',
}

export function getLoyaltyStatusName(status: LoyaltyStatuses | string): string {
  switch (status) {
    case LoyaltyStatuses.Silver: {
      return 'Серебро';
    }
    case LoyaltyStatuses.Gold: {
      return 'Золото';
    }
    case LoyaltyStatuses.Platinum: {
      return 'Платина';
    }
    default:
      return 'Серебро';
  }
}

export function getCarrierStatuses(carrierStatus: string, carrierStatusesList: DictionaryModel[]): DictionaryModel[] {
  switch (carrierStatus) {
    case CarrierStatus.New:
    case CarrierStatus.NoActivity:
    case CarrierStatus.Active:
      return carrierStatusesList.filter(
        ({ name }) => ![CarrierStatus.Suspended].includes(name),
      );
    default:
      return carrierStatusesList;
  }
}

export const orgLogistInitial = {
  counterpartyId: '',
  firstName: '',
  lastName: '',
  middleName: '',
  phone: '',
  email: '',
  type: ContactType.Logist,
};
