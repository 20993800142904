export default {
  primary: '--color-navy-500',
  danger: '--color-red-400',
  success: '--color-green-400',
  warning: '--color-yellow-300',
  info: '--color-light-blue-20',
  processing: '--color-neon-purple',
  cancelled: '--color-navy-14',
};

export enum ApprovingStatus {
  'NotVerified' = -2,
  'NotFilled' = -1,
  'UnknownStatus',
  'New',
  'OnReview',
  'Approved',
  'Rejected',
  'Correcting',
  'NeedAction',
  'NotCheckedPortal' = 11,
  'Suspended' = 20,
}

export enum ChatTypes {
  List = 'list',
  Chat = 'chat',
}

export enum DocumentType {
  Pts = 0,
  Sts = 1,
  Leasing = 2,

  DrivingLicense = 16,
  Passport = 21,
  ContractWithDriver = 36,
  PensionFundCertificate = 38,

  WarrantyDocument = 8800,

  SimplifiedTaxNotice = 37,

  LetterOfAuthority = 129,
  ExpeditorReceipt = 130,
  OrderWithCarrier = 131,
  OrderWithCustomer = 132,

  OrderTempContract = 133,

  RegistrationCertificateForInn = 1000,
  RegistrationCertificateForOgrn = 1001,

  ChiefElectionDecision = 1002,
  ChiefAppointmentOrder = 1003,
  ChiefAndFoundersPassports = 1004,

  ConstituentProtocol = 1005,
  LegalEntityCharter = 1006,
  AccountingBalance = 1007,
  IncomeLossesReport = 1008,
  NdsReport = 1009,

  LiabilityInsurancePolicy = 1010,

  OtherDocuments = 1011,

  BankDetails = 1012,
}

export enum ShipAgentId {
  Lorry = '631ba0e2-a94b-11e9-8a90-961370faad62',
  Magna = '4764ba61-cd67-11e9-b866-961370faad62',
  Gtl = '31cbdbc0-cd67-11e9-b866-961370faad62',
  Longrun = '89e01380-cd67-11e9-b866-961370faad62',
  Gruzoprovod = 'f6910820-bdf3-11e7-a5b5-961370faad62',
}

export enum DocumentStatuses {
  NotSelected = 0,
  NotDowloading = 1,
  Downloading = 2,
  Downloaded = 3,
  ErrorDownloading = 4,
  ReadyForDownload = 5,
  StatusNotFounded = 6,
  ReadyForUpload = 7,
}

export enum FileTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  MS_WORD = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL = 'application/vnd.ms-excel',
  SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum ChannelTypes {
  SPOT = 'SPOT',
  PARTNERS = 'PARTNERS',
  UNPROCESSED = 'UNPROCESSED',
  EXPRESS = 'EXPRESS',
  CONTRACT = 'CONTRACT',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  INTERGROUP = 'INTERGROUP',
  GROUP_INTERNAL = 'GROUP_INTERNAL',
  SINGLE_CONTRACT = 'SINGLE_CONTRACT',
}

export enum ChannelName {
  SPOT = 'Спот',
  PARTNERS = 'Партнеры',
  UNPROCESSED = 'Пройдены все каналы',
  EXPRESS = 'Экспресс',
  CONTRACT = 'Гарантии',
  INTERNAL = 'СТС',
  INTERGROUP = 'Межгруппа',
}

export function getChannelName(title: string): string {
  switch (title) {
    case ChannelTypes.SPOT:
      return ChannelName.SPOT;
    case ChannelTypes.PARTNERS:
      return ChannelName.PARTNERS;
    case ChannelTypes.UNPROCESSED:
      return ChannelName.UNPROCESSED;
    case ChannelTypes.EXPRESS:
      return ChannelName.EXPRESS;
    case ChannelTypes.SINGLE_CONTRACT:
    case ChannelTypes.CONTRACT:
      return ChannelName.CONTRACT;
    case ChannelTypes.INTERNAL:
      return 'СТС';
    case ChannelTypes.INTERGROUP:
      return ChannelName.INTERGROUP;
    default:
      return 'Не определено';
  }
}

export enum TypeVehicle {
  TRUCK = 'truck',
  TRAILER = 'trailer',
  TRAILER_REF = 'trailer_ref',
  LOW_TONNAGE = 'low_tonnage',
  LOW_TONNAGE_TRUCK = 'low_tonnage_truck',
  REF = 'ref',
}

export enum TypeVehicleIds {
  TRUCK = 0,
  TRAILER = 1,
  LOW_TONNAGE = 2,
}

export enum CountriesNames {
  Russia = 'RU',
  Kazakhstan = 'KZ',
  Belarus = 'BY',
  Ukraine = 'UA',
  Armenia = 'AM',
  Georgia = 'GE',
  Latvia = 'LV',
}

/**
 * UNKNOWN("Не задан", BigDecimal.valueOf(1), "БезНДС"),
 * ZERO("0%", BigDecimal.valueOf(1), "НДС0"),
 * TEN("10%", BigDecimal.valueOf(1.1), "НДС10"),
 * TWENTY("20%", BigDecimal.valueOf(1.2), "НДС20"),
 * WITHOUT("Без НДС", BigDecimal.valueOf(1), "БезНДС"),
 * EIGHTEEN("18%", BigDecimal.valueOf(1.18), "НДС18");
 */

export enum Nds {
  UNKNOWN = 'UNKNOWN',
  WITHOUT = 'WITHOUT',
  ZERO = 'ZERO',
  TEN = 'TEN',
  EIGHTEEN = 'EIGHTEEN',
  TWENTY = 'TWENTY',
}

export function getNdsName(nds: string): string {
  switch (nds) {
    case Nds.UNKNOWN:
      return 'Не задан';
    case Nds.WITHOUT:
      return 'Без НДС';
    case Nds.ZERO:
      return '0%';
    case Nds.TEN:
      return '10%';
    case Nds.EIGHTEEN:
      return '18%';
    case Nds.TWENTY:
      return '20%';
    default:
      return 'Не задан';
  }
}

export enum NdsById {
  UNKNOWN = -1,
  ZERO = 0,
  TEN = 10,
  TWENTY = 20,
  WITHOUT = -1,
  EIGHTEEN = 18,
}

export function getNdsByIdName(ndsId: string | number): string {
  switch (ndsId) {
    case NdsById.UNKNOWN:
      return 'Не задан';
    case NdsById.WITHOUT:
      return 'Без НДС';
    case NdsById.ZERO:
      return '0%';
    case NdsById.TEN:
      return '10%';
    case NdsById.EIGHTEEN:
      return '18%';
    case NdsById.TWENTY:
      return '20%';
    default:
      return 'Не задан';
  }
}

/**
 * CASHLESS("Безналичный"),
 * CASH("Наличные"),
 * COMBINED("Комбинированный");
 */
export enum TypesPay {
  CASHLESS = 'CASHLESS',
  CASH = 'CASH',
  COMBINED = 'COMBINED',
}

export function getNamePay(type: string): string {
  switch (type) {
    case TypesPay.CASHLESS:
      return 'Безналичный';
    case TypesPay.CASH:
      return 'Наличные';
    case TypesPay.COMBINED:
      return 'Комбинированный';
    default:
      return 'Не известен';
  }
}

export enum VehicleTypes {
  Truck,
  Trailer,
  LowTonnage,
}

export enum TextSize {
  Tiny = 'textTiny',
  Xsmall = 'textXsmall',
  Small = 'textSmall',
  Base = 'textBase',
  SmallHeader = 'textSmallHeader',
  MediumHeader = 'textMediumHeader',
}

export enum FileKind {
  OTHER_DOCUMENTS = 4,

  PASSPORT_WITH_PHOTO,
  PASSPORT_WITH_REG,

  DRIVING_LICENSE_FACE,
  DRIVING_LICENSE_BACK,

  STS_FACE,
  STS_BACK,

  PTS_FACE,
  PTS_BACK,

  LEASING_DOC,

  BANK_DETAILS_CARD,

  REGISTRATION_CERTIFICATE_FOR_INN,
  REGISTRATION_CERTIFICATE_FOR_OGRN,

  LEGAL_ENTITY_CHARTER,

  CHIEF_ELECTION_DECISION,
  CHIEF_APPOINTMENT_ORDER,

  INCOME_LOSSES_REPORT,

  CHIEF_AND_FOUNDERS_PASSPORT = 22,

  CONSTITUENT_PROTOCOL,
  ACCOUNTING_BALANCE,
  NDS_REPORT,
  LIABILITY_INSURANCE_POLICY = 26,
  LETTER_OF_AUTHORITY = 29,
  EXPEDITOR_RECEIPT,

  ORDER_WITH_CARRIER,
  ORDER_WITH_CUSTOMER,
  ORDER_TEMP_CONTRACT = 33,

  CONTRACT_WITH_DRIVER = 36,
  SIMPLIFIED_TAX_NOTICE,
}

export enum FileRequestType {
  STS = 'STS',
  PASSPORT = 'PASSPORT',
  INN = 'REGISTRATION_CERTIFICATE_FOR_INN',
}

export enum EntityType {
  NoType,
  Vehicle,
  Driver,
  CarrierCounterparty,
  Order,
  OrderContract,
  Warranty,
}

export enum CounterpartyLegalForm {
  'IP',
  'OOO',
  'AO',
  'ZAO',
  'OAO',
  'UP',
  'PAO',
}

export enum LegalFormNames {
  IP = 'ИП',
  OOO = 'ООО',
  AO = 'АО',
  ZAO = 'ЗАО',
  OAO = 'ОАО',
  UP = 'УП',
  PAO = 'ПАО',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum RequestFormMode {
  Create = 'create',
  Edit = 'edit',
  Template = 'template',
  RequestDocumentEdit = 'request-document-edit',
}

export enum OrderStatus {
  // Начальные статусы
  UnknownStatus = 0,
  New = 1,

  // Статусы при работе с бронированием
  Reserved = 1100,
  ReservationCanceled = 1101,
  ReservedWithCarrier = 1102,

  // Статусы при выполнении
  OnApprove = 2,
  Execution = 4,
  CancelExecuted = 13,
  Block = 2000,
  ReadyForExecution = 2001,
  ApproveCanceled = 2002,
  Waiting = 2004,

  // Неизвестные статусы (скорее всего не используется)
  AcceptedInGroup = 5,

  // Статусы отмены
  DeniedCarrier = 11,
  DeniedManager = 1002,
  DeniedCustomer = 12,

  // Статусы при работе с документами
  WaitingDocs = 21,
  WaitingPayment = 22,
  SentPayment = 23,
  DocsProblems = 24,

  // Статусы при работе пользователя перевозчика
  WaitingForApprove = 1001,
  CarrierChangeMind = 1003,

  // Статусы при работе с аукционной заявкой
  Auction = 200001,
  AuctionOpen = 210001,
  BiddingCompleting = 200003,

  // Статусы при работе с заявкой на запрос предложений
  OfferCollection = 200002,
  OfferOpen = 210002,
  OfferCompleting = 200004,
}

export enum ExecutorCompanyId {
  Lorry = '17f13ac4-5064-11e0-88ed-0026554cd008',
  Magna = '78717236-c28a-457a-a74a-c5fade730de5',
  Gtl = 'ed610b1b-1090-11e3-b6d1-000c2971d81c',
  Longrun = 'c11357df-a0b2-11e1-8d73-001517ac3744',
  Gruzoprovod = '6025193b-f767-11e7-a522-961370faad62',
}

export enum SMSSendStatuses {
  SEND = 'send',
  NOT_SEND = 'notSend',
}

export enum LinkType {
  EMAIL = 'email',
  EXTERNAL = 'external',
  TEL = 'tel',
}

export enum ElementHeight {
  Xs = 24,
  Small = 32,
  Base = 40,
  Large = 48,
  Xl = 50,
}

export enum MaxDisplayWidth {
  Phone = 767,
  SmallDesktop = 1279,
  LargeDesktop = 1919,
}

export enum DocumentTypes {
  PTS = 0,
  STS = 1,
  Rental = 2,
  DriverLicense = 16,
  Passport = 21,
  LaborContact = 36,
  Reference = 38,
  Inn = 1000,
  OGRN,
  AssumptionAgreement,
  DirectorAppointmentProtocol,
  DirectorPassport,
  FoundingProtocol,
  Charter,
  BalanceSheet,
  PLR,
  NDSDeclaration,
  Insurance,
  Other,
  Requisites,
  ConsignmentNote,
  Waybill,
  CarrierOrder = 131,
  ExpeditorReceipt = 130,
  CustomerOrder = 132,
}

export function getTrailerTypeIcon(type: TrailerType): string {
  switch (type) {
    case TrailerType.Ref:
      return 'icon-ref';
    case TrailerType.Tent:
      return 'icon-truck';
    default:
      return '';
  }
}

export enum TrailerType {
  Ref = 'ref',
  Close = 'close',
  Open = 'open',
  Tent = 'tent',
}

export enum TrailerTypeName {
  Ref = 'Реф',
  Tent = 'Тент',
}

export enum ScrollBehavior {
  Auto = 'auto',
  Smooth = 'smooth',
}

export enum StatusUiType {
  Chips = 'chips',
  Badge = 'badge',
  Icon = 'icon',
}

export enum DayjsTimezone {
  'UTC-12' = 'Pacific/Pohnpei',
  'UTC-11' = 'Pacific/Nauru',
  'UTC-10' = 'Pacific/Honolulu',
  'UTC-9' = 'America/Juneau',
  'UTC-8' = 'America/Vancouver',
  'UTC-7' = 'America/Cambridge_Bay',
  'UTC-6' = 'America/Regina',
  'UTC-5' = 'Pacific/Easter',
  'UTC-4' = 'America/Goose_Bay',
  'UTC-3' = 'America/Santiago',
  'UTC-2' = 'Atlantic/South_Georgia',
  'UTC-1' = 'Atlantic/Azores',
  'UTC+0' = 'Europe/London',
  'UTC+1' = 'Europe/Belgrade',
  'UTC+2' = 'Europe/Kaliningrad',
  'UTC+3' = 'Europe/Moscow',
  'UTC+4' = 'Europe/Astrakhan',
  'UTC+5' = 'Asia/Yekaterinburg',
  'UTC+6' = 'Asia/Omsk',
  'UTC+7' = 'Asia/Novosibirsk',
  'UTC+8' = 'Asia/Irkutsk',
  'UTC+9' = 'Asia/Yakutsk',
  'UTC+10' = 'Asia/Vladivostok',
  'UTC+11' = 'Asia/Magadan',
  'UTC+12' = 'Asia/Kamchatka',
  'UTC+13' = 'Pacific/Pago_Pago',
  'UTC+14' = 'Pacific/Rarotonga',
}

export enum OrdersTableTab {
  Home,
  Order,
}

export enum OrdersTab {
  OrdersNewForCarrier = 'OrdersNewForCarrier',
  OrdersInWorkForCarrier = 'OrdersInWorkForCarrier',
  ReservedToManager = 'ReservedToManager',
  OrdersPlanning = 'OrdersPlanning',
  OrdersRecommendForCarrier = 'OrdersRecommendForCarrier',
}

export enum RequestBusinessChannels {
  BUYOUT = 'BUYOUT',
  PARSING = 'PARSING',
  FORMALIZATION = 'FORMALIZATION',
  GUARANTEE = 'GUARANTEE',
  CLIENT_PERSONAL_ACCOUNT = 'CLIENT_PERSONAL_ACCOUNT',
  ROAD_TRAIN = 'ROAD_TRAIN',
}

export enum RequestTypeForCarrierLabel {
  AUCTION = 'Аукцион',
  OFFER_COLLECTION = 'Запрос предложений',
}

export enum RequestContractLabel {
  Legal = 'Контракт юридический',
  Conceptual = 'Контракт понятийный',
  Spot = 'Спот',
}

export enum RequestTypeForCarrier {
  AUCTION = 'AUCTION',
  OFFER_COLLECTION = 'OFFER_COLLECTION',
}
