
import {
  PropType,
} from 'vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import { Nullable } from '@/features/shared/types';

export default {
  name: 'UiModal',

  components: { DefaultTransition },
  props: {
    target: {
      type: String as PropType<string>,
      required: true,
    },

    overlayColor: {
      type: String as PropType<string>,
      default: 'light',
      validator: (value) => ['light', 'dark', 'dark-blue'].includes(value),
    },

    closeOnClickOverlay: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    zIndex: {
      type: Number as PropType<Nullable<number>>,
      default: null,
    },
  },

  emits: ['close'],

  mounted() {
    global.document.body.classList.add(this.$style.openModal);
  },

  unmounted() {
    global.document.body.classList.remove(this.$style.openModal);
  },

  methods: {
    onClose() {
      if (this.closeOnClickOverlay) {
        this.$emit('close');
      }
    },
  },
};
