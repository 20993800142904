
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { useToast } from 'vue-toastification';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import AppHeader from '@/features/redesigned/shared/layout/AppHeader.vue';
import AppSidebar from '@/features/redesigned/shared/layout/AppSidebar.vue';
import EmptyForTalkMe from '@/features/shared/talkMeExternal/EmptyForTalkMe.vue';
import EmailConfirm from '@/features/redesigned/shared/layout/EmailConfirm/EmailConfirm.vue';
import SRMWebsocketModule, { SRM_WEBSOCKET_MODULE_ID } from '@/features/srm/store/SRMWebsocketStore';
import container from '@/di';
import { isPhone } from '@/utils/device';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import {
  CarriersCommunicationService,
  SRM_CARRIERS_COMMUNICATION_SERVICE_ID,
} from '@/features/srm/carriers-communication/services/CarriersCommunicationService';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import { FileName } from '@/features/srm/carriers-communication/constants';
import {
  useAbility,
} from '@/utils/vue3';
import { getHTTPError } from '@/utils/errors';
import { downloadFile } from '@/utils/files';
import {
  Nullable,
  Undefinable,
} from '@/features/shared/types';
import toastRedesigned, { toastContent } from '@/utils/toastRedesigned';

export default defineComponent({
  name: 'AppLayout',

  components: {
    AppHeader,
    DefaultTransition,
    AppSidebar,
    EmptyForTalkMe,
    EmailConfirm,
  },

  setup() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const ability = useAbility();

    const isPopupVisible = ref(false);
    const onClickOutsidePopup = () => {
      if (isPopupVisible.value) {
        isPopupVisible.value = false;
      }
    };

    const carriersCommunicationService = computed<CarriersCommunicationService>(
      () => container.get(SRM_CARRIERS_COMMUNICATION_SERVICE_ID),
    );

    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const user = computed<Undefinable<UserModel>>(() => userModule.value?.user);

    const onDownloadFile = async (fileType: FileName) => {
      let file: Nullable<Blob> = null;

      try {
        switch (fileType) {
          case FileName.FuncCharacteristics:
            file = await carriersCommunicationService.value.downloadFuncCharacteristics();
            break;
          case FileName.PrivacyPolicy:
            file = await carriersCommunicationService.value.downloadPrivacyPolicy();
            break;
          case FileName.TechSupport:
            file = await carriersCommunicationService.value.downloadTechSupport();
            break;
          default:
            break;
        }

        if (file) {
          downloadFile(file, fileType);
        }
      } catch (e) {
        toast.error(getHTTPError(e));
      }

      isPopupVisible.value = false;
    };
    const configService = computed<ConfigService>(() => container.get(CONFIG_SERVICE_ID));
    const instructionLink = computed<string>(() => configService.value.CarrierInstructionLink);

    const isShowVerifyEmail = computed<boolean>(
      () => route.query.action === 'email-confirm' && ability.can('use', 'VerifyEmail'),
    );

    const isExpeditionActive = computed<boolean>(() => {
      if (!route.name) return false;

      return route.matched.some((route) => route.name === 'srm');
    });

    const websocketModule = computed<SRMWebsocketModule>(() => container.get(SRM_WEBSOCKET_MODULE_ID));
    watch(
      () => websocketModule.value.isClientConnected,
      (isConnected: boolean) => {
        if (!isConnected && isExpeditionActive.value) {
          websocketModule.value.connect();
        }
      },
    );

    onMounted(() => {
      if (isExpeditionActive.value) {
        websocketModule.value.connect();
      }

      updatedPasswordNotify();

      if (user.value?.emailVerified === false) {
        router.push({
          query: {
            action: 'email-confirm',
          },
        });
      }
    });

    const updatedPasswordNotify = () => {
      const params = new URLSearchParams(sessionStorage.getItem('params') ?? '');

      if (!params) return;

      const updatedPassword = params.get('updatedPassword');

      if (!updatedPassword) return;

      sessionStorage.removeItem('params');

      if (ability.can('use', 'RedesignedToast')) {
        toastRedesigned.success(toastContent('Пароль успешно обновлен', ''));
      } else {
        toast.success('Пароль успешно обновлен');
      }
    };

    const canUseTalkMe = computed<boolean>(() => ability.can('use', 'TalkMe'));

    const onCloseModal = () => {
      router.push({
        query: {},
      });
    };

    const currentYear = new Date().getFullYear().toString();

    return {
      onDownloadFile,
      FileName,
      isPhone,
      isPopupVisible,
      onClickOutsidePopup,
      instructionLink,
      canUseTalkMe,
      isShowVerifyEmail,
      onCloseModal,
      currentYear,
    };
  },
});
