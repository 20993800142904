/**
 * https://dev.api.portal.gt.local/order-service/swagger-ui/index.html?configUrl=/order-service/v3/api-docs/swagger-config#/
 */

import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import SRMNewOrderService, {
  SRM_NEW_ORDER_SERVICE_ID,
} from '@/features/srm/services/SRMNewOrderService';
import container, {
  Inject,
  Injectable,
} from '@/di';
import {
  PricesModel,
  OrderStatusModel,
  OrderModel,
  OrderHistoryModel,
  OrderInstructionsOperation,
  OrderActualDates,
  OrderInsuranceDefaultEmails,
  OrderSendEmailDTO,
  VehicleRecommendation,
  VehicleRecommendationListFilterDTO,
  SendCargoInsuranceEmailDTO,
  OrderCancelReason,
  OrderConformationSMSDTO,
  OrderResponse,
  LoadingEvent,
  OrderAuction,
  OrderAuctionPlaceBet,
  OrderAuctionPlaceBetResponse,
  OrderAuctionListItem,
  OrderRequestListItem,
  OrderRequest,
  OrderRequestPlaceBet,
  OrderRequestPlaceBetResponse,
  Status,
  RegionsParams,
  CitiesParams,
  AutotrainParams,
  VehicleInfo,
  CheckOrderBySecurityCategory,
  DecisionCheckOrderBySecurityCategory,
  OrderToAti,
  OrderToAtiResponse,
  OrderDislikeCreate,
  OfferOrderPriceParams,
  EditOrderResources,
} from '@/features/srm/orders/services/OrderService.types';
import {
  City,
  FilesModel,
  FilterListType,
  OperationResponse,
  OrderCounters,
  OrdersFilterDTO,
  Region,
  SendEmailDTO,
} from '@/features/srm/types';
import { ContractModel } from '@/features/srm/orgs/services/OrgService.types';
import {
  DictionaryModel,
  Nullable,
  Page,
  PaginationDTO,
} from '@/features/shared/types';
import SRMOrderAuctionRemoteService, {
  SRM_ORDER_ACTION_REMOTE_SERVICE,
} from '@/features/srm/services/SRMOrderAuctionRemoteService';
import {
  API_ATISU_SEND_ORDERS,
  API_AUCTION,
  API_AUCTION_DECLINE,
  API_AUTOTRAIN,
  API_CHECK_DOCUMENT,
  API_CITIES,
  API_CITY_BY_ID,
  API_GET_AUCTION_DATA,
  API_GET_CANCEL_REASON_LIST,
  API_GET_CARGO_INSURANCE,
  API_GET_CARGO_TYPE_LIST,
  API_GET_CONTRACT,
  API_GET_DEFAULT_CARGO_INSURANCE_EMAILS,
  API_GET_FILTERED_ORDER_LIST,
  API_GET_OFFER_COLLECTIONS,
  API_GET_OFFER_COLLECTIONS_BY_IDS,
  API_GET_ORDER_BY_ID,
  API_GET_ORDER_COUNTS,
  API_GET_ORDER_HISTORY_LIST,
  API_GET_ORDER_LIST,
  API_GET_RESPONSIBLE_LOGIST,
  API_GET_STATUS_LIST,
  API_GET_CHANNELS_LIST,
  API_GET_SOURCES_LIST,
  API_GET_VEHICLE_LOCATIONS,
  API_ORDER,
  API_ORDER_CARRIER_COUNTS,
  API_ORDER_CHECK_BY_SECURITY_CATEGORY,
  API_ORDER_COMPLETE,
  API_ORDER_REACTION,
  API_ORDER_REACTION_BY_ID,
  API_REGION_BY_ID,
  API_REGIONS,
  API_SEND_CONFIRM_SMS,
  API_SEND_EMAIL_WITH_DOCUMENT,
  API_SEND_EMAIL_WITH_ORDER,
  API_SEND_INSURANCE,
  API_SET_ACTUAL_DATES,
  API_SET_AUCTION_BET,
  API_SET_AUCTION_OFFER,
  API_SET_INSTRUCTIONS,
  API_SET_ORDER_DISLIKE_CREATE,
  API_SET_ORDER_DISLIKE_DELETE,
  API_SET_ORDER_PRICE,
  API_SET_ORDER_STATUS,
  API_SET_PRICE,
  GET_OFFER_COLLECTION_HISTORY,
  GET_ORDER_HISTORY_IN_AUCTION,
  API_ORDER_RESOURCES,
} from '@/features/srm/orders/services/endpoints';
import {
  ResponsibleLogist,
} from '@/features/srm/orders/types';
import { COUNTRY_RUSSIA_ID } from '@/features/srm/orders/constants';
import SRMNewOrderServiceV2, { SRM_NEW_ORDER_SERVICE_V2_ID } from '@/features/srm/services/SRMNewOrderServiceV2';
import SRMIntegrationsAtisuConnectorService, {
  SRM_INTEGRATIONS_ATISU_CONNECTOR_SERVICE_ID,
} from '@/features/srm/services/SRMIntegrationsAtisuConnectorService';

export const SRM_ORDER_SERVICE_ID = Symbol('SRMOrderService');

@Injectable()
export default class SRMOrderService {
  srmService: SRMService;

  srmNewOrderService: SRMNewOrderService;

  srmOrderAuctionService: SRMOrderAuctionRemoteService;

  srmNewOrderServiceV2: SRMNewOrderServiceV2;

  srmAtisuService: SRMIntegrationsAtisuConnectorService;

  getOrdersRequestController: Nullable<AbortController>;

  constructor(
    @Inject(SRM_SERVICE_ID) srmService: SRMService,
    @Inject(SRM_NEW_ORDER_SERVICE_ID) srmNewOrderService: SRMNewOrderService,
    @Inject(SRM_NEW_ORDER_SERVICE_V2_ID) srmNewOrderServiceV2: SRMNewOrderServiceV2,
    @Inject(SRM_ORDER_ACTION_REMOTE_SERVICE) srmOrderAuctionService: SRMOrderAuctionRemoteService,
    @Inject(SRM_INTEGRATIONS_ATISU_CONNECTOR_SERVICE_ID) srmAtisuService: SRMIntegrationsAtisuConnectorService,
  ) {
    this.srmService = srmService;
    this.srmNewOrderService = srmNewOrderService;
    this.srmNewOrderServiceV2 = srmNewOrderServiceV2;
    this.srmOrderAuctionService = srmOrderAuctionService;
    this.srmAtisuService = srmAtisuService;
  }

  /**
   * Получение списка заказов
   */
  async getOrders(filters: OrdersFilterDTO, params?: Partial<PaginationDTO>): Promise<OrderResponse> {
    const controller = new AbortController();
    if (this.getOrdersRequestController) this.getOrdersRequestController.abort();
    this.getOrdersRequestController = controller;

    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_GET_ORDER_LIST,
      params,
      data: filters,
      signal: controller.signal,
    });
  }

  /**
   * Получение списка заказов
   */
  getFiltersValues(filters: OrdersFilterDTO, params: Partial<PaginationDTO>): Promise<FilterListType> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_GET_FILTERED_ORDER_LIST,
      params,
      data: filters,
    });
  }

  /**
   * Получить кол-во заявок
   */
  getCountersOrders(filters: OrdersFilterDTO, params?: Partial<PaginationDTO>): Promise<OrderCounters> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_GET_ORDER_COUNTS,
      params,
      data: filters,
    });
  }

  /**
   * Получение заказа по id
   * @param {string} orderId
   */
  getOrder(orderId: string): Promise<OrderModel> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_GET_ORDER_BY_ID(orderId),
    });
  }

  checkOrderBySecurityCategory(params: CheckOrderBySecurityCategory): Promise<DecisionCheckOrderBySecurityCategory> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_ORDER_CHECK_BY_SECURITY_CATEGORY,
      params,
    });
  }

  /**
   * Получение определенной версии заказа
   * @param {string} orderVersionId
   */
  getOrderHistoricalVersion(orderVersionId: string): Promise<OrderModel> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_ORDER,
      params: {
        orderVersionId,
      },
    });
  }

  /**
   * Получение истории заказы
   * @param {string} orderVersionId
   * @param {string} orderId
   */
  getOrderHistory(orderId: string, orderVersionId: string): Promise<OrderHistoryModel[]> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_GET_ORDER_HISTORY_LIST,
      params: {
        id: orderId,
        versionId: orderVersionId,
      },
    });
  }

  /**
   * Установка инструкций
   */
  setInstructions(data: {
    id: string;
    loadingInstructions: string;
  }): Promise<OperationResponse<OrderInstructionsOperation>> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_SET_INSTRUCTIONS,
      data,
    });
  }

  /**
   * Изменение актуальных дат заказов
   */
  setActualDates(dates: OrderActualDates[]): Promise<OperationResponse<OrderActualDates>> {
    return this.srmNewOrderServiceV2.request({
      method: 'PUT',
      url: API_SET_ACTUAL_DATES,
      data: dates,
    });
  }

  /**
   * Установка статуса заказа
   */
  setOrderStatus(status: OrderStatusModel): Promise<OperationResponse<null>> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_SET_ORDER_STATUS,
      data: status,
      timeout: 60000,
      partialContentMessage: true,
    });
  }

  /**
   * Обновления заявки
   */
  updateOrder(data: Partial<OrderModel> & { reason?: string }): Promise<OrderModel> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_ORDER,
      data,
      timeout: 60000,
    });
  }

  /**
   * Установка цены заказа
   */
  setOrderPrice(orderId: string, newPrice: number, reason: string): Promise<OperationResponse<string>> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_SET_ORDER_PRICE(orderId, newPrice, reason),
    });
  }

  /**
   * Установка цен
   */
  setPrices(
    orderId: string,
    {
      priceWithTaxes, groupPrice, freightPrice, reason,
    }: PricesModel,
  ): Promise<OperationResponse<null>> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_SET_PRICE,
      data: {
        orderId,
        priceWithTaxes,
        groupPrice,
        freightPrice,
        reason,
      },
    });
  }

  /**
   * Установка цен
   */
  updateLoadingEvents(
    orderId: string,
    loadingEvents: LoadingEvent[],
  ): Promise<OperationResponse<null>> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_SET_PRICE,
      data: {
        orderId,
        loadingEvents,
      },
    });
  }

  /**
   * Получение договора
   * @param {string} counterpartyId
   */
  getContract(counterpartyId: string): Promise<ContractModel> {
    return this.srmService.request({
      method: 'GET',
      url: API_GET_CONTRACT,
      params: {
        counterpartyId,
      },
    });
  }

  /**
   * Получение списка почтовых адресов для отправки страхования
   * @param {string} shipAgentId
   */
  getDefaultCargoInsuranceEmails(shipAgentId: string): Promise<OrderInsuranceDefaultEmails> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_GET_DEFAULT_CARGO_INSURANCE_EMAILS,
      params: { shipAgentId },
    });
  }

  /**
   * Отправка письма с печатной формой заказа
   */
  sendEmailWithOrder(data: OrderSendEmailDTO): Promise<void> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_SEND_EMAIL_WITH_ORDER,
      data,
    });
  }

  /**
   * Отправка письма с документами заказа
   */
  sendEmailWithDocuments(data: SendEmailDTO): Promise<void> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_SEND_EMAIL_WITH_DOCUMENT,
      data,
    });
  }

  /**
   * Поиск транспорта
   */
  getVehicleLocations(query: VehicleRecommendationListFilterDTO): Promise<VehicleRecommendation[]> {
    return this.srmNewOrderService.request({
      method: 'POST',
      url: API_GET_VEHICLE_LOCATIONS,
      data: query,
    });
  }

  /**
   * Отправка страхования
   */
  sendEmailWithInsurance(
    data: SendCargoInsuranceEmailDTO,
  ): Promise<OperationResponse<SendCargoInsuranceEmailDTO>> {
    return this.srmNewOrderServiceV2.request({
      method: 'POST',
      url: API_SEND_INSURANCE,
      data,
    });
  }

  /**
   * Получить все грузы
   */
  getCargoType(): Promise<DictionaryModel[]> {
    return this.srmService.request({
      method: 'GET',
      url: API_GET_CARGO_TYPE_LIST,
    });
  }

  /**
   * Создание заказа из предзаказа
   */
  completeOrder(
    orderId: string,
    params: { groupCompanyContractId: string; sts?: boolean },
  ): Promise<OperationResponse<null>> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_ORDER_COMPLETE(orderId),
      params,
    });
  }

  editResourcesOrder(
    orderId: string,
    data: EditOrderResources,
  ): Promise<OperationResponse<null>> {
    return this.srmNewOrderServiceV2.request({
      method: 'PUT',
      url: API_ORDER_RESOURCES(orderId),
      data,
    });
  }

  /**
   * Получение списка причин отмены
   */
  getCancelReasons(): Promise<OrderCancelReason[]> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_GET_CANCEL_REASON_LIST,
    });
  }

  /**
   * Получение файла страхования
   */
  getCargoInsurance(orderId?: string): Promise<Blob> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_GET_CARGO_INSURANCE(orderId),
      responseType: 'blob',
    });
  }

  /**
   * Получение файла страхования
   */
  getConfirmationCode(context: OrderConformationSMSDTO): Promise<OperationResponse<void>> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_SEND_CONFIRM_SMS,
      params: context,
    });
  }

  /**
   * @param {string} orderId
   */
  checkDocuments(orderId: string): Promise<FilesModel[]> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_CHECK_DOCUMENT(orderId),
    });
  }

  /**
   * Получение начальных данных по аукционной заявке
   * @param {string} orderId
   */
  getAuctionOrder(orderId: string): Promise<OrderAuction> {
    return this.srmOrderAuctionService.request({
      method: 'GET',
      url: API_GET_AUCTION_DATA(orderId),
    });
  }

  /**
   * Получение начальных данных по сбору предложений
   * @param {string} orderId
   */
  getRequestOrder(orderId: string): Promise<OrderRequest> {
    return this.srmOrderAuctionService.request({
      method: 'GET',
      url: API_GET_OFFER_COLLECTIONS_BY_IDS(orderId),
    });
  }

  /**
   * Получение аукционов по id заявок
   * @param {string[]} orderIds
   */
  getAuctionsByOrderIds(orders: string[]): Promise<{ auctions: OrderAuctionListItem[] }> {
    return this.srmOrderAuctionService.request({
      method: 'POST',
      url: API_AUCTION,
      data: {
        orders,
      },
    });
  }

  /**
   * Получение запросов предложений по id заявок
   * @param {string[]} orderIds
   */
  getRequestsByOrderIds(orders: string[]): Promise<{ offers: OrderRequestListItem[] }> {
    return this.srmOrderAuctionService.request({
      method: 'POST',
      url: API_GET_OFFER_COLLECTIONS,
      data: {
        orders,
      },
    });
  }

  /**
   * Сделать ставку в аукционе
   * @param {OrderAuctionPlaceBet} data
   */
  placeAuctionBet(data: OrderAuctionPlaceBet): Promise<OrderAuctionPlaceBetResponse> {
    return this.srmOrderAuctionService.request({
      method: 'POST',
      url: API_SET_AUCTION_BET,
      data,
    });
  }

  /**
   * Сделать ставку в сборе предложений
   * @param {OrderRequestPlaceBet} data
   */
  placeRequestBet(data: OrderRequestPlaceBet): Promise<OrderRequestPlaceBetResponse> {
    return this.srmOrderAuctionService.request({
      method: 'POST',
      url: API_SET_AUCTION_OFFER,
      data,
    });
  }

  /**
   * Отказаться от аукционной заявки
   * @param {string} orderId
   */
  refuseAuctionOrder(orderId: string): Promise<string> {
    return this.srmNewOrderService.request({
      method: 'PUT',
      url: API_AUCTION_DECLINE,
      params: {
        orderId,
      },
    });
  }

  /**
   * Выгрузить историю аукционной заявки
   * @param {string} orderId
   */
  downloadAuctionHistory(orderId: string, date: string): Promise<Blob> {
    return this.srmOrderAuctionService.request({
      method: 'GET',
      url: GET_ORDER_HISTORY_IN_AUCTION,
      params: {
        orderId,
        time: date,
      },
      responseType: 'blob',
    });
  }

  /**
   * Выгрузить историю заявки с запросом предложений
   * @param {string} orderId
   */
  downloadRequestHistory(orderId: string, date: string): Promise<Blob> {
    return this.srmOrderAuctionService.request({
      method: 'GET',
      url: GET_OFFER_COLLECTION_HISTORY,
      params: {
        orderId,
        time: date,
      },
      responseType: 'blob',
    });
  }

  getAutotrain(params: AutotrainParams): Promise<VehicleInfo> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_AUTOTRAIN,
      params,
    });
  }

  getStatusList(): Promise<Status[]> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_GET_STATUS_LIST,
    });
  }

  loadOrderChannels(): Promise<Status[]> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_GET_CHANNELS_LIST,
    });
  }

  loadOrderSources(): Promise<Status[]> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_GET_SOURCES_LIST,
    });
  }

  getRegions(params: RegionsParams): Promise<Region[]> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_REGIONS,
      params,
    });
  }

  getRegionById(id: string): Promise<Region> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_REGION_BY_ID(id),
    });
  }

  getCities(params?: CitiesParams): Promise<Page<City>> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_CITIES,
      params: {
        countryId: COUNTRY_RUSSIA_ID,
        ...params,
      },
    });
  }

  getCityById(id: string): Promise<City> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_CITY_BY_ID(id),
    });
  }

  loadResponsibleLogistList(id: string): Promise<ResponsibleLogist[]> {
    return this.srmNewOrderService.request({
      method: 'GET',
      url: API_GET_RESPONSIBLE_LOGIST(id),
    });
  }

  sendOrdersToAtisu(data: OrderToAti[]): Promise<OrderToAtiResponse[]> {
    return this.srmAtisuService.request({
      method: 'POST',
      url: API_ATISU_SEND_ORDERS,
      data,
    });
  }

  createDislike(data: OrderDislikeCreate): Promise<void> {
    return this.srmNewOrderServiceV2.request({
      method: 'POST',
      url: API_SET_ORDER_DISLIKE_CREATE,
      data,
    });
  }

  deleteDislike(orderId: string): Promise<void> {
    return this.srmNewOrderServiceV2.request({
      method: 'DELETE',
      url: API_SET_ORDER_DISLIKE_DELETE,
      params: {
        orderId,
      },
    });
  }

  offerOrderPrice(data: OfferOrderPriceParams): Promise<void> {
    return this.srmNewOrderServiceV2.request({
      method: 'POST',
      url: API_ORDER_REACTION,
      data,
    });
  }

  getOrderPriceOffering(crmId: string): Promise<{ price: string }> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_ORDER_REACTION_BY_ID(crmId),
    });
  }

  getCarrierOrderCounter(): Promise<number> {
    return this.srmNewOrderServiceV2.request({
      method: 'GET',
      url: API_ORDER_CARRIER_COUNTS,
    });
  }
}

container.bind<SRMOrderService>(SRM_ORDER_SERVICE_ID).to(SRMOrderService);
