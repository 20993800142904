
import {
  computed,
  defineComponent,
  onMounted,
} from 'vue';
import container from '@/di';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import { Nullable } from '@/features/shared/types';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import SRMOrgModule, { SRM_ORG_MODULE_ID } from '@/features/srm/orgs/store/OrgModule';
import { CounterpartyModel } from '@/features/srm/orgs/services/OrgService.types';
import { RelationType } from '@/features/srm/profile/constants';

export default defineComponent({
  name: 'AppHeaderCarrierUser',

  components: {
    SvgIcon,
  },

  setup() {
    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const user = computed<UserModel | undefined>(() => userModule.value.user);
    const org = computed<Nullable<CounterpartyModel>>(() => user.value?.userCounterparties[0]?.counterparty ?? null);

    const userName = computed<string>(() => (user.value ? `${user.value.lastName} ${user.value.firstName.charAt(0)}.` : ''));
    const companyName = computed<Nullable<string>>(() => org.value?.name ?? null);
    const showCompanyName = computed<boolean>(() => (
      companyName && user.value?.userCounterparties[0]?.relationTypeId !== RelationType.CarrierEmployeeRequest
    ));

    const orgModule = computed<SRMOrgModule>(() => container.get(SRM_ORG_MODULE_ID));
    const legalFormName = computed<string>(() => {
      const legalFormId = org.value?.legalFormId as number;
      return orgModule.value.legalFormById(legalFormId)?.name ?? '';
    });

    onMounted(() => orgModule.value.loadLegalForms());

    return {
      user,
      userName,
      showCompanyName,
      companyName,
      legalFormName,
      org,
    };
  },
});
