
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';

import {
  useRoute,
} from 'vue-router';
import UiInputText from '@/features/redesigned/shared/kit/UiInputText.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import { Nullable } from '@/features/shared/types';
import container from '@/di';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';
import UiTooltipWithContent from '@/features/redesigned/shared/kit/UiTooltipWithContent.vue';
import useOrdersRoute from '@/features/redesigned/srm/orders/composable/orders-route';
import CarrierPreorderModule, { CARRIER_PREORDER_MODULE_ID } from '@/features/srm/truck-offering/store';

export default defineComponent({
  name: 'AppHeaderSearch',

  components: {
    UiInputText,
    SvgIcon,
    UiTooltipWithContent,
  },

  setup() {
    const route = useRoute();
    const searchValue = ref<Nullable<string>>(null);

    const orderModule = computed<SRMOrderModule>(() => container.get(SRM_ORDER_MODULE_ID));
    const carrierPreorderModule = computed<CarrierPreorderModule>(() => container.get(CARRIER_PREORDER_MODULE_ID));

    const searchOrderQuery = computed<Nullable<string>>(() => orderModule.value.searchQuery);
    const searchPreorderQuery = computed<Nullable<string>>(() => carrierPreorderModule.value.searchQuery);

    watch(searchOrderQuery, () => {
      searchValue.value = searchOrderQuery.value;
    });

    watch(searchPreorderQuery, () => {
      searchValue.value = searchPreorderQuery.value;
    });

    const { updateRoute, getOrdersQuery } = useOrdersRoute();

    const onSearchInput = async (searchText: string) => {
      if (route.name === 'orders' || route.name === 'order') {
        searchValue.value = searchText;

        const query = getOrdersQuery();

        await updateRoute({
          page: 1,
          size: query.size,
          scenario: query.activeScenario,
          activeFilters: query.activeFilters,
          search: searchValue.value,
        });

        orderModule.value.searchOrderQuery(searchValue.value);
      } else if (route.name === 'truck-offering' || route.name === 'crm-truck-offering') {
        searchValue.value = searchText;

        carrierPreorderModule.value.preorderSearchQuery(searchValue.value);
      }
    };

    return {
      searchValue,
      orderModule,
      onSearchInput,
    };
  },
});
