
import { PropType } from 'vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';

export default {
  name: 'UiModal',

  components: { DefaultTransition },
  props: {
    target: {
      type: String as PropType<string>,
      required: true,
    },

    overlayColor: {
      type: String as PropType<string>,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },

    closeOnClickOverlay: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  emits: ['close'],

  mounted() {
    global.document.body.classList.add(this.$style.openModal);
  },

  unmounted() {
    global.document.body.classList.remove(this.$style.openModal);
  },

  methods: {
    onClose() {
      if (this.closeOnClickOverlay) {
        this.$emit('close');
      }
    },
  },
};
