
import { PropType } from 'vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import inputMixin from '@/features/redesigned/shared/kit/mixins/input';
import {
  Nullable,
  Style,
  Styles,
} from '@/features/shared/types';
import UiInputProps from '@/features/redesigned/shared/kit/UiInput.props';

export default {
  name: 'UiInputText',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  props: {
    ...UiInputProps,
    id: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    dataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    inputDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    searchSvgUseDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    passportField: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    clearable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['update:modelValue', 'change'],

  data() {
    return {
      isInputEvent: false,
      maskedValue: this.modelValue,
    };
  },

  computed: {
    inputValue(): Nullable<string | number> {
      return this.mask ? this.maskedValue : this.modelValue;
    },

    inputClasses(): Styles {
      return {
        [this.$style.input]: true,
        [this.$style.passportField]: this.passportField,
      };
    },

    internalId(): string {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `input-${this._.uid}`;
    },

    isClearable(): boolean {
      return this.clearable;
    },

    hasValue(): boolean {
      return !!this.modelValue && (typeof this.modelValue === 'string' && this.modelValue.length >= 1);
    },

    rightIconClasses(): Style {
      return {
        [this.$style.rightIcon]: true,
        [this.$style.hover]: this.rightIcon === 'close',
      };
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!this.isInputEvent) {
          this.maskedValue = value;
        }
        this.isInputEvent = false;
      },
    },
  },

  methods: {
    onInput(e: Event): void {
      if (!this.mask) {
        this.$emit('update:modelValue', e.target?.value);
      } else {
        this.isInputEvent = true;
      }
    },

    onAccept(e: CustomEvent): void {
      this.maskedValue = e.detail.value;
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },

    onComplete(e: CustomEvent): void {
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },

    onClear(): void {
      this.$emit('update:modelValue', null);
    },

    onChange(e: CustomEvent): void {
      this.$emit('change', e);
    },
  },
};
