
import {
  computed,
  defineComponent,
  PropType,
  useCssModule,
} from 'vue';
import { get } from '@vueuse/core';
import { useRoute } from 'vue-router';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';

export default defineComponent({
  name: 'AppSidebarMenuItem',

  components: {
    SvgIcon,
  },

  props: {
    type: {
      type: String as PropType<string>,
      default: 'router-link',
    },

    to: {
      type: Object as PropType<Nullable<Location>>,
      default: null,
    },

    target: {
      type: String as PropType<string>,
      default: '_self',
    },

    label: {
      type: String as PropType<string>,
      required: true,
    },

    icon: {
      type: String as PropType<string>,
      required: true,
    },

    isSelected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    hasExpandIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isSubmenu: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isExpanded: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isCollapsed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    includedRoutes: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },

  emits: ['expand'],

  setup(props, { emit }) {
    const route = useRoute();
    const $style = useCssModule();

    const containerStyles = computed<Styles>(() => ({
      [$style.container]: true,
      [$style.selected]: get(isActive),
      [$style.submenu]: props.isSubmenu,
      [$style.collapsed]: props.isCollapsed,
      [$style.disabled]: props.isDisabled,
    }));

    const expandIconStyles = computed<Styles>(() => ({
      [$style.expandIcon]: true,
      [$style.expanded]: props.isExpanded,
    }));

    const onClick = () => {
      if (!props.isCollapsed) {
        emit('expand');
      }
    };

    const isActive = computed<boolean>(() => props.includedRoutes.includes(route?.name));

    return {
      containerStyles,
      expandIconStyles,
      onClick,
      isActive,
    };
  },
});
