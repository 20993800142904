import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';

import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SUPPORT_HTTP_SERVICE_ID = Symbol('supportRemoteService');

@Register(
  SUPPORT_HTTP_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SupportHttpService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SupportHttpService;
