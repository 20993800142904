
import { PropType } from 'vue';
import UiModal from '@/features/shared/kit/UiModal.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

import {
  Nullable,
  Style,
} from '@/features/shared/types';

export default {
  name: 'UiModalWithContent',

  components: {
    SvgIcon,
    UiModal,
  },

  props: {
    target: {
      type: String as PropType<string>,
      required: true,
    },

    width: {
      type: Number as PropType<number>,
      required: true,
    },

    isCentered: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    closeOnClickOverlay: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    overlayColor: {
      type: String as PropType<string>,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },

    hasCustomContainerStyle: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    customContainerStyle: {
      type: Object as PropType<Style>,
      default: () => ({}),
    },

    showClose: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    qaKeyCancel: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },
  },

  emits: ['close'],

  computed: {
    customStyles(): Style {
      return this.hasCustomContainerStyle ? this.customContainerStyle : {};
    },

    containerClasses() {
      return {
        [this.$style.container]: true,
        [this.$style.customContainerStyle]: this.hasCustomContainerStyle,
      };
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
