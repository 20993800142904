import { GetUrlWithStringIdParameter } from '@/features/srm/types';

export const API_GET_CARRIER_LIST = '/carrier/page';
export const API_GET_COUNTERPARTY_COUNTERS = '/carrier/counts';
export const API_GET_FILTERED_COUNTERPARTY_LIST = '/carrier/filter';

export const API_DRIVER = '/driver';
export const API_DRIVER_ALL = '/driver/page';

export const API_OPERATIONS_FOR_DRIVER_BY_ID: GetUrlWithStringIdParameter = (id) => `/driver/${id}`;
export const API_SEND_APPROVAL_ID: GetUrlWithStringIdParameter = (id) => `/driver/sendApproval/${id}`;
export const API_SEND_ALL_APPROVAL_ID: GetUrlWithStringIdParameter = (
  carrierId,
): string => `/driver/sendAllApproval?carrierId=${carrierId}`;

export const API_GET_STATUSES_LIST = '/carrier/statuses';
export const API_GET_REASON_SUSPENDED_LIST = '/status-reason';
export const API_GET_CARRIER_BY_ID: GetUrlWithStringIdParameter = (id) => `carrier/${id}`;
export const API_POST_CARRIER_STATUS: GetUrlWithStringIdParameter = (id) => `carrier/${id}/status`;
export const API_GET_DRIVER_RECOVER = (id: string): string => `driver/${id}/recover`;
export const API_GET_DRIVER = (id: string): string => `driver/${id}`;
export const USER_COUNTERPARTY_REL_PAGE = '/userCounterpartyRel/page';
export const API_GET_USERS_BY_CARRIER = '/userCounterpartyRel/usersByCarrier';
export const API_GET_DRIVER_COUNTS = '/driver/count';
export const API_GET_DRIVER_LIST = '/driver/full-page';
export const API_VEHICLE = '/vehicle';
export const API_GET_VEHICLE_RECOVER = (id: string): string => `/vehicle/${id}/recover`;
export const API_GET_VEHICLE = (id: string): string => `/vehicle/${id}`;
export const API_GET_VEHICLE_COUNTS = '/vehicle/count';
export const API_VEHICLE_ALL = '/vehicle/page';
export const API_GET_VEHICLE_LIST = '/vehicle/full-page';
export const API_CARRIER_COUNTERPARTY = '/carrierCounterparty/create';
export const API_VEHICLE_FIND_BY_VIN = '/vehicle/findByVin';
export const API_VEHICLE_SEND_APPROVAL_ID: GetUrlWithStringIdParameter = (id) => `/vehicle/sendApproval/${id}`;
export const API_VEHICLE_SEND_ALL_APPROVAL_ID = '/vehicle/sendAllApproval';
export const API_VEHICLE_MARK = '/mark';
export const API_CARRIER_COUNTERPARTY_ID = (id: string): string => `/carrierCounterparty/${id}`;
export const API_CARRIER_COUNTERPARTY_UPDATE = '/carrierCounterparty/update';
export const API_CONTACT = '/contact';
export const API_COUNTERPARTY_ID = '/counterparty/findById';
export const API_COUNTERPARTY_SEND_USER_NOTICE = '/counterparty/sendUserNotice';
export const API_COUNTERPARTY_SHIP_AGENTS = 'counterparty/shipAgents';
// eslint-disable-next-line max-len,vue/max-len
export const API_CARRIER_COUNTERPARTY_IS_MATCH_CARRIER_TO_MANAGER = '/carrierCounterparty/carrierCounterparty/isMatchCarrierToManager';
export const API_CARRIER_COUNTERPARTY_COUNTERPARTY_ID_DEFAULT_EMAIL = (
  counterpartyId: string,
): string => `/carrierCounterparty/${counterpartyId}/defaultEmail`;
