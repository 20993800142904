export const API_GET_COMPANIES = '/company';
export const API_GET_CLIENT_BY_ID = (id: string): string => `/counterparty/${id}`;
export const API_GET_CONSIGNEE_LIST = '/consignee/page';
export const API_GET_CONSIGNEE_TTN = '/consignee/ttn';
export const API_ADD_CONSIGNEE = '/consignee';
export const API_GET_CLIENT_CONTACT_LIST = '/counterparty-contact/page';
export const API_GET_CONTACT_LIST = '/counterparty-contact/page';
export const API_ADD_CLIENT_CONTACT = '/counterparty-contact';
export const API_GET_CONTRACT_LIST = '/contract-counterparty/page';
export const API_GET_LOADING_TYPES = '/loading-type';
export const API_GET_TRANSPORTATION_TYPES = '/transportation-type';
export const API_GET_CARGO_LIST = '/cargo/page';
export const API_GET_CARGO_BY_ID = (id: string): string => `/cargo/${id}`;
export const API_ADD_CARGO = '/cargo';
export const API_GET_LOCATION_LIST = '/location/page';
export const API_GET_LOCATION_NAME_LIST = '/location-name/page';
export const API_ADD_LOCATION_NAME = '/location-name';
export const API_GET_ROADMAP_TYPES = '/road-map-type';
export const API_GET_REGION_LIST = '/region/page';
export const API_GET_CITY_LIST = '/city/page';
export const API_GET_MANAGER_LIST = '/individuals/managers/page';
export const API_GET_MANAGER_BY_ID = (id: string): string => `/individuals/${id}`;
export const API_GET_TRAILER_TYPE_LIST = '/trailer-type/page';
export const API_GET_CANCEL_REASON_LIST = '/transportation-request/cancellation-reason';
export const API_GET_CONNECTION_LOCATION_AND_CONSIGNEE = '/locations-for-consignee';
export const API_DELETE_CONNECTION_LOCATION_AND_CONSIGNEE = '/locations-for-consignee/delete';
