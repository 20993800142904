import {
  mapFilterParams,
} from '@/filters/filter-params';
import { PreorderSummaryParams } from '@/features/shared/truck-offering/types';
import {
  PageParams,
  Undefinable,
} from '@/features/shared/types';
import { formatDateRangeToDateTimeString } from '@/filters/date';
import { LocationObjectType } from '@/features/srm/locations/constants';
import { LocationObjectInfo } from '@/features/srm/locations/services/LocationService.types';

export default class PreordersFilterMapper {
  static toRequest(params: PreorderSummaryParams): PageParams {
    const {
      fromDate,
      toDate,
    } = formatDateRangeToDateTimeString(params.filter?.period ?? null, 'YYYY-MM-DDTHH:mm:ssZ', false);

    const getPlaceLocationFromType = (type: string, location: Undefinable<LocationObjectInfo>):
      Undefinable<Record<string, Undefinable<string>>> => {
      if (!location) return {};

      switch (location.locationObjectType) {
        case LocationObjectType.City:
          return {
            [`${type}CityId`]: location?.locationObjectId,
          };
        case LocationObjectType.Region:
          return {
            [`${type}RegionId`]: location?.locationObjectId,
          };
        case LocationObjectType.FederalDistrict:
          return {
            [`${type}FederalDistrictName`]: location?.locationObjectDescription,
          };
        default:
          return {};
      }
    };

    const result = {
      id: (params.filter?.preorderNumber?.toString().length) ? params.filter?.preorderNumber : undefined,
      managerLogin: params.filter?.expeditor?.adLogin?.toLowerCase() ?? undefined,
      carrierId: params.filter?.carrier?.counterpartyId ?? undefined,
      loadingStartDate: fromDate?.length ? fromDate : undefined,
      loadingEndDate: toDate?.length ? toDate : undefined,
      ...getPlaceLocationFromType('loading', params.filter?.loadingPlace?.location),
      ...getPlaceLocationFromType('unloading', params.filter?.unloadingPlace?.location),
      trailerTypeCode: params.filter?.trailerType?.value?.toUpperCase(),
      trailerVolumeTypeCode: params.filter?.trailerVolumeType?.code,
      statusCode: params.filter?.status?.map((item) => item.value),
    };

    const hasFiler = Object.values(result).some((value) => value);

    return {
      page: params.page,
      size: params.size,
      filter: hasFiler ? mapFilterParams(result) : undefined,
      sort: ['creationDate|desc'],
    };
  }
}
