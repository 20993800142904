
import { PropType } from 'vue';
import UiProgressCircular from '@/features/shared/kit/UiProgressCircular.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';
import { ElementHeight } from '@/features/shared/constant';

export default {
  name: 'UiButton',

  components: {
    UiProgressCircular,
    SvgIcon,
  },

  props: {
    appearance: {
      type: String as PropType<string>,
      default: 'filled',
      validator: (value) => [
        'outline',
        'filled',
        'clean',
        'icon',
        'text',
      ].includes(value),
    },

    color: {
      type: String as PropType<string>,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'danger'].includes(value),
    },

    icon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    appendIcon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    prependIcon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    onlyIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    stroke: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    iconClassName: {
      type: [Object, String] as PropType<Nullable<string | Styles>>,
      default: null,
    },

    contentClassName: {
      type: [Object, String] as PropType<Nullable<string | Styles>>,
      default: null,
    },

    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    contentDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    textDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    svgUseDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    height: {
      type: Number as PropType<number>,
      default: 40,
      validator: (value) => [ElementHeight.Xs, ElementHeight.Small, ElementHeight.Base, ElementHeight.Xl].includes(value),
    },
  },

  computed: {
    classes(): Styles {
      return {
        [this.$style.base]: true,
        [this.$style[this.appearance]]: true,
        [this.$style[this.color]]: true,
        [this.$style.isHaveIcon]: !!this.icon,
        [this.$style.isLoading]: this.isLoading,
        [this.$style.isOnlyIcon]: this.onlyIcon,
        [this.$style.stroke]: this.onlyIcon,
      };
    },

    styles(): Record<string, string> {
      return {
        height: `${this.height}px`,
      };
    },
  },
};
