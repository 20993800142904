import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_app_sidebar_expedition = _resolveComponent("app-sidebar-expedition")!
  const _component_app_sidebar_support = _resolveComponent("app-sidebar-support")!
  const _component_app_sidebar_menu_item = _resolveComponent("app-sidebar-menu-item")!
  const _component_app_sidebar_timer = _resolveComponent("app-sidebar-timer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.containerStyles)
  }, [
    _createElementVNode("a", {
      class: _normalizeClass(_ctx.$style.logoContainer),
      href: "/"
    }, [
      _createVNode(_component_svg_icon, {
        class: _normalizeClass(_ctx.$style.logo),
        name: "logo_new"
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.logoText)
      }, " Портал для перевозчиков ", 2)
    ], 2),
    _createVNode(_component_app_sidebar_expedition, {
      "is-collapsed": _ctx.isCollapsed,
      class: _normalizeClass(_ctx.$style.mainMenu)
    }, null, 8, ["is-collapsed", "class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.border)
    }, null, 2),
    _createElementVNode("div", null, [
      _createVNode(_component_app_sidebar_support, { "is-collapsed": _ctx.isCollapsed }, null, 8, ["is-collapsed"]),
      _createVNode(_component_app_sidebar_menu_item, {
        label: "Настройки",
        icon: "settings_light",
        "is-collapsed": _ctx.isCollapsed,
        to: { name: 'srm-profile' }
      }, null, 8, ["is-collapsed"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.border)
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.bottom)
    }, [
      _createVNode(_component_app_sidebar_timer, {
        class: _normalizeClass(_ctx.$style.timer)
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.bottomBorder)
      }, null, 2),
      _createVNode(_component_svg_icon, {
        name: "arrow_left_tiny",
        class: _normalizeClass(_ctx.$style.expandIcon),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCollapsed = !_ctx.isCollapsed))
      }, null, 8, ["class"])
    ], 2)
  ], 2))
}