
import { defineComponent } from 'vue';
import UiHandlerStatus from '@/features/redesigned/shared/kit/UiHandlerStatus.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';

export default defineComponent({
  name: 'OrgPhoneConfirmThirdStep',

  components: {
    UiHandlerStatus,
    UiButton,
  },

  emits: ['click:finish'],
});
