import {
  BooleanParam,
  DateParam,
  decodeQueryParams,
  encodeQueryParams,
  NumberParam,
  StringParam,
} from 'serialize-query-params';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { computed } from 'vue';
import { ListFilterScenario } from '@/features/srm/constants';
import { OrdersTab } from '@/features/shared/constant';
import { OrderLocalFilters } from '@/features/srm/types';
import container from '@/di';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';
import { Nullable } from '@/features/shared/types';

const scenarioSchema = {
  activeScenario: StringParam,
};

const pageSchema = {
  page: NumberParam,
  size: NumberParam,
};

const filtersSchema = {
  isTent: StringParam,
  status: StringParam,
  dateFrom: DateParam,
  dateTo: DateParam,
  unloadDateFrom: DateParam,
  hasAutoApprove: BooleanParam,
  unloadDateTo: DateParam,
  loadDateTo: DateParam,
  loadDateFrom: DateParam,
  firstCityId: StringParam,
  lastCityId: StringParam,
  firstCityOrRegionIdOrFederalDistrictName: StringParam,
  firstCityOrRegionOrFederalDistrictName: StringParam,
  lastCityOrRegionIdOrFederalDistrictName: StringParam,
  lastCityOrRegionOrFederalDistrictName: StringParam,
  firstCityRadius: NumberParam,
  lastCityRadius: NumberParam,
};

export interface OrderRouteSchema {
  page?: number;
  size?: number;
  activeFilters?: OrderLocalFilters;
  search: Nullable<string>;
  scenario?: ListFilterScenario | OrdersTab;
}

const searchSchema = {
  search: StringParam,
};

export interface OrdersFiltersQuery {
  page?: number;
  size?: number;
  activeFilters?: OrderLocalFilters;
  search: Nullable<string>;
  activeScenario?: ListFilterScenario | OrdersTab;
}

export interface UseOrdersRoute {
  updateRoute: (schema: OrderRouteSchema) => Promise<void>;
  getOrdersQuery: () => OrdersFiltersQuery;
}

const useOrdersRoute = (): UseOrdersRoute => {
  const route = useRoute();
  const router = useRouter();

  const orderModule = computed<SRMOrderModule>(() => container.get(SRM_ORDER_MODULE_ID));

  const getOrdersQuery = (): OrdersFiltersQuery => {
    const queryParams = decodeQueryParams({
      ...pageSchema,
      ...scenarioSchema,
      ...filtersSchema,
      ...searchSchema,
    }, route.query) as OrdersFiltersQuery;

    const {
      page,
      size,
      activeScenario,
      search,
    } = queryParams;

    const activeFilters = {};

    Object.entries(queryParams).forEach(([key, value]) => {
      if (queryParams.hasOwnProperty(key)
          && !scenarioSchema.hasOwnProperty(key)
          && !pageSchema.hasOwnProperty(key)
          && !searchSchema.hasOwnProperty(key)
          && value !== undefined
          && value !== null) {
        Object.assign(activeFilters, {
          [key]: value === 'true' || value === 'false' ? JSON.parse(value) : value,
        });
      }
    });

    if (search) {
      orderModule.value.searchOrderQuery(search);
    }

    return {
      page,
      size,
      activeScenario,
      activeFilters,
      search,
    };
  };

  const updateRoute = async (schema: OrderRouteSchema) => {
    await router.push({
      name: 'orders',
      query: {
        ...encodeQueryParams(pageSchema, { page: schema.page, size: schema.size }),
        ...encodeQueryParams(scenarioSchema, { activeScenario: schema.scenario }),
        ...encodeQueryParams(filtersSchema, schema?.activeFilters),
        ...encodeQueryParams(searchSchema, { search: schema.search }),
      },
    });
  };

  return {
    updateRoute,
    getOrdersQuery,
  };
};

export default useOrdersRoute;
