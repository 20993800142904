
import Cookies from 'js-cookie';
import container from '@/di';

import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import SRMWebsocketModule, { SRM_WEBSOCKET_MODULE_ID } from '@/features/srm/store/SRMWebsocketStore';

import AppHeader from '@/features/shared/layout/AppHeader.vue';
import AppSidebar from '@/features/shared/layout/AppSidebar.vue';
import AboutProgram from '@/features/shared/modals/AboutProgram.vue';
import AppSidebarFuel from '@/features/shared/layout/AppSidebarFuel.vue';
import AppSidebarOrder from '@/features/shared/layout/AppSidebarOrder.vue';
import AppSidebarTimer from '@/features/shared/layout/AppSidebarTimer.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import InfoBanner from '@/features/support/components/banner/InfoBanner.vue';
import AppSidebarSupport from '@/features/shared/layout/AppSidebarSupport.vue';
import AppSidebarMailing from '@/features/shared/layout/AppSidebarMailing.vue';
import AppSidebarClients from '@/features/shared/layout/AppSidebarClients.vue';
import AppSidebarSecurity from '@/features/shared/layout/AppSidebarSecurity.vue';
import AppSidebarLogistics from '@/features/shared/layout/AppSidebarLogistics.vue';
import AppSidebarExpedition from '@/features/shared/layout/AppSidebarExpedition.vue';

import {
  Nullable,
  Styles,
} from '@/features/shared/types';

type MediaQueryArg = MediaQueryList | MediaQueryListEvent;

export default {
  name: 'AppLayout',

  components: {
    AppSidebarSecurity,
    AppSidebarOrder,
    AppSidebarLogistics,
    AppSidebarExpedition,
    AppSidebarFuel,
    AppSidebarClients,
    AppHeader,
    AppSidebar,
    AppSidebarTimer,
    AppSidebarButton,
    AppSidebarSupport,
    AppSidebarMailing,
    AboutProgram,
    InfoBanner,
    DefaultTransition,
  },

  data() {
    return {
      isWide: true,
      isReady: false,
      mediaQuery: null as Nullable<MediaQueryList>,
      isAboutModal: false,
      isBannerVisible: false,
      blinkElements: [] as string[],
    };
  },

  computed: {
    configService(): ConfigService {
      return container.get(CONFIG_SERVICE_ID);
    },

    websocketModule(): SRMWebsocketModule {
      return container.get(SRM_WEBSOCKET_MODULE_ID);
    },

    isExpeditionActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'srm');
    },

    isOrdersActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'crm');
    },

    isSecurityActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'security');
    },

    isIntegrationMonitor(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'integration-monitor');
    },

    isLogisticsActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'sop');
    },

    isTasksActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'tasks');
    },

    isFuelActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'fuel');
    },

    isClientsActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'mrz');
    },

    isIntActive(): boolean {
      if (!this.$route.name) return false;
      return this.$route.matched.some((route) => route.name === 'integration-monitor');
    },

    showFuelInstruction(): boolean {
      return this.$can('read', 'FuelInstruction') && this.isFuelActive;
    },

    fuelInstructionLink(): string {
      return this.configService.FuelInstructionLink;
    },

    containerStyles(): Styles[] {
      return [
        this.$style.container, this.isBannerVisible
          ? this.$style.bannerIsActive
          : null,
      ];
    },

    isBlackoutSidebar(): boolean {
      return !!this.blinkElements.length;
    },

    sibebarClasses(): Styles {
      return {
        [this.$style.sidebar]: true,
        [this.$style.blackout]: this.isBlackoutSidebar,
      };
    },
  },

  watch: {
    isExpeditionActive(isActive: boolean) {
      if (isActive) {
        this.websocketModule.connect();
      } else {
        this.websocketModule.disconnect();
      }
    },

    'websocketModule.isClientConnected': {
      handler(isConnected: boolean) {
        if (!isConnected && this.isExpeditionActive) this.websocketModule.connect();
      },
    },
  },

  mounted() {
    if (this.$can('read', 'TruckOfferingTooltip')
      && this.isExpeditionActive && !Cookies.get('truckOfferingTooltipViewed')) {
      this.blinkElements.push('truck-offering');
    }

    if (this.isExpeditionActive) this.websocketModule.connect();
    this.mediaQuery = window.matchMedia('(min-width: 1538px)');
    this.mediaQuery?.addEventListener('change', this.onChangeWidth);

    this.onChangeWidth(this.mediaQuery);

    this.isReady = true;
  },

  beforeUnmount() {
    this.mediaQuery?.removeEventListener('change', this.onChangeWidth);
  },

  methods: {
    onChangeWidth(matcher: MediaQueryArg) {
      this.isWide = matcher.matches;
    },

    toggleBannerVisible(status: boolean) {
      this.isBannerVisible = status;
    },

    onClickHideBlink(element: string) {
      if (this.$can('read', 'TruckOfferingTooltip')) {
        Cookies.set('truckOfferingTooltipViewed', 'true', { expires: 365 });
      }
      this.blinkElements = this.blinkElements.filter((blink: string) => element !== blink);
    },
  },
};
