export enum Gender {
  MALE,
  FEMALE,
  NEUTRAL
}

export function fieldWithFallback(
  value?: string | number,
  gender: Gender = Gender.MALE,
): string {
  let isFieldEmpty: boolean;
  let stringValue = '';

  if (value === null || value === undefined) {
    isFieldEmpty = true;
  } else {
    stringValue = `${value}`.trim();
    isFieldEmpty = !stringValue;
  }
  if (isFieldEmpty) {
    switch (gender) {
      case Gender.MALE:
        return 'Не указан';
      case Gender.FEMALE:
        return 'Не указана';
      case Gender.NEUTRAL:
        return 'Не указано';
      default:
        return '';
    }
  }

  return stringValue;
}
