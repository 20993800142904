import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '@/abilities.types';

// Клиентских менеджеров
export function defineAbilitiesSopClientManager(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('forKM', 'sop');
}

// Руководитель клиентских менеджеров
export function defineAbilitiesSopChiefClientManager(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  defineAbilitiesSopClientManager(builder);

  can('forChiefKM', 'sop');

  can('change', 'DashboardFilters');
}

// мэп
export function defineAbilitiesSopManagerExpedite(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('forMEP', 'sop');
}

// Руководитель МЭПов
export function defineAbilitiesSopChiefManagerExpedite(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  defineAbilitiesSopManagerExpedite(builder);

  can('forChiefMEP', 'sop');
}

// логисты
export function defineAbilitiesSopLogist(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  can('forLogist', 'sop');
}

// Руководитель логистов
export function defineAbilitiesSopChiefLogist(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  defineAbilitiesSopLogist(builder);

  can('forChiefLogist', 'sop');
}

// БОССЫ босов
export function defineAbilitiesSopChief(
  builder: AbilityBuilder<AppAbility>,
): void {
  const { can } = builder;

  defineAbilitiesSopChiefClientManager(builder);
  defineAbilitiesSopChiefManagerExpedite(builder);
  defineAbilitiesSopChiefLogist(builder);

  can('forChief', 'DashboardFilters');
  can('forChief', 'All');
}
