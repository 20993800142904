import container, {
  Inject,
  Injectable,
} from '@/di';

import HttpService, { SRM_SERVICE_ID } from '@/features/srm/services/http';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import {
  CarrierStatusDTO,
  CounterModel,
  CounterpartiesPages,
  CounterpartyExtendedModel,
  CounterpartyUserContent,
  EntityCounterModel,
  FilterModel,
  GetUserCounterpartyRelDTO,
  EntityCounterDTO,
} from '@/features/srm/counterparty/services/CounterpartyService.types';
import {
  ContactModel,
  DictionaryModel,
  Nullable,
  Page,
} from '@/features/shared/types';
import {
  API_GET_CARRIER_BY_ID,
  API_GET_CARRIER_LIST,
  API_GET_COUNTERPARTY_COUNTERS,
  API_GET_DRIVER,
  API_GET_DRIVER_COUNTS,
  API_GET_DRIVER_LIST,
  API_GET_DRIVER_RECOVER,
  API_GET_VEHICLE_RECOVER,
  API_GET_VEHICLE,
  API_GET_VEHICLE_COUNTS,
  API_GET_VEHICLE_LIST,
  API_GET_FILTERED_COUNTERPARTY_LIST,
  API_GET_REASON_SUSPENDED_LIST,
  API_GET_STATUSES_LIST,
  API_POST_CARRIER_STATUS,
  USER_COUNTERPARTY_REL_PAGE,
  API_DRIVER,
  API_DRIVER_ALL,
  API_OPERATIONS_FOR_DRIVER_BY_ID,
  API_SEND_APPROVAL_ID,
  API_SEND_ALL_APPROVAL_ID,
  API_CARRIER_COUNTERPARTY_ID,
  API_CARRIER_COUNTERPARTY_UPDATE,
  API_VEHICLE,
  API_VEHICLE_ALL,
  API_VEHICLE_FIND_BY_VIN,
  API_VEHICLE_MARK,
  API_VEHICLE_SEND_APPROVAL_ID,
  API_VEHICLE_SEND_ALL_APPROVAL_ID,
  API_CONTACT,
  API_COUNTERPARTY_ID,
  API_COUNTERPARTY_SEND_USER_NOTICE,
  API_COUNTERPARTY_SHIP_AGENTS,
  API_CARRIER_COUNTERPARTY_IS_MATCH_CARRIER_TO_MANAGER,
  API_CARRIER_COUNTERPARTY_COUNTERPARTY_ID_DEFAULT_EMAIL,
  API_CARRIER_COUNTERPARTY,
  API_GET_USERS_BY_CARRIER,
} from '@/features/srm/counterparty/services/endpoints';
import {
  Driver,
  LoadDriversRequest,
} from '@/features/srm/drivers/services/DriverService.types';
import { OperationResponse } from '@/features/srm/types';
import {
  FindByVinDTO,
  MarkModel,
  VehicleModel,
  VehicleParams,
} from '@/features/srm/vehicles/services/VehicleService.types';
import { API_VEHICLE_ID } from '@/features/security/services/endpoints';
import {
  CounterpartyModel,
  CounterpartyParams,
  OrgExistenceDTO,
  OrgModel,
} from '@/features/srm/orgs/services/OrgService.types';
import { UserModel } from '@/features/srm/profile/services/UserService.types';

export const SRM_COUNTERPARTY_SERVICE_ID = Symbol('srmCounterpartyService');

@Injectable()
export default class SrmCounterpartyService {
  private api: HttpService;

  constructor(
    @Inject(SRM_SERVICE_ID) api: HttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
  ) {
    this.api = api;
    this.api.remoteClient.defaults.baseURL = config.SRMCounterpartyServiceHost;
  }

  /**
   * Получить превозчиков
   */
  getCounterparties(params: CounterpartiesPages): Promise<Page<CounterpartyExtendedModel>> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CARRIER_LIST,
      params,
    });
  }

  findCarrierById(id: string): Promise<CounterpartyExtendedModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_CARRIER_BY_ID(id),
    });
  }

  getCountersCounterparties(params: CounterpartiesPages): Promise<CounterModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_COUNTERPARTY_COUNTERS,
      params,
    });
  }

  async updateCarrierStatus({
    carrierId,
    data,
  }: CarrierStatusDTO): Promise<void> {
    await this.api.request({
      method: 'POST',
      url: API_POST_CARRIER_STATUS(carrierId),
      data,
    });
  }

  getFilteredCounterparties(): Promise<FilterModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_FILTERED_COUNTERPARTY_LIST,
    });
  }

  getCarrierStatuses(): Promise<FilterModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_STATUSES_LIST,
    });
  }

  getCarrierReasonSuspended(): Promise<DictionaryModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_REASON_SUSPENDED_LIST,
      params: {
        type: 'SUSPENDED',
      },
    });
  }

  getDriverCounts(params: EntityCounterDTO): Promise<EntityCounterModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_DRIVER_COUNTS,
      params,
    });
  }

  getDriverList(params: EntityCounterDTO): Promise<EntityCounterModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_DRIVER_LIST,
      params,
    });
  }

  loadDriver(id: string): Promise<EntityCounterModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_DRIVER(id),
    });
  }

  recoverDriver(id: string): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_GET_DRIVER_RECOVER(id),
    });
  }

  getVehicleCounts(params: EntityCounterDTO): Promise<EntityCounterModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_VEHICLE_COUNTS,
      params,
    });
  }

  getVehicles(params: VehicleParams): Promise<Page<VehicleModel>> {
    return this.api.request({
      method: 'GET',
      url: API_VEHICLE_ALL,
      timeout: 60000,
      params,
    });
  }

  getVehicleList(params: EntityCounterDTO): Promise<EntityCounterModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_VEHICLE_LIST,
      params,
    });
  }

  loadVehicle(id: string): Promise<EntityCounterModel> {
    return this.api.request({
      method: 'GET',
      url: API_GET_VEHICLE(id),
    });
  }

  updateVehicle(vehicle: VehicleModel): Promise<void> {
    return this.api.request({
      method: 'PUT',
      url: API_VEHICLE,
      data: vehicle,
    });
  }

  recoverVehicle(id: string): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_GET_VEHICLE_RECOVER(id),
    });
  }

  addVehicle(vehicle: VehicleModel): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_VEHICLE,
      data: vehicle,
    });
  }

  sendVehicleApprovalById(id: string): Promise<boolean> {
    return this.api.request({
      method: 'GET',
      url: API_VEHICLE_SEND_APPROVAL_ID(id),
    });
  }

  sendVehicleAllApprovalById(): Promise<boolean> {
    return this.api.request({
      method: 'POST',
      url: API_VEHICLE_SEND_ALL_APPROVAL_ID,
    });
  }

  removeVehicle(vehicleId: string): Promise<void> {
    return this.api.request({
      method: 'DELETE',
      url: API_VEHICLE_ID(vehicleId),
    });
  }

  checkVINExistence(vinDTO: FindByVinDTO): Promise<void> {
    return this.api.request({
      method: 'GET',
      url: API_VEHICLE_FIND_BY_VIN,
      params: vinDTO,
    });
  }

  getVehicleBrands(): Promise<MarkModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_VEHICLE_MARK,
    });
  }

  getUserCounterpartyRel(params: GetUserCounterpartyRelDTO): Promise<Page<CounterpartyUserContent>> {
    return this.api.request({
      method: 'GET',
      url: USER_COUNTERPARTY_REL_PAGE,
      params,
    });
  }

  getUsersByCarrier(
    carrierId: string,
    relTypeId: string,
    checkCarrierEmployeeRequest?: boolean,
  ): Promise<UserModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_USERS_BY_CARRIER,
      params: {
        carrierId,
        relTypeId,
        checkCarrierEmployeeRequest,
      },
    });
  }

  getDrivers(params: LoadDriversRequest): Promise<Page<Driver>> {
    return this.api.request({
      method: 'GET',
      url: API_DRIVER_ALL,
      timeout: 60000,
      params,
    });
  }

  getDriver(id: string): Promise<Driver> {
    return this.api.request({
      method: 'GET',
      url: API_OPERATIONS_FOR_DRIVER_BY_ID(id),
    });
  }

  addDriver(driver: Driver): Promise<OperationResponse<Driver>> {
    return this.api.request({
      method: 'POST',
      url: API_DRIVER,
      data: driver,
    });
  }

  updateDriver(driver: Driver): Promise<OperationResponse<Driver>> {
    return this.api.request({
      method: 'PUT',
      url: API_DRIVER,
      data: driver,
    });
  }

  removeDriver(driverId: string): Promise<OperationResponse<Driver>> {
    return this.api.request({
      method: 'DELETE',
      url: API_OPERATIONS_FOR_DRIVER_BY_ID(driverId),
    });
  }

  sendApprovalById(id: string): Promise<boolean> {
    return this.api.request({
      method: 'GET',
      url: API_SEND_APPROVAL_ID(id),
    });
  }

  sendAllApprovalById(carrierId: string): Promise<boolean> {
    return this.api.request({
      method: 'POST',
      url: API_SEND_ALL_APPROVAL_ID(carrierId),
    });
  }

  getAdditionalContacts(
    counterpartyId: string,
    type: string,
  ): Promise<ContactModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_CONTACT,
      params: {
        counterpartyId,
        type,
      },
    });
  }

  addAdditionalContact(data: ContactModel): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_CONTACT,
      data,
    });
  }

  updateAdditionalContact(data: ContactModel): Promise<void> {
    return this.api.request({
      method: 'PUT',
      url: API_CONTACT,
      data,
    });
  }

  getCounterparty(params: CounterpartyParams): Promise<CounterpartyModel> {
    return this.api.request({
      method: 'GET',
      url: API_COUNTERPARTY_ID,
      params,
    });
  }

  sendUserNotice(params: Nullable<OrgExistenceDTO>): Promise<void> {
    return this.api.request({
      method: 'POST',
      url: API_COUNTERPARTY_SEND_USER_NOTICE,
      data: params,
    });
  }

  getShipAgents(): Promise<CounterpartyModel[]> {
    return this.api.request({
      method: 'GET',
      url: API_COUNTERPARTY_SHIP_AGENTS,
    });
  }

  getCheckingComplianceManagerCarrier(id: string): Promise<boolean> {
    return this.api.request({
      method: 'GET',
      url: API_CARRIER_COUNTERPARTY_IS_MATCH_CARRIER_TO_MANAGER,
      params: {
        counterpartyId: id,
      },
    });
  }

  getOrgEmail(counterpartyId: string): Promise<string> {
    return this.api.request({
      method: 'GET',
      url: API_CARRIER_COUNTERPARTY_COUNTERPARTY_ID_DEFAULT_EMAIL(counterpartyId),
    });
  }

  addOrg(org: OrgModel): Promise<string> {
    return this.api.request({
      method: 'POST',
      url: API_CARRIER_COUNTERPARTY,
      data: org,
    });
  }

  getOrg(id: string): Promise<OrgModel> {
    return this.api.request({
      method: 'GET',
      url: API_CARRIER_COUNTERPARTY_ID(id),
    });
  }

  updateOrg(org: OrgModel): Promise<OperationResponse<string>> {
    return this.api.request({
      method: 'PUT',
      url: API_CARRIER_COUNTERPARTY_UPDATE,
      data: org,
    });
  }
}

container.bind<SrmCounterpartyService>(SRM_COUNTERPARTY_SERVICE_ID).to(SrmCounterpartyService);
