
import { PropType } from 'vue';
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';

export default {
  name: 'AppSidebarSupport',
  components: { AppSidebarButton },
  props: {
    isWide: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    window.onmessage = (message: MessageEvent) => {
      if (message.data === 'skip:loading') {
        this.isLoading = false;
      }
    };
  },

  methods: {
    setPageUrl() {
      window.localStorage.setItem('pageUrl', window.location.href);
    },

    relocate() {
      window.open('https://jira.monopoly.su/servicedesk/customer/portal/12/group/60', '_blank');
    },
  },
};
