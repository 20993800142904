
import { PropType } from 'vue';
import UiTooltipWrapper from '@/features/shared/kit/UiTooltipWrapper.vue';
import UiTooltip from '@/features/shared/kit/UiTooltip.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

export default {
  name: 'TelegramTooltip',
  components: {
    UiTooltipWrapper,
    UiTooltip,
    SvgIcon,
  },

  props: {
    isShowOnHover: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    triggerTooltip(): string {
      return this.isShowOnHover ? '' : 'manual';
    },
  },
};
