
import { PropType } from 'vue';
import container from '@/di';
import {
  CarriersCommunicationService,
  SRM_CARRIERS_COMMUNICATION_SERVICE_ID,
} from '@/features/srm/carriers-communication/services/CarriersCommunicationService';
import handImg from '@/assets/img/cabinet/hand.svg';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import { Styles } from '@/features/shared/types';
import FuelFeedbackForm from '@/features/shared/feedback/FuelFeedbackForm.vue';
import CarrierFeedbackForm from '@/features/shared/feedback/CarrierFeedbackForm.vue';
import UiDraggableWrapper from '@/features/shared/kit/UiDraggableWrapper.vue';
import { isNotPhone } from '@/utils/device';

export default {
  name: 'FeedbackButton',

  components: {
    UiDraggableWrapper,
    SvgIcon,
    FuelFeedbackForm,
    CarrierFeedbackForm,
  },

  props: {
    isRedesigned: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      handImg,
      isOpen: false,
      isSuccessMessageVisible: false,
      isDragstop: false,
      bottomOffset: 64,
    };
  },

  computed: {
    carriersCommunicationService(): CarriersCommunicationService {
      return container.get(SRM_CARRIERS_COMMUNICATION_SERVICE_ID);
    },

    actionIcon(): string {
      return this.isOpen ? 'close' : 'dialog';
    },

    containerStyles(): Styles {
      return {
        [this.$style.redesigned]: this.isRedesigned,
      };
    },

    actionIconClasses(): Styles {
      return {
        [this.$style.actionIconClose]: this.isOpen,
        [this.$style.actionIconDialog]: !this.isOpen,
      };
    },

    actionClasses(): Styles {
      return {
        [this.$style.action]: true,
        [this.$style.opened]: this.isOpen,
      };
    },

    isFuelActive(): boolean {
      if (!this.$route.name) return false;

      return this.$route.matched.some((route) => route.name === 'fuel');
    },
  },

  mounted() {
    if (isNotPhone()) {
      this.bottomOffset = 180;
    }
  },

  methods: {
    open() {
      if (this.isDragstop) {
        this.isDragstop = false;
        return;
      }

      this.isOpen = !this.isOpen;
      this.isSuccessMessageVisible = false;
    },

    close() {
      this.isOpen = false;
    },

    showSuccessMessage() {
      this.isSuccessMessageVisible = true;
    },
  },
};
