
import {
  defineComponent,
  toRefs,
  computed,
  reactive,
  onMounted,
} from 'vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import UiInputText from '@/features/redesigned/shared/kit/UiInputText.vue';
import UiFormGroup from '@/features/redesigned/shared/kit/UiFormGroup.vue';

import toastRedesigned, { toastContent } from '@/utils/toastRedesigned';
import { getHTTPError } from '@/utils/errors';

import container from '@/di';

import VerificationService, { VERIFICATION_SERVICE_ID } from '@/features/shared/services/verification';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';

export default defineComponent({
  name: 'OrgPhoneConfirmSecondStep',

  components: {
    UiButton,
    UiInputText,
    UiFormGroup,
  },

  emits: ['click:next-step'],

  setup(_, { emit }) {
    const state = reactive({
      isLoading: false,
      email: '',
    });

    const srmUserModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));

    onMounted(() => {
      if (srmUserModule.value.user?.email) {
        state.email = srmUserModule.value.user?.email;
      }
    });

    const verificationService = computed<VerificationService>(() => container.get(VERIFICATION_SERVICE_ID));

    const onClickSendEmail = async () => {
      state.isLoading = true;

      try {
        const { sent, message } = await verificationService.value.sendCodeOnEmail(state.email);

        if (sent) {
          toastRedesigned.success(toastContent(
            `Код верификации отправлен на электронную почту ${state.email}`,
            'Отправлено письмо',
          ));
          emit('click:next-step', state.email);
        } else {
          toastRedesigned.error(toastContent(message));
        }
      } catch (e) {
        toastRedesigned.error(toastContent(getHTTPError(e)));
      }

      state.isLoading = false;
    };

    return {
      ...toRefs(state),
      onClickSendEmail,
    };
  },
});
