import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_label = _resolveComponent("ui-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.isInvalid]: $options.isInvalid,
    })
  }, [
    ($options.computedLabel)
      ? (_openBlock(), _createBlock(_component_ui_label, {
          key: 0,
          class: _normalizeClass(_ctx.$style.label),
          for: $options.computedId,
          "prepend-icon": $props.prependIcon,
          "data-qa": $props.labelDataQa
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($options.computedLabel), 1)
          ]),
          _: 1
        }, 8, ["class", "for", "prepend-icon", "data-qa"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {
      id: $options.computedId,
      isInvalid: $options.isInvalid
    }),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.messageContainer)
    }, [
      ($options.errorMessage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.errorMessage)
          }, _toDisplayString($options.errorMessage), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "message"),
      ($props.message)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.message)
          }, _toDisplayString($props.message), 3))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}