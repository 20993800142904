import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';

export const SRM_ORDER_ACTION_REMOTE_SERVICE = Symbol('SRMOrderAuctionRemoteService');

@Register(
  SRM_ORDER_ACTION_REMOTE_SERVICE,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMOrderAuctionRemoteService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMOrderAuctionServiceHost);
    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMOrderAuctionRemoteService;
