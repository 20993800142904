
import {
  defineComponent,
  PropType,
  useCssModule,
  computed,
} from 'vue';

import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import { HandlerType } from '@/features/redesigned/shared/kit/types';
import { Styles } from '@/features/shared/types';

export default defineComponent({
  name: 'UiHandlerStatus',

  components: {
    SvgIcon,
  },

  props: {
    type: {
      type: String as PropType<HandlerType>,
      validator: (value: HandlerType) => [HandlerType.SUCCESS, HandlerType.ERROR].includes(value),
      default: HandlerType.SUCCESS,
    },
  },

  setup(props) {
    const style = useCssModule();

    const containerStyles = computed<Styles>(() => ({
      [style.container]: true,
      [style.success]: props.type === HandlerType.SUCCESS,
    }));

    return {
      containerStyles,
      HandlerType,
    };
  },
});
