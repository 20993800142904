import { lookup } from 'mime-types';

export function readUploadedFileAsDataUrl(inputFile: File): Promise<string | ArrayBuffer | null> {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(
        new DOMException('Проблема с обработкой содержимого файла'),
      );
    };

    temporaryFileReader.onload = () => {
      const result = temporaryFileReader.result as unknown;
      const resultData = result as PromiseLike<string>;
      resolve(resultData);
    };

    temporaryFileReader.readAsDataURL(inputFile);
  });
}

export function getFileTypeFromBase64(base64String: string): string {
  return base64String.substring('data:'.length, base64String.indexOf(';base64'));
}

export function getFileTypeFromFileName(fileName: string): string {
  return lookup(fileName);
}

export function base64ToArrayBuffer(base64: string): Uint8Array {
  let isRawBase64StringIndex = base64.indexOf(';base64');
  const base64Strlen = base64.length;

  if (isRawBase64StringIndex !== -1) {
    isRawBase64StringIndex += 8;
    const preparedBase64String = base64.substring(isRawBase64StringIndex, base64Strlen);
    return Uint8Array.from(atob(preparedBase64String), (c) => c.charCodeAt(0));
  }

  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
}

export function formatBytes(
  bytes: number | null,
  decimals = 2,
): string {
  if (bytes === null) {
    return '';
  }

  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

export function downloadFile(file: File | Blob, name: string): void {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.cssText = 'display: none';
  const fileIsNotEmpty = file instanceof File || file instanceof Blob;
  if (!fileIsNotEmpty) return;
  a.href = window.URL.createObjectURL(file);
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(a.href);
}
