import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_header_search = _resolveComponent("app-header-search")!
  const _component_default_transition = _resolveComponent("default-transition")!
  const _component_app_header_carrier_user = _resolveComponent("app-header-carrier-user")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_tooltip_with_content = _resolveComponent("ui-tooltip-with-content")!
  const _component_logout_confirm = _resolveComponent("logout-confirm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.mobileContainer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.panel)
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.$style.logoContainer),
            href: "/"
          }, [
            _createVNode(_component_svg_icon, {
              class: _normalizeClass(_ctx.$style.logo),
              name: "logo_new"
            }, null, 8, ["class"])
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.text)
        }, " Портал для перевозчиков ", 2),
        _createVNode(_component_router_link, {
          class: _normalizeClass(_ctx.$style.burger),
          to: { name: 'menu' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_svg_icon, {
              class: _normalizeClass(_ctx.$style.burgerIcon),
              name: "menu"
            }, null, 8, ["class"])
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.desktopContainer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createVNode(_component_default_transition, null, {
          default: _withCtx(() => [
            (_ctx.isShowSearch)
              ? (_openBlock(), _createBlock(_component_app_header_search, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.search)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.$can('read', 'CarrierUserDetails'))
          ? (_openBlock(), _createBlock(_component_app_header_carrier_user, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_ui_tooltip_with_content, {
          placement: "bottom-end",
          "arrow-align": "end"
        }, {
          trigger: _withCtx(() => [
            _createVNode(_component_ui_button, {
              appearance: "clean",
              icon: "logout_new",
              "icon-class-name": _ctx.$style.logoutIcon,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLogoutOpenModal = true))
            }, null, 8, ["icon-class-name"])
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Выход ")
          ]),
          _: 1
        })
      ], 2),
      (_ctx.isLogoutOpenModal)
        ? (_openBlock(), _createBlock(_component_logout_confirm, {
            key: 0,
            onAccept: _ctx.onLogout,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLogoutOpenModal = false))
          }, null, 8, ["onAccept"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}