
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import UiTooltipWithContent from '@/features/redesigned/shared/kit/UiTooltipWithContent.vue';
import UiDraggableWrapper from '@/features/shared/kit/UiDraggableWrapper.vue';
import WindowService, { WINDOW_SERVICE_ID } from '@/features/shared/services/window';
import container from '@/di';
import { isNotPhone } from '@/utils/device';
import { nextTick } from 'vue';

export default {
  name: 'ScrollTopButton',
  components: {
    UiDraggableWrapper,
    UiTooltipWithContent,
    SvgIcon,
  },

  data() {
    return {
      isVisible: false,
      bottomOffset: 150,
    };
  },

  computed: {
    windowService(): WindowService {
      return container.get(WINDOW_SERVICE_ID);
    },
  },

  mounted() {
    nextTick(() => {
      this.windowService.content?.addEventListener('scroll', this.handleScroll);
    });

    if (isNotPhone()) {
      this.bottomOffset = 260;
    }
  },

  unmounted() {
    this.windowService.content?.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    onScrollTop() {
      this.windowService.scrollContentTop();
    },

    handleScroll() {
      if (this.$route.name !== 'order') {
        const { scrollTop } = this.windowService.content;
        this.isVisible = window.innerHeight <= scrollTop;
      } else {
        const { scrollTop } = this.windowService.content;
        this.isVisible = scrollTop >= 300;
      }
    },
  },
};
