import { OpUnitType } from 'dayjs';
import { Ref } from 'vue';
import { ValidationRuleWithParams } from '@vuelidate/core';
import {
  helpers,
  maxLength,
  minLength,
} from '@vuelidate/validators';
import { Nullable } from '@/features/shared/types';
import { isSameOrBefore } from '@/filters/date';

export const inputPatterns = {
  digits: /^\d*$/,
  fio: /^[-а-яёa-z\s]*$/i,
  phone: /^[+]*[(]?\d{1,4}[)]?[-\s./0-9]*$/,
};

const regExpValidator = (
  pattern: RegExp,
  value: string,
): boolean => !helpers.req(value) || pattern.test(value);

export const phone = (value: string): boolean => (!helpers.req(value)
  || (`${value}`.slice(0, 3) === '375' && value.length === 12)
  || (`${value}`.slice(0, 3) === '371' && value.length === 11)
  || (`${value}`.slice(0, 3) === '995' && value.length >= 12)
  || (`${value}`.slice(0, 3) === '374' && value.length === 11)
  || (`${value}`.slice(0, 1) === '7' && value.length === 11)
  || (`${value}`.slice(0, 1) === '8' && value.length === 11)
);

/**
 *Деление чисел на разряды
 */
const DIGITS_SEPARATION = /(\d)(?=(\d\d\d)+([^\d]|$))/g;
export const digitsSeparation = (value: string | number): string => value.toString()
  .replace(DIGITS_SEPARATION, '$1 ');

export const fio = (value: string): boolean => regExpValidator(inputPatterns.fio, value);

export const aboveZero = (value: number | string | null): boolean => {
  if (value === null || value === '') return true;
  return +value !== 0;
};

export const isSameOrBeforeThanToday = (unit: OpUnitType = 'day') => (
  value: Date,
): boolean => !helpers.req(value) || isSameOrBefore(value, new Date(), unit);

export const datePeriod = (value: Array<Nullable<Date>>): boolean => !helpers.req(value) || value.every((date) => date !== null);

export const minL = (length: number | Ref<number>): ValidationRuleWithParams => helpers.withMessage(`Минимум ${length} символов`, minLength(length));
export const maxL = (length: number | Ref<number>): ValidationRuleWithParams => helpers.withMessage(`Максимум ${length} символов`, maxLength(length));
