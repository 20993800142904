import {
  nextTick,
  VNode,
} from 'vue';
import UiInputProps from '@/features/redesigned/shared/kit/UiInput.props';
import {
  Nullable,
  Styles,
  Undefinable,
} from '@/features/shared/types';

export default {
  props: {
    ...UiInputProps,
  },

  data() {
    return {
      isFocused: false,
    };
  },

  emits: ['update:modelValue', 'blur', 'focus'],

  computed: {
    classes(): Styles {
      return {
        'input-text__container': true,
        [this.$style.container]: true,
        [this.$style.isInvalid]: this.isInvalid,
        [this.$style.isDisabled]: this.disabled,
        [this.$style.isFocused]: this.isFocused,
      };
    },

    isDisabled(): string {
      return this.$attrs.disabled;
    },

    isHaveAppendedContent(): Nullable<VNode[]> | Undefinable<string> {
      return this.$slots.append || this.rightIcon;
    },

    isHavePrependedContent(): Nullable<VNode[]> | Undefinable<string> {
      return this.$slots.prepend || this.leftIcon;
    },
  },

  methods: {
    onClick() {
      this.$refs.input?.focus();
    },

    onBlur(event: Event) {
      this.isFocused = false;

      if (event) {
        nextTick(() => {
          this.$emit('blur', event);
        });
      }
    },

    onFocus(event: Event) {
      if (!this.$refs.input) return;

      if (document.activeElement !== this.$refs.input) {
        this.$refs.input?.focus();
        return;
      }

      if (!this.isFocused) {
        this.isFocused = true;

        if (event) {
          this.$emit('focus', event);
        }
      }
    },

    onInput(event: Event) {
      this.$emit('update:modelValue', event?.target?.value);
    },
  },
};
