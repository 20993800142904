
import { PropType } from 'vue';
import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';

export default {
  name: 'UiModalConfirm',

  components: {
    UiButton,
    UiModalWithContent,
  },

  props: {
    header: {
      type: String as PropType<string>,
      required: true,
    },

    acceptLabel: {
      type: String as PropType<string>,
      default: 'Да',
    },

    cancelLabel: {
      type: String as PropType<string>,
      default: 'Отмена',
    },

    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  methods: {
    onReject() {
      this.$emit('reject');
      this.$emit('close');
    },
  },
};
