
import { PropType } from 'vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

export default {
  name: 'UiVideo',

  components: {
    SvgIcon,
  },

  props: {
    width: {
      type: String as PropType<string>,
      required: true,
    },

    height: {
      type: String as PropType<string>,
      required: true,
    },

    videoUrl: {
      type: String as PropType<string>,
      required: true,
    },

    imgFrameUrl: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data() {
    return {
      clicked: false,
    };
  },
};
