import container, {
  Inject,
  Injectable,
} from '@/di';

import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import HttpService, { SUPPORT_HTTP_SERVICE_ID } from '@/features/support/services/http';
import {
  Banner,
  BannerListParams,
} from '@/features/support/types';
import {
  API_GET_BANNERS,
  API_POST_BANNERS_ID_STATUS,
  API_POST_ISSUES,
  API_PUT_BANNERS_ID,
} from '@/features/support/services/endpoints';

export const TECHNICAL_SUPPORT_SERVICE_ID = Symbol('supportFormService');

/**
 * @see http://dev.api.portal.gt.local/technical-support/swagger-ui.html
 */

 @Injectable()
export class TechnicalSupportService {
  private api: HttpService;

  constructor(
    @Inject(SUPPORT_HTTP_SERVICE_ID) api: HttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
  ) {
    this.api = api;
    this.api.remoteClient.defaults.baseURL = config.technicalSupportHost;
  }

  /**
   * Отправка обращения в службу поддержки
   *
   */
  createSupportRequest(data: FormData): Promise<{ key: string }> {
    return this.api.request({
      method: 'POST',
      url: API_POST_ISSUES,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  }

  fetchBannersList(params?: BannerListParams): Promise<Banner[]> {
    return this.api.request({
      method: 'GET',
      url: API_GET_BANNERS,
      params,
    });
  }

  changeBannerText(id: number, text: string): Promise<Banner> {
    return this.api.request({
      method: 'PUT',
      url: API_PUT_BANNERS_ID(id),
      data: { text },
    });
  }

  toggleBannerPublishStatus(id: number, status: boolean): Promise<Banner> {
    return this.api.request({
      method: 'POST',
      url: API_POST_BANNERS_ID_STATUS(id, status),
    });
  }
}

container
  .bind<TechnicalSupportService>(TECHNICAL_SUPPORT_SERVICE_ID)
  .to(TechnicalSupportService);
