import container, {
  Inject,
  Injectable,
} from '@/di';
import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import {
  TentTypes,
} from '@/features/srm/vehicles/services/VehicleService.types';
import {
  DictionaryModel,
} from '@/features/shared/types';

export const SRM_VEHICLE_SERVICE_ID = Symbol('SRMVehicleService');

@Injectable()
export default class SRMVehicleService {
  srmService: SRMService;

  constructor(
    @Inject(SRM_SERVICE_ID) srmService: SRMService,
  ) {
    this.srmService = srmService;
  }

  getTrailerTypes(): Promise<TentTypes> {
    const TENT_TYPES = [
      {
        caption: 'Все',
        value: null,
        id: 0,
      },
      {
        caption: 'Рефрежиратор',
        value: false,
        id: 1,
      },
      {
        caption: 'Тент',
        value: true,
        id: 2,
      },
    ];

    return Promise.resolve(TENT_TYPES);
  }

  getLoadingOperationTypes(): Promise<DictionaryModel[]> {
    const LOADING_OPERATION_TYPES = [
      {
        id: 1,
        name: 'Погрузка',
      },
      {
        id: 2,
        name: 'Разгрузка',
      },
    ];

    return Promise.resolve(LOADING_OPERATION_TYPES);
  }

  getOptionalEquipments(): Promise<DictionaryModel[]> {
    const OPTIONAL_EQUIPMENTS = [
      {
        id: 1,
        name: 'Коники',
      },
      {
        id: 2,
        name: 'Алюминиевая обрешетка',
      },
      {
        id: 4,
        name: 'Перегородка',
      },
      {
        id: 8,
        name: 'Два уровня пола',
      },
      {
        id: 16,
        name: 'С подогревом',
      },
      {
        id: 32,
        name: 'Обшит фанерой',
      },
      {
        id: 64,
        name: 'Без петель в полу',
      },
    ];

    return Promise.resolve(OPTIONAL_EQUIPMENTS);
  }
}

container
  .bind<SRMVehicleService>(SRM_VEHICLE_SERVICE_ID)
  .to(SRMVehicleService);
