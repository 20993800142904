import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '@/abilities.types';
import { OrderModel } from '@/features/srm/orders/services/OrderService.types';
import { CarrierModel } from '@/features/srm/orgs/services/OrgService.types';
import { OrgStatuses } from '@/features/srm/orgs/constants';
import { ApprovingStatus, OrderStatus } from '@/features/shared/constant';
import { RelationType } from '@/features/srm/profile/constants';

import { AvailableEditUnitsForCustomer } from '@/features/srm/constants';

// Правила которые применяются для всех пользователей имеющих хотя бы 1 организацию
function defineBasicOrgAbilities(builder: AbilityBuilder<AppAbility>) {
  const { can } = builder;

  can('read', 'Org');
  can('update', 'Org');

  can('create', 'OrgDocument');
  can('delete', 'OrgDocument');
  can('loadExpeditors', 'OrgTabs');
  can('read', 'Orgs');

  can<CarrierModel>('create', 'Carrier', {
    statusId: {
      $nin: [ApprovingStatus.NotCheckedPortal],
    },
  });
  can('update', 'Vehicle');
  can('read', 'Vehicle');
  can('delete', 'Vehicle');
  can('approve', 'Vehicle', {
    status: {
      $eq: ApprovingStatus.Suspended,
    },
  });

  can('update', 'Driver');
  can('read', 'Driver');
  can('delete', 'Driver');
  can('approve', 'Driver', {
    statusId: {
      $eq: ApprovingStatus.Suspended,
    },
  });
}

function defineBasicOrderAbilities(builder: AbilityBuilder<AppAbility>) {
  const { can, cannot } = builder;

  can('read', 'Order');

  can('showButtonReserver', 'Order');

  can<OrderModel>('viewApprovingProcess', 'Order', {
    statusId: {
      $nin: [OrderStatus.Reserved, OrderStatus.ReservedWithCarrier],
    },
    carrierContract: {
      $nin: [true],
    },
  });

  can<OrderModel>('showInsurance', 'Order', {
    statusId: {
      $in: [OrderStatus.OnApprove, OrderStatus.Execution],
    },
  });

  can<OrderModel>('dislike', 'Order', {
    statusId: {
      $in: [
        OrderStatus.New,
        OrderStatus.OnApprove,
        OrderStatus.Reserved,
        OrderStatus.ReservedWithCarrier,
        OrderStatus.ReadyForExecution,
        OrderStatus.WaitingForApprove,
      ],
    },
  });

  can<OrderModel>('copy', 'Order', {
    statusId: {
      $nin: [
        OrderStatus.New,
        OrderStatus.UnknownStatus,
        OrderStatus.UnknownStatus,
        OrderStatus.Reserved,
      ],
    },
  });

  can<OrderModel>('print', 'Order', {
    statusId: {
      $nin: [
        OrderStatus.New,
        OrderStatus.UnknownStatus,
        OrderStatus.Auction,
        OrderStatus.AuctionOpen,
        OrderStatus.BiddingCompleting,
        OrderStatus.OfferCollection,
        OrderStatus.OfferOpen,
        OrderStatus.OfferCompleting,
        OrderStatus.Reserved,
        OrderStatus.ReservedWithCarrier,
      ],
    },
  });

  can<OrderModel>('accept', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved],
    },
  });

  can<OrderModel>('decline', 'Order', {
    statusId: {
      $in: [
        OrderStatus.OnApprove,
        OrderStatus.WaitingForApprove,
        OrderStatus.Execution,
        OrderStatus.ReadyForExecution,
      ],
    },
  });

  can<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });

  can<OrderModel>('showActualizeButton', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });

  can('viewOrder', 'Order');

  can('viewOrderHistory', 'Order');

  can('viewContract', 'Order');

  can('loadDefaultEmail', 'Order');

  can('editOrderNds', 'Order', {
    statusId: {
      $ne: OrderStatus.Execution,
    },
  });

  can('read', 'OrderInternalNumber');

  can('ShowOrderPrice', 'Order');

  can('edit', 'OrderWarrantyNumber');

  can('getOrderDocument', 'Order');

  can('viewVisibilityFilter', 'OrderNumberFilter');

  cannot('showLogistOrdersPageTitle', 'Orders');

  can('replaceStatusInFilterOrder', 'Order');

  can('viewNewStatusModel', 'OrderDateManagement');

  can<OrderModel>('upload-documents', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingPayment,
        OrderStatus.WaitingDocs,
        OrderStatus.SentPayment,
      ],
    },
  });
  can<OrderModel>('viewDocumentStatusIcon', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingPayment,
        OrderStatus.WaitingDocs,
        OrderStatus.SentPayment,
      ],
    },
  });

  can('showGridDriverTooltip', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingDocs,
        OrderStatus.WaitingPayment,
        OrderStatus.SentPayment,
      ],
    },
  });
}
// МПП
export function defineAbilitiesForAttractManager(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  defineBasicOrgAbilities(builder);

  can('showUsers', 'Users');

  can('showButtonReport', 'AppSidebarExpedition');

  can('loadOrgsPage', 'Orgs');

  can('showSubscriptionButton', 'Users');

  can('create', 'Org');

  can('read', 'OrgReport');
  can('showButtonReserverForCarrier', 'OrderStatusCell');

  can('showStatus', 'Orders');

  can('selectedCarrier', 'OrderAccept');

  can('read', 'OrgApprovingProcess');
  can('create', 'OrgApprovingProcess');

  can('read', 'OrgStatusHistory');

  can('showIconUsers', 'AppSidebarExpedition');

  can('showGroupPrice', 'OrderAccept');

  can('showFreightPrice', 'OrderAccept');

  can('showGroupPrice', 'Order');

  can('showFreightPrice', 'Order');

  can('read', 'OrderPrice');

  can('showManagerFilter', 'Orders');

  can('useSupport', 'AppLayout');

  // Права на левый сайд-бар
  can('showButtonOrg', 'AppSidebarExpedition');

  can('showApprovingProcess', 'OrgSideMenu');

  can('edit', 'OrgContainer');

  can('viewVisibilityFilter', 'OrderNumberFilter');

  can('showAddOrgButton', 'Orgs');

  can('read', 'AppSidebarSubscriptions');

  can('use', 'TruckOfferingForMEP');

  can('canEditRequisites', 'OrgEditForm');
}

export function defineAbilitiesSRMAdmin(builder: AbilityBuilder<AppAbility>): void {
  const { can, cannot } = builder;

  defineBasicOrgAbilities(builder);
  defineBasicOrderAbilities(builder);

  can('loadOrgsPage', 'Orgs');

  cannot('forMEP', 'Order');

  can('showButtonOrg', 'AppSidebarExpedition');
  can('showButtonOrders', 'AppSidebarExpedition');

  can('use', 'UsersMailing');

  can('showLogistFilter', 'Orders');

  can('showManagerFilter', 'Orders');

  can('showTabMyNew', 'Orders');

  can('filterByCarrier', 'Orders');

  can('showIconUsers', 'AppSidebarExpedition');

  can('showUsers', 'Users');

  can('showEditUsersButton', 'Users');

  cannot('showAddOrgButton', 'Orgs');

  can('showIconUsers', 'AppSidebarExpedition');

  can('showUsers', 'Users');

  can('canEditRequisites', 'OrgEditForm');
}

export function defineAbilitiesSRMAllOrders(builder: AbilityBuilder<AppAbility>): void {
  const { cannot } = builder;

  defineBasicOrderAbilities(builder);

  cannot('forMEP', 'Order');
}

export function defineAbilitiesForChief(builder: AbilityBuilder<AppAbility>): void {
  const { can, cannot } = builder;

  defineBasicOrgAbilities(builder);
  defineBasicOrderAbilities(builder);
  defineAbilitiesForExpeditionManager(builder);

  can('showLogistFilter', 'Orders');

  can('showUsers', 'Users');

  can('showIconUsers', 'AppSidebarExpedition');

  can('showApprovingProcess', 'OrgSideMenu');

  can('changeOMAP', 'OrgEditForm');

  cannot('forMEP', 'Order');

  can('showSubscriptionButton', 'Users');

  can('showButtonOrg', 'AppSidebarExpedition');

  can<OrderModel>('viewOrderResponsibleLogist', 'Order', {
    statusId: {
      $in: [OrderStatus.New],
    },
  });

  can('enter', 'AverageRates');

  can('showAddOrgButton', 'Orgs');
}

// Менеджер Экспедиции
export function defineAbilitiesForExpeditionManager(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  defineBasicOrgAbilities(builder);
  defineBasicOrderAbilities(builder);

  can('importantDocumentIconTitle', 'Order');

  can('showButtonReserverForCarrier', 'OrderStatusCell');

  can('create', 'Org');

  can('canEditRequisites', 'OrgEditForm');

  can('showModalForFilling', 'Order');

  can('showButtonReport', 'AppSidebarExpedition');

  can('edit', 'OrgContainer');

  can('showCustomer', 'OrderPreview');

  can('read', 'OrgApprovingProcess');

  can('create', 'OrgApprovingProcess');

  can('showSubscriptionButton', 'Users');

  can('showButtonSendToApproving', 'OrgApprovingProcess', {
    'carrier.status.id': {
      $in: [
        ApprovingStatus.New,
        ApprovingStatus.NotCheckedPortal,
        ApprovingStatus.Rejected,
        ApprovingStatus.Suspended,
      ],
    },
  });

  can('showButtonCancel', 'OrgApprovingProcess', {
    'carrier.status.id': {
      $in: [
        ApprovingStatus.New,
        ApprovingStatus.NotCheckedPortal,
      ],
    },
  });

  can('showStatus', 'Orders');

  can('read', 'OrgStatusHistory');

  can('read', 'OrderRecommendations');

  can('showGroupPrice', 'OrderAccept');

  can('showFreightPrice', 'OrderAccept');

  can('selectedCarrier', 'OrderAccept');

  can('filterByCarrier', 'Orders');

  can('showTabMyNew', 'Orders');

  can('showGroupPrice', 'Order');

  can('showFreightPrice', 'Order');

  can('read', 'OrderPrice');

  can('select-reason', 'OrderCancel');

  can('showAddress', 'Order');

  can('edit', 'Contract');

  can('create', 'Warranty');

  can('download', 'OrderAuctionHistory');

  can('download', 'OrderRequestHistory');

  can('selectedCarrier', 'WarrantyReports');

  can('penaltyFixation', 'GuaranteeOrderCancel');

  can('showUpdatePrice', 'OrderAccept');

  can('showManagerFilter', 'Orders');

  can('showStatus', 'OrdersFilter');

  can('showAdditionalConditions', 'OrderEdit');

  can('showAdditionalConditions', 'OrderHistoryChanges');

  can('useSupport', 'AppLayout');

  // Права на левый сайд-бар
  can('showButtonOrders', 'AppSidebarExpedition');
  can('showButtonOrg', 'AppSidebarExpedition');

  can('showApprovingProcess', 'OrgSideMenu');

  can('showUnapprovedEntities', 'Order', {
    statusId: {
      $in: [OrderStatus.OnApprove, OrderStatus.New],
    },
  });

  can<OrderModel>('reserve', 'Order', {
    statusId: {
      $in: [OrderStatus.New],
    },
  });

  can<OrderModel>('unreserved', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved, OrderStatus.ReservedWithCarrier, OrderStatus.ApproveCanceled],
    },
  });

  can<OrderModel>('reserveWithCarrier', 'Order', {
    statusId: {
      $in: [OrderStatus.New],
    },
  });

  can<OrderModel>('update', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.OnApprove, OrderStatus.ApproveCanceled, OrderStatus.WaitingDocs],
    },
    crmId: {
      $nin: [null],
    },
  });

  can<OrderModel>('create', 'Order', {
    statusId: {
      $in: [OrderStatus.ReadyForExecution],
    },
  });

  can<OrderModel>('approve', 'Order', {
    statusId: {
      $in: [OrderStatus.WaitingForApprove],
    },
  });

  can<OrderModel>('share', 'Order', {
    statusId: {
      $nin: [OrderStatus.UnknownStatus, OrderStatus.New, OrderStatus.Reserved],
    },
  });

  can('forMEP', 'Order');
  can('showExpeditionInAppMenu', 'AppMenu');
  can('forMEP', 'AppMenu');
  can<OrderModel>('upload-documents', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingPayment,
        OrderStatus.WaitingDocs,
        OrderStatus.SentPayment,
      ],
    },
  });
  can<OrderModel>('viewDocumentStatusIcon', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingPayment,
        OrderStatus.WaitingDocs,
        OrderStatus.SentPayment,
      ],
    },
  });

  can('use', 'TruckOfferingForMEP');

  can('showAddOrgButton', 'Orgs');

  can('read', 'AppSidebarSubscriptions');

  can('loadOrgsPage', 'Orgs');
}

// ПП
export function defineAbilitiesForCarrierUser(builder: AbilityBuilder<AppAbility>): void {
  const { can, cannot } = builder;

  defineBasicOrgAbilities(builder);
  defineBasicOrderAbilities(builder);

  can('sms', 'OrderAccept');

  can('placeBet', 'OrderAuction');

  can('placeBet', 'OrderRequest');

  can('showContactOmap', 'OrderPreview');

  // Права на левый сайд-бар
  can('showButtonOrders', 'AppSidebarExpedition');
  can('showButtonOrg', 'AppSidebarExpedition');

  can('showModalForFilling', 'Order');
  can('showModalForFilling', 'OrderAccept');

  can('showMessageSendToApproving', 'OrgDocuments');
  can('reservedForPP', 'Orders');

  can('useSupport', 'AppLayout');

  can<OrderModel>('hiddenCancelButton', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.AuctionOpen],
    },
  });
  can<OrderModel>('reserve', 'Order', {
    statusId: {
      $in: [OrderStatus.New, OrderStatus.BiddingCompleting],
    },
    counterpartyRelationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });
  can<OrderModel>('unreserved', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved, OrderStatus.ReservedWithCarrier],
    },
    counterpartyRelationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });
  can<OrderModel>('accept', 'Order', {
    statusId: {
      $in: [OrderStatus.ReservedWithCarrier],
    },
    counterpartyRelationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });

  can('enter', 'CarrierRequestsTab');

  can('enter', 'FuelTab');

  can('read', 'CarrierInstruction');

  can('read', 'CarrierGreeting');

  can('read', 'PartnerProjectBanner');

  can('notShowCarrierStatusPP', 'OrgHeader');

  can('use', 'FeedbackOrders');

  cannot('showButtonReserverForCarrier', 'OrderStatusCell');

  can('use', 'FeedbackComplaints');

  can('use', 'Feedback');

  can('loadFirstCounterparty', 'Orgs');

  cannot('loadExpeditors', 'OrgTabs');

  cannot<OrderModel>('print', 'Order', {
    statusId: {
      $nin: [
        OrderStatus.New,
        OrderStatus.UnknownStatus,
        OrderStatus.Auction,
        OrderStatus.AuctionOpen,
        OrderStatus.BiddingCompleting,
        OrderStatus.OfferCollection,
        OrderStatus.OfferOpen,
        OrderStatus.OfferCompleting,
        OrderStatus.Reserved,
        OrderStatus.ReservedWithCarrier,
      ],
    },
  });
  can('print', 'Order', {
    statusId: {
      $nin: [
        OrderStatus.WaitingForApprove,
        OrderStatus.ReadyForExecution,
        OrderStatus.OnApprove,
        OrderStatus.Waiting,
        OrderStatus.New,
        OrderStatus.UnknownStatus,
        OrderStatus.Auction,
        OrderStatus.AuctionOpen,
        OrderStatus.BiddingCompleting,
        OrderStatus.OfferCollection,
        OrderStatus.OfferOpen,
        OrderStatus.OfferCompleting,
        OrderStatus.Reserved,
        OrderStatus.ReservedWithCarrier,
      ],
    },
    counterpartyRelationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });

  cannot('viewOrderHistory', 'Order');

  cannot('viewContract', 'Order');

  cannot('viewOrder', 'Order', {
    isCurrent: {
      $ne: true,
    },
  });

  cannot('loadDefaultEmail', 'Order');

  can('select', 'OrderPayType');

  can('historyStatusName', 'OrderHistory');

  cannot('read', 'Orgs');

  cannot('read', 'OrderInternalNumber');

  cannot('edit', 'OrderWarrantyNumber');

  can('read', 'CarrierUserDetails');

  can('prefill', 'OrgForm');

  can('read', 'OrgOnboarding');

  can('read', 'OrderCard');

  can('read', 'OrderCardForwarder');

  cannot<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });
  can<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution],
      $nin: [OrderStatus.WaitingDocs],
    },
    counterpartyRelationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });

  cannot('viewVisibilityFilter', 'OrderNumberFilter');

  can('read', 'FuelAdvanceForm');

  can('read', 'FastSubscription');

  can('read', 'OrgProfile');

  can<CarrierModel>('redirectToOrgDocs', 'CarrierModel', {
    statusId: {
      $in: [
        OrgStatuses.NotChecked,
      ],
    },
  });

  can('use', 'RedesignedSrm');

  can('checkOrgBeforeSendApprove', 'OrgDocuments');

  can<CarrierModel>('editDocuments', 'CarrierModel', {
    statusId: {
      $in: [
        OrgStatuses.Approved,
      ],
    },
  });

  can('send', 'JoiningRequestToCarrier');

  can('showModalErrorAttracs', 'Orders');

  can('applyOrderRouteFilter', 'OrdersFilter');

  cannot('edit', 'SystemNotificationsSettings');

  can('replace', 'OrderStatus');

  cannot('replaceStatusInFilterOrder', 'Order');

  cannot('enter', 'AboutTab');

  cannot('read', 'AppSidebarVersion');

  can('read', 'AppSidebarSubscriptions');

  can('use', 'OrdersPlanning');

  can('read', 'TruckOfferingTooltip');

  can('read', 'orderReservePrompt');

  can('read', 'OrdersPlanningTooltip');

  can('read', 'OrgSearchInTable');

  can('replace', 'OrgPageTitle');

  can('showMyOfficeInAppMenu', 'AppMenu');

  can('read', 'AtiRatesDivergence');

  cannot('viewNewStatusModel', 'OrderDateManagement');

  can('use', 'TruckOfferingForCarrier');

  can('read', 'CarrierOrdersFilterTooltip');
  can('read', 'CarrierOrderTabTooltip');

  can('viewLoyalty', 'OrgTabs');

  can('showAddOrgButton', 'Orgs');

  can('use', 'RedesignedToast');

  can('use', 'FavoriteLocations');

  can('use', 'TalkMe');

  can('read', 'OrdersForCarrier');

  can('use', 'VerifyPhone');

  can('use', 'VerifyEmail');

  can('viewLoyalty', 'AppHeaderCarrierUser', {
    relationTypeId: {
      $nin: [RelationType.CarrierEmployeeRequest],
    },
  });

  can('carrierEditUnits', 'Order', {
    statusId: {
      $in: [
        OrderStatus.Execution,
        OrderStatus.WaitingForApprove,
        OrderStatus.ReadyForExecution,
      ],
    },
    orderContract: {
      $eq: false,
    },
    customerId: {
      $in: [
        AvailableEditUnitsForCustomer.Sibur,
        AvailableEditUnitsForCustomer.TimeTrade,
      ],
    },
  });
}

export function definedAbilitiesForLogistUser(builder: AbilityBuilder<AppAbility>): void {
  const { can, cannot } = builder;
  can('viewOrder', 'Order');

  can('showButtonOrders', 'AppSidebarExpedition');

  can('showTabMyNew', 'Orders');

  can('enter', 'CarrierRequestsTab');

  can('viewOrder', 'Order');

  can('showButtonReport', 'AppSidebarExpedition');

  can('createOrderLogist', 'Order');

  can('select-reason', 'OrderCancel');

  cannot('ShowOrderPrice', 'Order');

  can('showFreightPrice', 'Order');

  can('showGroupPrice', 'Order');

  can('showModalForFilling', 'Order');

  cannot('showButtonReserverForCarrier', 'OrderStatusCell');

  cannot('getOrderDocument', 'Order');

  can('useSupport', 'AppLayout');

  can('showAddress', 'Order');

  can<OrderModel>('accept', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved],
    },
  });

  can<OrderModel>('vehicleAssign', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved],
    },
    forSts: {
      $nin: [null, false],
    },
  });

  cannot<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution],
    },
  });

  cannot<OrderModel>('showActualizeButton', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution],
    },
  });

  can('hiddenAuctionTab', 'Orders');

  can<OrderModel>('update', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
    crmId: {
      $nin: [null],
    },
  });

  can<OrderModel>('reserve', 'Order', {
    statusId: {
      $in: [OrderStatus.New],
    },
  });

  can<OrderModel>('unreserved', 'Order', {
    statusId: {
      $in: [OrderStatus.Reserved],
    },
  });

  can<OrderModel>('decline', 'Order', {
    statusId: {
      $in: [
        OrderStatus.OnApprove,
        OrderStatus.WaitingForApprove,
        OrderStatus.Execution,
        OrderStatus.ReadyForExecution,
      ],
    },
  });

  can<OrderModel>('create', 'Order', {
    statusId: {
      $in: [OrderStatus.ReadyForExecution],
    },
  });

  can('filterByCarrier', 'Orders');

  can('viewContract', 'Order');

  can('viewVisibilityFilter', 'OrderNumberFilter');

  can('showLogistOrdersPageTitle', 'Orders');
}

export function definedAbilitiesFoChiefLogistUser(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;
  definedAbilitiesForLogistUser(builder);

  can<OrderModel>('viewOrderResponsibleLogist', 'Order', {
    statusId: {
      $in: [OrderStatus.New],
    },
  });

  can('showLogistFilter', 'Orders');

  can('reserveWithSTS', 'Order');
}

export function defineAbilitiesForSalesUser(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  can('showSubscriptionButton', 'Users');

  can('editStatusMPP', 'Users');

  can('showLogistFilter', 'Orders');

  can('canEditRequisites', 'OrgEditForm');
}

export function defineAbilitiesForDispatcher(builder: AbilityBuilder<AppAbility>): void {
  const { can, cannot } = builder;

  can('showButtonOrders', 'AppSidebarExpedition');
  can('viewOrder', 'Order');
  can('showButtonReport', 'AppSidebarExpedition');
  can('showButtonOrg', 'AppSidebarExpedition');
  can('read', 'Orgs');
  can('loadOrgsPage', 'Orgs');
  can('showAddOrgButton', 'Orgs');
  can('edit', 'OrgContainer');
  cannot('showButtonReserverForCarrier', 'OrderStatusCell');
  cannot('reserve', 'Order');

  can<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });

  can<OrderModel>('actualize', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });

  can('filterByCarrier', 'Orders');

  can('loadExpeditors', 'OrgTabs');

  can('showUsers', 'Users');

  can<OrderModel>('showActualizeButton', 'Order', {
    statusId: {
      $in: [OrderStatus.Execution, OrderStatus.WaitingDocs],
    },
  });
}
