import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_modal_confirm = _resolveComponent("ui-modal-confirm")!

  return (_openBlock(), _createBlock(_component_ui_modal_confirm, {
    header: "Вы уверены, что хотите выйти?",
    "accept-label": "Выйти",
    onAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('accept'))),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }))
}