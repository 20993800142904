
import { defineComponent } from 'vue';
import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import useOrgsJoiningRequests from '@/features/srm/orgs/composable/ogrs-joining-requests';
import { formatPhone } from '@/filters/phone';

export default defineComponent({
  name: 'OrgJoiningRequestModal',

  components: {
    UiModalWithContent,
    SvgIcon,
    UiButton,
  },

  setup() {
    const {
      carrierName,
      MPPName,
      MPPPhoneHref,
      MPPPhone,
    } = useOrgsJoiningRequests();

    return {
      formatPhone,
      carrierName,
      MPPName,
      MPPPhoneHref,
      MPPPhone,
    };
  },
});
