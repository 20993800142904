import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';
import {
  API_FEEDBACK,
  API_FEEDBACK_ORDERS_NOT_FOUND,
  API_FEEDBACK_COMPLAINTS,
  API_DOWNLOAD_ORDER_TEMPLATE,
  API_DOWNLOAD_CONTRACT_TEMPLATE,
  API_DOWNLOAD_FUNC_CHARACTERISTICS,
  API_DOWNLOAD_PRIVACY_POLICY,
  API_DOWNLOAD_TECH_SUPPORT,
} from '@/features/srm/carriers-communication/services/endpoints';
import {
  SendFeedbackRequestModel,
  SendFeedbackOrdersNotFoundRequestModel,
  SendFeedbackComplaintsRequestModel,
} from '@/features/srm/carriers-communication/services/types';

export const SRM_CARRIERS_COMMUNICATION_SERVICE_ID = Symbol('carriersCommunicationService');

@Register(SRM_CARRIERS_COMMUNICATION_SERVICE_ID, [CONFIG_SERVICE_ID])
export class CarriersCommunicationService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.carriersCommunicationServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  sendEmailForFeedbackOrdersNotFound(feedback: SendFeedbackOrdersNotFoundRequestModel): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_FEEDBACK_ORDERS_NOT_FOUND,
      data: feedback,
    });
  }

  sendEmailForFeedback(feedback: SendFeedbackRequestModel): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_FEEDBACK,
      data: feedback,
    });
  }

  sendEmailFeedbackComplaints(data: SendFeedbackComplaintsRequestModel): Promise<void> {
    return this.request({
      method: 'POST',
      url: API_FEEDBACK_COMPLAINTS,
      data,
    });
  }

  downloadOrderTemplate(): Promise<Blob> {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_ORDER_TEMPLATE,
      responseType: 'blob',
    });
  }

  downloadContractTemplate(): Promise<Blob> {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_CONTRACT_TEMPLATE,
      responseType: 'blob',
    });
  }

  downloadFuncCharacteristics(): Promise<Blob> {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_FUNC_CHARACTERISTICS,
      responseType: 'blob',
    });
  }

  downloadPrivacyPolicy(): Promise<Blob> {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_PRIVACY_POLICY,
      responseType: 'blob',
    });
  }

  downloadTechSupport(): Promise<Blob> {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_TECH_SUPPORT,
      responseType: 'blob',
    });
  }
}
