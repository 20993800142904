import container, {
  Inject,
  Injectable,
} from '@/di';
import SopHttpService, {
  SOP_SERVICE_ID,
} from '@/features/sop/services/SOPService';
import ConfigService, {
  CONFIG_SERVICE_ID,
} from '@/features/shared/services/config';
import {
  Company,
  ManagerListDTO,
} from '@/features/sop/types';
import { Page } from '@/features/shared/types';
import API from '@/features/sop/services/endpoints';
import { Individual } from '@/features/crm/types';

export const SOP_DICTIONARIES_SERVICE_ID = Symbol('SOPDictionariesService');

@Injectable()
export default class SOPDictionariesService {
  private sopService: SopHttpService;

  constructor(
    @Inject(SOP_SERVICE_ID) sopService: SopHttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
  ) {
    this.sopService = sopService;
    this.sopService.remoteClient.defaults.baseURL = config.CRMDictionaryServiceHost;
  }

  /**
   * Список компаний
   * @return Promise<Company[]>
   */
  getCompanies(): Promise<Company[]> {
    return this.sopService.request({
      method: 'GET',
      url: API.COMPANY_LIST,
    });
  }

  getManagerList(params: ManagerListDTO): Promise<Page<Individual>> {
    return this.sopService.request({
      method: 'GET',
      url: API.MANAGERS_LIST,
      params,
    });
  }
}

container
  .bind<SOPDictionariesService>(SOP_DICTIONARIES_SERVICE_ID)
  .to(SOPDictionariesService);
