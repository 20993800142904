import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feedback_form_group = _resolveComponent("feedback-form-group")!

  return (_openBlock(), _createBlock(_component_feedback_form_group, {
    "is-loading": $data.isLoading,
    "show-close-button": $props.showCloseButton,
    onSubmit: $options.sendFeedback,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    "onFocus:textArea": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus:text-area')))
  }, null, 8, ["is-loading", "show-close-button", "onSubmit"]))
}