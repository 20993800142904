export const API_CLIENT_LIST = '/fuel-clients/paged';
export const API_CLIENTS_BY_ID = (clientId: string): string => `/clients/${clientId}`;
export const API_CLIENT_NAMES = '/fuel-clients';
export const API_STATISTIC_BRIEFLY = (clientId: number): string => `/statistic/briefly/${clientId}`;
export const API_CLIENTS_EDIT = '/clients/edit';
export const API_CLIENT_EMPLOYEE = '/fuel-clients/employees';
export const API_CLIENT_EMPLOYEE_BY_ID = (employeeId: number): string => `/fuel-clients/employees/${employeeId}`;
export const API_CLIENT_EMPLOYEES_BY_CLIENT_ID = (clientId: number): string => `/fuel-clients/${clientId}/employees`;
export const API_DIRECTORY_COUNTRY = '/directory/country';
export const API_CLIENTS_BALANCE_REPL = '/clients/balance/repl';
export const API_IS_CLIENT_WITH_INN_EXISTS = (inn: string): string => `/clients/inn/${inn}`;
export const API_CURRENT_CLIENT = '/current-user';
export const API_CLIENT_MIN_BALANCE = (clientId: number): string => `/fuel-clients/${clientId}/minimum-balance/`;
export const API_CLIENT_MONEY_SHORTAGE_WARNING_LIMIT = (
  clientId: number,
): string => `/fuel-clients/${clientId}/money-shortage-warning-limit/`;
export const API_NOTIFICATION_RECIPIENTS = '/notification-recipients';
export const API_NOTIFICATION_RECIPIENT = (recipientId: number): string => `/notification-recipients/${recipientId}`;
export const API_NOTIFICATION_RECIPIENT_CREATE = '/notification-recipients/create';
export const API_NOTIFICATION_RECIPIENT_EDIT = (recipientId: number): string => `/notification-recipients/${recipientId}/edit`;
export const API_NOTIFICATION_RECIPIENT_ADD_NOTIFICATION_TYPE = (
  recipientId: number,
): string => `/notification-recipients/${recipientId}/add-notification-type`;
export const API_NOTIFICATION_RECIPIENT_DELETE_NOTIFICATION_TYPE = (
  recipientId: number,
): string => `/notification-recipients/${recipientId}/delete-notification-type`;

export const API_DIRECTORY_DICTIONARY = (dictionary: string): string => `/directory/${dictionary}`;
export const API_DIRECTORY_DICTIONARY_BY_VINK = (
  dictionary: string,
  vinkId: number,
): string => `/directory/${dictionary}/${vinkId}`;

export const API_FUEL_REPORT_EXCEL = '/fuel-report/excel';
export const API_FUEL_REPORT_CASHBACK = '/fuel-report/cashback';
