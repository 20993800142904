import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SRM_NEW_ORDER_SERVICE_ID = Symbol('srmNewOrderService');

@Register(
  SRM_NEW_ORDER_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMNewOrderService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMOrderHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMNewOrderService;
