import { CountriesNames } from '@/features/shared/constant';
import { Nullable } from '@/features/shared/types';

export interface TableSizeSelectOption {
  title: string;
  number: number;
}

export interface Row {
  id: number | string;
  aggregateId: string;
}

export interface TableHeader {
  key: string;
  title: string;
  subTitle?: string;
  isSingleLine?: boolean;
  isStickyLeft?: boolean;
  isStickyRight?: boolean;
  isTopAligned?: boolean;
}

export interface DatepickerMomentFormat {
 getWeek: (value: Date) => number;
 parse: (value: Date) => Nullable<Date>;
}

export interface CountryOption {
  name: string;
  code: CountriesNames;
  prefix: string;
  icon: string;
}

export enum HandlerType {
  SUCCESS = 'success',
  ERROR = 'error',
}
