
import dayjs from 'dayjs';
import { PropType } from 'vue';
import {
  email,
  required,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { useVuelidate } from '@vuelidate/core';
import container from '@/di';
import { getHTTPError } from '@/utils/errors';

import SRMReportService, { SRM_REPORT_SERVICE_ID } from '@/features/srm/reports/services/SRMReportService';

import UiButton from '@/features/shared/kit/UiButton.vue';
import UiFormGroup from '@/features/shared/kit/UiFormGroup.vue';
import UiDatepicker from '@/features/shared/kit/UiDatepicker.vue';
import UiModalWithContent from '@/features/shared/kit/UiModalWithContent.vue';
import UiInputText from '@/features/shared/kit/UiInputText.vue';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import {
  Nullable,
  ValidationSchema,
} from '@/features/shared/types';
import { FastPayReportResponse } from '@/features/srm/reports/types';

export default {
  name: 'SrmReportFastPay',
  components: {
    UiButton,
    UiDatepicker,
    UiModalWithContent,
    UiFormGroup,
    UiInputText,
  },

  props: {
    isFastPay: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    return {
      toast: useToast(),
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      period: null as Nullable<Date[]>,
      loading: false,
      email: '' as Nullable<string>,
    };
  },

  computed: {
    userModule(): SRMUserModule {
      return container.get(SRM_USER_MODULE_ID);
    },

    reportService(): SRMReportService {
      return container.get(SRM_REPORT_SERVICE_ID);
    },

    reportType(): string {
      return this.isFastPay
        ? 'Сформировать отчет по быстрой оплате'
        : 'Сформировать отчет по причинам невзятия заявки';
    },
  },

  validations(): ValidationSchema {
    return {
      period: { required },
      email: {
        required,
        email,
      },
    };
  },

  mounted() {
    this.email = this.userModule.user?.email ?? null;
  },

  methods: {
    onChangeDate(date: Date[]) {
      this.period = date[0] ? date : null;
    },

    async confirm() {
      this.v$.$touch();

      if (this.v$.$invalid || !this.period) return;

      this.loading = true;
      let content: string | FastPayReportResponse;
      try {
        content = this.isFastPay ? await this.reportService.getReportFastPay({
          from: dayjs(this.period[0]).format('YYYY-MM-DDT00:00:00'),
          to: dayjs(this.period[1]).format('YYYY-MM-DDT23:59:59'),
          email: this.email,
        }) : await this.reportService.getReportReasonsRejection({
          from: dayjs(this.period[0]).format('YYYY-MM-DDT00:00:00'),
          to: dayjs(this.period[1]).format('YYYY-MM-DDT23:59:59'),
        });

        if ((content as FastPayReportResponse)?.message) {
          this.toast.success((content as FastPayReportResponse).message);
          this.$emit('close');
        } else {
          this.toast.error('За данный период отчетов не найдено');
        }
      } catch (e) {
        this.toast.error(getHTTPError(e));
      }

      this.loading = false;
    },
  },
};
