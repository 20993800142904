export enum FederalDistricts {
  'Unknown',
  'Central',
  'NorthWest',
  'South',
  'NorthCaucasus',
  'Volga',
  'Ural',
  'Siberian',
  'FarEast',
}

export enum LocationObjectType {
  Unknown,
  Location,
  City,
  Region,
  FederalDistrict,
}
