import RemoteService from '@/features/shared/services/base/remote';
import {
  Register,
} from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/srm/services/SRMService.interceptors';
import { ORDER_CARD_BY_ID } from '@/features/srm/services/endpoints';
import { OrderCardResponse } from '@/features/srm/orders/services/OrderService.types';

export const SRM_API_COMPOSER_SERVICE_ID = Symbol('srmApiComposerService');

@Register(SRM_API_COMPOSER_SERVICE_ID, [CONFIG_SERVICE_ID])
export class SRMApiComposerService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.SRMApiComposerServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  getOrderCard(orderId: string): Promise<OrderCardResponse> {
    return this.request({
      method: 'GET',
      url: ORDER_CARD_BY_ID(orderId),
    });
  }
}
