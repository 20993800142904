import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import { STORE_ID } from '@/store';
import ClientMapper from '@/features/fuel/clients/mappers/ClientMapper';
import ReplenishmentMapper from '@/features/fuel/shared/mappers/ReplenishmentMapper';
import {
  ClientDirectory,
  ClientDTO,
  ClientLoadingParams,
  ClientModel,
  FuelReportParams,
  CreateNotificationRecipientParams,
  EmployeeModel,
  FuelClientDTO,
  NotificationRecipientDTO,
  SetClientMinBalanceParams,
  SetClientMinBalanceResponse,
  SetMoneyShortageWarningLimitParams,
  UpdateNotificationRecipientParams,
  UpdateNotificationTypeParams,
} from '@/features/fuel/clients/types';
import OrganizationsService, { ORGANIZATIONS_SERVICE_ID } from '@/features/shared/services/organizations';
import {
  DictionaryModel,
  Nullable,
  Page,
} from '@/features/shared/types';
import {
  ReplenishmentModel,
  StatisticsModel,
} from '@/features/fuel/statistics/types';
import FuelClientService, { FUEL_CLIENT_SERVICE_ID } from '@/features/fuel/clients/services/ClientService';
import { SuggestionModel } from '@/features/shared/services/suggestions/types';
import SuggestionsService, { SUGGESTIONS_SERVICE_ID } from '@/features/shared/services/suggestions/suggestions';
import StatisticsMapper from '@/features/fuel/shared/mappers/StatisticsMapper';
import {
  mapDatePeriod,
  mapPageParams,
} from '@/filters/filter-params';
import { PageFilter } from '@/features/fuel/shared/types';
import FuelReportService, { FUEL_REPORT_SERVICE_ID } from '@/features/fuel/clients/services/ReportService';
import { downloadFile } from '@/utils/files';
import { formatDate } from '@/filters/date';
import { getCompanyCountersInitialState } from '@/features/fuel/shared/constants';

export const FUEL_CLIENT_MODULE_ID = 'fuel-client';

@Module
@Injectable()
export default class FuelClientModule extends VuexModule {
  @LazyInject(FUEL_CLIENT_SERVICE_ID) clientService: FuelClientService;

  @LazyInject(FUEL_REPORT_SERVICE_ID) reportService: FuelReportService;

  @LazyInject(ORGANIZATIONS_SERVICE_ID)
  organizationsService: OrganizationsService;

  @LazyInject(SUGGESTIONS_SERVICE_ID) suggestionsService: SuggestionsService;

  clientsPage: Page<ClientDTO> | null = null;

  client: ClientModel | null = null;

  employees: EmployeeModel[] = [];

  brieflyStatistics: StatisticsModel = {
    costs: getCompanyCountersInitialState(),
    refueled: getCompanyCountersInitialState(),
    currentBalance: 0,
    lastReplenishment: null,
  };

  countries: DictionaryModel[] = [];

  legalForms: DictionaryModel[] = [];

  constructor(@Inject(STORE_ID) store: Store<unknown>) {
    super({ name: FUEL_CLIENT_MODULE_ID, store });
  }

  @Mutation
  setCountries(countries: DictionaryModel[]): void {
    this.countries = countries;
  }

  @Mutation
  setClients(clientsPage: Page<ClientDTO>): void {
    this.clientsPage = clientsPage;
  }

  @Mutation
  setClient(client: ClientModel | null): void {
    this.client = client;
  }

  @Mutation
  setBrieflyStatistics(brieflyStatistics: StatisticsModel): void {
    this.brieflyStatistics = brieflyStatistics;
  }

  @Mutation
  setLegalForms(legalForms: DictionaryModel[]): void {
    this.legalForms = legalForms;
  }

  @Mutation
  setEmployees(employees: EmployeeModel[]): void {
    this.employees = employees;
    if (this.client) {
      this.client.employees = employees;
    }
  }

  @Action
  async loadClients(clientsParams: PageFilter): Promise<void> {
    const params = mapPageParams(clientsParams, 20);
    const res = await this.clientService.getClients(params);
    this.setClients(res);
  }

  @Action
  async loadClient({ isFuelAdmin, clientId }: ClientLoadingParams): Promise<void> {
    if (!isFuelAdmin || clientId) {
      const id = isFuelAdmin && clientId ? clientId.toString() : 'client';

      const response = await this.clientService.getClient(id);
      const client = ClientMapper.fromResponse(response);
      this.setClient(client);
    }
  }

  @Action
  async loadCurrentClient(): Promise<void> {
    await this.loadClient({ isFuelAdmin: false });
  }

  @Action
  async getCurrentClient(): Promise<FuelClientDTO> {
    return this.clientService.getCurrentClient();
  }

  @Action
  async loadBrieflyStatistics(clientId: number): Promise<void> {
    const response = await this.clientService.getBrieflyStatistics(clientId);
    const brieflyStatistics = StatisticsMapper.fromResponse(response);
    this.setBrieflyStatistics(brieflyStatistics);
  }

  @Action
  async loadCountries(): Promise<void> {
    const countries = await this.clientService.getCountries();
    this.setCountries(countries);
  }

  @Action
  async loadLegalForms(): Promise<void> {
    const legalForms = await this.organizationsService.getLegalForms();
    this.setLegalForms(legalForms);
  }

  @Action
  async loadEmployees(clientId?: Nullable<number>): Promise<void> {
    if (!clientId) {
      return;
    }

    const employees = await this.clientService.getEmployees(clientId);
    this.setEmployees(employees);
  }

  @Action
  async removeEmployee(employeeId: number | null): Promise<void> {
    if (!employeeId) {
      return;
    }

    await this.clientService.removeEmployee(employeeId);
  }

  @Action
  async saveEmployee(employee: EmployeeModel): Promise<EmployeeModel> {
    return this.clientService.saveEmployee(employee);
  }

  @Action
  async saveClient(client: ClientModel): Promise<number | null | undefined> {
    const request = ClientMapper.toRequest(client);
    const response = await this.clientService.saveClient(request);
    const updatedClient = ClientMapper.fromResponse(response);
    this.setClient(updatedClient);
    return updatedClient.id;
  }

  @Action
  async saveReplenishment(replenishment: ReplenishmentModel): Promise<void> {
    if (this.client?.id) {
      const request = ReplenishmentMapper.toRequest(replenishment);
      await this.clientService.saveReplenishment(request);
      await this.loadBrieflyStatistics(this.client.id);
    }
  }

  @Action
  async loadSuggestions(query: string): Promise<SuggestionModel[]> {
    const { suggestions } = await this.suggestionsService.getPartySuggestions({
      query,
    });
    return suggestions;
  }

  @Action
  async checkIsClientWithInnExists(inn: string): Promise<boolean> {
    return this.clientService.isClientWithInnExists(inn);
  }

  @Action
  async getClientNames(query: string): Promise<ClientDirectory[]> {
    return this.clientService.getClientNames(query);
  }

  @Action
  async setMinBalance(params: SetClientMinBalanceParams): Promise<SetClientMinBalanceResponse> {
    return this.clientService.setMinBalance(params);
  }

  @Action
  async getNotificationRecipients(): Promise<NotificationRecipientDTO[]> {
    return this.clientService.getNotificationRecipients();
  }

  @Action
  async createNotificationRecipient(params: CreateNotificationRecipientParams): Promise<NotificationRecipientDTO> {
    return this.clientService.createNotificationRecipient(params);
  }

  @Action
  async updateNotificationRecipient(params: UpdateNotificationRecipientParams): Promise<NotificationRecipientDTO> {
    return this.clientService.updateNotificationRecipient(params);
  }

  @Action
  async addNotificationType(params: UpdateNotificationTypeParams): Promise<NotificationRecipientDTO> {
    return this.clientService.addNotificationType(params);
  }

  @Action
  async deleteNotificationType(params: UpdateNotificationTypeParams): Promise<NotificationRecipientDTO> {
    return this.clientService.deleteNotificationType(params);
  }

  @Action
  async deleteNotificationRecipient(recipientId: number): Promise<void> {
    return this.clientService.deleteNotificationRecipient(recipientId);
  }

  @Action
  async setMoneyShortageWarningLimit(params: SetMoneyShortageWarningLimitParams): Promise<ClientDTO> {
    return this.clientService.setMoneyShortageWarningLimit(params);
  }

  @Action
  async downloadFuelReport(params: FuelReportParams): Promise<void> {
    const { fromDate, toDate } = mapDatePeriod(params.period);

    if (params.period && fromDate && toDate) {
      const report = await this.reportService.downloadFuelReport({
        fromDate,
        toDate,
        clientId: params.clientId,
        tariffPolicyId: params.tariffPolicyId,
      });
      downloadFile(report, `Итоговый отчет с ${formatDate(params.period[0])} по ${formatDate(params.period[1])}.xlsx`);
    }
  }

  @Action
  async getCashbackValue(params: FuelReportParams): Promise<Nullable<number>> {
    const { fromDate, toDate } = mapDatePeriod(params.period);

    if (params.period && fromDate && toDate) {
      return this.reportService.getCashbackValue({
        fromDate,
        toDate,
        clientId: params.clientId,
        tariffPolicyId: params.tariffPolicyId,
      });
    }

    return null;
  }
}

container.bind<FuelClientModule>(FUEL_CLIENT_MODULE_ID).to(FuelClientModule).inSingletonScope();
