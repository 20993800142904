import Keycloak, {
  KeycloakError,
  KeycloakPromise,
  KeycloakProfile,
  KeycloakTokenParsed,
} from 'keycloak-js';
import container, {
  Injectable,
  Inject,
} from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import TEST_GROUP_NAMES from '@/features/auth/constant';
import { Nullable, Undefinable } from '@/features/shared/types';

export const AUTH_SERVICE_ID = Symbol('authService');

@Injectable()
export default class AuthService {
  private client: Keycloak;

  getSysId1c(): string {
    return this.client.profile?.attributes?.sysId1c[0];
  }

  getToken(): string | undefined {
    return this.client.token;
  }

  getUserName(): string | undefined {
    return this.client.profile?.firstName || this.client.tokenParsed?.given_name;
  }

  getTokenParsed(): Undefinable<KeycloakTokenParsed> {
    return this.client.tokenParsed;
  }

  getUserLogin(): string | undefined {
    return this.client.profile?.username || this.client.tokenParsed?.preferred_username;
  }

  getUserEmail(): string | undefined {
    return this.client.profile?.email || this.client.tokenParsed?.email;
  }

  getUserId(): string | undefined {
    return this.client.tokenParsed?.sub;
  }

  getRoles(): string[] | undefined {
    return this.client.realmAccess?.roles;
  }

  getProfile(): KeycloakProfile | undefined {
    return this.client.profile;
  }

  getUserPhoneNumber(): Nullable<string> {
    return this.getProfile()?.attributes?.mobile && this.getProfile()?.attributes?.mobile[0]
      ? this.getProfile()?.attributes?.mobile[0]
      : null;
  }

  getCompanyName(): string | null {
    let result: string | null = null;

    const company = this.client.profile?.attributes?.company;
    if (company && company[0]) {
      [result] = company;
    }

    return result;
  }

  getFirstGroupName(): string | null {
    let result: string | null = null;

    const groups = this.client.idTokenParsed?.groups;

    const validGroup = groups?.find((group) => !TEST_GROUP_NAMES.includes(group.trim()));
    if (validGroup) {
      [result] = validGroup.split('#');
    }

    return result;
  }

  isExpeditionManager(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.expeditionManagerRole,
      ),
    );
  }

  isSrmAdminRole(): boolean {
    return Boolean(this.getRoles()?.includes(this.configService.srmAdminRole));
  }

  isCarrierUser(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.carrierEmployee,
      ),
    );
  }

  isAttractionManager(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.attractionManagerRole,
      ),
    );
  }

  isDispatcher(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.dispatcherRole,
      ),
    );
  }

  isClientManager(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.clientManagerRole,
      ),
    );
  }

  isSecurityManager(): boolean {
    return Boolean(
      this.getRoles()?.includes(
        this.configService.securityRole,
      ),
    );
  }

  constructor(
    @Inject(CONFIG_SERVICE_ID) private configService: ConfigService,
  ) {
    const { keycloakHost, keycloakRealm, keycloakClientId } = this.configService;

    this.client = Keycloak({
      url: keycloakHost,
      realm: keycloakRealm || 'master',
      clientId: keycloakClientId || 'gt-arm',
    });
  }

  async init(): Promise<KeycloakPromise<boolean, KeycloakError>> {
    const { location } = window;
    if (location.pathname.includes('draft-registration')) {
      const urlParams = new URLSearchParams(location.search);
      const carrierDraftId = urlParams.get('id');

      if (carrierDraftId) {
        sessionStorage.setItem('carrierDraftId', carrierDraftId);
      }
    }

    return this.client.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
    });
  }

  async fetchProfile(): Promise<void> {
    await this.client.loadUserProfile();
  }

  scheduleTokenRefresh(): void {
    setInterval(() => {
      this.client.updateToken(100);
    }, this.configService.keycloakRefreshRate);
  }

  redirectToLoginPage(): void {
    const loginUrl = this.client.createLoginUrl();
    window.location.replace(loginUrl);
  }

  logout(): void {
    this.client.logout();
  }
}

container
  .bind<AuthService>(AUTH_SERVICE_ID)
  .to(AuthService)
  .inSingletonScope();
