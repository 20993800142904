import {
  MultiSelectOption,
  Nullable,
} from '@/features/shared/types';
import {
  FilterValue,
  LocationExtended,
} from '@/features/srm/types';

import {
  ApprovingStatus,
  DocumentType,
  OrderStatus,
  RequestBusinessChannels,
  RequestTypeForCarrierLabel,
  RequestContractLabel,
  ChannelName,
} from '@/features/shared/constant';
import { OrderModel } from '@/features/srm/orders/services/OrderService.types';
import {
  ListFilterScenario,
  OrderType,
} from '@/features/srm/constants';
import { LocationObjectInfo } from '@/features/srm/locations/services/LocationService.types';
import { formatDate } from '@/filters/date';

export const COUNTRY_RUSSIA_ID = '795d6d93-a55e-4344-a257-c5769e15d083';
export function getAffiliationTypeColor(type?: string): string {
  switch (type) {
    case AffiliationType.Own:
      return 'success';
    case AffiliationType.Group:
      return 'primary';
    case AffiliationType.External:
      return 'warning';
    default:
      return 'dark';
  }
}
export function getAffiliationTypeTitle(type?: string): string {
  switch (type) {
    case AffiliationType.Own:
      return 'Собственный';
    case AffiliationType.Group:
      return 'Группа';
    case AffiliationType.External:
      return 'Внешний';
    case AffiliationType.Partner:
      return 'Партнерский';
    default:
      return 'Не определено';
  }
}

export function color(color: number): string {
  switch (color) {
    case ApprovingStatus.NotFilled:
      return 'dark';
    case ApprovingStatus.New:
      return 'danger';
    case ApprovingStatus.OnReview:
      return 'warning';
    case ApprovingStatus.Approved:
      return 'success';
    case ApprovingStatus.Rejected:
      return 'error';
    case ApprovingStatus.Correcting:
      return 'purple';
    case ApprovingStatus.Suspended:
      return 'inactive';
    default:
      return 'secondPrimary';
  }
}

export function getDocumentName(value: number): string {
  switch (value) {
    case DocumentType.Pts:
      return 'ПТС';
    case DocumentType.Sts:
      return 'СТС';
    case DocumentType.DrivingLicense:
      return 'Водительское удостоверение';
    case DocumentType.Passport:
      return 'Паспорт';
    case DocumentType.ContractWithDriver:
      return 'Трудовой Договор с водителем';
    case DocumentType.RegistrationCertificateForInn:
    case DocumentType.PensionFundCertificate:
      return 'ИНН';
    case DocumentType.RegistrationCertificateForOgrn:
      return 'ОГРН';
    case DocumentType.Leasing:
      return 'Договор Аренды';
    case DocumentType.WarrantyDocument:
      return 'Гарантийный документ';
    default:
      return 'Тип документа не определен';
  }
}

export enum ContractType {
  /** С покупателем */
  Customer = 'CUSTOMER',
  Supplier = 'SUPPLIER',
}

export enum VehicleTypes {
  Truck = 'TRUCK',
  Trailer = 'TRAILER',
}

export enum VehicleOwnerships {
  'All' = 'ALL',
  'Own' = 'OWN',
  'Group' = 'GROUP',
}

export enum AffiliationType {
  Own = 'OWN',
  Group = 'GROUP',
  External = 'EXTERNAL',
  Partner = 'PARTNER',
}

export enum OrderSubscriptions {
  Auction = 'auctionOrdersSubscription',
  Request = 'requestOrdersSubscription',
}

export interface OrderListFilter {
  trailerType: Nullable<MultiSelectOption<Nullable<boolean>>>;
  orderType: MultiSelectOption<ListFilterScenario>;
  loadingPlace: Nullable<FilterValue>;
  unloadingPlace: Nullable<FilterValue>;
  period: Nullable<Date[]>;
  carrierName: Nullable<FilterValue>;
  customerName: Nullable<FilterValue>;
}

export interface OrdersFilter {
  trailerType: Nullable<MultiSelectOption<Nullable<boolean>>>;
  loadingPlace: Nullable<LocationExtended<LocationObjectInfo>>;
  unloadingPlace: Nullable<LocationExtended<LocationObjectInfo>>;
  period: Nullable<Date[]>;
  carrierName: Nullable<FilterValue>;
  customerName: Nullable<FilterValue>;
  hasAutoApprove: Nullable<boolean>;
}

export const getInitialOrdersFilter = (): OrdersFilter => ({
  trailerType: {
    name: 'Все',
    value: null,
  },
  loadingPlace: null,
  unloadingPlace: null,
  period: null,
  carrierName: null,
  customerName: null,
  hasAutoApprove: null,
});

export function getListFilterScenarioName(scenario: string): string {
  switch (scenario) {
    case ListFilterScenario.OrdersAll:
      return 'Все';
    case ListFilterScenario.OrdersNew:
      return 'Новые';
    case ListFilterScenario.AuctionOrders:
      return 'Аукционные';
    case ListFilterScenario.ReservedToManager:
      return 'Забронированные';
    case ListFilterScenario.WaitingForApprove:
      return 'Ожидающие ответа';
    case ListFilterScenario.OrdersInWork:
      return 'В работе';
    case ListFilterScenario.OrdersDenied:
      return 'Отмененные';
    case ListFilterScenario.OrdersCompleted:
      return 'Завершенные';
    case ListFilterScenario.OrdersNewForLogist:
      return 'Мои Новые';
    default:
      return '';
  }
}

export const getInitialOrderListFilter = (): OrderListFilter => ({
  ...getInitialOrdersFilter(),
  loadingPlace: null,
  unloadingPlace: null,
  orderType: {
    name: getListFilterScenarioName(ListFilterScenario.OrdersAll),
    value: ListFilterScenario.OrdersAll,
  },
});

export enum OrderResponsibleCopyType {
  Email,
  Phone,
}

export enum OrderCancelReasons {
  ClientCancel = 1,
  CarrierLogistCancel,
  LowTariff,
  EndTimer,
  MoveToExpress,
  NoVehicleOrOrderIrrelevant,
  ErrorOrder

}

export function getOrderStatusName(orderStatus: Nullable<OrderStatus>): string {
  switch (orderStatus) {
    case OrderStatus.UnknownStatus:
      return 'Неизвестно';
    case OrderStatus.New:
      return 'Новая';
    case OrderStatus.Reserved:
      return 'Забронировано';
    case OrderStatus.ReservedWithCarrier:
      return 'Забронировано другим МЭП';
    case OrderStatus.ReservationCanceled:
      return 'Бронь снята';
    case OrderStatus.WaitingForApprove:
      return 'Перевозчик принял заявку';
    case OrderStatus.OnApprove:
      return 'Ожидание согласования';
    case OrderStatus.Execution:
      return 'Выполнение';
    case OrderStatus.DeniedCarrier:
      return 'Отмена предзаказа';
    case OrderStatus.DeniedManager:
      return 'Отменена менеджером';
    case OrderStatus.CancelExecuted:
      return 'Отмена заказа';
    case OrderStatus.Block:
      return 'Заблокирована';
    case OrderStatus.DeniedCustomer:
      return 'Отменена заказчиком';
    case OrderStatus.CarrierChangeMind:
      return 'Перевозчик передумал';
    case OrderStatus.AcceptedInGroup:
      return 'Принята в группе';
    case OrderStatus.WaitingDocs:
      return 'Ожидание документов';
    case OrderStatus.DocsProblems:
      return 'Проблемы с документами';
    case OrderStatus.WaitingPayment:
      return 'Ожидание платежа';
    case OrderStatus.SentPayment:
      return 'Платеж отправлен';
    case OrderStatus.Auction:
      return 'Аукцион создан';
    case OrderStatus.AuctionOpen:
      return 'Аукцион открыт';
    case OrderStatus.OfferCollection:
      return 'Запрос создан';
    case OrderStatus.OfferOpen:
      return 'Запрос открыт';
    case OrderStatus.BiddingCompleting:
      return 'Аукцион завершен';
    case OrderStatus.OfferCompleting:
      return 'Запрос завершен';
    case OrderStatus.ReadyForExecution:
      return 'Готова к выполнению';
    case OrderStatus.ApproveCanceled:
      return 'Согласование отклонено';
    default:
      return '';
  }
}

export enum CargoInsurance {
  Unknown,
  NotRequired,
  ByCarrier,
  ByClient,
}

export enum OperationType {
  Unknown,
  Loading,
  Unloading,
}

export enum OrderHistoryContent {
  PRICE_WITH_TAXES = 'Цена с НДС',
  PRICE_WITHOUT_TAXES = 'Цена без НДС',
}

export enum AttachmentType {
  DOCUMENTS = 'documents',
  ORDER = 'order',
  INSURANCE = 'insurance',
}

export enum PayType {
  QUICK = 'QUICK',
  STANDARD = 'STANDARD',
}

export enum PayTypeNames {
  QUICK = 'Быстрая',
  STANDARD = 'Стандартная',
}

export enum OrderEventName {
  First = 'loading',
  Last = 'unloading',
}

export enum SearchLocationByOptions {
  Region = 'region',
  City = 'city',
}

export enum ConditionalOrderStatusName {
  ReservedForYouMEP = 'Забронировано',
  ReservedForYouMEPForFilter = 'Забронировано под перевозчика',
  ReservedOtherMEP = 'Забронировано другим МЭП',
}

export enum VehicleTypeDTO {
  Truck = 'TRUCK',
  Trailer = 'TRAILER',
}

export enum OrderAllowedStatus {
  ALLOWED = 'ALLOWED',
  ALLOWED_WITH_INSURANCE = 'ALLOWED_WITH_INSURANCE',
  ALLOWED_WITH_HEAD_AGREEMENT = 'ALLOWED_WITH_HEAD_AGREEMENT',
}

export enum OrderSourceName {
  GT2 = 'GT2',
  OneC = '1С',
  SELTA = 'Сельта',
  Atracks = 'Атракс',
  CPA = 'ЛК Клиента',
}

export enum OrderSource {
  GT2 = 'gt2',
  OneC = '1c',
  SELTA = 'selta',
  Atracks = 'atrucks',
  CPA = 'cpa',
}

export enum OrderContractType {
  SPOT = 'SPOT',
  CONTRACT_LEGAL = 'CONTRACT_LEGAL',
  CONTRACT_CONCEPTUAL = 'CONTRACT_CONCEPTUAL',
}

export enum OrderClientType {
  SPOT = 'spot',
  CONTRACT_LEGAL = 'contract_legal',
  CONTRACT_CONCEPTUAL = 'contract_conceptual',
  AUCTION = 'auction',
  OFFER_COLLECTION = 'offer_collection',
}

export enum OrderChannelName {
  GUARANTEE = 'Гарантии',
  CLIENT_PERSONAL_ACCOUNT = 'ЛКК',
  BUYOUT = 'Выкуп',
  PARSING = 'Парсинг',
  ROAD_TRAIN = 'Наряд',
  FORMALIZATION = 'Оформление',
  EXTERNAL = 'Площадки',
  CLIENT = 'ЛК Клиента',
}

export const getOrderStatusColor = (orderStatus: OrderStatus): string => {
  switch (orderStatus) {
    case OrderStatus.UnknownStatus:
    case OrderStatus.New:
    case OrderStatus.Auction:
    case OrderStatus.AuctionOpen:
    case OrderStatus.OfferCollection:
    case OrderStatus.OfferOpen:
      return '--color-blue-400';
    case OrderStatus.OnApprove:
    case OrderStatus.BiddingCompleting:
    case OrderStatus.OfferCompleting:
      return '--color-green-400';
    case OrderStatus.AcceptedInGroup:
    case OrderStatus.Reserved:
    case OrderStatus.ReservedWithCarrier:
    case OrderStatus.ReservationCanceled:
      return '--color-neon-purple';
    case OrderStatus.DeniedCarrier:
    case OrderStatus.DeniedCustomer:
    case OrderStatus.DeniedManager:
    case OrderStatus.CancelExecuted:
    case OrderStatus.ApproveCanceled:
      return '--color-blue-200';
    case OrderStatus.Execution:
    case OrderStatus.WaitingForApprove:
    case OrderStatus.ReadyForExecution:
    case OrderStatus.WaitingDocs:
    case OrderStatus.Waiting:
      return '--color-yellow-400';
    case OrderStatus.DocsProblems:
    case OrderStatus.WaitingPayment:
    case OrderStatus.SentPayment:
    case OrderStatus.CarrierChangeMind:
      return '--color-red-300';
    default:
      return '--color-grey-200';
  }
};

export const orderStatusDescription = (order: OrderModel): string => {
  const isShowDescription = [
    OrderStatus.WaitingPayment,
    OrderStatus.SentPayment,
  ].includes(order.statusId);

  if (isShowDescription) {
    return formatDate(order?.paymentDate) ?? '';
  }

  return '';
};

export const getOrderChannelName = (channel: RequestBusinessChannels): OrderChannelName | string => {
  switch (channel) {
    case RequestBusinessChannels.BUYOUT:
      return OrderChannelName.BUYOUT;
    case RequestBusinessChannels.CLIENT_PERSONAL_ACCOUNT:
      return OrderChannelName.CLIENT_PERSONAL_ACCOUNT;
    case RequestBusinessChannels.FORMALIZATION:
      return OrderChannelName.FORMALIZATION;
    case RequestBusinessChannels.GUARANTEE:
      return OrderChannelName.GUARANTEE;
    case RequestBusinessChannels.PARSING:
      return OrderChannelName.PARSING;
    case RequestBusinessChannels.ROAD_TRAIN:
      return OrderChannelName.ROAD_TRAIN;
    default: return 'Неизвестно';
  }
};

export const getOrderSourceName = (source: OrderSource): OrderSourceName | string => {
  switch (source) {
    case OrderSource.Atracks:
      return OrderSourceName.Atracks;
    case OrderSource.CPA:
      return OrderSourceName.CPA;
    case OrderSource.GT2:
      return OrderSourceName.GT2;
    case OrderSource.OneC:
      return OrderSourceName.OneC;
    case OrderSource.SELTA:
      return OrderSourceName.SELTA;
    default: return 'Неизвестно';
  }
};

export const getClientTypeName = (
  clientType: OrderClientType,
): RequestTypeForCarrierLabel | RequestContractLabel | string => {
  switch (clientType) {
    case OrderClientType.SPOT:
      return RequestContractLabel.Spot;
    case OrderClientType.CONTRACT_CONCEPTUAL:
      return RequestContractLabel.Conceptual;
    case OrderClientType.CONTRACT_LEGAL:
      return RequestContractLabel.Legal;
    case OrderClientType.AUCTION:
      return RequestTypeForCarrierLabel.AUCTION;
    case OrderClientType.OFFER_COLLECTION:
      return RequestTypeForCarrierLabel.OFFER_COLLECTION;
    default: return 'Неизвестно';
  }
};

export const getTypeName = (
  type: OrderType | OrderContractType,
): ChannelName | RequestContractLabel | OrderChannelName | string => {
  switch (type) {
    case OrderType.Contract:
      return ChannelName.CONTRACT;
    case OrderType.Spot:
      return ChannelName.SPOT;
    case OrderContractType.CONTRACT_CONCEPTUAL:
      return RequestContractLabel.Conceptual;
    case OrderType.Buyout:
      return OrderChannelName.BUYOUT;
    case OrderType.Parsing:
      return OrderChannelName.PARSING;
    case OrderType.External:
      return OrderChannelName.EXTERNAL;
    case OrderType.Client:
      return OrderChannelName.CLIENT;
    default: return 'Неизвестно';
  }
};

export enum CounterpartyEntityTabName {
  ACTUAL = 'ACTUAL',
  ALL = 'ALL',
  ARCHIVE = 'ARCHIVE',
  TRUCK = 'TRUCK',
  LOW_TONNAGE = 'LOW_TONNAGE',
  TRAILER = 'TRAILER',
}

export enum CounterpartyEntityStatus {
  READY = 'ready',
  LOADING = 'loading',
  SYNCING = 'syncing',
}

export enum DocumentSides {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
}
