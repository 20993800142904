import { PropType } from 'vue';
import IMask from 'imask';
import { Nullable } from '@/features/shared/types';

export default {
  modelValue: {
    type: [String, Number] as PropType<Nullable<string | number>>,
    default: null,
  },
  id: {
    type: String as PropType<Nullable<string>>,
    default: null,
  },
  isInvalid: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  leftIcon: {
    type: String as PropType<Nullable<string>>,
    default: null,
  },
  rightIcon: {
    type: String as PropType<Nullable<string>>,
    default: null,
  },
  mask: {
    type: Object as PropType<Nullable<IMask.MaskedNumberOptions>>,
    default: null,
  },
};
