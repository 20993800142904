export const API_ORDER = '/order';
export const API_ORDER_CHECK_BY_SECURITY_CATEGORY = '/order/check-by-security-category';
export const API_AUCTION = '/auction';
export const API_GET_OFFER_COLLECTIONS = '/offers-collection';
export const API_SET_AUCTION_BET = '/bid/auction/create';
export const API_SET_AUCTION_OFFER = '/bid/offers/create';
export const API_AUCTION_DECLINE = '/auction/decline';
export const GET_ORDER_HISTORY_IN_AUCTION = '/auction/history/file';
export const GET_OFFER_COLLECTION_HISTORY = '/offers-collection/history/file';
export const API_GET_ORDER_LIST = '/search/page';
export const API_GET_FILTERED_ORDER_LIST = '/search/filter';
export const API_GET_ORDER_COUNTS = '/search/counts';
export const API_SET_INSTRUCTIONS = 'loadingEvent/setInstructions';
export const API_SET_ACTUAL_DATES = 'loading-event/actual-dates';
export const API_SET_ORDER_DISLIKE_CREATE = '/order/dislike/create';
export const API_SET_ORDER_DISLIKE_DELETE = '/order/dislike/delete';
export const API_SET_ORDER_STATUS = '/order/status';
export const API_SET_PRICE = '/order/updateExecuted';
export const API_GET_CONTRACT = '/contract';
export const API_GET_DEFAULT_CARGO_INSURANCE_EMAILS = '/order/defaultCargoInsuranceEmails';
export const API_SEND_EMAIL_WITH_ORDER = '/order/sendOrderEmail';
export const API_SEND_EMAIL_WITH_DOCUMENT = '/order/sendEmail';
export const API_GET_VEHICLE_LOCATIONS = 'order/vehicleLocations';
export const API_SEND_INSURANCE = 'insurances/send';
export const API_GET_CARGO_TYPE_LIST = '/cargoType';
export const API_GET_CARGO_LIST = '/cargo/page';
export const API_GET_CANCEL_REASON_LIST = '/order-cancel-reason';
export const API_SEND_CONFIRM_SMS = 'order/sendOrderConfirmSms';
export const API_GET_STATUS_LIST = 'order/statuses';
export const API_GET_CHANNELS_LIST = '/enums/channels';
export const API_GET_SOURCES_LIST = '/enums/sources';
export const API_GET_BUSINESS_CHANNELS = '/v1/business-channels';
export const API_CITIES = '/city';
export const API_CITY_BY_ID = (id: string): string => `/city/${id}`;
export const API_REGIONS = '/region';
export const API_REGION_BY_ID = (id: string): string => `/region/${id}`;
export const API_AUTOTRAIN = '/autotrain';
export const API_ATISU_SEND_ORDERS = '/cargos';
export const API_ORDER_REACTION = '/order/reaction';
export const API_ORDER_REACTION_BY_ID = (crmId: string): string => `/order/reaction/${crmId}`;
export const API_ORDER_CARRIER_COUNTS = '/order/carrier-counts';

export const API_GET_ORDER_BY_ID = (id: string): string => `/order/${id}`;
export const API_GET_RESPONSIBLE_LOGIST = (id: string): string => `/order-responsible/${id}`;
export const API_GET_ORDER_HISTORY_LIST = '/order/history';
export const API_SET_ORDER_PRICE = (
  orderId: string,
  newPrice: string | number,
  reason?: string,
): string => `/order/setPrice/${orderId}?newPrice=${newPrice}${reason ? `&reason=${reason}` : ''}`;
export const API_ORDER_COMPLETE = (orderId: string): string => `order/${orderId}/complete`;
export const API_GET_CARGO_INSURANCE = (orderId?: string): string => `/insurances/${orderId}`;
export const API_ORDER_RESOURCES = (orderId: string): string => `order/${orderId}/resources`;
export const API_CHECK_DOCUMENT = (orderId: string): string => `/order/checkDocuments/${orderId}`;
export const API_GET_AUCTION_DATA = (orderId: string): string => `/auction/${orderId}`;
export const API_GET_OFFER_COLLECTIONS_BY_IDS = (orderId: string): string => `/offers-collection/${orderId}`;

export const WS_TOPICS_ADDRESS_ORDER_ID = (orderId: string): string => `/topic/order/${orderId}`;
export const API_GET_VEHICLE_INFO_ID = (id: string): string => `/v1/vehicle-info/${id}`;
