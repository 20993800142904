
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';
import { useAbility } from '@/utils/vue3';

import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import EmailConfirmFirstStep from '@/features/redesigned/shared/layout/EmailConfirm/EmailConfirmFirstStep.vue';
import EmailConfirmSecondStep from '@/features/redesigned/shared/layout/EmailConfirm/EmailConfirmSecondStep.vue';
import EmailConfirmThirdStep from '@/features/redesigned/shared/layout/EmailConfirm/EmailConfirmThirdStep.vue';

import container from '@/di';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';

import { Undefinable } from '@/features/shared/types';
import { UserModel } from '@/features/srm/profile/services/UserService.types';

export default defineComponent({
  name: 'OrgPhoneConfirm',

  components: {
    UiModalWithContent,
    DefaultTransition,
    EmailConfirmFirstStep,
    EmailConfirmSecondStep,
    EmailConfirmThirdStep,
  },

  setup(_, { emit }) {
    const state = reactive({
      stages: [1, 2, 3],
      email: '',
      currentStage: 1,
    });

    const router = useRouter();
    const ability = useAbility();

    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const user = computed<Undefinable<UserModel>>(() => userModule.value.user);

    onMounted(() => {
      if (!ability.can('use', 'VerifyEmail') || user.value?.emailVerified !== false) {
        router.push({
          query: {},
        });
      }
    });

    const onFinishedFirstStep = (email: string) => {
      state.currentStage = 2;
      state.email = email;
    };

    const onFinishedConfirm = async () => {
      await userModule.value.loadCurrentUser();

      router.push({
        query: {},
      });
    };

    const onClosePhoneConfirmModal = () => {
      if (state.currentStage === 3) {
        onFinishedConfirm();

        return;
      }

      emit('close');
    };

    return {
      ...toRefs(state),
      onFinishedFirstStep,
      onFinishedConfirm,
      onClosePhoneConfirmModal,
    };
  },
});
