import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_draggable_resizable = _resolveComponent("vue-draggable-resizable")!

  return (_openBlock(), _createBlock(_component_vue_draggable_resizable, {
    w: $props.width,
    h: $props.height,
    "on-drag": $options.onDragCallback,
    "drag-handle": $options.dragHandle,
    class: _normalizeClass(_ctx.$style.container),
    style: _normalizeStyle($options.styles),
    z: $props.zIndex,
    onDragstop: $options.dragstop
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["w", "h", "on-drag", "drag-handle", "class", "style", "z", "onDragstop"]))
}