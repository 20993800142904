import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_button = _resolveComponent("app-sidebar-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$can('forKM', 'sop'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 0,
          caption: "Плановый объем",
          icon: "briefcase",
          to: { name: 'contract-clients' },
          "included-routes": ['contracts', 'contract-clients', 'contract-requests'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('forMEP', 'sop'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 1,
          caption: "План МЭП",
          icon: "calendar",
          to: { name: 'mep-plan' },
          "included-routes": ['mep-plan'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('forLogist', 'sop'))
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 2,
          caption: "План Логист",
          icon: "calendar",
          to: { name: 'logist-plan' },
          "included-routes": ['logist-plan'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    (_ctx.$can('forKM', 'sop') && false)
      ? (_openBlock(), _createBlock(_component_app_sidebar_button, {
          key: 3,
          caption: "Дашборд",
          icon: "dashboard",
          to: { name: 'sop-dashboard' },
          "included-routes": ['sop-dashboard'],
          "is-wide": $props.isWide
        }, null, 8, ["is-wide"]))
      : _createCommentVNode("", true),
    _createVNode(_component_app_sidebar_button, {
      caption: "Отчеты",
      icon: "paper",
      to: { name: 'report' },
      "included-routes": ['report', 'list', 'control-plan', 'report-volume'],
      "is-wide": $props.isWide
    }, null, 8, ["is-wide"]),
    _createVNode(_component_app_sidebar_button, {
      caption: "Форма обратной связи",
      icon: "email",
      type: "a",
      target: "_blank",
      rel: "noopener noreferrer",
      href: $options.feedBack,
      "is-wide": $props.isWide
    }, null, 8, ["href", "is-wide"])
  ]))
}