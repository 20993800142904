import container, { Injectable } from '@/di';
import { DictionaryModel } from '@/features/shared/types';

export const ORGANIZATIONS_SERVICE_ID = Symbol('organization-service-id');

@Injectable()
export default class OrganizationsService {
  /**
   * Получение юридических типов
   */
  getLegalForms(): Promise<DictionaryModel[]> {
    const LEGAL_FORMS = [
      {
        id: 0,
        name: 'ИП',
      },
      {
        id: 1,
        name: 'ООО',
      },
      {
        id: 2,
        name: 'АО',
      },
      {
        id: 3,
        name: 'ЗАО',
      },
      {
        id: 4,
        name: 'ОАО',
      },
      {
        id: 5,
        name: 'УП',
      },
      {
        id: 6,
        name: 'ПАО',
      },
    ];

    return Promise.resolve(LEGAL_FORMS);
  }

  /**
   * Получение типов НДС
   */
  getNdsTypes(): Promise<DictionaryModel[]> {
    const NDS_TYPES = [
      {
        id: 0,
        name: 'Неизвестен',
      },
      {
        id: 1,
        name: '0%',
      },
      {
        id: 3,
        name: '20%',
      },
      {
        id: 4,
        name: 'Без НДС',
      },
    ];

    return Promise.resolve(NDS_TYPES);
  }

  /**
   * Получение типов перевозчика
   */
  getCarrierTypes(): Promise<DictionaryModel[]> {
    const CARRIER_TYPES = [
      {
        id: 0,
        name: 'Не указан',
      },
      {
        id: 1,
        name: 'Собственник',
      },
      {
        id: 2,
        name: 'Экспедитор',
      },
      {
        id: 3,
        name: 'Комбинированный',
      },
    ];

    return Promise.resolve(CARRIER_TYPES);
  }

  /**
   * Получение типов перевозчика
   */
  getShippingTypes(): Promise<DictionaryModel[]> {
    const SHIPPING_TYPES = [
      {
        id: 0,
        name: 'Не указан',
      },
      {
        id: 2,
        name: 'Внутригородские',
      },
      {
        id: 4,
        name: 'Междугородние',
      },
      {
        id: 8,
        name: 'Международные',
      },
    ];

    return Promise.resolve(SHIPPING_TYPES);
  }

  /**
   * Получение статусов развития
   */
  getDevelopmentStatuses(): Promise<DictionaryModel[]> {
    const STATUSES = [
      {
        id: 0,
        name: 'Не указан',
      },
      {
        id: 1,
        name: 'Новый',
      },
      {
        id: 2,
        name: 'Активный',
      },
      {
        id: 3,
        name: 'На развитие',
      },
    ];

    return Promise.resolve(STATUSES);
  }

  /**
   * Получение статусов развития
   */
  getCommercialRatings(): Promise<DictionaryModel[]> {
    const STATUSES = [
      {
        id: 0,
        name: 'Не указан',
      },
      {
        id: 1,
        name: 'АА',
      },
      {
        id: 2,
        name: 'А',
      },
      {
        id: 3,
        name: 'B',
      },
      {
        id: 4,
        name: 'C',
      },
    ];

    return Promise.resolve(STATUSES);
  }
}

container
  .bind<OrganizationsService>(ORGANIZATIONS_SERVICE_ID)
  .to(OrganizationsService);
