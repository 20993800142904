import { Register } from '@/di';

import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';

export const SRM_INTEGRATIONS_ATISU_CONNECTOR_SERVICE_ID = Symbol('srmIntegrationsAtisuConnectorService');

@Register(
  SRM_INTEGRATIONS_ATISU_CONNECTOR_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMIntegrationsAtisuConnectorService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.IntegrationsAtisuConnectorServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }
}

export default SRMIntegrationsAtisuConnectorService;
