import { AxiosError } from 'axios';
import { AxiosErrorResponce } from '@/features/shared/types';

export function getGenericError(errorCode: number): string {
  return `
    Что-то пошло не так. Пожалуйста, повторите попытку позже. ${
  errorCode ? `Код ошибки: ${errorCode}` : ''
}
  `.trim();
}

export function getHTTPError(error: AxiosError | unknown): string {
  let errorWithMessage = '';
  const axiosError = error as AxiosError;
  const axiosErrorWithMessages = axiosError as AxiosErrorResponce;

  if (axiosError?.response) {
    errorWithMessage = axiosErrorWithMessages.response.data?.userMessage
      || axiosErrorWithMessages.response.data?.message
      || axiosErrorWithMessages.response.data?.error
      || axiosErrorWithMessages.response.data?.title
      || getGenericError(axiosErrorWithMessages.response.status);
  } else {
    errorWithMessage = `${axiosError.message}`;
    console.error(axiosError);
  }

  return errorWithMessage;
}

export enum ErrorCodes {
  ECONNABORTED = 'ECONNABORTED',
  BAD_REQUEST = 'BAD_REQUEST',
}

export const getPageTitle = (ctx: Vue): string => {
  if (ctx.$route.meta?.pageNotFound) {
    return 'Страница не найдена';
  }

  return 'Главная страница';
};
