
import { PropType } from 'vue';

import {
  TECHNICAL_SUPPORT_SERVICE_ID,
  TechnicalSupportService,
} from '@/features/support/services';
import container from '@/di';
import { ModuleCode } from '@/features/support/types';
import { Nullable } from '@/features/shared/types';
import { getHTTPError } from '@/utils/errors';

export default {
  name: 'InfoBanner',

  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isExpeditionActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isOrdersActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isSecurityActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isLogisticsActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isFuelActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

  },

  data() {
    return {
      text: '',
    };
  },

  computed: {
    technicalSupportService(): TechnicalSupportService {
      return container.get(TECHNICAL_SUPPORT_SERVICE_ID);
    },

    currentBannerTypeCode(): Nullable<ModuleCode> {
      switch (true) {
        case this.$can('showLogistOrdersPageTitle', 'Orders') && this.isExpeditionActive:
          return ModuleCode.LOGIST;
        case this.$can('enter', 'CarrierRequestsTab') && this.isExpeditionActive:
          return ModuleCode.CARRIER;
        case this.isExpeditionActive:
          return ModuleCode.SRM;
        case this.isOrdersActive:
          return ModuleCode.CRM;
        case this.isSecurityActive:
          return ModuleCode.SECURITY;
        case this.isLogisticsActive:
          return ModuleCode.SOP;
        case this.isFuelActive:
          return ModuleCode.FUEL;
        default:
          return null;
      }
    },
  },

  watch: {
    currentBannerTypeCode() {
      this.fetchBannersList();
    },
  },

  mounted() {
    this.fetchBannersList();

    this.timer = setInterval(this.fetchBannersList, 1000 * 60);
  },

  beforeUnmount() {
    clearInterval(this.timer);
  },

  methods: {
    async fetchBannersList() {
      if (!this.currentBannerTypeCode) return;

      try {
        const banners = await this.technicalSupportService
          .fetchBannersList({ published: true, system: this.currentBannerTypeCode });

        if (!banners.length || !banners[0].text) {
          this.$emit('toggle:banner', false);
          return;
        }

        this.text = banners[0].text;

        this.$emit('toggle:banner', true);
      } catch (e) {
        getHTTPError(e);
      }
    },
  },
};
