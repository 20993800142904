import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_draggable_wrapper = _resolveComponent("ui-draggable-wrapper")!
  const _component_ui_tooltip_with_content = _resolveComponent("ui-tooltip-with-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($data.isVisible)
      ? (_openBlock(), _createBlock(_component_ui_draggable_wrapper, {
          key: 0,
          id: "scroll-top-button",
          width: 64,
          height: 64,
          right: 24,
          bottom: $data.bottomOffset,
          "drag-element-id": "arrow-up"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              id: "arrow-up",
              class: _normalizeClass(_ctx.$style.container),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onScrollTop && $options.onScrollTop(...args)))
            }, [
              _createVNode(_component_svg_icon, {
                name: "arrow_up_long",
                class: _normalizeClass(_ctx.$style.icon)
              }, null, 8, ["class"])
            ], 2)
          ]),
          _: 1
        }, 8, ["bottom"]))
      : _createCommentVNode("", true),
    ($data.isVisible)
      ? (_openBlock(), _createBlock(_component_ui_tooltip_with_content, {
          key: 1,
          "to-selector": "#scroll-top-button",
          distance: 12,
          "arrow-align": "end",
          placement: "bottom-end",
          "z-index": 30
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Вверх ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}