import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar_menu_item = _resolveComponent("app-sidebar-menu-item")!
  const _component_default_transition = _resolveComponent("default-transition")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_video_instuctions_modal = _resolveComponent("video-instuctions-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_app_sidebar_menu_item, {
        label: "Заявки",
        icon: "receipt",
        "is-collapsed": _ctx.isCollapsed,
        to: { name: 'orders' },
        "included-routes": [
          'orders',
          'order',
          'order-tab-actualize',
          'order-tab-accept',
        ]
      }, null, 8, ["is-collapsed"]),
      _createVNode(_component_app_sidebar_menu_item, {
        label: "Мой офис",
        icon: "building",
        "has-expand-icon": "",
        "is-expanded": _ctx.isShowSubmenu,
        "is-collapsed": _ctx.isCollapsed,
        type: _ctx.isCollapsed ? 'router-link' : 'div',
        to: _ctx.getOrgSectionRoute('org'),
        onExpand: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowSubmenu = !_ctx.isShowSubmenu))
      }, null, 8, ["is-expanded", "is-collapsed", "type", "to"]),
      (_ctx.isShowSubmenu && !_ctx.isCollapsed)
        ? (_openBlock(), _createBlock(_component_default_transition, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Организация",
                  icon: "briefcase_light",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org')
                }, null, 8, ["is-collapsed", "to"]),
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Гарантийные обязательства",
                  icon: "shield_check",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org-warranty')
                }, null, 8, ["is-collapsed", "to"]),
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Транспорт",
                  icon: "truck_light",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org-vehicles')
                }, null, 8, ["is-collapsed", "to"]),
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Водители",
                  icon: "person_driver",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org-drivers')
                }, null, 8, ["is-collapsed", "to"]),
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Документы",
                  icon: "document_pdf",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org-documents')
                }, null, 8, ["is-collapsed", "to"]),
                _createVNode(_component_app_sidebar_menu_item, {
                  label: "Договоры",
                  icon: "contract",
                  "is-submenu": true,
                  "is-collapsed": _ctx.isCollapsed,
                  to: _ctx.getOrgSectionRoute('org-contracts')
                }, null, 8, ["is-collapsed", "to"]),
                (_ctx.isCarrierHasLoyalty)
                  ? (_openBlock(), _createBlock(_component_app_sidebar_menu_item, {
                      key: 0,
                      label: "Программа лояльности",
                      icon: "percent",
                      "is-submenu": true,
                      "is-collapsed": _ctx.isCollapsed,
                      to: _ctx.getOrgSectionRoute('org-loyalty-program')
                    }, null, 8, ["is-collapsed", "to"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_app_sidebar_menu_item, {
        label: "Предложить машину",
        icon: "truck_ref",
        "is-collapsed": _ctx.isCollapsed,
        to: { name: 'truck-offering' },
        "included-routes": [
          'truck-offering',
          'truck-offering-order',
          'truck-offering-order-tab-accept',
        ]
      }, null, 8, ["is-collapsed"]),
      _createVNode(_component_app_sidebar_menu_item, {
        label: "Подписки",
        icon: "mail_plus",
        "is-collapsed": _ctx.isCollapsed,
        to: { name: 'srm-subscriptions' },
        "included-routes": [
          'srm-subscriptions',
        ]
      }, null, 8, ["is-collapsed"]),
      (_ctx.$can('enter', 'FuelTab'))
        ? (_openBlock(), _createBlock(_component_app_sidebar_menu_item, {
            key: 1,
            label: "Топливо",
            icon: "gas_pump_light",
            "is-collapsed": _ctx.isCollapsed,
            to: _ctx.fuelRoute
          }, null, 8, ["is-collapsed", "to"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.guideClass),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowVideo = true))
    }, [
      _createVNode(_component_svg_icon, {
        name: "guide",
        "view-box": "0 0 24 24"
      }),
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Инструкция по работе с порталом "))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.isShowVideo)
      ? (_openBlock(), _createBlock(_component_video_instuctions_modal, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowVideo = false))
        }))
      : _createCommentVNode("", true)
  ], 2))
}