/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import container, {
  Inject,
  Injectable,
} from '@/di';
import { STORE_ID } from '@/store';
import SRMUserService, { SRM_USER_SERVICE_ID } from '@/features/srm/profile/services/UserService';
import { UserModel } from '@/features/srm/profile/services/UserService.types';

export const SRM_USER_MODULE_ID = 'srm-user';

@Module
@Injectable()
export default class SRMUserModule extends VuexModule {
  private userService: SRMUserService;

  public user?: UserModel = undefined;

  constructor(
    @Inject(STORE_ID) store: Store<unknown>,
    @Inject(SRM_USER_SERVICE_ID) userService: SRMUserService,
  ) {
    super({ store, name: SRM_USER_MODULE_ID });

    this.userService = userService;
  }

  getRelationTypeId() {
    return this.user?.userCounterparties[0]?.relationTypeId;
  }

  @Action
  async loadCurrentUser() {
    const currentUser = await this.userService.getCurrentUser();

    currentUser.userCounterparties = currentUser.userCounterparties.map((item) => {
      const result = { ...item };

      result.counterparty = {
        id: item.carrier.id,
        name: item.carrier.name,
      };

      return result;
    });

    this.setUser(currentUser);
  }

  @Action
  async updateNotificationSettings(notifications) {
    await this.userService.updateUserNotificationFlag({
      ...this.user,
      emailNotificationSettings: notifications,
    });

    await this.loadCurrentUser();
  }

  @Mutation
  setUser(user: UserModel) {
    this.user = user;
  }
}

container.bind<SRMUserModule>(SRM_USER_MODULE_ID).to(SRMUserModule).inSingletonScope();
