
import AppLayout from '@/features/shared/layout/AppLayout.vue';

export default {
  name: 'CrmLayout',

  components: {
    AppLayout,
  },
};
