
import {
  computed,
  defineComponent,
  PropType,
  ref,
  useCssModule,
} from 'vue';
import { RouteLocationRaw } from 'vue-router';
import AppSidebarMenuItem from '@/features/redesigned/shared/layout/AppSidebarMenuItem.vue';
import DefaultTransition from '@/features/shared/kit/DefaultTransition.vue';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import container from '@/di';
import {
  Nullable,
  Style,
} from '@/features/shared/types';
import { ABILITY_ID } from '@/abilities';
import { AppAbility } from '@/abilities.types';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import VideoInstuctionsModal from '@/features/shared/modals/VideoTutorialModal.vue';
import LoyaltyProgramModule, { LOYALTY_PROGRAM_MODULE_ID } from '@/features/srm/loyalty/store';
import { useCarrierInfo } from '@/features/redesigned/srm/composable/carrier-info';

export default defineComponent({
  name: 'AppSidebarExpedition',

  components: {
    VideoInstuctionsModal,
    SvgIcon,
    AppSidebarMenuItem,
    DefaultTransition,
  },

  props: {
    isCollapsed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const $style = useCssModule();
    const isShowSubmenu = ref(false);
    const isShowVideo = ref(false);

    const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
    const orgId = computed<Nullable<string>>(() => userModule.value?.user?.userCounterparties[0]?.counterparty?.id ?? null);

    const { carrierId } = useCarrierInfo();

    const guideClass = computed<Style>(() => ({
      [$style.bottomContent]: true,
      [$style.collapsed]: props.isCollapsed,
    }));

    const ability: AppAbility = container.get(ABILITY_ID);

    const fuelRoute = computed<RouteLocationRaw>(() => (ability.can('use', 'Fuel') ? { name: 'fuel' } : { name: 'fuel-greeting' }));

    const loyaltyModule = computed<LoyaltyProgramModule>(() => container.get(LOYALTY_PROGRAM_MODULE_ID));
    const isCarrierHasLoyalty = computed<boolean>(() => !!loyaltyModule.value.isCarrierHasLoyalty);

    const getOrgSectionRoute = (name: string): RouteLocationRaw => {
      let result = {
        name: 'orgs',
      };

      if (carrierId.value) {
        result = {
          name,
          params: {
            orgId: carrierId.value,
          },
        };
      }

      return result;
    };

    return {
      isShowSubmenu,
      isCarrierHasLoyalty,
      isShowVideo,
      orgId,
      getOrgSectionRoute,
      fuelRoute,
      guideClass,
    };
  },
});
