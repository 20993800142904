import { FilterValue } from '@/features/srm/types';
import { DictionaryModel } from '@/features/shared/types';

export default class StatusMapper {
  static fromResponse(statuses: FilterValue[]): DictionaryModel[] {
    return statuses.map((status) => ({
      id: status.id as string,
      name: status.title,
    }));
  }
}
