import {
  Register,
} from '@/di';
import RemoteService from '@/features/shared/services/base/remote';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import authRequestInterceptor from '@/features/auth/services/auth.interceptor';
import {
  API_FUEL_REPORT_CASHBACK,
  API_FUEL_REPORT_EXCEL,
} from '@/features/fuel/clients/services/endpoints';
import { DownloadFuelRequestParams } from '@/features/fuel/clients/types';

export const FUEL_REPORT_SERVICE_ID = Symbol('fuel-report-service');

@Register(
  FUEL_REPORT_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
export default class FuelReportService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.FuelReportHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  downloadFuelReport(params: DownloadFuelRequestParams): Promise<File> {
    return this.request({
      method: 'GET',
      url: API_FUEL_REPORT_EXCEL,
      responseType: 'blob',
      params,
    });
  }

  getCashbackValue(params: DownloadFuelRequestParams): Promise<number> {
    return this.request({
      method: 'GET',
      url: API_FUEL_REPORT_CASHBACK,
      params,
    });
  }
}
