import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import WebsocketService from '@/features/shared/services/WebsocketService';

export const SRM_WEBSOCKET_SERVICE_ID = Symbol('srmWebsocketService');

@Register(
  SRM_WEBSOCKET_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SRMWebsocketService extends WebsocketService {
  constructor(configService: ConfigService) {
    super(configService.SRMWebsocketGatewayServiceHost);
  }
}

export default SRMWebsocketService;
