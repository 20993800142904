/**
 * Support form
 */
export interface AuthorData {
    initials: string;
    organization: string;
    phone: string;
    email: string;
    description: string;
    subject: string;
    pageUrl: string;
}

export interface FileModel {
    id: number;
    thumbnail: string;
}

export interface FileObject {
    ref: string;
    id: number | null;
    status: number;
    fileId: string;
    name: string;
    type: string;
    size: number;
    thumbnail: string;
    uploadingProgress: () => void | null;
}

/**
 * Banner
 */
export interface BannerType {
  code: ModuleCode;
  name: string;
}

export enum ModuleCode {
  CRM = 'CRM',
  SRM = 'SRM',
  SECURITY = 'SECURITY',
  FUEL = 'FUEL',
  SOP = 'SOP',
  CARRIER = 'CARRIER',
  LOGIST = 'LOGIST',
}

export interface Banner {
  id: number;
  systemList: ModuleCode[];
  published: boolean;
  text: string;
}

export interface BannerListParams {
  published?: boolean;
  system?: ModuleCode;
}
