
import {
  nextTick,
  PropType,
} from 'vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/ru';
import dayjs from 'dayjs';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import UiInputText from '@/features/shared/kit/UiInputText.vue';
import { DateValue } from '@/features/shared/types';

export default {
  name: 'UiDatepicker',

  components: {
    DatePicker,
    SvgIcon,
    UiInputText,
  },

  props: {
    isInvalid: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    inline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    appearance: {
      type: String as PropType<string>,
      default: 'input',
      validator: (value) => ['input', 'clean'].includes(value),
    },

    modelValue: {
      type: [String, Date, Number, Array] as PropType<DateValue>,
      default: null,
    },

    format: {
      type: String as PropType<string>,
      default: 'DD.MM.YYYY',
    },

    placeholder: {
      type: String as PropType<string>,
      default: 'дд.мм.гггг',
    },

    start: {
      type: Date as PropType<Date>,
      default: null,
    },

    end: {
      type: Date as PropType<Date>,
      default: null,
    },

    range: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    clearable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    type: {
      type: String as PropType<string>,
      default: 'date',
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    qaKey: {
      type: String as PropType<string>,
      default: null,
    },

    dayInWeek: {
      type: Number as PropType<number>,
      default: null,
    },

    confirmByButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    confirmButtonText: {
      type: String as PropType<string>,
      default: 'OK',
    },

    calendarSvgUseDataQa: {
      type: String as PropType<string>,
      default: null,
    },

    valueType: {
      type: String as PropType<string>,
      default: undefined,
    },
  },

  emits: ['update:modelValue', 'close'],

  data() {
    return {
      dayjsFormat: {
        parse: (value) => {
          if (!value) return null;
          const formatReplaced = this.format.replace(/\W/g, '');
          const valueTypeReplaced = this.valueType?.replace(/\W/g, '');
          if (!this.valueType) {
            return dayjs(value, formatReplaced).toDate();
          }
          if (dayjs(value, formatReplaced).isValid()) {
            const withFormat = dayjs(value, formatReplaced).utc(true).toISOString();
            return dayjs(withFormat, valueTypeReplaced).utc(false).toDate();
          }
          return dayjs(value, valueTypeReplaced).utc(false).toDate();
        },
      },
    };
  },

  methods: {
    onChangeInputHandler(value: string) {
      const parsedDate = this.dayjsFormat.parse(value);
      if (!dayjs(parsedDate).isValid()) return;
      if (this.valueType) {
        this.$emit('update:modelValue', dayjs(parsedDate).format(this.valueType));
      } else {
        this.$emit('update:modelValue', parsedDate);
      }
    },

    disabledDate(date) {
      if (this.dayInWeek) {
        const day = this.dayInWeek === 7 ? 0 : this.dayInWeek;
        return date.getDay() !== day;
      }

      if (this.start) {
        return date < new Date(this.start).setHours(0, 0, 0, 0);
      }

      if (this.end) {
        return date > new Date(this.end).setHours(0, 0, 0, 0);
      }

      return false;
    },

    disabledTime(time) {
      if (this.start) {
        return time < this.start;
      }

      if (this.end) {
        return time > this.end;
      }

      return false;
    },

    skipClickIfDisabled() {
      nextTick(() => {
        if (!this.$refs.calendar || !this.$refs.calendar.clear) return;
        this.$refs.calendar.clear();
      });
    },
  },
};
