import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type), {
    to: _ctx.to,
    target: _ctx.target,
    disabled: _ctx.isDisabled,
    class: _normalizeClass(_ctx.containerStyles),
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.border)
      }, null, 2),
      _createVNode(_component_svg_icon, {
        class: _normalizeClass(_ctx.$style.icon),
        name: _ctx.icon
      }, null, 8, ["class", "name"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.text)
      }, _toDisplayString(_ctx.label), 3),
      (_ctx.hasExpandIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.expandIconWrapper)
          }, [
            _createVNode(_component_svg_icon, {
              name: "arrow_down",
              class: _normalizeClass(_ctx.expandIconStyles)
            }, null, 8, ["class"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to", "target", "disabled", "class", "onClick"]))
}