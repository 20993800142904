
import {
  defineComponent,
} from 'vue';
import { Location } from 'vue-router/types/router';
import Cookies from 'js-cookie';
import UiModalWithContent from '@/features/redesigned/shared/kit/UiModalWithContent.vue';
import UiButton from '@/features/redesigned/shared/kit/UiButton.vue';
import { useCarrierInfo } from '@/features/redesigned/srm/composable/carrier-info';
import { isPhone } from '@/utils/device';

export default defineComponent({
  name: 'IncreasedRateModal',

  components: {
    UiModalWithContent,
    UiButton,
  },

  setup(_props, { emit }) {
    const { counterparty } = useCarrierInfo();
    const getOrgRoute = (): Location => (isPhone()
      ? { name: 'org-adding' }
      : {
        name: 'orgs',
        query: {
          action: 'add',
        },
      });

    const onClose = () => {
      const modalType = counterparty.value ? 'welcomeCashbackModalForCarrierWithOrgViewed' : 'welcomeCashbackModalViewed';
      Cookies.set(modalType, '1', { expires: 365 });
      emit('close');
    };

    return {
      counterparty,
      getOrgRoute,
      onClose,
    };
  },
});
