import {
  AbilityBuilder,
  Ability,
} from '@casl/ability';
import { AppAbility } from '@/abilities.types';
import {
  defineAbilitiesSRMAdmin,
  defineAbilitiesForAttractManager,
  defineAbilitiesForCarrierUser,
  defineAbilitiesForChief,
  defineAbilitiesForExpeditionManager,
  defineAbilitiesSRMAllOrders,
  definedAbilitiesForLogistUser,
  definedAbilitiesFoChiefLogistUser,
  defineAbilitiesForSalesUser,
  defineAbilitiesForDispatcher,
} from '@/features/srm/abilities';
import {
  defineAbilitiesSopChiefClientManager,
  defineAbilitiesSopClientManager,
  defineAbilitiesSopManagerExpedite,
  defineAbilitiesSopLogist,
  defineAbilitiesSopChief,
  defineAbilitiesSopChiefLogist,
  defineAbilitiesSopChiefManagerExpedite,
} from '@/features/sop/abilities';
import {
  defineAbilitiesForClientManager,
  defineAbilitiesForDebtLimitAdministrator,
  defineAbilitiesForSupportBannerAdministrator,
  defineAbilitiesForTariffAdministrator,
} from '@/features/crm/abilities';
import {
  defineAbilitiesForFuelAdmin,
  defineAbilitiesForFuelClient,
} from '@/features/fuel/abilities';
import {
  definedAbilitiesTaskManager,
  definedAbilitiesTaskManagerCS,
} from '@/features/task-tracker/abilities';

export const ABILITY_ID = Symbol('Ability');

export function defineAbilitiesForSbUser(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  can('useSupport', 'AppLayout');

  can('use', 'Verification');
}

export function defineAbilitiesForIntegrationMonitorUser(builder: AbilityBuilder<AppAbility>): void {
  const { can } = builder;

  can('enter', 'IntegrationMonitor');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function defineAbilitiesFor(roles: string[]) {
  const builder = new AbilityBuilder<AppAbility>(Ability);

  builder.can('enter', 'AboutTab');
  builder.can('read', 'AppSidebarVersion');

  roles.forEach((role: string) => {
    switch (role) {
      case 'SRM_Dispatcher':
        defineAbilitiesForDispatcher(builder);
        break;
      case 'SB_User':
        defineAbilitiesForSbUser(builder);
        break;
      case 'SOP_Chief':
        defineAbilitiesSopChief(builder);
        break;
      case 'SOP_ChiefClientManager':
        defineAbilitiesSopChiefClientManager(builder);
        break;
      case 'SOP_ClientManager':
        defineAbilitiesSopClientManager(builder);
        break;
      case 'SOP_ManagerExpediteChief':
        defineAbilitiesSopChiefManagerExpedite(builder);
        break;
      case 'SOP_ManagerExpedite':
        defineAbilitiesSopManagerExpedite(builder);
        break;
      case 'SOP_ChiefLogist':
        defineAbilitiesSopChiefLogist(builder);
        break;
      case 'SOP_Logist':
        defineAbilitiesSopLogist(builder);
        break;
      case 'SRM_CarrierEmployee':
        defineAbilitiesForCarrierUser(builder);
        break;
      case 'SRM_Sales':
        defineAbilitiesForSalesUser(builder);
        break;
      case 'SRM_ManagerAttract':
        defineAbilitiesForAttractManager(builder);
        break;
      case 'SRM_ManagerExpedite':
        defineAbilitiesForExpeditionManager(builder);
        break;
      case 'SRM_Logist':
        definedAbilitiesForLogistUser(builder);
        break;
      case 'CRM_ClientManager':
        defineAbilitiesForClientManager(builder);
        break;
      case 'Tariff_Administrator':
        defineAbilitiesForTariffAdministrator(builder);
        break;
      case 'Fuel_admin':
        defineAbilitiesForFuelAdmin(builder);
        break;
      case 'Fuel_client':
        defineAbilitiesForFuelClient(builder);
        break;
      case 'SD_Notifier':
        defineAbilitiesForSupportBannerAdministrator(builder);
        break;
      case 'SRM_ChiefLogist':
        definedAbilitiesFoChiefLogistUser(builder);
        break;
      case 'Debt_Limit_Administrator':
        defineAbilitiesForDebtLimitAdministrator(builder);
        break;
      case 'LOG_TaskManagerCallSpecialist':
        definedAbilitiesTaskManagerCS(builder);
        break;
      case 'LOG_TaskManagerClientManager':
        definedAbilitiesTaskManager(builder);
        break;
      case 'LOG_TaskManagerCommercialDirector':
        definedAbilitiesTaskManager(builder);
        break;
      case 'LOG_TaskManagerExpediteManager':
        definedAbilitiesTaskManager(builder);
        break;
      case 'LOG_TaskManagerBusinessService':
        definedAbilitiesTaskManager(builder);
        break;
      case 'LOG_TaskManagerJobCreator':
        definedAbilitiesTaskManager(builder);
        break;
      case 'LOG_TaskManagerManagerAttract':
        definedAbilitiesTaskManager(builder);
        break;
      default:
        break;
    }
  });

  if (roles.includes('SRM_Chief')) {
    defineAbilitiesForChief(builder);
  }

  if (roles.includes('SRM_Chief')) {
    defineAbilitiesForChief(builder);
  }

  if (roles.includes('SRM_Admin')) {
    defineAbilitiesSRMAdmin(builder);
  }

  if (roles.includes('SRM_AllOrders')) {
    defineAbilitiesSRMAllOrders(builder);
  }

  if (roles.includes('int-monitor-user')) {
    defineAbilitiesForIntegrationMonitorUser(builder);
  }

  return builder.build();
}
