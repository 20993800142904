import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_textarea = _resolveComponent("ui-textarea")!
  const _component_ui_form_group = _resolveComponent("ui-form-group")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_ui_form_group, {
      class: _normalizeClass(_ctx.$style.formItem),
      field: $setup.v$.description
    }, {
      default: _withCtx(({ id, isInvalid }) => [
        _createVNode(_component_ui_textarea, {
          id: id,
          modelValue: $setup.v$.description.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.description.$model) = $event)),
          class: _normalizeClass(_ctx.$style.textarea),
          "is-invalid": isInvalid,
          resize: false,
          placeholder: "Напишите нам",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus:text-area')))
        }, null, 8, ["id", "modelValue", "class", "is-invalid"])
      ]),
      _: 1
    }, 8, ["class", "field"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.action)
    }, [
      ($props.showCloseButton)
        ? (_openBlock(), _createBlock(_component_ui_button, {
            key: 0,
            class: _normalizeClass(_ctx.$style.button),
            color: "secondary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Закрыть ")
            ]),
            _: 1
          }, 8, ["class"]))
        : (_openBlock(), _createBlock(_component_ui_button, {
            key: 1,
            "is-loading": $props.isLoading,
            class: _normalizeClass(_ctx.$style.button),
            color: "secondary",
            onClick: $options.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отправить ")
            ]),
            _: 1
          }, 8, ["is-loading", "class", "onClick"]))
    ], 2)
  ], 2))
}