
import AppSidebarButton from '@/features/shared/layout/AppSidebarButton.vue';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import container from '@/di';

export default {
  name: 'AppSidebarSecurity',

  components: { AppSidebarButton },

  props: {
    isWide: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    configService(): ConfigService {
      return container.get(CONFIG_SERVICE_ID);
    },

    authService(): AuthService {
      return container.get<AuthService>(AUTH_SERVICE_ID);
    },

    dashboardLink(): string {
      return this.configService.securityDashboardLink;
    },
  },
};
