import {
  createRouter as _createRouter,
  createWebHistory,
  LocationQuery,
  NavigationGuardNext,
  RouteLocationNormalizedLoaded,
  Router,
} from 'vue-router';
import qs from 'query-string';
import {
  subject,
} from '@casl/ability';
import { useToast } from 'vue-toastification';
import defineAbilitiesFor from '@/abilities';

import container from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';

import AppLayout from '@/features/shared/layout/AppLayout.vue';
import CrmLayout from '@/features/crm/components/CrmLayout.vue';

import SrmLayout from '@/features/srm/layout/SrmLayout.vue';
import {
  isNotPhone,
  isPhone,
} from '@/utils/device';
import { RelationType } from '@/features/srm/profile/constants';
import CarrierPreorderModule, { CARRIER_PREORDER_MODULE_ID } from '@/features/srm/truck-offering/store';
import { getHTTPError } from '@/utils/errors';
import SRMOrderModule, { SRM_ORDER_MODULE_ID } from '@/features/srm/orders/store/OrderStore';
import toastRedesigned, { toastContent } from '@/utils/toastRedesigned';

const EmptyForMetrics = () => import('@/features/shared/metrikaProxy/EmptyForMetrics.vue');
const TrackerLayout = () => import('@/features/task-tracker/TrackerLayout.vue');

const Users = () => import('@/features/srm/users/pages/Users.vue');
const Requests = () => import(/* webpackChunkName: "Requests" */ '@/features/crm/pages/requests/Requests.vue');
const RequestsForm = () => import(/* webpackChunkName: "RequestsForm" */ '@/features/crm/pages/form/RequestsForm.vue');
const CrmTruckOffering = () => import('@/features/crm/truck-offering/pages/TruckOffering.vue');

const OrdersWrapper = () => import(/* webpackChunkName: "Orders" */ '@/features/srm/orders/pages/OrdersWrapper.vue');
const Orders = () => import(/* webpackChunkName: "Orders" */ '@/features/srm/orders/pages/Orders.vue');
const Order = () => import(/* webpackChunkName: "Order" */ '@/features/srm/orders/pages/Order.vue');
const OrderAccept = () => import(/* webpackChunkName: "OrderAccept" */ '@/features/srm/orders/pages/OrderAccept.vue');
const OrderReserveWithCarrier = () => import(/* webpackChunkName: "OrderReserveWithCarrier" */
  '@/features/srm/orders/pages/OrderReserveWithCarrier.vue'
);
const RedesignedOrderReserveWithCarrier = () => import('@/features/redesigned/srm/orders/pages/OrderReserveWithCarrier.vue');
const VehicleAssign = () => import('@/features/srm/orders/panels/VehicleAssign.vue');
const OrderCancel = () => import(/* webpackChunkName: "OrderCancel" */ '@/features/srm/orders/pages/OrderCancel.vue');
const OrderDateManagement = () => import(
  /* webpackChunkName: "OrderDateManagement" */ '@/features/srm/orders/pages/OrderDateManagement.vue'
);

const RedesignedOrderDateManagement = () => import('@/features/redesigned/srm/orders/pages/OrderDateManagement.vue');
const RedesignedOrderAccept = () => import('@/features/redesigned/srm/orders/pages/OrderAccept.vue');
const RedesignedOrderCard = () => import('@/features/redesigned/srm/orders/carrier-orders/pages/OrderCard.vue');
const RedesignedOrder = () => import('@/features/redesigned/srm/orders/pages/Order.vue');
const RedesignedOrdersWrapper = () => import('@/features/redesigned/srm/orders/pages/OrdersWrapper.vue');
const RedesignedOrders = () => import('@/features/redesigned/srm/orders/pages/Orders.vue');

const OrderTab = () => import('@/features/redesigned/srm/orders/pages/OrderTab.vue');
const OrderAcceptTab = () => import('@/features/redesigned/srm/orders/pages/OrderAcceptTab.vue');
const OrderEditUnits = () => import('@/features/redesigned/srm/orders/pages/OrderEditUnits.vue');

const OrderEdit = () => import(/* webpackChunkName: "OrderEdit" */ '@/features/srm/orders/pages/OrderEdit.vue');
const OrderPreview = () => import(/* webpackChunkName: "OrderPreview" */ '@/features/srm/orders/pages/OrderPreview.vue');
const OrderShare = () => import(/* webpackChunkName: "OrderShare" */ '@/features/srm/orders/pages/OrderShare.vue');

const VehicleSuggestions = () => import('@/features/srm/suggestions/pages/VehicleSuggestions.vue');

const ProfileTabs = () => import('@/features/srm/profile/pages/ProfileTabs.vue');
const Profile = () => import('@/features/srm/profile/pages/Profile.vue');
const SrmNotifications = () => import('@/features/srm/profile/pages/Notifications.vue');
const SrmSubscriptions = () => import('@/features/srm/profile/pages/Subscriptions.vue');

const ProfileTabsCRM = () => import('@/features/crm/pages/profile/ProfileTabs.vue');
const ProfileCRM = () => import('@/features/crm/pages/profile/Profile.vue');
const NotificationsCRM = () => import('@/features/crm/pages/profile/Notifications.vue');

const Vehicles = () => import('@/features/srm/vehicles/pages/Vehicles.vue');
const Drivers = () => import('@/features/srm/drivers/pages/Drivers.vue');
const Warranty = () => import('@/features/srm/warranty/pages/Warranty.vue');

const SRMReports = () => import('@/features/srm/reports/pages/ReportsList.vue');
const WarrantyReport = () => import('@/features/srm/reports/pages/WarrantyReport.vue');

const Orgs = () => import('@/features/srm/orgs/pages/Orgs.vue');
const OrgTabs = () => import('@/features/srm/orgs/pages/OrgTabs.vue');
const OrgApprovingProcess = () => import('@/features/srm/orgs/pages/OrgApprovingProcess.vue');
const OrgDocuments = () => import('@/features/srm/orgs/pages/OrgDocuments.vue');
const OrgContracts = () => import('@/features/srm/contracts/pages/Contracts.vue');
const Org = () => import('@/features/srm/orgs/pages/OrgContainer.vue');
const OrgLoyaltyProgram = () => import('@/features/srm/orgs/pages/OrgLoyaltyProgram.vue');
const SrmOrgsJoiningRequests = () => import('@/features/srm/orgs/pages/OrgsJoiningRequests.vue');

const OrdersPlanning = () => import('@/features/srm/planning/pages/OrdersPlanning.vue');
const VolumeCard = () => import('@/features/srm/planning/pages/VolumeCard.vue');

const TruckOffering = () => import('@/features/srm/truck-offering/pages/TruckOffering.vue');
const TruckOfferingModal = () => import('@/features/srm/truck-offering/pages/TruckOfferingModal.vue');

const AverageRates = () => import('@/features/srm/average-rate/pages/AverageRates.vue');

const FuelGreeting = () => import('@/features/srm/fuel-greeting/pages/FuelGreeting.vue');
const PartnerProjectGreeting = () => import('@/features/srm/partner-project/pages/PartnerProjectGreeting.vue');

const Menu = () => import('@/features/shared/layout/AppMenu.vue');
const MenuExpedition = () => import('@/features/shared/menu/pages/MenuExpedition.vue');
const MenuProfile = () => import('@/features/shared/menu/pages/MenuProfile.vue');
const MenuOffice = () => import('@/features/shared/menu/pages/MenuOffice.vue');
const MenuSettings = () => import('@/features/shared/menu/pages/MenuSettings.vue');

const Subscriptions = () => import('@/features/redesigned/srm/profile/pages/Subscriptions.vue');
const Notifications = () => import('@/features/redesigned/srm/profile/pages/Notifications.vue');
const OrgProfile = () => import('@/features/redesigned/srm/profile/pages/OrgProfile.vue');
const OrgAdding = () => import('@/features/redesigned/srm/orgs/pages/OrgAdding.vue');
const OrgSendingJoinRequest = () => import('@/features/redesigned/srm/orgs/pages/OrgSendingJoinRequest.vue');
const OrgsJoiningRequests = () => import('@/features/redesigned/srm/orgs/pages/OrgsJoiningRequests.vue');
const OrgRegistration = () => import('@/features/redesigned/srm/orgs/pages/OrgRegistration.vue');
const Contracts = () => import('@/features/redesigned/srm/profile/pages/Contracts.vue');
const OrgDrivers = () => import('@/features/redesigned/srm/profile/pages/Drivers.vue');
const OrgTrailers = () => import('@/features/redesigned/srm/profile/pages/Trailers.vue');
const OrgTrucks = () => import('@/features/redesigned/srm/profile/pages/Trucks.vue');
const DriverCard = () => import('@/features/redesigned/srm/profile/pages/DriverCard.vue');
const VehicleCard = () => import('@/features/redesigned/srm/profile/pages/VehicleCard.vue');

const Landing = () => import('@/features/auth/pages/Landing.vue');
const NoAccess = () => import('@/features/auth/pages/NoAccess.vue');
const PageNotFound = () => import('@/features/shared/layout/AppPageNotFound.vue');
const AppWrapper = () => import('@/features/shared/layout/AppWrapper.vue');

const SecurityRequests = () => import('@/features/security/pages/SecurityRequests.vue');
const SecurityCounterparties = () => import('@/features/security/pages/SecurityCounterparties.vue');
const SecurityCounterparty = () => import('@/features/security/pages/SecurityCounterparty.vue');
const SecurityCounterpartyTabs = () => import('@/features/security/pages/SecurityCounterpartyTabs.vue');
const SecurityCounterpartyDrivers = () => import('@/features/security/pages/SecurityCounterpartyDrivers.vue');
const SecurityCounterpartyHistories = () => import('@/features/security/pages/SecurityCounterpartyHistories.vue');
const SecurityCounterpartyVehicles = () => import('@/features/security/pages/SecurityCounterpartyVehicles.vue');
const SecurityCounterpartyAutoCheck = () => import('@/features/security/pages/SecurityCounterpartyAutoCheck.vue');
const SecurityCounterpartyDocuments = () => import('@/features/security/pages/SecurityCounterpartyDocuments.vue');
const SecurityDefinitionSettings = () => import('@/features/security/pages/SecurityDefinitionSettings.vue');
const SecurityCargoSettings = () => import('@/features/security/pages/SecurityCargoSettings.vue');
const FileViewer = () => import('@/features/shared/pages/FilesViewer.vue');

const SOPDashboard = () => import('@/features/sop/dashboard/pages/Dashboard.vue');
const SopContractClients = () => import('@/features/sop/contracts/pages/ContractClients.vue');
const SopContractRequests = () => import('@/features/sop/contracts/pages/ContractRequests.vue');
const SopContracts = () => import('@/features/sop/contracts/pages/Contracts.vue');
const SopMepPlan = () => import('@/features/sop/mep-plan/pages/MepPlan.vue');
const SopLogistPlan = () => import('@/features/sop/logist-plan/pages/LogistPlan.vue');
const SopReport = () => import('@/features/sop/report/pages/Report.vue');
const SopReportList = () => import('@/features/sop/report/pages/ReportList.vue');
const SopControlPlan = () => import('@/features/sop/report/pages/ControlPlan.vue');
const SopReportVolume = () => import('@/features/sop/report/pages/ReportVolume.vue');

const FuelLayout = () => import('@/features/fuel/layout/FuelLayout.vue');
const FuelTransactions = () => import('@/features/fuel/transactions/pages/FuelTransactions.vue');
const FuelStatistics = () => import('@/features/fuel/statistics/pages/FuelStatistics.vue');
const FuelClients = () => import('@/features/fuel/clients/pages/FuelClients.vue');
const ClientCard = () => import('@/features/fuel/clients/pages/ClientCard.vue');
const ClientCardOverview = () => import('@/features/fuel/clients/pages/ClientCardOverview.vue');
const ClientCardEmployees = () => import('@/features/fuel/clients/pages/ClientCardEmployees.vue');
const ClientCardCards = () => import('@/features/fuel/clients/pages/ClientCardCards.vue');
const ClientCardLimits = () => import('@/features/fuel/clients/pages/ClientCardLimits.vue');
const ClientCardReport = () => import('@/features/fuel/clients/pages/ClientCardReport.vue');
const ClientCardNotifications = () => import('@/features/fuel/clients/pages/ClientCardNotifications.vue');
const FuelCards = () => import('@/features/fuel/cards/pages/FuelCards.vue');
const FuelTariffPolicy = () => import('@/features/fuel/tariff-policy/pages/TariffPolicyList.vue');

const SupportForm = () => import('@/features/support/components/supportForm/SupportForm.vue');
const SupportFilesViewer = () => import('@/features/support/components/supportForm/SupportFilesViewer.vue');
const BannerAdminPanel = () => import('@/features/support/pages/BannerAdminPanel.vue');

const IntegrationMonitor = () => import('@/features/integrations/pages/IntegrationMonitor.vue');
const IntegrationMonitorSummary = () => import('@/features/integrations/pages/IntegrationMonitorSummary.vue');
const IntegrationMonitorDetail = () => import('@/features/integrations/pages/IntegrationMonitorDetail.vue');

export function createRouter(): Router {
  const configService = container.get<ConfigService>(CONFIG_SERVICE_ID);
  const authService = container.get<AuthService>(AUTH_SERVICE_ID);
  const ability = defineAbilitiesFor(authService.getRoles() || []);

  const orderModule = container.get<SRMOrderModule>(SRM_ORDER_MODULE_ID);

  const isCarrier = (): boolean => !!authService.getRoles()?.includes(configService.carrierEmployee);

  const router = _createRouter({
    history: createWebHistory(),

    stringifyQuery: (params) => {
      const ns = qs.stringify(params, {
        arrayFormat: 'bracket',
        skipEmptyString: true,
        skipNull: true,
      });

      return ns ? `${ns}` : '';
    },

    parseQuery: (string) => qs.parse(string, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    }) as LocationQuery,

    routes: [
      {
        path: '',
        name: 'landing',
        component: Landing,
      },
      {
        path: '',
        name: 'wrapper',
        component: AppWrapper,
        children: [
          {
            path: 'yandex-metrika',
            name: 'yandex-metrika',
            component: EmptyForMetrics,
          },
          {
            path: 'menu',
            name: 'menu',
            component: Menu,
          },
          {
            path: 'expedition',
            name: 'menu-expedition',
            component: MenuExpedition,
          },
          {
            path: 'profile',
            name: 'profile',
            component: MenuProfile,
          },
          {
            path: 'office',
            name: 'office',
            component: MenuOffice,
          },
          {
            path: 'office/drivers/:driverId',
            name: 'driver-card',
            component: DriverCard,
          },
          {
            path: 'office/vehicles/:vehicleId',
            name: 'vehicle-card',
            component: VehicleCard,
          },
          {
            path: 'office/org-profile',
            name: 'org-profile',
            component: OrgProfile,
            async beforeEnter(_to, _from, next) {
              if (ability.can('loadFirstCounterparty', 'Orgs')) {
                const userModule = container.get<SRMUserModule>(SRM_USER_MODULE_ID);
                if (!userModule.user) {
                  await userModule.loadCurrentUser();
                }
                const counterparty = userModule.user?.userCounterparties[0];

                if (!counterparty) {
                  next({ name: 'org-adding' });
                  return;
                }

                if (counterparty?.relationTypeId === RelationType.CarrierEmployeeRequest) {
                  next({ name: 'orgs-joining-requests' });
                  return;
                }

                if (ability.can('read', 'OrgProfile')) {
                  next();
                  return;
                }
              }

              next({ name: 'landing' });
            },
          },
          {
            path: 'office/org-adding',
            name: 'org-adding',
            component: OrgAdding,
          },
          {
            path: 'office/org-sending-request',
            name: 'org-sending-join-request',
            component: OrgSendingJoinRequest,
            props: (route) => ({ ...route.query }),
            beforeEnter(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) {
              if (to.query.orgExistenceDTO) {
                next();
              } else {
                next({ name: 'org-adding' });
              }
            },
          },
          {
            path: 'office/orgs-joining-requests',
            name: 'orgs-joining-requests',
            component: OrgsJoiningRequests,
          },
          {
            path: 'office/org-registration',
            name: 'org-registration',
            component: OrgRegistration,
          },
          {
            path: 'office/contracts',
            name: 'contracts',
            component: Contracts,
          },
          {
            path: 'office/drivers',
            name: 'org-office-drivers',
            component: OrgDrivers,
          },
          {
            path: 'office/trailers',
            name: 'org-office-trailers',
            component: OrgTrailers,
          },
          {
            path: 'office/trucks',
            name: 'org-office-trucks',
            component: OrgTrucks,
          },
          {
            path: 'settings',
            name: 'settings',
            component: MenuSettings,
          },
          {
            path: 'subscriptions',
            name: 'subscriptions',
            component: Subscriptions,
          },
          {
            path: 'settings/notifications',
            name: 'notifications',
            component: Notifications,
          },
        ],
      },
      {
        path: '/no-access',
        name: 'no-access',
        component: NoAccess,
      },
      {
        path: '/file-viewer/:id/:type/',
        name: 'file-viewer',
        component: FileViewer,
        props: true,
      },
      {
        path: '/support-file-viewer',
        name: 'support-file-viewer',
        component: SupportFilesViewer,
        props: true,
      },
      {
        path: '/task-manager',
        name: 'tasks',
        redirect: { name: 'task-manager' },
        component: AppLayout,
        children: [{
          name: 'task-manager',
          path: '',
          component: TrackerLayout,
        }],
      },
      // Logistics routes
      {
        path: '/sop',
        name: 'sop',
        redirect: { name: 'contract-clients' },
        component: AppLayout,
        children: [
          {
            path: 'contracts',
            redirect: { name: 'contract-clients' },
            component: SopContracts,
            beforeEnter(_to, _from, next) {
              if (ability.can('forKM', 'sop')) {
                next();
              } else {
                next({ name: 'mep-plan' });
              }
            },
            children: [
              {
                name: 'contract-clients',
                path: 'clients',
                component: SopContractClients,
              },
              {
                name: 'contract-requests',
                path: 'requests',
                component: SopContractRequests,
              },
            ],
          },
          {
            name: 'mep-plan',
            path: 'mep-plan',
            component: SopMepPlan,
            beforeEnter(_to, _from, next) {
              if (ability.can('forMEP', 'sop')) {
                next();
              } else {
                next({ name: 'logist-plan' });
              }
            },
          },
          {
            name: 'logist-plan',
            path: 'logist-plan',
            component: SopLogistPlan,
            beforeEnter(_to, _from, next) {
              if (ability.can('forLogist', 'sop')) {
                next();
              } else {
                next({ name: 'report' });
              }
            },
          },
          {
            name: 'report',
            path: 'report',
            redirect: { name: 'list' },
            component: SopReport,
            children: [
              {
                name: 'list',
                path: 'list',
                component: SopReportList,
              },
              {
                name: 'control-plan',
                path: 'control-plan',
                component: SopControlPlan,
              },
              {
                name: 'report-volume',
                path: 'report-volume',
                component: SopReportVolume,
              },
            ],
          },
          {
            name: 'sop-dashboard',
            path: 'dashboard',
            component: SOPDashboard,
          },
        ],
        meta: {
          role: [
            configService.sopClientManagerRole,
            configService.sopChiefClientManagerRole,
            configService.sopMepRole,
            configService.sopChiefMepRole,
            configService.sopLogistRole,
            configService.sopChiefLogistRole,
            configService.sopChiefRole,
          ],
        },
      },
      // Security routes
      {
        path: '/security',
        name: 'security',
        redirect: { name: 'security-requests' },
        component: AppLayout,
        children: [
          {
            name: 'security-requests',
            path: 'requests',
            component: SecurityRequests,
          },
          {
            name: 'security-counterparties',
            path: 'counterparties',
            component: SecurityCounterparties,
          },
          {
            name: 'security-definition-settings',
            path: 'definition-settings',
            component: SecurityDefinitionSettings,
          },
          {
            name: 'security-cargo-settings',
            path: 'cargo-settings',
            component: SecurityCargoSettings,
          },
          {
            path: 'counterparty/:id',
            component: SecurityCounterpartyTabs,
            children: [
              {
                name: 'security-counterparty',
                path: '',
                component: SecurityCounterparty,
              },
              {
                name: 'security-counterparty-drivers',
                path: 'drivers',
                component: SecurityCounterpartyDrivers,
              },
              {
                name: 'security-counterparty-vehicles',
                path: 'vehicles',
                component: SecurityCounterpartyVehicles,
              },
              {
                name: 'security-counterparty-history',
                path: 'history',
                component: SecurityCounterpartyHistories,
              },
              {
                name: 'security-counterparty-auto-check',
                path: 'auto-check',
                component: SecurityCounterpartyAutoCheck,
              },
              {
                name: 'security-counterparty-documents',
                path: 'documents',
                component: SecurityCounterpartyDocuments,
              },
            ],
          },
        ],
        meta: { role: [configService.securityRole] },
      },
      // SRM Routes
      {
        path: '/srm',
        name: 'srm',
        component: SrmLayout,
        redirect: { name: 'orders' },
        children: [
          {
            name: 'orders-wrapper',
            path: '',
            component: () => (isCarrier() && isNotPhone() ? RedesignedOrdersWrapper() : OrdersWrapper()),
            redirect: { name: 'orders' },
            children: [
              {
                path: 'orders',
                name: 'orders',
                alias: '/orders',
                component: () => (isCarrier() && isNotPhone() ? RedesignedOrders() : Orders()),
                children: [
                  {
                    name: 'order',
                    path: ':orderId',
                    component: () => {
                      if (ability.can('use', 'RedesignedSrm')) {
                        return isPhone() ? RedesignedOrderCard() : OrderTab();
                      }
                      return isPhone() ? RedesignedOrder() : Order();
                    },
                    beforeEnter: async (to, _from, next) => {
                      if (!ability.can('read', 'OrderCard') || isPhone()) {
                        next();
                        return;
                      }

                      orderModule.loadOrderCard(to.params.orderId)
                        .catch((e) => {
                          const { status } = e.response;
                          if (e.response && status === 401) {
                            toastRedesigned.error(toastContent('Похоже, эту заявку уже кто-то забрал.'));
                          } else {
                            toastRedesigned.error(toastContent(getHTTPError(e)));
                          }

                          next({ name: 'orders' });
                        });

                      next();
                    },
                    children: [
                      {
                        name: 'order-preview',
                        path: 'preview',
                        component: OrderPreview,
                      },
                      {
                        name: 'order-share',
                        path: 'share',
                        component: OrderShare,
                      },
                      {
                        name: 'order-edit',
                        path: 'edit',
                        component: OrderEdit,
                      },
                      {
                        name: 'order-tab-actualize',
                        path: 'actualize',
                        redirect: !isCarrier() ? { name: 'order-actualize' } : undefined,
                        component: () => RedesignedOrderDateManagement(),
                      },
                      {
                        name: 'order-tab-accept',
                        path: 'accept',
                        redirect: !isCarrier() ? { name: 'order-accept' } : undefined,
                        component: () => OrderAcceptTab(),
                        props: true,
                      },
                    ],
                  },
                  {
                    name: 'order-edit-units',
                    path: ':orderId/edit-units',
                    component: OrderEditUnits,
                    beforeEnter: async (
                      to: RouteLocationNormalizedLoaded,
                      _from: RouteLocationNormalizedLoaded,
                      next: NavigationGuardNext,
                    ) => {
                      await orderModule.loadOrder({ orderId: to.params.orderId });
                      if (!ability.can('carrierEditUnits', orderModule.order)) {
                        next({ name: 'order', params: { orderId: to.params.orderId } });
                        return;
                      }
                      next();
                    },
                  },
                  {
                    name: 'order-actualize',
                    path: ':orderId/actualize/:event',
                    component: () => (isPhone() ? RedesignedOrderDateManagement() : OrderDateManagement()),
                  },
                  {
                    name: 'order-accept',
                    path: ':orderId/accept',
                    component: () => (isPhone() ? RedesignedOrderAccept() : OrderAccept()),
                    props: true,
                  },
                  {
                    name: 'order-reserve-with-carrier',
                    path: ':orderId/reserve-with-carrier',
                    component: () => (isPhone() ? RedesignedOrderReserveWithCarrier() : OrderReserveWithCarrier()),
                  },
                  {
                    name: 'order-vehicle-assign',
                    path: ':orderId/vehicle-assign',
                    component: VehicleAssign,
                  },
                  {
                    name: 'order-cancel',
                    path: ':orderId/cancel',
                    component: OrderCancel,
                  },
                ],
              },
              {
                path: 'planning',
                name: 'planning',
                component: OrdersPlanning,
                children: [
                  {
                    name: 'volume',
                    path: ':orderId',
                    component: VolumeCard,
                  },
                ],
              },
            ],
          },
          {
            path: 'orgs',
            alias: '/orgs',
            component: Orgs,
            name: 'orgs',
            async beforeEnter(to, _from, next) {
              const userModule = container.get<SRMUserModule>(SRM_USER_MODULE_ID);
              await userModule.loadCurrentUser();

              if (ability.can('loadFirstCounterparty', 'Orgs')) {
                const counterparty = userModule.user?.userCounterparties[0];
                if (counterparty?.relationTypeId === RelationType.CarrierEmployeeRequest) {
                  next({ name: 'srm-orgs-joining-requests' });
                  return;
                }

                if (counterparty && counterparty.counterpartyId) {
                  const carrierAsSubject = () => subject('CarrierModel', counterparty.carrier);
                  const routeName = to.params.name ?? 'org';

                  next({
                    name: ability.can('redirectToOrgDocs', carrierAsSubject()) ? 'org-documents' : routeName,
                    params: {
                      orgId: counterparty.counterpartyId,
                    },
                  });
                  return;
                }
              }

              next();
            },
          },
          {
            path: 'orgs/:orgId',
            alias: '/orgs/:orgId',
            component: OrgTabs,
            async beforeEnter(_to, _from, next) {
              if (ability.can('loadFirstCounterparty', 'Orgs')) {
                const userModule = container.get<SRMUserModule>(SRM_USER_MODULE_ID);
                await userModule.loadCurrentUser();

                const counterparty = userModule.user?.userCounterparties[0];
                if (!counterparty) {
                  next('orgs');
                }

                if (counterparty?.relationTypeId === RelationType.CarrierEmployeeRequest) {
                  next({ name: 'srm-orgs-joining-requests' });
                  return;
                }
              }

              next();
            },
            children: [
              {
                name: 'org',
                path: '',
                component: Org,
              },
              {
                name: 'org-documents',
                path: 'docs',
                component: OrgDocuments,
              },
              {
                name: 'org-contracts',
                path: 'contracts',
                component: OrgContracts,
              },
              {
                name: 'org-drivers',
                path: 'drivers',
                component: Drivers,
              },
              {
                name: 'org-vehicles',
                path: 'vehicles',
                component: Vehicles,
              },
              {
                name: 'org-warranty',
                path: 'warranty',
                component: Warranty,
              },
              {
                name: 'org-users',
                path: 'users',
                component: Users,
              },
              {
                name: 'org-approving-process',
                path: 'approving',
                component: OrgApprovingProcess,
              },
              {
                name: 'org-loyalty-program',
                path: 'loyalty-program',
                component: OrgLoyaltyProgram,
              },
            ],
          },
          {
            path: 'orgs-joining-requests',
            name: 'srm-orgs-joining-requests',
            component: SrmOrgsJoiningRequests,
          },
          {
            name: 'vehicle-suggestions',
            path: 'orders/:orderId/suggestions',
            component: VehicleSuggestions,
            children: [
              {
                name: 'order-accept-suggestions',
                path: ':id/accept',
                component: OrderAccept,
              },
            ],
          },
          {
            name: 'srm-subscriptions',
            path: 'subscriptions',
            component: SrmSubscriptions,
          },
          {
            path: 'profile',
            component: ProfileTabs,
            children: [
              {
                name: 'srm-profile',
                path: '',
                component: Profile,
              },
              {
                name: 'srm-notifications',
                path: 'notifications',
                component: SrmNotifications,
              },
            ],
          },
          {
            name: 'reports',
            path: 'reports',
            alias: '/reports',
            component: SRMReports,
          },
          {
            name: 'warranty-report',
            path: 'reports/warranty-report',
            alias: '/reports/warranty-report',
            component: WarrantyReport,
          },
          {
            name: 'users',
            path: 'users',
            alias: '/users',
            component: Users,
          },
          {
            name: 'partner-project-greeting',
            path: 'partner-project-greeting',
            component: PartnerProjectGreeting,
          },
          {
            path: 'fuel-greeting',
            name: 'fuel-greeting',
            component: FuelGreeting,
            meta: {
              role: [configService.carrierEmployee],
              excludedRoles: [configService.fuelAdmin, configService.fuelClient],
            },
            beforeEnter(to, _from, next) {
              const hasExcludedRole = to.matched.some((record) => (record.meta.excludedRoles as string[])
                ?.some((role) => authService.getRoles()?.includes(role)));

              if (hasExcludedRole) {
                next({ name: 'no-access' });
              } else {
                next();
              }
            },
          },
          {
            path: 'truck-offering',
            name: 'truck-offering',
            component: TruckOffering,
            children: [
              {
                name: 'truck-offering-order',
                path: 'order/:orderId',
                component: () => (isPhone() ? RedesignedOrderCard() : OrderTab()),
                beforeEnter: async (to, _from, next) => {
                  const toast = useToast();

                  if (ability.can('viewOrder', 'Order')) {
                    try {
                      await orderModule.loadOrderCard(to.params.orderId);
                      next();
                    } catch (e) {
                      if (ability.can('use', 'RedesignedToast')) {
                        toastRedesigned.error(toastContent(getHTTPError(e)));
                      } else {
                        toast.error(getHTTPError(e));
                      }

                      next({ name: 'truck-offering' });
                    }
                  } else {
                    if (ability.can('use', 'RedesignedToast')) {
                      toastRedesigned.error(toastContent(('Вам недоступна данная заявка')));
                    } else {
                      toast.error('Вам недоступна данная заявка');
                    }

                    next({ name: 'truck-offering' });
                  }
                },
                children: [
                  {
                    name: 'truck-offering-order-tab-accept',
                    path: 'accept',
                    redirect: !isCarrier() ? { name: 'order-accept' } : undefined,
                    component: () => OrderAcceptTab(),
                    props: true,
                  },
                ],
              },
              {
                name: 'truck-offering-create',
                path: 'truck-offering-create',
                component: TruckOfferingModal,
              },
              {
                name: 'truck-offering-copy',
                path: ':truckOfferingId/copy',
                component: TruckOfferingModal,
                beforeEnter: async (
                  to: RouteLocationNormalizedLoaded,
                  _from: RouteLocationNormalizedLoaded,
                  next: NavigationGuardNext,
                ) => {
                  const toast = useToast();
                  const carrierPreorderModule = container.get<CarrierPreorderModule>(CARRIER_PREORDER_MODULE_ID);

                  const { truckOfferingId } = to.params;
                  try {
                    await carrierPreorderModule.loadPreorderById(truckOfferingId);
                  } catch (e) {
                    toast.error(getHTTPError(e));
                  }

                  next();
                },
              },
              {
                name: 'truck-offering-edit',
                path: ':truckOfferingId/edit',
                component: TruckOfferingModal,
                beforeEnter: async (to, _from, next) => {
                  const toast = useToast();
                  const carrierPreorderModule = container.get<CarrierPreorderModule>(CARRIER_PREORDER_MODULE_ID);

                  const { truckOfferingId } = to.params;
                  try {
                    await carrierPreorderModule.loadPreorderById(truckOfferingId as number);
                  } catch (e) {
                    toast.error(getHTTPError(e));
                  }

                  next();
                },
              },
            ],
          },
          {
            path: 'average-rates',
            name: 'average-rates',
            component: AverageRates,
          },
        ],
        meta: {
          role: [
            configService.expeditionManagerRole,
            configService.attractionManagerRole,
            configService.carrierEmployee,
            configService.srmChiefRole,
            configService.srmAdminRole,
            configService.srmLogisticRole,
            configService.dispatcherRole,
          ],
        },
        async beforeEnter(_to, _from, next) {
          try {
            const userModule = container.get<SRMUserModule>(SRM_USER_MODULE_ID);
            await userModule.loadCurrentUser();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
          next();
        },
      },
      // CRM Routes
      {
        path: '/crm',
        name: 'crm',
        redirect: { name: 'requests' },
        component: CrmLayout,
        children: [
          {
            path: 'profile',
            component: ProfileTabsCRM,
            children: [
              {
                name: 'crm-profile',
                path: '',
                component: ProfileCRM,
              },
              {
                name: 'crm-notifications',
                path: 'notifications',
                component: NotificationsCRM,
              },
            ],
          },
          {
            name: 'requests',
            path: 'requests',
            alias: '/requests',
            component: Requests,
            children: [
              {
                name: 'requests-edit',
                path: ':requestId/edit',
                component: RequestsForm,
                props: {
                  mode: 'edit',
                },
              },
              {
                name: 'requests-create',
                path: 'add',
                component: RequestsForm,
                props: {
                  mode: 'create',
                },
              },
              {
                name: 'requests-template',
                path: ':requestId/template',
                component: RequestsForm,
                props: {
                  mode: 'template',
                },
              },
            ],
          },
          {
            name: 'requests-documents',
            path: 'requests-documents',
            alias: '/requests-documents',
            component: Requests,
            children: [
              {
                name: 'requests-documents-edit',
                path: ':requestId/edit',
                component: RequestsForm,
                props: {
                  mode: 'request-document-edit',
                },
              },
            ],
          },
          {
            name: 'requests-client-list',
            path: 'clients',
            component: () => import('@/features/crm/pages/client/Clients.vue'),
          },
          {
            // name: 'requests-client',
            path: 'client/:clientId',
            component: () => import('@/features/crm/pages/client/Client.vue'),
            children: [
              {
                name: 'requests-client',
                path: '',
                component: () => import('@/features/crm/pages/client/ClientGeneral.vue'),
              },
              {
                name: 'requests-client-locations',
                path: 'locations',
                component: () => import('@/features/crm/pages/client/ClientLocations.vue'),
              },
              {
                name: 'requests-client-mrz',
                path: 'mrz',
                component: () => import('@/features/crm/mrz/rules/pages/MrzClientChannels.vue'),
              },
              {
                name: 'requests-client-debt',
                path: 'debt',
                component: () => import('@/features/crm/pages/client/ClientDebt.vue'),
              },
              {
                name: 'requests-client-manager',
                path: 'manager',
                component: () => import('@/features/crm/pages/client/ClientManager.vue'),
              },
            ],
          },
          {
            name: 'requests-tariffs',
            path: 'tariffs',
            component: () => import('@/features/crm/pages/tariffs/Tariffs.vue'),
            meta: { role: [configService.tariffAdmin] },
          },
          {
            name: 'requests-tariff',
            path: 'tariff/:tariffId?',
            component: () => import('@/features/crm/pages/tariffs/Tariff.vue'),
            meta: { role: [configService.tariffAdmin] },
          },
          {
            name: 'requests-create-tariff',
            path: 'tariff/new/:donorId?',
            component: () => import('@/features/crm/pages/tariffs/Tariff.vue'),
            meta: { role: [configService.tariffAdmin] },
          },
          {
            name: 'banner-admin-panel',
            path: 'banner-admin-panel',
            component: BannerAdminPanel,
            meta: { role: [configService.sdNotifier] },
          },
          {
            path: 'truck-offering',
            name: 'crm-truck-offering',
            component: CrmTruckOffering,
          },
        ],
        meta: { role: [configService.clientManagerRole] },
      },
      // Fuel Routes
      {
        path: '/fuel',
        name: 'fuel',
        redirect: { name: 'fuel-transactions' },
        component: FuelLayout,
        children: [
          {
            name: 'fuel-transactions',
            path: 'transactions',
            component: FuelTransactions,
          },
          {
            name: 'fuel-statistics',
            path: 'statistics',
            component: FuelStatistics,
          },
          {
            name: 'fuel-clients',
            path: 'clients',
            component: FuelClients,
            meta: {
              role: [configService.fuelAdmin],
            },
          },
          {
            name: 'fuel-client-card',
            path: 'clients/:clientId',
            redirect: { name: 'fuel-client-overview' },
            component: ClientCard,
            children: [
              {
                name: 'fuel-client-overview',
                path: 'overview',
                component: ClientCardOverview,
              },
              {
                name: 'fuel-client-employees',
                path: 'employees',
                component: ClientCardEmployees,
              },
              {
                name: 'fuel-client-cards',
                path: 'cards',
                component: ClientCardCards,
              },
              {
                name: 'fuel-client-limits',
                path: 'limits',
                component: ClientCardLimits,
              },
              {
                name: 'fuel-client-notifications',
                path: 'notification',
                component: ClientCardNotifications,
                meta: {
                  role: [configService.fuelClient],
                },
              },
              {
                name: 'fuel-report',
                path: 'report',
                component: ClientCardReport,
                meta: {
                  role: [configService.fuelAdmin],
                },
              },
            ],
          },
          {
            name: 'fuel-cards',
            path: 'cards',
            component: FuelCards,
            meta: {
              role: [configService.fuelAdmin],
            },
          },
          {
            name: 'fuel-tariff-policy',
            path: 'tariff-policy',
            component: FuelTariffPolicy,
            meta: {
              role: [configService.fuelAdmin],
            },
          },
        ],
        meta: {
          role: [configService.fuelAdmin, configService.fuelClient],
        },
      },

      {
        name: 'technical-support',
        path: '/technical-support',
        component: SupportForm,
      },
      {
        name: 'integration-monitor',
        redirect: { name: 'integration-monitor-summary' },
        path: '',
        component: IntegrationMonitor,
        children: [
          {
            name: 'integration-monitor-summary',
            path: 'int-monitor-summary',
            component: IntegrationMonitorSummary,
          },
          {
            name: 'integration-monitor-detail',
            path: 'int-monitor-detail',
            component: IntegrationMonitorDetail,
          },
        ],
      },

      {
        name: 'page-not-found',
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: {
          pageNotFound: true,
        },
      },
    ],
  });

  router.beforeEach((to, _from, next) => {
    if (
      to.matched.every((record) => {
        if (record.meta.role) {
          return (record.meta.role as string[]).some((role) => authService.getRoles()?.includes(role));
        }
        return true;
      })
    ) {
      next();
    } else {
      next({ name: 'no-access' });
    }
  });

  return router;
}

export const ROUTER_ID = Symbol('router');
