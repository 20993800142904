import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_datepicker = _resolveComponent("ui-datepicker")!
  const _component_ui_form_group = _resolveComponent("ui-form-group")!
  const _component_ui_input_text = _resolveComponent("ui-input-text")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_with_content = _resolveComponent("ui-modal-with-content")!

  return (_openBlock(), _createBlock(_component_ui_modal_with_content, {
    target: "modal",
    width: 450,
    "close-on-click-overlay": false,
    onClose: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString($options.reportType), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ui_form_group, {
        field: $setup.v$.period,
        label: "Период"
      }, {
        default: _withCtx(({ id }) => [
          _createVNode(_component_ui_datepicker, {
            id: id,
            modelValue: $setup.v$.period.$model,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.period.$model) = $event)),
              $options.onChangeDate
            ],
            placeholder: "Выберите период",
            range: ""
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["field"]),
      ($props.isFastPay)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.emailRow)
          }, [
            _createVNode(_component_ui_form_group, {
              field: $setup.v$.email,
              label: "Почта"
            }, {
              default: _withCtx(({ id }) => [
                _createVNode(_component_ui_input_text, {
                  id: id,
                  modelValue: $setup.v$.email.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.email.$model) = $event)),
                  placeholder: "Введите email"
                }, null, 8, ["id", "modelValue"])
              ]),
              _: 1
            }, 8, ["field"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_ui_button, {
        "is-loading": $data.loading,
        disabled: $setup.v$.$error,
        onClick: _withModifiers($options.confirm, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Отправить на почту ")
        ]),
        _: 1
      }, 8, ["is-loading", "disabled", "onClick"])
    ]),
    _: 1
  }))
}