import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_app_header_menu_item = _resolveComponent("app-header-menu-item")!
  const _component_app_header_fuel_user = _resolveComponent("app-header-fuel-user")!
  const _component_app_header_carrier_user = _resolveComponent("app-header-carrier-user")!
  const _component_app_header_manager_expedition = _resolveComponent("app-header-manager-expedition")!
  const _component_app_header_task_manager_user = _resolveComponent("app-header-task-manager-user")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_tooltip = _resolveComponent("ui-tooltip")!
  const _component_ui_tooltip_wrapper = _resolveComponent("ui-tooltip-wrapper")!
  const _component_logout_confirm = _resolveComponent("logout-confirm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.panel)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.left)
    }, [
      _createElementVNode("a", {
        class: _normalizeClass(_ctx.$style.logoContainer),
        href: "/"
      }, [
        _createVNode(_component_svg_icon, {
          class: _normalizeClass(_ctx.$style.logo),
          name: "logo_new",
          "svg-use-data-qa": "AppHeaderLogoIcon"
        }, null, 8, ["class"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.menu)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.flex)
        }, [
          ($options.isExpeditionTabActive)
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 0,
                to: { name: 'srm' },
                "is-active": $props.isExpeditionActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($options.expeditionTabLabel), 1)
                ]),
                _: 1
              }, 8, ["is-active"]))
            : _createCommentVNode("", true),
          ($options.showTM)
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 1,
                to: { name: 'tasks' },
                "is-active": $props.isTasksActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Задачи ")
                ]),
                _: 1
              }, 8, ["is-active"]))
            : _createCommentVNode("", true),
          ($options.authService.isClientManager())
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 2,
                to: { name: 'crm' },
                "is-active": $props.isOrdersActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Заявки ")
                ]),
                _: 1
              }, 8, ["is-active"]))
            : _createCommentVNode("", true),
          ($options.authService.isSecurityManager())
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 3,
                to: { name: 'security' },
                "is-active": $props.isSecurityActive,
                "data-qa": "AppHeaderMenuItemSecurity"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Безопасность ")
                ]),
                _: 1
              }, 8, ["is-active"]))
            : _createCommentVNode("", true),
          ($options.showSOP)
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 4,
                to: { name: 'sop' },
                "is-active": $props.isLogisticsActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Планирование ")
                ]),
                _: 1
              }, 8, ["is-active"]))
            : _createCommentVNode("", true),
          (_ctx.$can('enter', 'FuelTab'))
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 5,
                "is-active": $props.isFuelActive,
                to: $options.fuelRoute
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Топливо ")
                ]),
                _: 1
              }, 8, ["is-active", "to"]))
            : _createCommentVNode("", true),
          (_ctx.$can('enter', 'IntegrationMonitor'))
            ? (_openBlock(), _createBlock(_component_app_header_menu_item, {
                key: 6,
                "is-active": $props.isIntegrationMonitor,
                to: $options.integrationMonitorRoute
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Мониторинг ошибок интеграции ")
                ]),
                _: 1
              }, 8, ["is-active", "to"]))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.$can('enter', 'AboutTab'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.about),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('about-modal-open')))
            }, " О программе ", 2))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.userWrapper)
      }, [
        ($options.showFuelUser)
          ? (_openBlock(), _createBlock(_component_app_header_fuel_user, {
              key: 0,
              class: _normalizeClass(_ctx.$style.user)
            }, null, 8, ["class"]))
          : ($options.showCarrierUser)
            ? (_openBlock(), _createBlock(_component_app_header_carrier_user, {
                key: 1,
                class: _normalizeClass(_ctx.$style.user)
              }, null, 8, ["class"]))
            : ($options.showManagerExpeditionUser)
              ? (_openBlock(), _createBlock(_component_app_header_manager_expedition, {
                  key: 2,
                  class: _normalizeClass(_ctx.$style.user)
                }, null, 8, ["class"]))
              : ($options.showTM)
                ? (_openBlock(), _createBlock(_component_app_header_task_manager_user, {
                    key: 3,
                    class: _normalizeClass(_ctx.$style.user)
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_ui_tooltip_wrapper, null, {
        trigger: _withCtx(() => [
          _createVNode(_component_ui_button, {
            appearance: "icon",
            icon: "logout_right",
            "icon-class-name": _ctx.$style.logoutIcon,
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.isLogoutOpenModal = true))
          }, null, 8, ["icon-class-name"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ui_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(" Выход ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2),
    ($data.isLogoutOpenModal)
      ? (_openBlock(), _createBlock(_component_logout_confirm, {
          key: 0,
          onAccept: $options.onLogout,
          onClose: _cache[2] || (_cache[2] = ($event: any) => ($data.isLogoutOpenModal = false))
        }, null, 8, ["onAccept"]))
      : _createCommentVNode("", true)
  ], 2))
}