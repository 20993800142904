import container, {
  Inject,
  Injectable,
} from '@/di';
import SRMLoyaltyService, { SRM_LOYALTY_SERVICE_ID } from '@/features/srm/services/SRMLoyaltyService';
import {
  API_APPLY_CASHBACK,
  API_CARRIER_HAS_LOYALTY,
  API_CARRIER_LOYALTY_INFO,
  API_CASHBACK_HISTORY,
  API_MAX_CASHBACK_BY_ORDER_ID,
} from '@/features/srm/loyalty/services/endpoints';
import {
  ApplyCashbackParams,
  CarrierHasLoyaltyInfo,
  CashbackHistoryItem,
  LoyaltyInfo,
  OrderCashbackResponse,
} from '@/features/srm/loyalty/types';
import {
  Page,
  PageParams,
} from '@/features/shared/types';

export const LOYALTY_SERVICE_ID = Symbol('loyaltyService');

@Injectable()
export default class LoyaltyService {
  srmLoyaltyService;

  constructor(
    @Inject(SRM_LOYALTY_SERVICE_ID) srmLoyaltyService: SRMLoyaltyService,
  ) {
    this.srmLoyaltyService = srmLoyaltyService;
  }

  getLoyaltyInfo(): Promise<LoyaltyInfo> {
    return this.srmLoyaltyService.request({
      method: 'GET',
      url: API_CARRIER_LOYALTY_INFO,
    });
  }

  carrierHasLoyalty(): Promise<CarrierHasLoyaltyInfo> {
    return this.srmLoyaltyService.request({
      method: 'GET',
      url: API_CARRIER_HAS_LOYALTY,
    });
  }

  getCashbackHistory(params: PageParams): Promise<Page<CashbackHistoryItem>> {
    return this.srmLoyaltyService.request({
      method: 'GET',
      url: API_CASHBACK_HISTORY,
      params,
    });
  }

  applyCashback(data: ApplyCashbackParams): Promise<string> {
    return this.srmLoyaltyService.request({
      method: 'POST',
      url: API_APPLY_CASHBACK,
      data,
    });
  }

  getOrderCashbackInfo(orderId: string): Promise<OrderCashbackResponse> {
    return this.srmLoyaltyService.request({
      method: 'GET',
      url: API_MAX_CASHBACK_BY_ORDER_ID(orderId),
    });
  }
}

container.bind<LoyaltyService>(LOYALTY_SERVICE_ID).to(LoyaltyService);
