

import { PropType } from 'vue';
import { Styles } from '@/features/shared/types';

export default {
  name: 'UiTooltip',

  props: {
    hasBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    hasShadow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    rootClasses(): Styles {
      return {
        [this.$style.tooltip]: true,
        [this.$style.border]: this.hasBorder,
        [this.$style.shadow]: this.hasShadow,
      };
    },
  },
};
