import container from '@/di';
import { ABILITY_ID } from '@/abilities';
import {
  formatDate,
  parseDate,
} from '@/filters/date';
import { AppAbility } from '@/abilities.types';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import { CarrierSelectOption } from '@/features/srm/orders/types';
import {
  CreatePreorderParams,
  TruckOfferingForm,
} from '@/features/srm/truck-offering/types';
import { PreorderSummaryDTO } from '@/features/shared/truck-offering/types';
import {
  CityFilterValue,
  LocationExtended,
} from '@/features/srm/types';
import { MultiSelectOption } from '@/features/shared/types';
import { TrailerType } from '@/features/shared/constant';

export default class CarrierPreorderMapper {
  static toRequest(item: TruckOfferingForm): CreatePreorderParams {
    const ability = container.get<AppAbility>(ABILITY_ID);
    let carrierId;
    let carrierName;

    if (ability.can('use', 'TruckOfferingForCarrier')) {
      const userModule = container.get<SRMUserModule>(SRM_USER_MODULE_ID);
      const org = userModule?.user?.userCounterparties[0]?.counterparty;
      carrierId = org?.id;
      carrierName = org?.name;
    }

    const unloadingPlace = item.unloadingPlace
      ? {
        id: item.unloadingPlace?.location?.id,
        regionId: item.unloadingPlace?.location?.region.id,
        name: item.unloadingPlace?.location?.filter,
      }
      : null;

    const desiredPriceValueWithVat = item.desiredPriceValueWithVat ? parseFloat(item.desiredPriceValueWithVat as string) : null;

    return {
      carrierId: carrierId ?? (item.carrier as CarrierSelectOption)?.counterpartyId,
      carrierName: carrierName ?? item.carrier?.name,
      trailerType: item.trailerType?.value,
      trailerVolumeTypeId: item.trailerVolumeType?.value,
      loadingPoint: {
        id: item.loadingPlace?.location?.id,
        regionId: item.loadingPlace?.location?.region.id,
        name: item.loadingPlace?.location?.filter,
      },
      loadingStartDate: item.period ? formatDate(item.period[0], 'DD-MM-YYYY') : null,
      loadingEndDate: item.period ? formatDate(item.period[1], 'DD-MM-YYYY') : null,
      loadingDistanceOffset: item.loadingPlace?.radius ?? 0,
      unloadingPoint: unloadingPlace,
      unloadingDistanceOffset: item.unloadingPlace?.radius,
      comment: item.comment,
      desiredPriceValueWithVat,
      desiredPriceVatRateTypeId: item.desiredPriceVatRateTypeId?.value,
    } as CreatePreorderParams;
  }

  static fromResponse(item: PreorderSummaryDTO): TruckOfferingForm {
    const trailerTypeOptions: MultiSelectOption<TrailerType>[] = [
      { name: 'Тент', value: TrailerType.Tent },
      { name: 'Реф', value: TrailerType.Ref },
    ];
    const trailerType = trailerTypeOptions.find(
      (option) => option.value.toLowerCase() === item.trailerTypeCode.toLowerCase(),
    ) ?? null;

    const loadingPlace = {
      location: {
        id: item.loadingCityId,
        filter: item.loadingCityName,
        region: {
          id: item.loadingRegionId,
          name: item.loadingRegionName,
        },
      },
      radius: item.loadingDistanceOffset,
    } as LocationExtended<CityFilterValue>;

    const unloadingPlace = {
      location: {
        id: item.unloadingCityId,
        filter: item.unloadingCityName,
        region: {
          id: item.unloadingRegionId,
          name: item.unloadingRegionName,
        },
      },
      radius: item.unloadingDistanceOffset,
    } as LocationExtended<CityFilterValue>;

    return {
      trailerType,
      trailerVolumeType: item.trailerVolumeTypeId
        ? {
          name: `${item.trailerVolumeTypeName}, м\u00B3` as string,
          value: item.trailerVolumeTypeId as number,
        }
        : null,
      loadingPlace: item.loadingCityId ? loadingPlace : null,
      unloadingPlace: item.unloadingCityId ? unloadingPlace : null,
      period: [parseDate(item.loadingStartDate) as Date, parseDate(item.loadingEndDate) as Date],
      carrier: item.carrierName
        ? {
          counterpartyId: item.carrierId ?? undefined,
          name: item.carrierName as string,
        }
        : null,
      comment: item.comment,
      desiredPriceValueWithVat: item.desiredPriceValueWithVat,
      desiredPriceVatRateTypeId: item.desiredPriceVatRateTypeId
        ? {
          name: item.desiredPriceVatRateTypeName as string,
          value: item.desiredPriceVatRateTypeId,
        } : null,
    };
  }
}
