import './bootstrap';

import {
  createApp,
  h,
} from 'vue';
import { initYandexMetrika } from 'yandex-metrika-vue3';
import { abilitiesPlugin } from '@casl/vue';
import Toast, { POSITION } from 'vue-toastification';
import { vOnClickOutside } from '@vueuse/components';
import { createHead } from '@unhead/vue';
import maskDirective from '@/directives/mask';
import App from '@/App.vue';

import container, {
  registerConstantValue,
} from '@/di';
// import {
//   createLogger,
//   LOGGER_ID,
// } from '@/logger';
import {
  createStore,
  STORE_ID,
} from '@/store';
import {
  createRouter,
  ROUTER_ID,
} from '@/router';
import defineAbilitiesFor, { ABILITY_ID } from '@/abilities';
import AuthService, { AUTH_SERVICE_ID } from '@/features/auth/services/auth';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';

async function initApp() {
  const app = createApp(App);

  const authService = container.get<AuthService>(AUTH_SERVICE_ID);
  const isAuth = await authService.init();
  if (!isAuth) {
    authService.redirectToLoginPage();
  }
  authService.scheduleTokenRefresh();
  await authService.fetchProfile();

  const store = createStore();
  registerConstantValue(STORE_ID, store);
  app.use(store);

  // const logger = createLogger(app);
  // registerConstantValue(LOGGER_ID, logger);
  //
  // logger.setUser({
  //   id: authService.getUserId(),
  //   email: authService.getUserEmail(),
  //   username: authService.getUserName(),
  // });

  const ability = defineAbilitiesFor(authService.getRoles() || []);
  registerConstantValue(ABILITY_ID, ability);
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  });
  const head = createHead();
  app.use(head);

  const router = createRouter();
  registerConstantValue(ROUTER_ID, router);
  app.use(router);

  app.use(Toast, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000,
    maxToasts: 5,
    hideProgressBar: true,
    toastClassName: 'gt-toast',
    closeButton: {
      functional: true,
      render() {
        return h(
          'button',
          { class: 'Vue-Toastification__close-button' },
          [
            h(SvgIcon, { name: 'close_light' }),
          ],
        );
      },
    },
    filterBeforeCreate(toast, toasts) {
      const hasSameToasts = toasts.some((item) => item.content === toast.content);
      if (hasSameToasts) {
        return false;
      }
      return toast;
    },
  });

  if (process.env.VUE_APP_YANDEX_METRIKA_ID) {
    const userCompany = authService.getCompanyName() ?? authService.getFirstGroupName() ?? 'без компании, без группы';

    app.use(initYandexMetrika, {
      id: process.env.VUE_APP_YANDEX_METRIKA_ID,
      router,
      env: process.env.NODE_ENV,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        userParams: {
          UserID: userCompany,
          fio: authService.getUserName(),
          id: authService.getUserId(),
        },
      },
    });
  }

  app.directive('click-outside', vOnClickOutside);
  app.directive('mask', maskDirective);

  app.mount('#app');
}

initApp()
  .catch((e) => console.error(e));
