export default function generateStoreActions(method) {
  return {
    async getList({ commit }, request) {
      try {
        commit('SET_LOADING', true);

        const {
          content: list,
          totalPages,
          number,
        } = await method({
          page: 1,
          size: 50,
          ...request,
        });

        commit('SET_LIST', list);
        commit('SET_PAGE', number + 1);
        commit('SET_TOTAL_PAGES', totalPages);

        commit('SET_LOADING', false);

        return Promise.resolve();
      } catch (e) {
        commit('SET_LOADING', false);

        console.error(e);
        return Promise.reject(e);
      }
    },
  };
}
