
import { PropType } from 'vue';
import tippy, { Instance } from 'tippy.js';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import {
  Nullable,
  Styles,
} from '@/features/shared/types';
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import defaultProps, { booleanProps } from '@/features/shared/kit/UiTooltipWrapper.props';

export default {
  name: 'UiTooltipWithContent',

  components: {
    SvgIcon,
  },

  props: {
    to: {
      type: [String, Element] as PropType<Nullable<Element> | string>,
      default: null,
    },

    toSelector: {
      type: [String, Element] as PropType<Nullable<Element> | string>,
      default: null,
    },

    toElement: {
      type: [String, Element] as PropType<Nullable<Element> | string>,
      default: null,
    },

    triggerTarget: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    trigger: {
      type: String as PropType<string>,
      validator: (value) => [
        'manual',
        'click',
        'focusin',
        'mouseenter focus',
        'mouseenter click',
        'mouseenter manual',
      ].includes(value),

      default: 'mouseenter focus',
    },

    enabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    arrow: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    arrowPosition: {
      type: String as PropType<string>,
      validator: (value) => [
        'top',
        'bottom',
        'left',
        'right',
      ].includes(value),

      default: 'top',
    },

    arrowAlign: {
      type: String as PropType<string>,
      validator: (value) => [
        'start',
        'center',
        'end',
      ].includes(value),

      default: 'center',
    },

    visible: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    hideOnClick: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    placement: {
      type: String as PropType<string>,
      validator: (value) => [
        'top',
        'top-start',
        'top-end',
        'right',
        'right-start',
        'right-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left',
        'left-start',
        'left-end',
        'auto',
        'auto-start',
        'auto-end',
      ].includes(value),

      default: 'auto',
    },

    interactive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    theme: {
      type: String as PropType<string>,
      validator: (value) => ['default', 'primary', 'dark'].includes(value),
      default: 'default',
    },

    closed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    hiddenClose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    shownDelay: {
      type: Number as PropType<number>,
      default: 0,
    },

    hiddenDelay: {
      type: Number as PropType<number>,
      default: 200,
    },

    offset: {
      type: Array as PropType<Nullable<Array<number>>>,
      default: null,
    },

    customStylesContainer: {
      type: Object as PropType<Nullable<Record<string, string>>>,
      default: null,
    },

    skidding: {
      type: Number as PropType<number>,
      default: 0,
    },

    distance: {
      type: Number as PropType<number>,
      default: 0,
    },

    zIndex: {
      type: Number as PropType<number>,
      default: 27,
    },
  },

  data() {
    return {
      tip: null as Nullable<Instance>,
      options: {},
    };
  },

  computed: {
    isInteractive(): boolean {
      return this.closed || this.interactive || this.isPrimaryTheme;
    },

    isManualTrigger(): boolean {
      return this.trigger === 'manual' || this.isInteractive;
    },

    isPrimaryTheme(): boolean {
      return this.theme === 'primary';
    },

    isDarkTheme(): boolean {
      return this.theme === 'dark';
    },

    isCloseEnabled(): boolean {
      return (this.closed || this.isPrimaryTheme) && !this.hiddenClose;
    },

    contentWrapperStyles(): Styles {
      return {
        [this.$style.content]: true,
        [this.$style.primary]: this.isPrimaryTheme,
        [this.$style.dark]: this.isDarkTheme,
      };
    },

    arrowStyles(): Styles {
      return {
        [this.$style.arrow]: this.arrow,
        [this.$style[`${this.arrowPosition}`]]: this.arrow,
        [this.$style[`${this.arrowAlign}`]]: this.arrow,
        [this.$style.primary]: this.arrow && this.isPrimaryTheme,
        [this.$style.dark]: this.arrow && this.isDarkTheme,
      };
    },
  },

  watch: {
    content() {
      if (this.tip !== null) {
        this.tip?.setProps(this.getOptions());
      }
    },

    enabled(val: boolean) {
      if (!this.tip) return;

      if (val) {
        this.tip?.enable();
      } else {
        this.tip?.hide();
        this.tip?.disable();
        this.$emit('close');
      }
    },

    visible(val: boolean) {
      if (!this.tip) return;

      if (val) {
        this.tip?.show();
      } else {
        this.tip?.hide();
        this.$emit('hide');
      }
    },
  },

  mounted() {
    this.init();
  },

  updated() {
    if (this.tip) {
      this.tip.setProps(this.getOptions());
    }
  },

  beforeUnmount() {
    if (!this.tip) return;
    this.tip.destroy();
  },

  methods: {
    init() {
      if (this.tip) {
        this.tip.destroy();
        this.tip = null;
      }

      let elm = this.toElement;

      if (elm == null) {
        if (this.to) {
          elm = document.querySelector(`[name='${this.to}']`);
        } else if (this.toSelector) {
          elm = document.querySelector(this.toSelector as string);
        } else if (
          this.$refs.trigger
          && this.$refs.trigger.childElementCount > 0
        ) {
          elm = this.$refs.trigger as Element;
        } else {
          elm = this.$el.parentElement;
        }
      }

      if (!elm) return;
      const tip = tippy(elm as Element, this.getOptions());

      if (!tip) return;

      if (Array.isArray(tip)) {
        if (tip.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.tip = tip[0];
        } else {
          return;
        }
      }

      this.tip = tip;

      this.$emit('on-create', this.tip);
      this.$emit('init', this.tip);

      if (this.enabled === false) {
        this.tip.disable();
      }

      if (this.isManualTrigger && this.visible === true) {
        this.tip.show();
      }
    },

    tippy() {
      return this.tip as Element;
    },

    filterOptions() {
      const getValue = (key, value) => {
        if (booleanProps.hasOwnProperty(key)) {
          if (value === '') return true;
          if (value === 'false') return false;
          if (value === 'true') return true;
        }

        return value;
      };

      Object.keys(this.options).forEach((key) => {
        if (!defaultProps.hasOwnProperty(key)) {
          delete this.options[key];
        } else {
          this.options[key] = getValue(key, this.options[key]);
        }
      });

      return this.options;
    },

    getOptions() {
      Object.assign(
        this.options,
        mapKeys(
          this.$attrs,
          (value, key) => camelCase(key),
        ),
      );

      this.filterOptions();

      if (!this.options.onShow && this.$attrs.onShow) {
        this.options.onShow = (...args) => this.$attrs.onShow(...args);
      }

      if (!this.options.onShown) {
        this.options.onShown = (...args) => {
          this.$emit('shown', ...args);
        };
      }

      if (!this.options.onHidden) {
        this.options.onHidden = (...args) => {
          this.$emit('hidden', ...args);
        };
      }

      if (!this.options.onHide && this.$attrs.onHide) {
        this.options.onHide = (...args) => this.$attrs.onHide(...args);
      }

      if (!this.options.onMount) {
        this.options.onMount = (...args) => {
          this.$emit('mount', ...args);
        };
      }

      if (!this.options.hasOwnProperty('content')) {
        this.options.content = this.content ? this.content : this.$refs.content;
      }

      if (this.trigger) {
        this.options.trigger = this.trigger;
      }

      if (this.isInteractive) {
        this.options.interactive = this.isInteractive;
      }

      if (typeof (this.hideOnClick) !== 'undefined') {
        this.options.hideOnClick = this.hideOnClick;
      }

      this.options.placement = this.placement;

      this.options.triggerTarget = this.triggerTarget;
      this.options.offset = this.offset?.length ? this.offset : [this.skidding, this.distance];
      this.options.zIndex = this.zIndex;

      this.options.delay = [this.shownDelay, this.hiddenDelay];

      return this.options;
    },

    onClickClose() {
      this.tip?.hide();

      this.$emit('close');
    },
  },
};
