import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.type), {
    to: $props.to,
    class: _normalizeClass($options.classes),
    target: $props.target,
    "data-qa": $props.dataQa
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.icons)
      }, [
        ($props.icon)
          ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 0,
              class: _normalizeClass(_ctx.$style.icon),
              name: $props.icon,
              "data-qa": $props.svgDataQa,
              "svg-use-data-qa": $props.svgUseDataQa
            }, null, 8, ["class", "name", "data-qa", "svg-use-data-qa"]))
          : _createCommentVNode("", true),
        ($props.additionalIcon)
          ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 1,
              class: _normalizeClass([_ctx.$style.icon, _ctx.$style.additionalIcon]),
              name: $props.additionalIcon
            }, null, 8, ["class", "name"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("span", {
        "data-qa": $props.textDataQa,
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString($props.caption), 11, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to", "class", "target", "data-qa"]))
}