import {
  computed,
  ComputedRef,
} from 'vue';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import container from '@/di';
import {
  Nullable,
  Undefinable,
} from '@/features/shared/types';
import {
  CarrierModel,
  CounterpartyModel,
} from '@/features/srm/orgs/services/OrgService.types';
import { UserModel } from '@/features/srm/profile/services/UserService.types';

interface CarrierInfoModule {
  carrier: ComputedRef<Nullable<CarrierModel>>;
  counterparty: ComputedRef<Nullable<CounterpartyModel>>;
  carrierId: ComputedRef<Nullable<string>>;
  user: ComputedRef<Undefinable<UserModel>>;
}

export const useCarrierInfo = (): CarrierInfoModule => {
  const userModule = computed<SRMUserModule>(() => container.get(SRM_USER_MODULE_ID));
  const user = computed<Undefinable<UserModel>>(() => userModule.value.user);

  const carrier = computed<Nullable<CarrierModel>>(() => userModule.value?.user?.userCounterparties[0]?.carrier ?? null);
  const counterparty = computed<Nullable<CounterpartyModel>>(
    () => userModule.value?.user?.userCounterparties[0]?.counterparty ?? null,
  );
  const carrierId = computed<Nullable<string>>(() => counterparty.value?.id ?? null);

  return {
    carrier,
    counterparty,
    carrierId,
    user,
  };
};
