
import { PropType } from 'vue';
import UiLabel from '@/features/shared/kit/UiLabel.vue';
import {
  Nullable,
  Validation,
  Styles,
} from '@/features/shared/types';

const errors = {
  required: () => 'Обязательное поле',
  requiredIf: () => 'Обязательное поле',
  // minLength: (arg) => (arg.min ? `Минимум ${arg.min} символов` : `Минимум ${arg.$params.min} символов`),
  // maxLength: (arg) => (arg.max ? `Максимум ${arg.max} символов` : `Минимум ${arg.$params.max} символов`),
  email: () => 'Введите корректный адрес почты',
  phone: () => 'Введите корректный номер телефона',
  isAllowedCarrierId: () => 'Заявку может принять только согласованный перевозчик',
  checkEndDate: () => 'Дата не может быть раньше начальной',
  checkStartDate: () => 'Дата должна быть раньше конечной',
  aboveZero: () => 'Значение должно быть больше 0',
  checkEndDateBetweenPeriod: () => 'Есть периоды с более поздней датой',
  checkStartDateBetweenPeriod: () => 'Есть периоды с более ранней датой',
  checkDateBetweenWarranty: () => 'Дата должна быть в рамках гарантии',
  checkWarrantyNumber: () => 'Гарантия с таким номером уже существует',
  isActualDate: () => 'Дата не может быть прошедшей',
  vehicleCountValidateFractional: () => 'Количество не может быть дробным',
  notNull: () => 'Значение должно быть больше 0',
  priceFreightMoreThanStartPrice: () => 'Стартовая цена аукциона должна быть не больше цены фрахта',
  startAuctionPriceLessFreightPrice: () => 'Сумма фрахта должна быть выше или равна стартовой цене аукциона',
  dateLessThanStartDateLoading: () => 'Дата начала или завершения аукциона должна быть не позже даты начала первой погрузки',
  dateTimeStartMoreThanDateTimeEnd: () => 'Дата/время начала события должна быть меньше даты/времени окончания',
  dateTimeStartMoreThanDateTimeEndPrevPoint: () => 'Нарушена хронологическая последовательность погрузок и разгрузок',
  dateEndLessThanDateTimeStartNextPoint: () => 'Нарушена хронологическая последовательность погрузок и разгрузок',
  dateTimeStartLessThanFourDaysBefore: () => (
    'Заявка просрочена, т.к. указанная дата погрузки меньше текущей даты более, чем на 4 дня'
  ),
  // minimumAuctionStep: (arg) => (arg.step ? `Минимальный шаг аукциона ${arg.step}руб. Пожалуйста, укажите другую величину шага`
  //   : `Минимальный шаг аукциона ${arg.$params.step}руб. Пожалуйста, укажите другую величину шага`),
  dateLoadingMoreThanDateAuction: () => 'Дата начала погрузки должна быть позже даты окончания аукциона',
  startPriceLessThanMinPrice: () => 'Стартовая цена не может быть меньше минимальной',
  minPriceMoreThanStartPrice: () => 'Минимальная цена не можеть быть больше стартовой',
  // minimumAuctionBet: (arg) => (arg.minBid ? `Минимальный размер ставки ${arg.minBid}руб. Пожалуйста, укажите другую сумму`
  //   : `Минимальный размер ставки ${arg.$params.minBid}руб. Пожалуйста, укажите другую сумму`),
  dateMoreThanClientConfirmationDeadline: () => 'Дата не можеть быть позже срока подтвержения клиенту',
  clientConfirmationDeadlineLessThanAuctionEndDate: () => 'Срок подтверждения клиенту не может быть раньше окончания аукциона',
  clientConfirmationDeadlineLessThanOfferEndDate:
    () => 'Срок подтверждения клиенту не может быть раньше окончания запроса предложений',
  maximumAuctionStep: () => 'Максимальный шаг аукциона не может быть больше разницы между стартовой и минамальной ценами',
  maxContinueValue: () => 'Время продления не может быть больше 300 секунд',
  endDateMoreThanStart: () => 'Дата должна быть позже начальной',
  fio: () => 'Значение может содержать только буквенные символы и тире',
  lessThanLimitAdmin: () => 'Значение не должно превышать ограничение',
  isSameOrBeforeThanToday: () => 'Дата не должна быть позже текущей',
  isSameLocationInternationalType: () => (
    'Данная локация международная, при этом тип перевозки выбран "Внутренняя" или "Междугородная",'
    + ' измените локации или смените тип перевозки на "Международная"'
  ),
  isValidTransportationInternationalType:
    () => 'Выбран тип погрузки "Международная", при этом не выбрано ни одной международной локации.',
  isValidTransportationInternalType: () => (
    'Выбран тип погрузки "Внутренняя", при этом не выбрано ни одной внутренней локации.'
  ),
  isValidTransportationIntercityType: () => (
    'Выбран тип погрузки "Междугородная", при этом не выбрано ни одной междугородной локации.'
  ),
  isManagerDismissed: () => (
    'Данный сотрудник уволен, выберите другого.'
  ),
  checkIdOrder: () => 'Заявки не существует',
  isCorrectInitials: () => 'Введите корректные инициалы',
};

export default {
  name: 'UiFormGroup',

  components: {
    UiLabel,
  },

  props: {
    narrowErrorMessage: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    label: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    prependIcon: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    id: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    field: {
      type: Object as PropType<Nullable<Validation<unknown>>>,
      default: null,
    },

    message: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    customErrorMessage: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },

    labelDataQa: {
      type: String as PropType<Nullable<string>>,
      default: null,
    },
  },

  computed: {
    classes(): Styles {
      return {
        [this.$style.errorMessage]: true,
        [this.$style.narrowErrorMessage]: this.narrowErrorMessage,
      };
    },

    computedId(): string {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `input-${this._uid}`;
    },

    computedLabel(): Nullable<string> {
      return this.label ? `${this.label}${this.isRequired ? '*' : ''}` : null;
    },

    isRequired(): boolean {
      if (!this.field) {
        return false;
      }

      return !!(this.field?.required);
    },

    isInvalid(): boolean {
      return !!this.field?.$error;
    },

    errorMessage(): string {
      if (!this.field || !this.isInvalid) return '';

      let message = '';
      if (this.field) {
        if (this.customErrorMessage !== null) {
          message = this.customErrorMessage;
          return message;
        }

        const validationKey = Object.keys(this.field).find((key) => !!this.field[key].$invalid);
        if (validationKey) {
          if (Object.hasOwnProperty.call(errors, validationKey)) {
            message = errors[validationKey](this.field[validationKey]);
          } else if (this.field[validationKey]?.$message) {
            message = this.field[validationKey]?.$message;
          } else {
            message = 'Ошибка';
          }
        }
      } else {
        this.field?.$silentErrors?.map((error) => {
          message = errors[error?.type](this.field[error?.type]);
          return error;
        });
      }

      return message;
    },
  },
};
