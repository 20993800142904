import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("textarea", _mergeProps(_ctx.$attrs, {
      id: $options.internalId,
      ref: "input",
      "data-testid": "UiTextarea",
      class: $options.inputClasses,
      value: _ctx.modelValue,
      disabled: _ctx.disabled,
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }), null, 16, _hoisted_1),
    (_ctx.isHaveAppendedContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.appendedContent)
        }, [
          _renderSlot(_ctx.$slots, "append", {}, () => [
            (_ctx.rightIcon)
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.rightIcon),
                  name: _ctx.rightIcon
                }, null, 8, ["class", "name"]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}