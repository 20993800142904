import container, {
  Inject,
  Injectable,
} from '@/di';
import HttpService, { SRM_SERVICE_ID } from '@/features/srm/services/http';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import SRMWarrantyReportsRemoteService, {
  SRM_WARRANTY_REPORTS_REMOTE_SERVICE,
} from '@/features/srm/reports/services/WarantyReportsRemoteService';
import {
  ActualDateReportWithEmailLocalFiltersAfterMapping,
  ActualDatesReportEmailResponse,
  ReportFastPayParams,
  ReportsModelDTO,
  ReportsQueryModel,
} from '@/features/srm/reports/services/ReportsService.types';
import {
  API_ORDER_ACTUAL_DATES_REPORT_WITH_EMAIL,
  API_ORDER_REJECTION_REASON,
  API_QUICK_PAYMENT,
  API_QUICK_PAYMENT_REPORT_TO_EMAIL,
  API_REPORT_ORDERS,
  API_REPORT_ORDERS_FILE,
  API_REPORT_SERVICE,
  API_REPORT_SERVICE_FILE,
} from '@/features/srm/reports/services/endpoints';
import { FastPayReportResponse } from '@/features/srm/reports/types';

export const SRM_REPORT_SERVICE_ID = Symbol('SRMReportService');

/**
 * https://dev.api.portal.gt.local/report-service/swagger-ui/index.html?configUrl=/report-service/v3/api-docs/swagger-config
 */

@Injectable()
export default class SRMReportService {
  private api: HttpService;

  private warrantyReportsRemoteService: SRMWarrantyReportsRemoteService;

  constructor(
    @Inject(SRM_SERVICE_ID) api: HttpService,
    @Inject(CONFIG_SERVICE_ID) config: ConfigService,
    @Inject(SRM_WARRANTY_REPORTS_REMOTE_SERVICE)
      warrantyReportsRemoteService: SRMWarrantyReportsRemoteService,
  ) {
    this.api = api;
    this.api.remoteClient.defaults.baseURL = config.SRMReportServiceHost;
    this.warrantyReportsRemoteService = warrantyReportsRemoteService;
  }

  /**
   * Получить отчет по быстрой оплате
   */
  getReportFastPay(data: ReportFastPayParams): Promise<string | FastPayReportResponse> {
    const url = data.email ? API_QUICK_PAYMENT_REPORT_TO_EMAIL : API_QUICK_PAYMENT;

    return this.api.request({
      method: 'POST',
      url,
      data,
    });
  }

  getReportReasonsRejection(data: { from: string; to: string }): Promise<string> {
    return this.api.request({
      method: 'POST',
      url: API_ORDER_REJECTION_REASON,
      data,
    });
  }

  /**
   * Получить отчет по гарантийным заявкам
   */
  getWarrantyRequestReports(params: ReportsQueryModel): Promise<ReportsModelDTO> {
    return this.warrantyReportsRemoteService.request({
      method: 'GET',
      url: API_REPORT_ORDERS,
      params,
    });
  }

  /**
   * Получить отчет по выполнению обязательств по гарантиям
   */
  getWarrantyExecutionReports(params: ReportsQueryModel): Promise<ReportsModelDTO> {
    return this.warrantyReportsRemoteService.request({
      method: 'GET',
      url: API_REPORT_SERVICE,
      params,
    });
  }

  /**
   * Получение отчета в виде постраничного списка гарантийных заявок
   *
   * @carrierId {string} - Идентификатор перевозчика
   * @loadingStartDate {string} - Дата начала промежутка погрузки
   * @loadingEndDate {string} - Дата конца промежутка погрузки
   */
  getOrdersExcelReport(params: ReportsQueryModel): Promise<Blob> {
    return this.warrantyReportsRemoteService.request({
      method: 'GET',
      url: API_REPORT_ORDERS_FILE,
      params,
      responseType: 'blob',
    });
  }

  /**
   * Получение отчета в виде постраничного списка с информацией об уровне сервиса перевозчика
   *
   * @carrierId {string} - Идентификатор перевозчика
   * @loadingStartDate {string} - Дата начала промежутка погрузки
   * @loadingEndDate {string} - Дата конца промежутка погрузки
   */
  getServiceExcelReport(params: ReportsQueryModel): Promise<Blob> {
    return this.warrantyReportsRemoteService.request({
      method: 'GET',
      url: API_REPORT_SERVICE_FILE,
      params,
      responseType: 'blob',
    });
  }

  sendActualDatesReportEmail(data: ActualDateReportWithEmailLocalFiltersAfterMapping): Promise<ActualDatesReportEmailResponse> {
    return this.api.request({
      method: 'POST',
      url: API_ORDER_ACTUAL_DATES_REPORT_WITH_EMAIL,
      data,
      timeout: 200000,
    });
  }
}

container.bind<SRMReportService>(SRM_REPORT_SERVICE_ID).to(SRMReportService);
