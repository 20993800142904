import RemoteService from '@/features/shared/services/base/remote';
import { Register } from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import { authRequestInterceptor } from '@/features/shared/services/suggestions/suggestions.interceptors';
import {
  SuggestionQueryParams,
  SuggestionsResponse,
} from '@/features/shared/services/suggestions/types';
import {
  API_SUGGEST_PARTY,
  API_FINDBYID_PARTY,
} from '@/features/shared/services/endpoints';

export const SUGGESTIONS_SERVICE_ID = Symbol('suggestionsService');

@Register(
  SUGGESTIONS_SERVICE_ID,
  [
    CONFIG_SERVICE_ID,
  ],
)
class SuggestionsService extends RemoteService {
  constructor(configService: ConfigService) {
    super(configService.suggestionsServiceHost);

    this.remoteClient.interceptors.request.use(authRequestInterceptor);
  }

  getPartySuggestions(data: SuggestionQueryParams): Promise<SuggestionsResponse> {
    return this.request({
      method: 'POST',
      url: API_SUGGEST_PARTY,
      data,
    });
  }

  getPartyById(data: { query: string }): Promise<SuggestionsResponse> {
    return this.request({
      method: 'POST',
      url: API_FINDBYID_PARTY,
      data,
    });
  }
}

export default SuggestionsService;
