import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_transition = _resolveComponent("default-transition")!

  return (_openBlock(), _createBlock(_Teleport, {
    to: `#${$props.target}`
  }, [
    _createVNode(_component_default_transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.container, _ctx.$style[$props.overlayColor]]),
          style: _normalizeStyle({ backgroundColor: $props.overlayColor, zIndex: $props.zIndex }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClose && $options.onClose(...args)))
        }, [
          _renderSlot(_ctx.$slots, "close"),
          _renderSlot(_ctx.$slots, "default")
        ], 6)
      ]),
      _: 3
    })
  ], 8, ["to"]))
}