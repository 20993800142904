/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-class-modules';
import { Store } from 'vuex';
import { IMessage } from '@stomp/stompjs';
import container, {
  Inject,
  Injectable,
  LazyInject,
} from '@/di';
import { STORE_ID } from '@/store';
import SRMOrgService, { SRM_ORG_SERVICE_ID } from '@/features/srm/orgs/services/OrgService';
import {
  DictionaryModel,
  Nullable,
} from '@/features/shared/types';
import {
  OrgExistenceDTO,
  OrgModel,
  OrgsWithFilterModel,
} from '@/features/srm/orgs/services/OrgService.types';
import SRMUserModule, { SRM_USER_MODULE_ID } from '@/features/srm/profile/store/UserModule';
import SRMUserService, { SRM_USER_SERVICE_ID } from '@/features/srm/profile/services/UserService';
import { UserModel } from '@/features/srm/profile/services/UserService.types';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';
import SrmCounterpartyService, {
  SRM_COUNTERPARTY_SERVICE_ID,
} from '@/features/srm/counterparty/services/CounterpartyService';
import OrganizationsService, {
  ORGANIZATIONS_SERVICE_ID,
} from '@/features/shared/services/organizations';
import { RequestsHttp } from '@/features/security/services/ServicesTypes';
import {
  CounterModel,
  FilterModel,
} from '@/features/srm/counterparty/services/CounterpartyService.types';
import SRMWebsocketModule, {
  SRM_WEBSOCKET_MODULE_ID,
} from '@/features/srm/store/SRMWebsocketStore';
import { OrderAuctionShort } from '@/features/srm/orders/services/OrderService.types';
import {
  WS_TOPICS_ADDRESS_ORG,
  WS_TOPICS_ADDRESS_ORG_ID,
} from '@/features/srm/orgs/services/endpoints';
import StatusMapper from '@/features/srm/orgs/mappers/StatusMapper';
import {
  LoadCarrierMPPParams,
  UpdateManagerParams,
} from '@/features/srm/orgs/types';
import { CounterpartyLegalForm } from '@/features/shared/constant';
import {
  CarrierType,
  orgLogistInitial,
} from '@/features/srm/orgs/constants';
import SRMService, { SRM_SERVICE_ID } from '@/features/srm/services/SRMService';
import { stringToConstantCase } from '@/features/srm/constants';

const ORGS_PER_PAGE = 20;
const MPP = 'ManagerAttract';
const OMAP = 'ResponsibleManagerExpedite';

export const SRM_ORG_MODULE_ID = 'srm-org';

@Module
@Injectable()
export default class SRMOrgModule extends VuexModule {
  @LazyInject(SRM_WEBSOCKET_MODULE_ID) websocketModule: SRMWebsocketModule;

  // Injections
  orgService: SRMOrgService;

  userModule: SRMUserModule;

  userService: SRMUserService;

  srmService: SRMService;

  configService: ConfigService;

  organizationsService: OrganizationsService;

  counterpartyService: SrmCounterpartyService;

  // State
  statuses: DictionaryModel[] = [];

  ndsTypes: DictionaryModel[] = [];

  legalForms: DictionaryModel[] = [];

  shippingTypes: DictionaryModel[] = [];

  carrierTypes: DictionaryModel[] = [];

  commercialRatings: DictionaryModel[] = [];

  developmentStatuses: DictionaryModel[] = [];

  carrierStatuses: DictionaryModel[] = [];

  reasonSuspendedCarrierStatus: DictionaryModel[] = [];

  orgs: OrgModel[] = [];

  orgsOffset = 0;

  orgsTotal = 0;

  orgsFilters?: FilterModel = undefined;

  orgsCounters?: CounterModel = undefined;

  org?: OrgModel = undefined;

  carriers: CarrierType[] = [];

  statusMessage = '';

  statusMessageOrg = '';

  carrierMPP: UserModel = <UserModel>{};

  carrierOMAP: UserModel = <UserModel>{};

  expeditors: UserModel[] = [];

  carrierAttracts: UserModel[] = [];

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(STORE_ID) store: Store<any>,
    @Inject(SRM_SERVICE_ID) srmService: SRMService,
    @Inject(SRM_ORG_SERVICE_ID) orgService: SRMOrgService,
    @Inject(SRM_USER_MODULE_ID) userModule: SRMUserModule,
    @Inject(SRM_USER_SERVICE_ID) userService: SRMUserService,
    @Inject(CONFIG_SERVICE_ID) configService: ConfigService,
    @Inject(SRM_COUNTERPARTY_SERVICE_ID) counterpartyService: SrmCounterpartyService,
    @Inject(ORGANIZATIONS_SERVICE_ID) organizationsService: OrganizationsService,
  ) {
    super({ store, name: SRM_ORG_MODULE_ID });
    this.orgService = orgService;
    this.srmService = srmService;
    this.userModule = userModule;
    this.userService = userService;
    this.configService = configService;
    this.counterpartyService = counterpartyService;
    this.organizationsService = organizationsService;
  }

  get statusById() {
    return (id?: number) => {
      if (!this.orgsFilters) return null;
      return StatusMapper.fromResponse(this.orgsFilters.status).find((item) => item.id === id);
    };
  }

  get ndsTypeById() {
    return (id?: Nullable<number>) => this.ndsTypes.find((item) => item.id === id);
  }

  get legalFormById() {
    return (id?: number) => this.orgsFilters?.legalForm.find((item) => item.id === id);
  }

  get legalFormByName() {
    return (name?: string) => this.legalForms.find((item) => item.name === name);
  }

  get shippingTypeById() {
    return (id?: number) => this.shippingTypes.find((item) => item.id === id);
  }

  get carrierTypeById() {
    return (id?: number) => this.carrierTypes.find((item) => item.id === id);
  }

  get commercialRatingById() {
    return (id?: number) => this.commercialRatings.find((item) => item.id === id);
  }

  get developmentStatusById() {
    return (id?: number) => this.developmentStatuses.find((item) => item.id === id);
  }

  isIndividualBusinessman() {
    return this.org?.counterparty?.legalForm.id === CounterpartyLegalForm.IP;
  }

  getCurrentPage() {
    return this.orgsOffset / ORGS_PER_PAGE;
  }

  getTotalPages() {
    return Math.ceil(this.orgsTotal / ORGS_PER_PAGE);
  }

  getCounterpartyId(): string | undefined {
    return this.org?.counterparty.id;
  }

  @Action
  async loadFilters() {
    const data = await this.counterpartyService.getFilteredCounterparties();
    this.setFilters(data);
  }

  @Action
  async loadCounters(params: RequestsHttp) {
    const data = await this.counterpartyService.getCountersCounterparties(params);
    this.setCounters(data);
  }

  @Action
  async loadCarrierStatuses() {
    const data = await this.counterpartyService.getCarrierStatuses();
    this.setCarrierStatus(data);
  }

  @Action
  async loadCarrierReasonSuspended() {
    const data = await this.counterpartyService.getCarrierReasonSuspended();
    this.setReasonSuspendedCarrierStatus(data);
  }

  @Action
  async loadOrgs({
    page,
    localFilters,
  }: RequestsHttp) {
    const data = await this.counterpartyService.getCounterparties({
      page,
      size: 20,
      ...localFilters,
    });

    this.setOrgs(data);
  }

  @Action
  async loadOrg(orgId: string) {
    const org = await this.counterpartyService.getOrg(orgId);

    if (!org.carrier.logist) {
      orgLogistInitial.counterpartyId = org.carrier.id as string;
      org.carrier.logist = orgLogistInitial;
    }

    this.setOrg(org);
  }

  @Action
  async updateOrg(org: OrgModel) {
    await this.counterpartyService.updateOrg(org);
  }

  @Action
  async updateStatusOrg(org: { id: string; statusId: number }) {
    await this.orgService.updateStatusOrg(org);
  }

  @Action
  async checkOrgExistence(params: OrgExistenceDTO) {
    return this.orgService.checkOrgExistence(params);
  }

  @Action
  async sendUserNotice(params: Nullable<OrgExistenceDTO>) {
    return this.counterpartyService.sendUserNotice(params);
  }

  @Action
  async loadStatuses() {
    const statuses = await this.orgService.getOrgStatuses();
    this.setStatuses(statuses);
  }

  @Action
  async loadCarrierTypes() {
    const types = await this.organizationsService.getCarrierTypes();
    this.setCarrierTypes(types);
  }

  @Action
  async loadShippingTypes() {
    const types = await this.organizationsService.getShippingTypes();
    this.setShippingTypes(types);
  }

  @Action
  async loadDevelopmentStatuses() {
    const statuses = await this.organizationsService.getDevelopmentStatuses();
    this.setDevelopmentStatuses(statuses);
  }

  @Action
  async loadCommercialRatings() {
    const ratings = await this.organizationsService.getCommercialRatings();
    this.setCommercialRatings(ratings);
  }

  @Action
  async loadNdsTypes() {
    const ndsTypes = await this.organizationsService.getNdsTypes();
    this.setNdsTypes(ndsTypes);
  }

  @Action
  async loadLegalForms() {
    if (!this.legalForms.length) {
      const legalForms = await this.organizationsService.getLegalForms();
      this.setLegalForms(legalForms);
    }
  }

  @Action
  async loadExpeditors(): Promise<void> {
    const expeditors = await this.userService.getUsersInRole(this.configService.expeditionRoleId);
    this.setExpeditors(expeditors);
  }

  @Action
  async loadCarrierAttracts(): Promise<void> {
    const attracts = await this.userService.getUsersInRole(this.configService.attractionManagerRoleId);
    this.setCarrierAttracts(attracts);
  }

  @Action
  async loadCarrierMPP({
    orgId,
    checkCarrierEmployeeRequest,
  }: LoadCarrierMPPParams): Promise<void> {
    const carrierMPP = await this.counterpartyService
      .getUsersByCarrier(orgId, stringToConstantCase(MPP), checkCarrierEmployeeRequest);
    this.setCarrierMPP(carrierMPP[0]);
  }

  @Action
  async loadCarrierMAP(orgId: string): Promise<void> {
    const carrierOMAP = await this.counterpartyService
      .getUsersByCarrier(orgId, stringToConstantCase(OMAP));
    this.setCarrierOMAP(carrierOMAP[0]);
  }

  @Action
  async orgsTopicSubscribe() {
    await this.websocketModule.subscribeTopicV2({
      destination: WS_TOPICS_ADDRESS_ORG,
      messageCallback: this.updateWebsocketRequest,
      subscriptionId: 'topicCarrier',
    });
  }

  @Action
  async orgTopicSubscribeId(orgId: string) {
    await this.websocketModule.subscribeTopicV2({
      destination: WS_TOPICS_ADDRESS_ORG_ID(orgId),
      messageCallback: this.updateWebsocketRequestOrg,
      subscriptionId: orgId,
    });
  }

  @Action
  async updateManager(data: UpdateManagerParams) {
    await this.userService.setUserCounterpartyRel({
      ...data.filter,
    });
    this.setCarrierOMAP(data?.expeditor);
  }

  @Action
  async updateManagerAttract(data: UpdateManagerParams) {
    await this.userService.setUserCounterpartyRel({
      ...data.filter,
    });
    this.setCarrierMPP(data?.attract);
  }

  updateWebsocketRequest({ body }: IMessage) {
    const orgs = JSON.parse(body) as OrderAuctionShort;
    this.updateStatusMessage(orgs);
  }

  updateWebsocketRequestOrg({ body }: IMessage) {
    const org = JSON.parse(body) as OrderAuctionShort;
    this.updateStatusMessageOrg(org);
  }

  @Mutation
  updateStatusMessage({ message }: OrderAuctionShort) {
    this.statusMessage = message;
  }

  @Mutation
  updateStatusMessageOrg({ message }: OrderAuctionShort) {
    this.statusMessageOrg = message;
  }

  @Mutation
  setFilters(data: FilterModel) {
    this.orgsFilters = data;
    this.legalForms = data.legalForm;
  }

  @Mutation
  setCounters(data: CounterModel) {
    this.orgsCounters = data;
  }

  @Mutation
  setCarrierStatus(data: DictionaryModel[]) {
    this.carrierStatuses = data;
  }

  @Mutation
  setReasonSuspendedCarrierStatus(data: DictionaryModel[]) {
    this.reasonSuspendedCarrierStatus = data;
  }

  @Mutation
  setExpeditors(expeditors: UserModel[]) {
    this.expeditors = expeditors;
  }

  @Mutation
  setCarrierAttracts(attracts: UserModel[]) {
    this.carrierAttracts = attracts;
  }

  @Mutation
  setCarrierMPP(user: UserModel) {
    this.carrierMPP = user;
  }

  @Mutation
  setCarrierOMAP(user: UserModel) {
    this.carrierOMAP = user;
  }

  @Mutation
  setOrg(org?: OrgModel) {
    this.org = org;
  }

  @Mutation
  setOrgs(orgsResponse: OrgsWithFilterModel) {
    this.orgs = orgsResponse.content;
    this.orgsOffset = orgsResponse.pageable.offset + ORGS_PER_PAGE;
    this.orgsTotal = orgsResponse.totalElements;
  }

  @Mutation
  setStatuses(statuses: DictionaryModel[]) {
    this.statuses = statuses;
  }

  @Mutation
  setCarrierTypes(carrierTypes: DictionaryModel[]) {
    this.carrierTypes = carrierTypes;
  }

  @Mutation
  setShippingTypes(shippingTypes: DictionaryModel[]) {
    this.shippingTypes = shippingTypes;
  }

  @Mutation
  setDevelopmentStatuses(developmentStatuses: DictionaryModel[]) {
    this.developmentStatuses = developmentStatuses;
  }

  @Mutation
  setCommercialRatings(commercialRatings: DictionaryModel[]) {
    this.commercialRatings = commercialRatings;
  }

  @Mutation
  setNdsTypes(ndsTypes: DictionaryModel[]) {
    this.ndsTypes = ndsTypes;
  }

  @Mutation
  setLegalForms(legalForms: DictionaryModel[]) {
    this.legalForms = legalForms;
  }

  @Mutation
  setCarriers(carriers: CarrierType[]) {
    this.carriers = [...carriers];
  }
}

container.bind<SRMOrgModule>(SRM_ORG_MODULE_ID).to(SRMOrgModule).inSingletonScope();
