<template>
  <div
    :class="classes"
    :data-qa="dataQa"
    @click="onClick"
  >
    <div
      v-if="isHavePrependedContent"
      :class="$style.prepend"
    >
      <slot name="prepend">
        <svg-icon
          :class="$style.leftIcon"
          :name="leftIcon"
          :svg-use-data-qa="searchSvgUseDataQa"
        />
      </slot>
    </div>
    <div :class="$style.control">
      <input
        v-bind="$attrs"
        :id="internalId"
        ref="input"
        v-mask="mask"
        data-testid="UiInputText"
        :class="$style.input"
        :value="inputValue"
        :disabled="disabled"
        :type="type"
        :data-qa="inputDataQa"
        @input="onInput"
        @accept="onAccept"
        @complete="onComplete"
        @change="onChange"
        @blur="onBlur"
      >
    </div>
    <div
      v-if="isHaveAppendedContent"
      :class="$style.append"
    >
      <slot name="append">
        <svg-icon
          v-if="rightIcon"
          :class="rightIconClasses"
          :name="rightIcon"
          @click="$emit('clean')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/features/shared/kit/SvgIcon.vue';
import inputMixin from '@/features/shared/kit/mixins/input';

export default {
  name: 'UiInputText',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  props: {
    dataQa: {
      type: String,
      default: null,
    },

    inputDataQa: {
      type: String,
      default: null,
    },

    searchSvgUseDataQa: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },
  },

  emits: ['update:modelValue', 'clean', 'change'],

  data() {
    return {
      isInputEvent: false,
      maskedValue: this.modelValue,
    };
  },

  computed: {
    inputValue() {
      return this.mask ? this.maskedValue : this.modelValue;
    },

    internalId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `input-${this._uid}`;
    },

    rightIconClasses() {
      return {
        [this.$style.rightIcon]: true,
        [this.$style.hover]: this.rightIcon === 'close',
      };
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!this.isInputEvent) {
          this.maskedValue = value;
        }
        this.isInputEvent = false;
      },
    },
  },

  methods: {
    onInput(e) {
      if (this.type === 'number') {
        const symbols = ['e', '+', '-'];
        if (symbols.includes(e.data)) e.target.value = this.inputValue;
      }
      if (!this.mask) {
        this.$emit('update:modelValue', e.target.value);
      } else {
        this.isInputEvent = true;
      }
    },

    onAccept(e) {
      this.maskedValue = e.detail.value;
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },

    onComplete(e) {
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },

    onChange(e) {
      this.$emit('change', e);
    },

    onBlur(e) {
      this.$emit('blur', e);
    },
  },
};
</script>

<style module>
.container {
  display: flex;
  flex-flow: row;
  cursor: text;

  background-clip: padding-box;
  border-radius: var(--border-radius-base);
  box-shadow: inset 0 0 0 1px var(--color-gray-300);
  background-color: var(--color-white-100);

  height: var(--size-form-element-height);
  padding: 2px 10px 2px;

  transition: box-shadow var(--timing-input-animation) ease-in-out;
}

.container.isDisabled {
  opacity: 0.33;
}

.container.isInvalid {
  box-shadow: inset 0 0 0 1px var(--color-red-400);
}

.container:not(.isDisabled):hover {
  box-shadow: inset 0 0 0 1px var(--color-black-80);
}

.container:not(.isDisabled).isFocused {
  box-shadow: inset 0 0 0 2px var(--color-navy-500);
}

.container:not(.isDisabled).isInvalid:hover {
  box-shadow: inset 0 0 0 1px var(--color-red-400);
}

.container:not(.isDisabled).isInvalid .isFocused {
  box-shadow: inset 0 0 0 2px var(--color-red-400);
}

.control {
  display: flex;
  flex: 1 1 auto;
}

.input {
  font: inherit;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  max-width: 100%;

  padding: 5px 0;
  border-radius: var(--border-radius-base);

  font-size: var(--font-size-base);
  line-height: var(--line-height-small);
  color: var(--color-black-100);
}

.input::placeholder {
  color: var(--color-black-65);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-white-100) inset;
}

.prepend {
  margin-right: 10px;
  margin-left: -2px;
}

.append {
  margin-left: 10px;
  margin-right: -2px;
}

.prepend,
.append {
  display: inline-flex;
}

.leftIcon,
.rightIcon {
  width: 24px;
  height: 24px;
  align-self: center;
  color: var(--color-black-65);
}
.hover{
  cursor: pointer;
}
</style>
